
<div align="end" style="position: relative;right: 10px;bottom: 10px;">
  <!-- <button  mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button> -->
  <mat-icon class="material-icons-outlined" mat-button [mat-dialog-close]="true" style="color:red;font-size: 22px;position: absolute;" cdkFocusInitial>highlight_off</mat-icon>
</div>
<h1 mat-dialog-title class="title">
  <span class="material-icons icon" style="transform: translateY(3px);font-size: 17px;">place</span>
  <span class="header">Location Details</span>
</h1>
<div mat-dialog-content>
  <div class="table-responsive">
    <table class="table">
        <thead >
          <tr>
            <th class="text-center">S.No</th>
            <th class="text-center">Country</th>
            <th class="text-center">From&nbsp;Location</th>
            <th class="text-center">To&nbsp;Location</th>
            <th class="text-center">Preferred&nbsp;Time</th>
            <th class="text-center">Travel&nbsp;Mode</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data; let i = index">
              <td class="text-center">{{i+1}}</td>
              <td class="text-center">{{item.country}}</td>
              <td class="text-center">{{item.fromLocation}}</td>
              <td class="text-center">{{item.toLocation}}</td>
              <td class="text-center">{{item.preferredTime }}</td>
              <td class="text-center">{{item.travelMode.travelModeName}}</td>
            </tr>
        </tbody>
    </table>
  </div>
</div>

<style>
.title{
  background: linear-gradient(180deg, #3A94FF 0%, #006BEA 100%);
  border: 1px solid #EEEEEE;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  border-radius: 5px 5px 0px 0px;
  width: fit-content;
  font-size: 14px;
  color: white;
  padding: 0 10px;
  margin: 0;
  margin-left: 10px;
  font-family: 'Poppins', sans-serif !important;
}
.table{
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.table thead tr{
  background: #F6F6F6;
  border-radius: 5px 5px 0px 0px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif !important;
  line-height: 15px;
  color: #595959;
  font-weight: 500;
}
.table tbody tr{
  font-size: 12px;
  font-family: 'Poppins', sans-serif !important;
}
.table thead th {
  border-bottom: 0px solid #dee2e6 !important;
}
.table th{
  border-top: 0px solid #dee2e6 !important;
}
</style>
