import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'employeepipe'
})
export class EmployeepipePipe implements PipeTransform {

  transform(value: any, args?: any): any {
  console.log(value);
    if(!value)return null;
    if(!args)return value;

    args = args.toLowerCase();

    return value.filter(function(item){
        return JSON.stringify(item.getRawValue()).toLowerCase().includes(args);
    });
  }
}
