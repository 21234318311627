import { AfterViewInit, Component, OnInit, ViewChild, Inject, ErrorHandler } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { EmployeeListDataSource, EmployeeListItem } from './employee-list-datasource';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserType } from 'src/app/model/usertype.model';

export interface DialogData {
  leaveType: any;
  leaveRequestId: any;
}

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
  list_count = 0;
  data: any[] = [];
  nodata=false;
  isHr: boolean
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['sno','employeeNumber', 'employeeName', 'organizationEmail', 'contact', 'designation', 'userType', 'status', 'star'];
  dataSource: any;
  isData = 0;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog) {
  }
  ngOnInit() {
    this.isHr = false
    let x = JSON.parse(localStorage.getItem('data'));

    this.gs.getEmpListById(x.employeeId).subscribe(
      (data: any) => {
        this.loader=false;
        if (data.userType.userType == 'HumanResource') {
          this.isHr = true;
        }
      }
    )
    this.fetchdata();
  }
  // status color
  statuscolor(i:any) {
    if (i == null) {
      return
    }
    if (i == 'Active') {
      return '#28a745'
    }
    if (i == 'In-Active') {
      return '#f44336'
    }
  }
  fetchdata(){

    this.gs.getEmpList().subscribe((d: any) => {
      console.log("success");
      this.loader=false;
      this.gs.getListTypeById(4).subscribe(
        (e: any) => {
          this.loader=false;
          for (let i = 0; i < e.length; i++) {
            d.forEach(element => {
              if (element.designation == e[i].listTypeValueId) {
                element.designation = e[i].listTypeValueName
              }
              if (element.status == 1) {
                element.status = 'Active'
              }
              if (element.status == 2) {
                element.status = 'In-Active'
              }
            });
          }
        },
      )
      if(d.length==0)
      {
        this.nodata =true;
        console.log("NoData");
      }
      this.data = d;
      this.isData = this.data.length;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
      this.list_count = this.data.length;
    },
    
    (error) =>
    {
      this.loader=false;
      this.nodata= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.nodata=true;
         console.log("204");
      }
    }
    )
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  edit(i) {
    this.router.navigate(['../../employee-edit', i.employeeId])
  }

  statusupdate(i){
    console.log(i);
    console.log(i.status=='Active');
    if(i.status=='Active'){
      Swal.fire({
        title: 'Are you sure? Do you want to in-activate',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3f51b5',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'In-Active'
      }).then((result) => {
        if (result.value) {
          this.gs.employeestatusupdate(i.employeeId,2).subscribe(d => {
            // Swal.fire(
            //   'done!',
            // )
            // this.data.splice(i, 1)
            // this.dataSource = new MatTableDataSource<any>(this.data);
            this.fetchdata();
          },
            () => {
              setTimeout(
                e => {
                  this.data
                }, 50
              )
            }
          );
        }
      })
    }
    else if (i.status=='In-Active'){
      Swal.fire({
        title: 'Are you sure? Do you want to Activate',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3f51b5',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'Active'
      }).then((result) => {
        if (result.value) {
          this.gs.employeestatusupdate(i.employeeId,1).subscribe(d => {
            // Swal.fire(
            //   'done!',
            // )
            // this.data.splice(i, 1)
            //this.dataSource = new MatTableDataSource<any>(this.data);
            this.fetchdata();
          },
            () => {
              setTimeout(
                e => {
                  this.data
                }, 50
              )
            }
          );
        }
      })
    }
    

  }

  delete(i) {
    Swal.fire({
      title: 'Are you sure? Do you want to delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        this.gs.deleteEmployee(i.employeeId).subscribe(d => {
          Swal.fire(
            'done!',
          )
          this.data.splice(i, 1)
          this.dataSource = new MatTableDataSource<any>(this.data);
        },
          () => {
            setTimeout(
              e => {
                this.data
              }, 50
            )
          }
        );
      }
    })
  }

  // dialog
  openDialog(i) {
    let id = i.employeeId;
    let leave_list_ofEmp: any = []
    this.gs.getMyleave(id).subscribe((a: any) => {
      a.forEach(element => {
        leave_list_ofEmp.push(element)
      });
    })
    let v = leave_list_ofEmp
    const x = this.dialog.open(DialogDataExampleDialog, {
      data: v
    });
    x.afterClosed().subscribe(result => {
      //
    });
  }
}

@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialog {
  isData: boolean
  x = this.data
  displayedColumns: string[] = ['leaveRequestId'];
  dataSource = this.data;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any[]) {

    this.isData = false
    if (this.x.length > 0) {
      this.isData = true
    }
  }

  test(i) {
    if (i.leaveStatusName == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.leaveStatusName == 'Denied') {
      return '#f44336'
    }
    if (i.leaveStatusName == 'Withdrawn') {
      return '#20B2AA'
    }
    if (i.leaveStatusName == 'Cancelled') {
      return '#e40000'
    }
  }
}
