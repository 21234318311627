<span>
  <h4 class="dynamic-title">Apply Leave</h4>
</span>
<div class="col-sm-9">
  <mat-card>
    <!-- <mat-card class="header">
      <p><span class="material-icons"> perm_identity </span>Apply Leave</p>
    </mat-card> -->
    <ng-container *ngIf="isLoading; else elseTemplate">
      <div class="loading">
        <h4 class="text-muted">loading....</h4>
        <br />
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <form (ngSubmit)="onSubmit(leaveForm.value)" [formGroup]="leaveForm">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">*Enter details to apply Leave*</h3>
          </div>
        </div>

        <!-- employeeName -->
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Employee Name</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>account_box</mat-icon>
            <input matInput type="text" formControlName="employeeName"
              [ngClass]="{ 'is-invalid': submitted && f.employeeName.errors }" readonly />
            <div *ngIf="submitted && f.employeeName.errors" class="invalid-feedback">
              <div *ngIf="f.employeeName.errors.required">
                employee_Name is required
              </div>
            </div>
          </mat-form-field>
        </div>

        <!-- employeeEmail -->
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Email-id</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>email</mat-icon>
            <input matInput type="text" formControlName="email" readonly />
          </mat-form-field>
        </div>

        <!-- leaveType -->
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Leave Type</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>person</mat-icon>
            <mat-select placeholder="Select Leave Type" formControlName="leaveType" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let t of type; let i = index" value="{{ t.leaveType.leaveTypeId }}">
                {{ t.leaveType.leaveName }} <em> * </em>bal: {{ t.noOfDays }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.leaveType.hasError('required')">
              Please select <strong>Leave Type</strong>
            </mat-error>
          </mat-form-field>
        </div>

          <div class="row">
            <!-- startDate -->
            <div class="col-md-4">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">From Date</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>today</mat-icon>
                  <input matInput (click)="picker.open()" [matDatepickerFilter]="myFilter" (dateInput)="nofoDays($event)"
                    (dateChange)="someMethodName($event)" [errorStateMatcher]="matcher"
                    formControlName="startDate"  
                    [matDatepicker]="picker" placeholder="select start date" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker touchUi></mat-datepicker>
                  <mat-error *ngIf="f.startDate.hasError('required')">
                    Please select <strong>From Date</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
             <!-- endDate -->
            <div class="col-md-4">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">To Date</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>today</mat-icon>
                  <input matInput (click)="a.open()" [matDatepickerFilter]="myFilter" (dateInput)="nofoDays($event)"
                    [min]="min" [errorStateMatcher]="matcher" formControlName="endDate" [matDatepicker]="a"
                    placeholder="select end date" placeholder="select end date" [disabled]="endDate_disable" />
                  <mat-datepicker-toggle matSuffix [for]="a"></mat-datepicker-toggle>
                  <mat-datepicker #a touchUi></mat-datepicker>
                  <mat-error *ngIf="f.endDate.hasError('required')">
                    Please select <strong>To Date</strong>
                  </mat-error>
                  <mat-error *ngIf="show_error_msg">
                    To date should be greater than from date.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-4">
              <ng-container *ngIf="halfDay;else weekDay">
                <!-- Half Day -->
                <mat-form-field class="example-full-width" appearance="outline" *ngIf="halfDay" id="validateNums">
                  <mat-label class="form-label">No.of Days</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>today</mat-icon>
                  <mat-select formControlName="noOfDays">
                    <mat-option value="0.5">0.5</mat-option>
                    <mat-option value="1">1</mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.noOfDays.hasError('required')">
                    Please select <strong>Number Of Days</strong>
                  </mat-error>
                </mat-form-field>
              </ng-container>
      
              <ng-template #weekDay>
                <!-- nofoDays -->
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">No.of Days</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>today</mat-icon>
                  <input type="text" matInput [value]="noOFDays" readonly />
                </mat-form-field>
              </ng-template>
            </div>
        </div>


        <!-- apply leave -->
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label class="form-label">First Approver</mat-label>
          <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>mail</mat-icon>
          <input type="text" matInput [formControl]="applyToControl" [errorStateMatcher]="matcher" readonly />
          <mat-error *ngIf="applyToControl.hasError('required')">
            Please select <strong>Approver 1</strong>
          </mat-error>
        </mat-form-field>

        <!-- fyi -->
        <div class="">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Second Approver</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>account_box</mat-icon>
            <input matInput type="text" [formControl]="fyi" readonly />
          </mat-form-field>
        </div>
        <div class="form-group">
          <mat-form-field class="example-full-width form-textarea" appearance="outline">
            <mat-label class="form-label">Reason for Leave</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b;transform: translateY(15px)" matPrefix>insert_comment</mat-icon>
            <textarea matInput type="text" formControlName="leaveReason" placeholder="Enter leave reason!!!!"
              [errorStateMatcher]="matcher" rows="2"></textarea>
            <mat-error *ngIf="f.leaveReason.hasError('required')">
              Please enter <strong>leave reason</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- button -->
        <div class="form-group">
          <div class="d-flex">
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="cancel-button" color="accent" type="button" (click)="Cancel()">
                <span class="material-icons-outlined cancel-button-icon">close</span>
                Cancel
              </button>
            </div>
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="apply-button" color="primary" type="submit">
                <span class="material-icons-outlined apply-button-icon">done</span>
                &nbsp;Apply&nbsp;
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </mat-card>
</div>
