import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EmployeeTimeSettingsComponent } from '../employee-time-settings/employee-time-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsettingsComponent } from '../permissionsettings/permissionsettings.component';
@Component({
  selector: 'app-employee-time-settings-list',
  templateUrl: './employee-time-settings-list.component.html',
  styleUrls: ['./employee-time-settings-list.component.css']
})
export class EmployeeTimeSettingsListComponent implements OnInit {

  settingslist : any = [];
  constructor(private GlobalserviceService : GlobalserviceService,
    private httpClient:HttpClient,
    private router:Router,
    private dialog:MatDialog) { }

  ngOnInit(): void {

    this.GlobalserviceService.getsettings().subscribe
    ((data:any)=>
    {
      this.settingslist = data;
    })
  }
  edit(settingsId){
    console.log("create");
    const dialogbox = this.dialog.open(EmployeeTimeSettingsComponent,{
      width:'400px',
      height:'fit-content',
      data : settingsId
    })

    dialogbox.afterClosed().subscribe(
      (res)=>{
        console.log(res);
         if(res)
         {
           if(res.statusCode == 200)
           {
            setTimeout(() => {
              this.GlobalserviceService.getsettings().subscribe
              ((data:any)=>{
                this.settingslist = data;
              })
            }, 2000)
           }
         }
      }
    );
  }
  editPermission(settingsId){
    console.log("create");
    const dialogRef = this.dialog.open(PermissionsettingsComponent,{
      width:'400px',
      height:'fit-content',
      data : settingsId
    })
    dialogRef.afterClosed().subscribe(
      (res)=>{
        console.log(res);
         if(res)
         {
           if(res.statusCode == 200)
           {
            setTimeout(() => {
              this.GlobalserviceService.getsettings().subscribe
              ((data:any)=>{
                this.settingslist = data;
              })
            }, 2000)
           }
         }
      }
    );
  }

}
