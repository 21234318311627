<span>
  <h4 class="dynamic-title">Leave Type List</h4>
</span>
<!-- <ng-container *ngIf="loader; else elseLoading">
  <div  class="loading" align="center"> 
    <img src="assets/img/Loader3.gif" alt="LoaderImage">
  </div>
</ng-container>  -->

<ng-container *ngIf="loader; else elseLoading" class="loading">
  <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 


<ng-template #elseLoading>
<div class="container-fluid" style="padding: 5px 15px 0px 15px;">
  <div class="float-left">
    <!-- <button mat-raised-button  style="transform: translateY(10px);" [routerLink]="['../type-add']">
      <mat-icon>add_circle_outline</mat-icon>Create Leave_Type
    </button> -->
    <button mat-stroked-button class="apply-button"  [routerLink]="['../type-add']" color="primary">
    <mat-icon class="material-icons-outlined apply-button-icon">add_circle_outline</mat-icon>
    Add Leave Type</button>
  </div>
    <div class="float-right">
      <!-- <button mat-raised-button>
        <mat-icon>search</mat-icon>
      </button>
      <mat-form-field style="line-height: 1.6;margin: 0 0 0 8px; ">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field> -->
      <div class="form-group search-input topnav-right">                                                                     
        <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
        <button mat-raised-button class="search-btn">
          <mat-icon class="search-icon">search</mat-icon>
        </button>                                                                                                  
      </div>
    </div>
    <span> </span>
  </div>
  
  <ng-container>
    <div class="example-container mat-elevation-z8 main-block">
      <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count > 0">
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            <b>S.No</b>
          </th>
          <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}</td>
        </ng-container> 
        <ng-container matColumnDef="leaveName" >
          <th mat-header-cell *matHeaderCellDef> <b>
            Leave&nbsp;Type&nbsp;Name
          </b> </th>
          <td mat-cell *matCellDef="let element"> {{element.leaveName | titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="noOfDays" >
          <th mat-header-cell *matHeaderCellDef class="text-center">
          <b>No&nbsp;of&nbsp;Days</b>
          </th>
          <td mat-cell *matCellDef="let element" class="text-center"> {{element.noOfDays}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> <b>Status</b> </th>
          <td mat-cell *matCellDef="let element" [style.color]="statuscolor(element.status)"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="star">
          <th mat-header-cell *matHeaderCellDef class="text-center"></th>
          <td mat-cell *matCellDef="let element" class="text-center">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <a mat-menu-item (click)="edit(element)">
                    <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#1A83FF">create</mat-icon>
                    <span class="cancel-trip-text" style="color:#1A83FF">Edit</span>
                  </a>
                  <a mat-menu-item (click)="inactive(element)">
                    <mat-icon *ngIf="element.status=='Active'" class="cancel-trip-icon material-icons-outlined" style="color:#FF7171">block</mat-icon>
                    <span *ngIf="element.status=='Active'"class="cancel-trip-text" style="color:#FF7171">In-Active</span>
                    <mat-icon *ngIf="element.status=='In-Active'" class="cancel-trip-icon material-icons-outlined" style="color:#01E325">done</mat-icon>
                    <span *ngIf="element.status=='In-Active'" class="cancel-trip-text" style="color:#01E325">Active</span>
                  </a> 
                  <!-- <a mat-menu-item (click)="inactive(element)">
                    <mat-icon>delete</mat-icon>
                    <span>In-Active</span>
                  </a> -->
                </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="container" *ngIf="noData">
        <div class="alert alert-primary" align="center" style="width: 100%;height: 50px;">
              No Leave Type List
        </div>
      </div>
    </div>
   
  </ng-container>
</ng-template>

<div [hidden]="loader">
  <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>
 

    

  