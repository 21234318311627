import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {url} from '../providers/properties'
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router)
  { }

  logintest(username: string, password: string) {
    return this
      .http
      .post(`${url}user_validation`, { organizationEmail: username, password: password } );
  }
}
