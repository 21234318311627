<style>
  .text{
    font-size: 12px;
    padding: 8px 0px;
  }
  .icons{
    font-size: 18px;
    color: gray;
    margin: 0px 8px 0px 0px;
    padding: 0px;
    transform: translateY(5px);
    font-size: 18px;
    color: #595959;
  }
</style>
<div align="end" style="position: relative;right: 10px;bottom: 10px;">
  <!-- <button  mat-button [mat-dialog-close]="true" cdkFocusInitial>Ok</button> -->
  <mat-icon class="material-icons-outlined icons" mat-button [mat-dialog-close]="true" style="color:red;font-size: 22px;position: absolute;transform: translate(-2px ,-2px);" cdkFocusInitial>highlight_off</mat-icon>
</div>
<h2 mat-dialog-title>Withdraw Details</h2>
<mat-dialog-content class="mat-typography">
  <div class="row text">
    <div class="col-md-6">
      <span> 
        <span class="material-icons-outlined icons" >today</span> 
        <b>Start_Date</b>
         : {{withdraw_details.leaveWithdraw.startDate | date}}
      </span>
    </div>
    <div class="col-md-6">
      <span> 
        <span class="material-icons-outlined icons">today</span> <b>End_Date</b> : {{withdraw_details.leaveWithdraw.endDate | date}}
      </span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="row text">
    <div class="col-md-6">
      <span > 
        <span class="material-icons-outlined icons">local_library</span> <b>Status</b> : {{withdraw_details.leaveWithdraw.withdrawStatus }}
      </span>
    </div>
    <div class="col-md-6">
      <span> 
        <span class="material-icons-outlined icons">timelapse</span> <b>No_Of_Days</b> : {{withdraw_details.leaveWithdraw.withdrawDays }}
      </span>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="alert alert-primary text">
    <div class="row">
      <div class="col-md-12">
        <p style="font-size: 12px;padding: 0 10px;">
          <!-- <span class="material-icons">description</span>  -->
          <b style="font-size: 13px;">Reason</b><br>
          &nbsp;&nbsp;&nbsp;{{withdraw_details.leaveWithdraw.withdrawReason}}
        </p>
      </div>
    </div>
  </div>

</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>OK</button>
</mat-dialog-actions> -->
