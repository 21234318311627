<h2 mat-dialog-title style="color: #000000;margin-bottom:10px;">Change Profile Image</h2>
<mat-dialog-content>
    <!-- <form action="" [formGroup]="ProfileEditForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline">
        <mat-label>NAME</mat-label>
        <input type="text" matInput formControlName="employeeName">
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="outline">
        <mat-label>GENDER</mat-label>
      <mat-select formControlName="gender">
          <mat-option value = "Male">
            Male
          </mat-option>
          <mat-option value = "Female">
            FEMALE
          </mat-option>
      </mat-select>
      </mat-form-field>
      <br>
      <input type="file" >
      <br>
      <br>
      <mat-form-field appearance="outline">
        <mat-label>PHONE_NUMBER</mat-label>
        <input type="text" matInput formControlName="contact">
      </mat-form-field>
      &nbsp;
      <mat-form-field appearance="outline">
        <mat-label>PERSONAL_MAIL-ID</mat-label>
        <input type="text" matInput formControlName="personalEmail">
      </mat-form-field>
      <br>pro
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>STATUS</mat-label>
        <mat-select formControlName="status">
          <mat-option value = "Active">
            Active
          </mat-option>
          <mat-option value = "InActive">
            InActive
          </mat-option>
      </mat-select>
      </mat-form-field>
    </form> --> 
    <input type="file" accept='image/jpeg,image/png,image/x-eps' (change)="onSelectFile($event)">
    <img [src]="url" height="100px" width="100px" class="profile">


</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 10px;margin-bottom: -15px;">
    <button mat-button mat-dialog-close [mat-dialog-close]="true" style="color: #FF7575;">Cancel</button>
    <button mat-button type="submit" [mat-dialog-close]="false" style="color: #279600;">Save</button>
</mat-dialog-actions>


<style>
  .profile{
    height: 125px;
    width: 125px;
    border-radius: 50%;
    flex-shrink: 0;
    -o-object-fit: cover;
    object-fit: cover;
    /* bottom: 20px; */
    position: relative;
    border: 2px solid rgb(30, 121, 224);
    letter-spacing: 0.005em;
    margin: 10px;
  }
  *,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border:0px;
}
input[type="file"] {
    outline: 0;
}
input[type="file"]::before {
  content: 'Choose File';
  display: inline-block;
  border: 1px solid white;
  padding: 7px 11px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  font-weight: 700;
  font-size: 10pt;
  color: #000000;
  background: #D9D9D9;
  border-radius: 5px;
  letter-spacing: 0.005em;
}
input[type="file"] {
  color: #A6A6A6;
  letter-spacing: 0.005em;
}
input[type="file"]::-webkit-file-upload-button {
  display:none;
}
button:hover{
  outline: 0;
}
</style>