<div style="height: 100%;overflow: hidden;">
    <html>
    <body class="container-fluid login-background">   
        <div class="card-body login-card-body card-background">
            <form [formGroup]="emailForm" (ngSubmit)="submitData()">                  
                <div class="col-md-12 content">
                    <h1 class="login-title">Forget Password</h1>
                </div>                                            
                <div class="">
                    <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label style="font-size: 15px;">Mail-Id</mat-label>
                        <mat-icon style="font-size: 22px;" matPrefix>account_box</mat-icon>
                        <input matInput type="text" formControlName="organizationEmail" placeholder="Enter Email">
                    </mat-form-field>
                    <div class="valid-msg" *ngIf="(organizationEmail.invalid && organizationEmail.touched) || organizationEmail.dirty">
                        <small *ngIf="organizationEmail.errors?.required" class="text-danger">Please enter email</small>
                        <small *ngIf="organizationEmail.errors?.pattern" class="text-danger">Please enter valid email</small>
                    </div>
                </div>                                 
                <div class="form-group">
                    <button [disabled]="emailForm.invalid || btnDisable" type="submit" class="btn btn-primary"> Login</button>
                </div>
            </form>  
        </div>
    </body>
</html>
<div *ngIf="backdrop" class="cdk-overlay-backdrop dark-backdrop cdk-overlay-backdrop-showing"></div>
<div *ngIf="loader" class="align-items-center text-center" style="position: relative; bottom: 60vh; z-index: 2;">
    <div class="spinner-border align-self-center" style="width: 3rem; height: 3rem;" role="status"></div>
</div>  
<!-- <div class="text-center" *ngIf="success"> 
    <div class="alert alert-success" role="alert">
        <strong>Success!</strong><br>{{create_sucess_msg}}
    </div>
</div>
<div *ngIf="error" class="alert alert-danger alert-dismissible">
    <strong>Error!</strong><br>{{update_data_error_msg}}
</div> -->
</div>


