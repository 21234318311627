import { Component, OnInit,Inject} from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder }from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-leavebalancenotifiction',
  templateUrl: './leavebalancenotifiction.component.html',
  styleUrls: ['./leavebalancenotifiction.component.css']
})
export class LeavebalancenotifictionComponent implements OnInit {
  // isData: boolean
  nodata:boolean=false;
  displayedColumns: string[] = ['EmployeeNumber', 'EmployeeName', 'OrganziationEmail', 'DateofJoing'];
  dataSource: any;
  constructor(
    private activatedRoute : ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog) { }

  ngOnInit(): void 
  {
    console.log(this.data);
    this.dataSource = this.data;
    console.log(this.dataSource);
  }

}
