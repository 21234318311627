import { Injectable, Output, EventEmitter } from '@angular/core';
import { globalTimeOut, url } from './properties';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, timeout } from 'rxjs/operators';
import { UserType } from '../model/usertype.model';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
// import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
// import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GlobalserviceService {



  myFile;
  yearname = new Date().getFullYear();

  constructor(private http: HttpClient) {
    console.log("yearname " + this.yearname);
    console.log("this")
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  //---------------------getmethod sample---------------------------------

  // usertype
  get_usertype() {
    return this.http.get<UserType[]>(`${url}user_type`)
  }
  // profile
  get_profile(id) {
    return this.http.get(`${url}employee_profile/${id}`)
  }
  // get business request based on employee email id

  // getEmployeeBusinessTravelRequest_EmailID(applyTo, categoryId, businesssTravelStatus) {
  //   return this.http.get(`${url}business_travel/${applyTo}/${categoryId}/${businesssTravelStatus}`)
  // }

  getEmployeeBusinessTravelRequest_EmailID(reportingto, businesssTravelStatus) {
    return this.http.get(`${url}business_travel/reportingto/${reportingto}/${businesssTravelStatus}`)
  }

  // leaveType
  getUserTypeById(id) {
    return this.http.get(`${url}leave_type/${id}`)
  }
  getLeaveType() {
    return this.http.get(`${url}leave_type`)
  }

  // employee
  getEmpList() {
    return this.http.get(`${url}employee`)
  }
  // Employee Active List only in drop dowm
  getEmpList1() {
    return this.http.get(`${url}employee/activestatus`)
  }

  getLeaveBalanceById(employeeId) {
    return this.http.get(`${url}leave_balance_of_employee_by_year/${employeeId}/${this.yearname}`)
  }

  getPermissions(orgId) {
    return this.http.get(`${url}permissions/organization/${orgId}`)
  }

  // getEmployee_based_on_reportingTo(mail) {
  //   return this.http.get(`${url}employee/all/${mail}`)
  // }http://192.168.1.218:6001/leavemanagement/employee

  // Business Organization based List
  // http://localhost:6001/leavemanagement/business_travel/organization/1
  getBusinesstravelList(orgId) {
    return this.http.get(`${url}business_travel/organization/${orgId}`)
  }

  getEmployee_based_on_reportingTo(mail) {
    return this.http.get(`${url}employee/all/${mail}`)
  }
  getEmpListById(id): Observable<any> {
    return this.http.get(`${url}employee/${id}`)
  }
  // my leave list
  getMyleave(id) {
    return this.http.get(`${url}leave_request_of_employee/${id}`);
  }
  //  leave request pending
  // getleaveReqPending() {
  //   return this.http.get(`${url}leave_request_pending`);
  // }

  getleaveReqPending(mail) {
    return this.http.get(`${url}leave_request/pending/${mail}`);
  }

  GetMYleaveBalance(id) {
    return this.http.get<any>(`${url}leave_balance_of_employee_by_year/${id}/${this.yearname}`)
  }
  getEmpLEaveList(mail) {
    return this.http.get(`${url}leave_request/all/${mail}`)
  }

  getLeaveBalance() {
    return this.http.get(`${url}leave_balance`)
  }

  getemp_withdraw_list(mail) {
    return this.http.get(`${url}leave_withdraw/pending/${mail}`)
  }

  withdraw_pending_list(mail) {
    return this.http.get(`${url}leave_withdraw/pending/${mail}`)
  }

  get_leave_date(id) {
    return this.http.get(`${url}leave_request_dates/${id}`)
  }

  get_myWfh(empid, categoryId) {
    return this.http.get(`${url}wfh/${empid}/${categoryId}`)
  }
  get_permissionList(empid) {
    return this.http.get(`${url}permissions/empolyee/${empid}`)
  }
  // getWFHRequest(applyTo, categoryId, status) {
  //   return this.http.get(`${url}wfh/${applyTo}/${categoryId}/${status}`)
  // }
  getWFHRequest(reportingTo, status) {
    return this.http.get(`${url}wfh/reportingto/${reportingTo}/${status}`)
  }
  getPermission_Manage(id) {
    return this.http.get(`${url}permissions/pending/reporting/${id}`)
  }
  // get travel mode
  getTravel_Mode() {
    return this.http.get(`${url}travelmode`);
  }
  // get business travel
  getMyBusinessTravel_List(empId, categoryId) {
    return this.http.get(`${url}business_travel/${empId}/${categoryId}`)
  }

  getListTypeById(listTypeId) {
    return this.http.get(`${url}list-type-values/${listTypeId}`)
  }

  // EmployeeType List aOptionList Api
  getListTypeByEmployeeType(val) {
    return this.http.get(`${url}list-type-values/${val}`)
  }

  getAdjustment(id) {
    return this.http.get(`${url}leave_adjustment/organization/${id}`);
  }
  getOrganization() {
    return this.http.get(`${url}organization`);
  }
  getBranch() {
    return this.http.get(`${url}branch`);
  }
  // only Active Branch Status
  getBranchActive(organizationId) {
    return this.http.get(`${url}branch/organization/${organizationId}`);
  }
  getBranchByID(branchid) {
    return this.http.get(`${url}branch/${branchid}`);
  }
  // RequestParam use Api
  // getBranchByID(branchid) {
  //   return this.http.get(`${url}branchs`);
  // }
  getYear() {
    return this.http.get(`${url}year`);
  }

  getYearByYearName() {
    return this.http.get(`${url}year/yearname/${this.yearname}`);
  }
  getYearbyYearId(yearId: any) {
    return this.http.get(`${url}year/${yearId}`);
  }

  getCalendar() {
    return this.http.get(`${url}calendar`);
  }
  getCalendarById(calendarId) {
    return this.http.get(`${url}calendar/${calendarId}`);
  } 
  getCalendarById_Active(calendarId) {
    return this.http.get(`${url}calendar/active_calendar/${calendarId}`);
  }
  getHoliday() {
    return this.http.get(`${url}holiday`);
  }
  getHolidayById(holidayId) {
    return this.http.get(`${url}holiday/${holidayId}`);
  }
  getExceptionById(exceptionId) {
    return this.http.get(`${url}exceptions/${exceptionId}`);
  }
  getHolidayByCalId(calendarId) {
    return this.http.get(`${url}holiday/calendar/${calendarId}`);
  }
  getExceptionByCalId(calendarId) {
    return this.http.get(`${url}holiday/calendar/${calendarId}`);
  }
  getCalByBranch(branchId) {
    return this.http.get(`${url}calendar/branch/${branchId}`);
  }

  //--------------------------------post------------------------------------
  // business apply
  Apply_Business_Travel_Request(data) {
    return this.http.post(`${url}business_travel`, data)
  }

  // action
  //
  createLeaveType(data) {
    return this.http.post(`${url}leave_type`, data)
  }
  // withdraw
  withdraw(data) {
    return this.http.post(`${url}leave_withdraw`, data)
  }
  //leave Balance
  leaveBalance(data) {
    return this.http.post(`${url}multiple/leave_balance`, data)
  }
  // employee
  CreateEmployee(Form: FormData) {
    return this
      .http
      .post(`${url}employee`, Form);
  }
  //calendar
  createCalendar(Form) {
    return this
      .http
      .post(`${url}calendar`, Form)
  }
  //branch
  createBranch(Form) {
    return this
      .http
      .post(`${url}branch`, Form)
  }

  updateBranch(branchId, Branch) {
    return this
      .http
      .put(`${url}branch/${branchId}`, Branch)

  }
  deleteBranch(branchId) {
    return this
      .http
      .delete(`${url}branch/${branchId}`)

  }
  //Working Days
  createWoringDays(Form) {
    return this
      .http
      .post(`${url}calendarworkingdays`, Form)
  }
  addLeave(Form) {
    return this
      .http
      .post(`${url}leave_request`, Form);
  }
  applyPermission(Form) {
    return this
      .http
      .post(`${url}permissions`, Form);
  }
  // wrok from home
  addWfm(Data) {
    return this
      .http
      .post(`${url}wfh`, Data);
  }
  addAdjustment(Data) {
    return this
      .http
      .post(`${url}leave_adjustment`, Data);
  }
  createHoliday(Data: FormData) {
    return this
      .http
      .post(`${url}holiday`, Data);
  }
  createMultipleHoliday(Data) {
    return this
      .http
      .post(`${url}multiple/holidays`, Data);
  }

  createException(Data: FormData) {
    return this
      .http
      .post(`${url}exceptions`, Data);
  }
  createMultipleException(Data) {
    return this
      .http
      .post(`${url}multiple/exceptions`, Data);
  }

  // createMultipleException(Data) {
  //   let urls = `${url}multiple/exceptions`
  //   const options = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     }),
  //     body: JSON.stringify(Data)
  //   }
  //   return this.http.post(urls, options)
  // }



  //--------------------------------update------------------------------------
  //  leave status update
  UpdateLeaveStatus(id, Form) {
    return this
      .http
      .put(`${url}leave_request_status/${id}`, Form);
  }
  updateLeaveType(id, data) {
    return this.http.put(`${url}leave_type/${id}`, data)
  }
  UpdateEmployee(id, data: FormData) {
    return this.http.put(`${url}employee/${id}`, data)
  }

  UpdateEmployeeProfile(id, data) {
    return this.http.put(`${url}employee_image/${id}`, data)
  }

  UpdateHoliday(holidayId, data) {
    return this.http.put(`${url}holiday/${holidayId}`, data)
  }

  UpdateException(exceptionId, data) {
    return this.http.put(`${url}exceptions/${exceptionId}`, data)
  }

  // update Password
  updateAccountSettings(id, Form) {
    return this
      .http
      .put(`${url}change_password/${id}`, Form);
  }

  updateCalendar(calendarId, Form) {
    return this
      .http
      .put(`${url}calendar/${calendarId}`, Form)
  }

  deactiveCalendar(calendarId, Form) {
    return this
      .http
      .put(`${url}calendar/deactivate/${calendarId}`, Form)
  }

  //Woring Days Update
  updateWoringDays(workDayId, Form) {
    return this
      .http
      .put(`${url}calendarworkingdays/${workDayId}`, Form)
  }

  Actionwfh(id, data) {
    return this
      .http
      .put(`${url}wfh_status/${id}`, data);
  }
  AcctionBusiness(id, data) {
    return this
      .http
      .put(`${url}business_travel_status/${id}`, data);
  }

  //--------------------------------delete------------------------------------
  deleteLeaveType(id) {
    return this.http.delete(`${url}leave_type/${id}`)
  }
  deleteEmployee(employeeId: number) {
    return this
      .http
      .delete<any>(`${url}employee/${employeeId}`);
  }
  deleteLeave(leaveId, data) {
    return this
      .http
      .put(`${url}leave_request_status/${leaveId}`, data);
  }

  deleteHoliday(holidayId: number) {
    return this
      .http
      .delete<any>(`${url}holiday/${holidayId}`);
  }
  // +++++++++++++++++++++++++++++ withdraw ++++++++++++++++++++++++++++++++++++++++++++++//

  employee_withdraw_list_action(empId, mail) {
    return this.http.get(`${url}leave_withdraw/employee/${empId}/${mail}`)

  }
  // Withdraw Approved and Denied
  withdrawAction(id, data) {
    return this.http.put(`${url}leave_withdraw_status/${id}`, data)
  }

  Permission_Status(id, data) {
    return this.http.put(`${url}permissions_status/${id}`, data)
  }

  getAll_withdrawList(mail) {
    return this.http.get(`${url}leave_withdraw/all/${mail}`)
  }
  getfolder(folder) {
    return this.http.get<any>('https://www.jespersoft.com:6003/email/subfoldermail/31', folder)
  }
  //Month api consume
  getMonthEmpList(monthyear) {
    return this.http.get<any>(`${url}employee/inout/month-year/${monthyear}`)
    // http://192.168.2.19:6001/leavemanagement/employee/inout/month-year/2022-10
  }
  //Employee In Out Create Api
  employeeInOutCreate(createdata) {
    return this.http.post(`${url}employee/inout/`, createdata)
  }

  //get EmployeeIn Out Details
  Get() {
    // this.http.post({`$url`});
    return this.http.get(`${url}employee/inout/`).
      pipe(map(data => data));
  }
  //findby headerId use Patch Values
  findByheaderId(headerid) {
    return this.http.get(`${url}employee/inout/${headerid}`).
      pipe(map(data => data));
  }
  //Upload  Excel File
  uploadFile(formData) {
    // export const url=`http://192.168.2.57:6001/leavemanagement/`; 
    // localhost:6001/leavemanagement/employee/inout/import/uploadfile
    return this.http.post(`${url}employee/inout/import/uploadfile`, formData)
    // pipe(map(data=>data));
  }

  //update api in Employee in out
  updateEmployeeInOut(headerid, updatedata) {
    return this.http.put(`${url}employee/inout/${headerid}`, updatedata).
      pipe(map(data => data));
  }

  //Filter in Month Based in List
  //  http://localhost:6001/leavemanagement/employee/inout/month/2022-09
  GetMonth(month) {
    // this.http.post({`$url`});
    return this.http.get(`${url}employee/inout/month/${month}`).
      pipe(map(data => data));
  }
  //get StartDate and EndDate Filter in List
  //  http://localhost:6001/leavemanagement/employee/inout/date/2022-09-09/2022-10-09
  GetDate(start, end) {
    // this.http.post({`$url`});
    return this.http.get(`${url}employee/inout/date/${start}/${end}`).
      pipe(map(data => data));
  }
  // http://localhost:6001/leavemanagement/employee/inout/attendance/2022-01-01/2022-01-03
  // Employee startdate endate List
  GetAttendenceList(start, end) {
    // this.http.post({`$url`});
    return this.http.get(`${url}employee/inout/attendance/${start}/${end}`).
      pipe(map(data => data));
  }

  getsettings() {
    return this.http.get(`${url}settings`).
      pipe(map(data => data));
  }

  getsettingsbyId(data: any) {
    return this.http.get(`${url}settings/${data}`).
      pipe(map(data => data));
  }

  updatesettings(settingsobj: any) {
    return this.http.put(`${url}settings/${settingsobj.settingsId}`, settingsobj).
      pipe(map(data => data));
  }

  //------------------ update employee status --------------
  employeestatusupdate(employeeId: number, statusid: number) {
    return this
      .http
      .put<any>(`${url}employee/deactive/${employeeId}/${statusid}`, null);
  }
  branchstatusupdate(employeeId: number, statusid: number) {
    return this
      .http
      .put<any>(`${url}branch/deactive/${employeeId}/${statusid}`, null);
  }

  inactiveLeaveType(leaveTypeId: number, statusId: number) {
    return this
      .http
      .put(`${url}leavetype/deactive/${leaveTypeId}/${statusId}`, null);
  }

  activeemployeelist() {
    return this.http.get(`${url}employee/activestatus`);
  }
// Year List
  activeyearlist() {
    return this.http.get(`${url}year`);
  }
  ///// leave report ////
  getLeaveListByOrg(orgId) {
    return this.http.get(`${url}leave_request/organization/${orgId}`)
  }
  leavereportfilter(startdate: any, enddate: any, orgId) {
    return this.http.get(`${url}leave_request/organization/${startdate}/${enddate}/${orgId}`)
  }
  leavereportBystartdateenddateempname(startdate: any, enddate: any, employeename: any) {
    return this.http.get(`${url}leave_request/employee/${startdate}/${enddate}/${employeename}`)
  }
  leavereportByempname(employeeId: number) {
    return this.http.get(`${url}leave_request_of_employee/${employeeId}`)
  }
  ////// work from home ////
  getWFHlistByOrg(orgId) {
    return this.http.get(`${url}wfh/organization/${orgId}`)
  }
  getWFHfilters(startdate: any, enddate: any, orgId) {
    return this.http.get(`${url}wfh/organization/${startdate}/${enddate}/${orgId}`)
  }
  WFHBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
    return this.http.get(`${url}wfh/employee/${startdate}/${enddate}/${employeeId}`)
  }
  WFHByEmplId(employeeId: number) {
    return this.http.get(`${url}wfh/${employeeId}/1`)
  }

  ////// business travel  //////////
  // get Filter based like StartDate and EndDate based filter Data in Bsinesstravel
  businessreportfilter(startdate, enddate, orgId) {
    return this.http.get(`${url}business_travel/organization/${startdate}/${enddate}/${orgId}`)
  }
  businesslistBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
    return this.http.get(`${url}business_travel/employee/${startdate}/${enddate}/${employeeId}`)
  }
  businesslistByempId(employeeId: number) {
    return this.http.get(`${url}business_travel/${employeeId}/2`)
  }

  //// permission /////
  // get Filter based like StartDate and EndDate based filter Data in Permission
  filterPermissionList(stDate, edDate, orgId) {
    return this.http.get(`${url}permissions/organization/${stDate}/${edDate}/${orgId}`)
  }
  permissionlistBystartdateenddateempId(startdate: any, enddate: any, employeeId: any) {
    return this.http.get(`${url}permissions/employee/${startdate}/${enddate}/${employeeId}`)
  }
 
  permissionlistByempId(employeeId: number) {
    return this.http.get(`${url}permissions/empolyee/${employeeId}`)
  }
// Leave/Permission Balance Filter
filterBalancePermissionList(min:any, max:any, employeeId:number) {
  return this.http.get(`${url}v2/permissions/employee/${min}/${max}/${employeeId}`)
}

filterBalanceLeavelistByempId(employeeId: number,year:any) {
  return this.http.get(`${url}v2/leave_balance_of_employee_by_year/${employeeId}/${year}`)
}

filterAllBalancePermissionList(min:any, max:any,orgId:any) {
  return this.http.get(`${url}v2/permissions/organization/${min}/${max}/${orgId}`)
}

filterAllBalanceLeavelistByempId(orgId: number,year:any) {
  return this.http.get(`${url}leave_balance_of_organization_by_year/${orgId}/${year}`)
}

  ////// calendar //////
  calenderOfYear(currentyear: any) {
    return this.http.get(`${url}calendar/year/${currentyear}`);
  }
  yearTypelist() {
    return this.http.get(`${url}year`);
  }

  /// leave adjustment filter ////
  leaveadjustmentByEmp(employeeId: any) {
    return this.http.get(`${url}leave_adjustment/employee/${employeeId}`);
  }
  leaveadjustmentByLeavetype(leavetypeId: any) {
    return this.http.get(`${url}leave_adjustment/leavetype/${leavetypeId}`);
  }
  leaveadjustmentByEmpAndLeavetype(leavetypeId: any, employeeId: any) {
    return this.http.get(`${url}leave_adjustment/leavetype/employee/${leavetypeId}/${employeeId}`);
  }

  ////   Designation /////
  createdesignation(createdata: any) {
    return this.http.post(`${url}list-type-values`, createdata);
  }
  updatedesignation(designationId: any, updatedata: any) {
    return this.http.put(`${url}list-type-values/${designationId}`, updatedata);
  }
  getdesignationList() {
    return this.http.get(`${url}list-type-values/4`);
  }
  getdesignationListById(designationId: any) {
    return this.http.get(`${url}list-type-value/${designationId}`);
  }

  //////   forget password //////
  forgetpassword(emaildata: any) {
    return this.http.post(`${url}Forgotpassword/OrganizationEmail`, emaildata)
      .pipe(map(data => data));
  }
  ///otp
  otp(otpdata: any): Observable<any> {
    return this.http.post(`${url}login/Otpvaildation`, otpdata)
      .pipe(map(data => data));
  }
  //GetEmployeeByEmail
  getemployyebyEmail(organizationEmail: any) {
    return this.http.get(`${url}employee/organizationEmail/${organizationEmail}`)
      .pipe(map(data => data));
  }
  ////// New Password /////
  newpassword(id, Form) {
    return this.http.put(`${url}forget_password/${id}`, Form);
  }
 ///// upcoming holidays ////
  getUpComingHoliday(employeeId:any,currentdate:any){
    return this.http.get(`${url}dashboard/holiday/employee/${employeeId}/${currentdate}`)
    .pipe(map(data => data));
  }
  getUpComingLeaves(employeeId:any,currentdate:any){
    return this.http.get(`${url}dashboard/leave_request_of_employee/employee/${employeeId}/${currentdate}/5`)
    .pipe(map(data => data));
  }
  getbalancePermission(currentdate:any,employeeId:any,){
    return this.http.get(`${url}dashboard/premissiondate/employee/${currentdate}/${employeeId}`)
    .pipe(map(data => data));
  }
  getleaveOfMonthCount(employeeId:any,currentdate:any){
    return this.http.get(`${url}dashboard/leave_request/employee/month/${employeeId}/${currentdate}`)
    .pipe(map(data => data));
  }
  getExceptionlist(employeeId:any,currentdate:any){
    return this.http.get(`${url}dashboard/exceptions/employee/${employeeId}/${currentdate}`)
    .pipe(map(data => data));
  }
  
  //  Admin dashboard
  employeeRequestCount(orgId:any,currentdate:any,approverid:any){
    return this.http.get(`${url}admindashboard/organization/leave_request/permission/workfromhome/${orgId}/${currentdate}/${approverid}`)
    .pipe(map(data => data));
  }
  upcomingsheduleleave(orgId:any,currentdate:any){
    return this.http.get(`${url}leave_request/approved/organization/${orgId}/${currentdate}/5`)
    .pipe(map(data => data));
  }
  upcomingsheduleWFH(orgId:any,currentdate:any){
    return this.http.get(`${url}wfh/organization/approved/${orgId}/${currentdate}/5`)
    .pipe(map(data => data));
  }
  upcomingshedulePermission(orgId:any,currentdate:any){
    return this.http.get(`${url}permissions/approved/organization/${orgId}/${currentdate}/5`)
    .pipe(map(data => data));
  }  
  upcomingsheduleBT(orgId:any,currentdate:any){
    return this.http.get(`${url}business_travel/approved/organization/${orgId}/${currentdate}/5`)
    .pipe(map(data => data));
  }


  // HrMS EMp API
  // getHrmsEmpList() {
  //   return this.http.get(`${url}employee`)
  // }

}
