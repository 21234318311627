<!-- 
<ng-container *ngIf="isLoading; else elseData">
  <div class="loading">
    <h4 class="text-muted">loading....</h4>
    <br />
    <div class="spinner-grow text-secondary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-dark" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container> -->
<span>
  <h4 class="dynamic-title">Manage Leave Request</h4>
</span>
<ng-container *ngIf="loader; else elseLoading" class="loading">
  <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 

<ng-template #elseLoading>
  <div class="container-fluid">
    <!-- <div class="float-left">
      <button mat-raised-button style="transform: translateY(10px);">
        <span class="material-icons ">device_hub</span> Leave Request
      </button>
    </div> -->
    <div class="float-right">
      <!-- <button mat-raised-button>
        <mat-icon>search</mat-icon>
      </button>
      <mat-form-field style="    line-height: 1.6;margin: 0 0 0 8px;" >
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field> -->

      <div class="form-group search-input topnav-right">                                                                     
        <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
        <button mat-raised-button class="search-btn">
          <mat-icon class="search-icon">search</mat-icon>
        </button>                                                                                                 
      </div>
    </div>
    <span> </span>
  </div>
<ng-container>
  <div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort *ngIf="isData > 0">
      <ng-container matColumnDef="employeeNumber">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          <b>Emp&nbsp;No</b>
        </th>
        <td mat-cell class="text-center" *matCellDef="let element"> {{element.employee.employeeNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="employeeName">
        <th mat-header-cell *matHeaderCellDef >
          <b>Emp&nbsp;Name</b>
        </th>
        <td mat-cell *matCellDef="let element">{{element.employee.employeeName | titlecase}} </td>
      </ng-container>
      <ng-container matColumnDef="organizationEmail">
        <th mat-header-cell *matHeaderCellDef>
          <b>Organization&nbsp;Email</b>
        </th>
        <td mat-cell *matCellDef="let element" [matTooltip]="element.leaveReason" [matTooltipPosition]="'right'">
          {{element.employee.organizationEmail}} </td>
      </ng-container>


      <!-- <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>
          <b>
            CONTACT&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </b>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.employee.mobileNumber}} </td>
      </ng-container> -->

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef>
          <b>From&nbsp;Date</b>
        </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.startDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>
          <b>To&nbsp;Date</b>
        </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.endDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="noOfLeaveDays">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          <b>No.of&nbsp;Days</b>
        </th>
        <td mat-cell class="text-center" *matCellDef="let element"> {{element.noOfLeaveDays}} </td>
      </ng-container>

      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%"><b>Leave&nbsp;Reason</b></th>
        <td mat-cell *matCellDef="let element; let i = index">
          <span class="reason-span" >{{element.leaveReason}}</span>
        </td>
      </ng-container>


      <ng-container matColumnDef="leaveStatus">
        <th mat-header-cell *matHeaderCellDef>
          <b>Status</b>
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="leaveStatus(element.leaveStatusName)">
           {{element.leaveStatusName}} </td>
      </ng-container>


      <ng-container matColumnDef="star">
        <th mat-header-cell *matHeaderCellDef class="text-center"></th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon style="font-size: 17px;">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item (click)="approve(element)">
              <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#01E325">done</mat-icon>
              <span class="cancel-trip-text" style="color:#01E325">Approve</span>
            </a>
            <a mat-menu-item (click)="denied(element)">
              <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#FF7171">close</mat-icon>
              <span class="cancel-trip-text" style="color:#FF7171">Deny</span>
            </a>
            <!-- <a mat-menu-item (click)="reason(element)">
              <mat-icon class="cancel-trip-icon material-icons-outlined">chat</mat-icon>
              <span class="cancel-trip-text">Leave Reason</span>
            </a> -->
            <a mat-menu-item (click)="openDialog(element)">
              <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#5E06D0">toc</mat-icon>
              <span class="cancel-trip-text" style="color:#5E06D0">Show Leave List</span>
            </a>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <div class="container" *ngIf="leaveData">
      <div class="alert alert-primary" style="width: 100%; justify-content: center;">
         No Leave Request
      </div>
    </div>
  </div>
</ng-container>
</ng-template>
<div [hidden]="loader">
  <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>



