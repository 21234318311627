<span>
  <h4 class="dynamic-title">My Profile</h4>
</span>
<div class="container-fluid p-0">
  <mat-card class="profile-card">
    <div class="row" style="height: fit-content;align-items: center;margin: 0px 0px;">
      <div class="col-md-6 col-sm-12 col-lg-6 col-xl-5">
        <div class="row" style="height: 143px;align-items: center;">
          <!-- <div class="col col-md-3 col-sm-3 col-lg-2 col-xl-2"> -->
            <div class="profile-img" (click)="openDialog()" style="margin: 10px;">
              <div class="overlay-image">
                <div class="profile-text">
                  <div>
                    <mat-icon class="material-icons-outlined" style="font-size: 18px;color:white">add_a_photo</mat-icon>
                  </div>
                  <a class="text-white">Change</a>
                </div>
                <img *ngIf="!testpic" mat-card-avatar class="user-profile" src="assets/img/profilepic.jpg" alt="pic">
                <img *ngIf="testpic" mat-card-avatar class="user-profile" [src]="testpic" alt="">
              </div>
            </div>
          <!-- </div> -->
          <!-- <div class="col col-md-3 col-sm-9 col-lg-4 col-xl-3"> -->
            <div class="user-details">
              <div class="user-name">{{ User_Name | titlecase }}</div>
              <div class="job-description">{{User_designation}}</div>
              <div class="joining-date">{{employeeJoinedDate}}</div>
              <div class="joining-day">{{employeeJoinedDay}}</div>
            </div>
          <!-- </div> -->
        </div>
      </div>
      <div class="col-md-4 col-sm-8 col-lg-4 col-xl-5">
        <div class="contact-details">
          <div class="email-id d-flex"><span><img style="margin-right: 5px;" src="assets/img/MAil.png"></span>{{email}}</div>
          <div class="ph-no d-flex"><span><img style="margin-right: 5px;" src="assets/img/phone.png"></span>+91 {{contact}}</div>
          <div class="gender d-flex"><span><img style="margin-right: 5px;" src="assets/img/person.png"></span>{{ gender | titlecase }}</div>
        </div>
      </div>
      <div class="col-md-2 col-sm-4 col-lg-2 col-xl-2 responsive-active" >
        <div class="account-status">
          <button  type="button" class="btn btn-success status-button">{{myStatus}}</button>
        </div>
      </div>
    </div>
  </mat-card>
  <mat-card style="margin: 15px; padding: 0;min-height: 363px;">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Summary">
        <div class="row">
          <div class="col-xl-8 col-md-12 col-sm-12 col-s-12" [ngStyle]="{'border-right':isEmployee == false ? '2px solid #EEEEEE' : '0px solid #EEEEEE' }" >
            <div class="row m-0">
              <div class="col-sm-12 mb-4">
                <div class="row mt-3">
                  <div class="col-sm-12">
                  <h4 class="summary">Leave Summary</h4>
                  </div>
                </div>
                <mat-card>
                  <div style="display: flex;">
                    <div class="title-card-right" style="position: relative;top: 4px;">
                      <span class="block1-value">Total Leave</span>
                      <span class="new-badge bc-green">
                        <span class="block1-count">{{totalLeave}}</span>
                      </span>
                    </div>
                    <div class="vl"></div>
                    <div class="title-card-left" style="position: relative;top: 4px;">
                      <span class="block1-value">Balance Leave</span>
                      <span class="new-badge bc-orange">
                        <span class="block1-count">{{noOfDays}}</span>
                      </span>
                    </div>
                    <div class="vl"></div>
                    <div class="title-card-left" style="position: relative;top: 4px;">
                      <span class="block1-value">This Month</span>
                      <span class="new-badge bc-orange">
                        <span class="block1-count">{{permonthcount}}</span>
                      </span>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-sm-12 mb-4">
                <div class="row">
                  <div class="col-sm-12">
                  <h4 class="summary">Permission Summary</h4>
                  </div>
                </div>
                <mat-card>
                  <div style="display: flex;">
                    <div class="title-card-right" style="position: relative;top: 4px;">
                      <span class="block1-value">Total Permission</span>
                      <span class="new-badge bc-green">
                        <span class="block1-count">{{maximumPermissionHours}}Hr</span>
                      </span>
                    </div>
                    <div class="vl"></div>
                    <div class="title-card-left" style="position: relative;top: 4px;">
                      <span class="block1-value">Balance Permission</span>
                      <span class="new-badge bc-blue">
                        <span class="block1-count">{{balancePermission}}Hr</span>
                      </span>
                    </div>
                    <div class="vl"></div>
                    <div class="title-card-left" style="position: relative;top: 4px;">
                      <span class="block1-value">This Month</span>
                      <span class="new-badge bc-blue">
                        <span class="block1-count">{{thisMonth}}Hr</span>
                      </span>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-md-6 col-sm-8 col-s-12" *ngIf="isEmployee==false" style="margin: auto;">
            <div class="row m-0">
              <div class="col-sm-12">
                <div class="apply-block">
                  <!-- <h3 class="apply-header">Apply Leave & Permission</h3> -->
                  <button mat-stroked-button class="apply-button new-style" [routerLink]="['../apply']"
                  color="primary" style="background: #1A83FF;border: 1px solid #1A83FF;">Apply Leave
                  <mat-icon class="material-icons-outlined apply-button-icon">person_add_alt</mat-icon>
                  </button>
                  <button mat-stroked-button class="apply-button new-style" [routerLink]="['../permission']"
                  color="primary" style="background: #0EAB00;border: 1px solid #0EAB00;">Apply Permission
                  <mat-icon class="material-icons-outlined apply-button-icon">verified</mat-icon>
                  </button>
                  <button mat-stroked-button class="apply-button new-style" [routerLink]="['../workFromHome-apply']"
                  color="primary" style="background: #FF9C41;border: 1px solid #FF9C41;">Apply Work From Home
                  <mat-icon class="material-icons-outlined apply-button-icon">maps_home_work</mat-icon>
                  </button>
                  <button mat-stroked-button class="apply-button new-style" [routerLink]="['../business-apply']"
                  color="primary" style="background: #446C8C;border: 1px solid #446C8C;">Apply Business Travel
                  <mat-icon class="material-icons-outlined apply-button-icon">home_repair_service</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Account Settings" [routerLink]="['settings']">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <form action="" [formGroup]="accountForm" (ngSubmit)="save(accountForm.value)">
                <div class="">
                  <h4 class="password-title">*Change your Password*</h4>
                </div>
                <div class="form-group">
                  <mat-form-field appearance="outline" class="example-full-width" style="display: initial;">
                    <mat-label class="form-label">Current Password</mat-label>
                    <mat-icon class="material-icons-outlined form-icon" matPrefix
                      style="color: #595959; transform: translateY(0px) ;">lock</mat-icon>
                      <mat-icon matSuffix
                      (click)="hide = !hide" 
                      [attr.aria-label]="'Hide password'"
                      class="material-icons-outlined form-icon"
                      style="color: #595959; transform: translateY(0px) ;"
                      [attr.aria-pressed]="hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input matInput [type]="hide ? 'password' : 'text'" 
                      placeholder="Enter Current Password!!" id="current"
                      formControlName="oldPassword"
                      [class.is-invalid]="accountForm.get('oldPassword').invalid&&accountForm.get('oldPassword').touched"
                      [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }" />
                    <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
                      <mat-error *ngIf="f.oldPassword.errors.required">currentPassword is required</mat-error>
                    </div>
                    <mat-error
                    *ngIf="accountForm.controls['oldPassword'].hasError('required') 
                      && (accountForm.controls['oldPassword'].dirty 
                      ||accountForm.controls['oldPassword'].touched)">Please
                    enter the Current Password</mat-error>
                  </mat-form-field>
                </div>
        
                <div class="form-group">
                  <mat-form-field appearance="outline" class="example-full-width" style="display: initial;">
                    <mat-label class="form-label">New Password</mat-label>
                    <mat-icon class="material-icons-outlined form-icon" matPrefix
                    style="color: #595959; transform: translateY(0px) ;">lock</mat-icon>
                      <mat-icon (click)="hide1 = !hide1" 
                      [attr.aria-label]="'Hide password'" 
                      [attr.aria-pressed]="hide1"
                      class="form-icon material-icons-outlined" 
                      matSuffix
                      style="color: #595959; transform: translateY(0px) ;">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input id="newPassword" 
                      [type]="hide1 ? 'password' : 'text'" 
                      matInput 
                      placeholder="Enter New Password"
                      formControlName="newPassword"
                      [class.is-invalid]="accountForm.get('newPassword').invalid&&accountForm.get('newPassword').touched"
                      [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
                      <mat-error
                      *ngIf="accountForm.controls['newPassword'].hasError('required') 
                      && (accountForm.controls['newPassword'].dirty 
                      ||accountForm.controls['newPassword'].touched)">
                      Please enter the New Password
                    </mat-error>
                    <mat-error
                      *ngIf="accountForm.controls['newPassword'].hasError('pattern') 
                      &&(accountForm.controls['newPassword'].dirty 
                      ||accountForm.controls['newPassword'].touched)">
                      Please enter password atleast one special character,number,Capital and four small letters 
                    </mat-error>
                  </mat-form-field>
                </div>
        
                <div class="form-group">
                  <mat-form-field appearance="outline" class="example-full-width"  style="display: initial;">
                    <mat-label class="form-label">Confirm Password</mat-label>
                    <mat-icon class="material-icons-outlined form-icon" matPrefix
                    style="color: #595959; transform: translateY(0px) ;">lock</mat-icon>
                    <mat-icon matSuffix 
                      (click)="hide2 = !hide2" 
                      [attr.aria-label]="'Hide password'"
                      [attr.aria-pressed]="hide2"
                      class="material-icons-outlined form-icon"
                      style="color: #595959; transform: translateY(0px) ;"
                      >{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <input [type]="hide2 ? 'password' : 'text'" 
                      matInput placeholder="Enter Confirm Password"
                      id="confirmPassword" 
                      formControlName="confirmPassword" 
                      [class.is-inValid]="accountForm.errors?.misMatch"
                      [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                      <mat-error
                      *ngIf="accountForm.controls['confirmPassword'].hasError('required') 
                      && (accountForm.controls['confirmPassword'].dirty 
                      || accountForm.controls['confirmPassword'].touched)">Confirm
                      Password is required
                    </mat-error>
                    <mat-error
                      *ngIf="accountForm.controls['confirmPassword'].hasError('mustMatch') 
                      && (accountForm.controls['confirmPassword'].dirty 
                      ||accountForm.controls['confirmPassword'].touched)">Mustmatch the new password
                    </mat-error>
                  </mat-form-field>
                </div>
    
                <div class="form-group">
                  <div class="d-flex">
                    <div style="width: 45%;margin: 0 auto;">
                      <button mat-stroked-button class="cancel-button" color="accent" type="button" 
                      [routerLink]="['../../calendar']">
                        <span class="material-icons-outlined cancel-button-icon">close</span>
                        Cancel
                      </button>
                    </div>
                    <div style="width: 45%;margin: 0 auto;">
                      <button mat-stroked-button class="apply-button" color="primary" type="submit">
                        <span class="material-icons-outlined apply-button-icon">done</span>
                        &nbsp;Apply&nbsp;
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>








<!-- <br>
&nbsp;&nbsp;&nbsp; <button mat-stroked-button color="primary" [routerLink]="['../../calendar']">Close</button>
<div class="col-md-8">
  <mat-card>
    <mat-card class="header">
      <p> <span class="material-icons">
          perm_identity
        </span>Profile </p>
    </mat-card>
    <div>
      <mat-card-header>
        <div class="float-right">
          <button mat-mini-fab color="primary" (click)="openDialog()"
            aria-label="Example icon button with a plus one icon" class="primary">
            <mat-icon>create</mat-icon>
          </button>
        </div>
        <img *ngIf="!testpic.fileViewUrl" mat-card-avatar src="assets/img/profilepic.jpg" alt="pic">
          <img *ngIf="testpic.fileViewUrl" mat-card-avatar [src]="testpic.fileViewUrl" alt="">
        <mat-card-title>{{ User_Name | titlecase }}</mat-card-title>
        <mat-card-subtitle>{{userType | titlecase}}</mat-card-subtitle>
      </mat-card-header>
      <hr>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <label for="" class="text-muted">Name</label>
          <h4 class="pro_value">{{ User_Name | titlecase }}</h4>
        </div>
        <div class="col-sm">
          <label for="" class="text-muted">Gender</label>
          <h4 class="pro_value">{{ gender | titlecase }}</h4>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm">
          <label for="" class="text-muted">Contact Number</label>
          <h4 class="pro_value">{{contact}}</h4>
        </div>
        <div class="col-sm">
          <label for="" class="text-muted">Organization Email</label>
          <h4 class="pro_value">{{email}}</h4>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-sm">
          <label for="" class="text-muted">BloodGroup</label>
          <h4 class="pro_value">{{bloodgrp}}</h4>
        </div>
        <div class="col-sm">
          <label for="" class="text-muted">Status</label>
          <h4 class="pro_value">{{myStatus}}</h4>
        </div>
      </div>
    </div>
  </mat-card>
</div> -->
