import { Component, OnInit, Inject, Input, Output,EventEmitter ,ViewChild,ElementRef} from '@angular/core';
import { url } from 'src/app/providers/properties';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../employee/employee-list/employee-list.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MustMatch } from '../account-settings/mustmatch';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @Input() url :any

  User_Name:any;
  gender: string;
  contact:any;
  email:any;
  bloodgrp:any;
  myStatus:any;
  userType:any;
  testpic:any;
  empId:any;
  myFile
  employeeJoinedDate: any;
  employeeJoinedDay: number;
  monthyear: string;
  balancePermission: any;
  noOfDays: any = 0;
  employeeId: any
  totalLeave: any = 0

  hide = true;
  hide2 = true;
  hide1 = true;
  accountForm: FormGroup;
  submitted = false;
  @ViewChild("current", { static: true }) private current: ElementRef;
  permonthcount: any;
  loggedeEmpId: any;
  thisMonth: any=0;
  User_designation: any;
  settingslist: any;
  maximumPermissionHours: any;
  id:any
  isEmployee: boolean=false;

  constructor(private gs: GlobalserviceService,
    public dialog: MatDialog,
    private datepipe: DatePipe,
    private fb: FormBuilder, 
    private router: Router,
    ) { }
  ngOnInit(): void {
    this.accountForm = this.fb.group({
      employeeId: [],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$')]],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );

    let x = JSON.parse(localStorage.getItem('data'));
    this.loggedeEmpId=x.employeeId;
    this.User_designation = x.designationResponse.designationName;
    let user_type = x.userType.userType;
    if(user_type == 'Employee') {
      this.isEmployee = true;
    }
    this.gs.getsettings().subscribe
    ((data:any)=>
    {
      this.settingslist = data;
      console.log(this.settingslist);
      this.settingslist.forEach(element => {
        this.maximumPermissionHours=element.maximumPermissionHours;
        console.log("maximumPermissionHours",this.maximumPermissionHours);
        this.permission();
      });
    })

    this.leaveBalance();
    this.leaveOfMonthCount();

    this.gs.getEmpListById(x.employeeId).subscribe(
      (d:any) =>{
        this.User_Name = d.employeeName;
        this.empId = d.employeeId;
        this.employeeJoinedDate = this.datepipe.transform(d.employeeJoinedDate, 'dd-MMMM-yyyy');
        this.employeeJoinedDay = new Date(d.employeeJoinedDate).getDay();


        let day;
        var val= d.employeeJoinedDate;
        let result = new Date(val).getDay();
        switch(result)
        {
          case 0:
            day="sunday";
          break;
          case 1:
            day="Monday";
          break;
          case 2:
            day="Thuesday";
          break;
          case 3:
            day="Wednesday";
          break;
          case 4:
            day="Thursday";
          break;
          case 5:
            day="Friday";
          break;
          case 6:
            day="Saturday";
          break;
        }
        console.log(day);
        this.employeeJoinedDay =day;

        
        if(d.gender == 9){
          this.gender = "Male"
        }
        if(d.gender == 10){
          this.gender = "Female"
        }
        if(d.gender == 11){
          this.gender = "Others"
        }
        if(d.status == 1){
          this.myStatus = "Active"
        }
        if(d.status == 2){
          this.myStatus = "InActive"
        }
        this.contact = d.mobileNumber;
        //this.bloodgrp = x.bloodGroup;
        this.userType = d.userType.userType;
        this.email = d.organizationEmail;

        this.id = d.employeeId;

        this.gs.getListTypeById(3).subscribe(
          (data:any) =>{
            data.forEach(
              e=>{
                if(e.listTypeValueId == d.bloodGroup){
                  this.bloodgrp = e.listTypeValueName;
                }
              }
            )
          }
        )

        this.gs.getEmpListById(this.id).subscribe( (d:any) => {
          this.testpic = d.attachment.fileViewUrl;
          console.log(this.testpic)
        })
      }
    )

    let data = {
      className: "class-A",
      status: "Active"
    }

  }

  permission(){
    let todaydate = new Date();
    this.monthyear = this.datepipe.transform(todaydate, 'yyyy-MM'),
    this.gs.getbalancePermission(this.monthyear,this.loggedeEmpId).subscribe(
      (data: any) => {
        console.log(data);
        this.balancePermission = data.availablePermissionBalance;
        console.log("balancePermission",this.balancePermission);
        this.thisMonth= this.maximumPermissionHours - this.balancePermission;
        console.log( "thisMonth",this.thisMonth);
      })
  }
  leaveOfMonthCount(){
    let todaydate = new Date();
    this.monthyear = this.datepipe.transform(todaydate, 'yyyy-MM');
    this.gs.getleaveOfMonthCount(this.loggedeEmpId,this.monthyear,).subscribe(
      (data: any) => {
        console.log(data);
        this.permonthcount = data.leavethismonth;
        console.log(this.permonthcount);
      })
  }
  leaveBalance() {
    this.gs.GetMYleaveBalance(this.loggedeEmpId).subscribe(
      (data: any) => {
        console.log(data);
        data.map(
          e => {
            console.log(e);
            this.noOfDays = e.noOfDays;
            this.totalLeave = e.leaveType.noOfDays
          }
        )
      }
    )
  }

  get f() { return this.accountForm.controls; }

  save(i) {
    console.log(i);
    if (this.accountForm.invalid) {
      return
    }
    let x = JSON.parse(localStorage.getItem('data'))
    let id = x.employeeId
    Swal.fire({
      title: 'Are you sure? Do you want to Update',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Update'
    }).then((result) => {
      if (result.value) {
        this.gs.updateAccountSettings(id, i).subscribe(d => {
          Swal.fire(
            {
              text: 'Changed!! Please login with new password',
              icon: 'success'
            }
          );
          this.router.navigate(['../../login'])
          localStorage.clear()
        },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            }
            )
          },
          () => {
            console.log('done')
          }
        )
      }
    })
  }

  openDialog() {

    const dialogRef =  this.dialog.open(profile, {
      data: {
        pic: this.testpic,
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      if(result == true){
        return
      }
      if(this.gs.myFile == undefined){
        alert('Profile is required')
        return
      }
      if(result == undefined){
        return
      }
      this.url = result;
      var data;
      this.gs.getEmpListById(this.empId).subscribe(
        (a:any) =>{
          data = {
            employeeId:this.empId,
            employeeName:a.employeeName,
            employeeNumber:a.employeeNumber,
            address:a.address,
            bloodGroup:a.bloodGroup,
            mobileNumber:a.mobileNumber,
            dateOfBirth:a.dateOfBirth,
            designation:a.designation,
            emergencyContactNumber:a.emergencyContactNumber,
            gender:a.gender,
            employeeJoinedDate:a.employeeJoinedDate,
            organizationEmail:a.organizationEmail,
            personalEmail:a.personalEmail,
            status:a.status,
            reportingTo:a.reportingTo,
            reportingToSecond:a.reportingToSecond,
            noOfWorkingDays:a.noOfWorkingDays,
            attachment:this.gs.myFile,
            userType:a.userType,
            organization:a.organization
          }
          this.gs.UpdateEmployeeProfile(this.empId,data).subscribe(
            d =>{
              Swal.fire({
                text: 'Profile Updated Successfully',
                icon: 'success'
              });
              window.location.reload()
              
            },
            err=>{
              console.log(err.error.message)
              Swal.fire({
                text: 'Error',
                icon: 'error'
              });
            }
          )
        }
      )

    });
  }

  
}

@Component({
  selector: 'profile',
  templateUrl: 'profile.html',
})
export class profile implements OnInit {

  @Input() pic:any;
  empId:any
  url = "../../../assets/img/profilepic.jpg";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private gs: GlobalserviceService, private fb: FormBuilder) {}
  ProfileEditForm: FormGroup;

  ngOnInit() {
    console.log(this.data.pic);
    const myData = JSON.parse(localStorage.getItem('data'));
    this.empId = myData.employeeId;
    if(this.data.pic !=null)
    {
      this.gs.getEmpListById(this.empId).subscribe(
        (e:any)=>{
          this.url = e.attachment.fileViewUrl;
          // console.log(this.url);
        }
      )
      // this.url = this.data.pic.fileViewUrl;
    }
  }
  onSubmit() {

  }

  File: any;
  testfile: any;
  onSelectFile(event) {

    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      if (fileList) {
        for (let i = 0; i < fileList.length; i++) {
          const file:any = {
            fileName: '',
            fileType: '',
            fileSize: '',
            fileByte: '',
          };
          file.fileName = fileList[i].name;
          file.fileType = fileList[i].type;
          file.fileSize = fileList[i].size;
          const reader = new FileReader();

          reader.onload = () => {
            let x: any = reader.result;
            let y: any = x.split(',')
            file.fileByte = y[1];
            this.testfile = file;
            this.gs.myFile = this.testfile;
          }
          reader.readAsDataURL(fileList[i])
        }
      }
    }
  }

  save() {
    const formData: FormData = new FormData();
    formData.append('file', this.testfile);

  }
  
}
