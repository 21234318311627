import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { FormArray, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment'
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { timeout } from 'rxjs/operators';
import { ThrowStmt } from '@angular/compiler';
import { element } from 'protractor';
import { Console } from 'console';
import { DatePipe } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import { Sort } from '@angular/material/sort';


@Component({
  selector: 'app-employee-in-out',
  templateUrl: './employee-in-out.component.html',
  styleUrls: ['./employee-in-out.component.css']
})
export class EmployeeInOutComponent implements OnInit {
  isSubmitted = false;
  minDate: Date;
  maxDate: Date;
  chooseDate:any;
  employeeList:any;
   control: FormArray;
  @ViewChild('select') select: MatSelect;
  employeeForm:FormGroup;
  // employeeData:FormGroup;
  allSelected=false;
  empData:boolean;
  isRowData:any=[];
  updateEmployee:any=[];
  isIn:boolean=false;
  isOut:any;
   timeInData:any;
   timeOutData:any;
  // checkTime:boolean=false;
  inTime:any;
  outTime:any;
  totalTime:any;
  empName:any;
  diffTime:any;
  Hour:any;
  // Indexs:any;
  indexs:any;
  touchedRows:any;
  emparray :any = [];
  caldate:any;
  id:any;
  Value:any;
  employeoutline:any;
  success:boolean=false;
  error:boolean=false;
  message:any;
  today:any;
  deletedlines : any = [];
  editDelete:any =[];
  empLength:any;
  mySelect:any =5;
  beforesearchpage :any;
  //  p:any;
  p:number =1;
  // page:any;
  itempages : any = 5 ;
  search:any;
  isDisabled = true;
  emparraysort: any = [];

 
  constructor(private GlobalserviceService : GlobalserviceService,
    private activatedRoute:ActivatedRoute,
    private fb: FormBuilder, private datepipe  : DatePipe,private router:Router) {
    const currentYear = new Date().getFullYear();
    // this.minDate = new Date(currentYear - 20, 0, 1);
    // this.maxDate = new Date(currentYear + 1, 11, 31);
   }

  ngOnInit(): void {
    this.today =new Date();
    this.employeeForm = this.fb.group({
      employee:['',Validators.required],
      date:['',Validators.required],
      tableRows: this.fb.array([],Validators.required),
      empInOutHeaderId:['']
    })
  
    // employee List APiin Leave 
    //console.log(this.employeeForm.value);
    this.GlobalserviceService.getEmpList()
    .subscribe((d:any)=>{
    console.log(d);
    this.employeeList = d;
    console.log(this.employeeList);
    console.log(this.employeeList.length);
    

     //hrms Employee Consume
    // this.GlobalserviceService.getHrmsEmpList()
    // .subscribe((data:any)=>{
    // //console.log(data);
    // this.employeeList = data;
    // //console.log(this.employeeList);
    // }); 

    //update Pass HeadeID 
    this.id=this.activatedRoute.snapshot.params['headerid'];
    console.log(this.id);
    //console.log("Edit ID");
    if(this.id)
    {
       this.isDisabled = false;
      this.GlobalserviceService.findByheaderId(this.id)
      .subscribe((data:any)=>
      {
        //console.log(data);
        //console.log(data.date);
        //console.log("headerId");
        this.Value=data;
        //console.log(this.Value);
        //console.log(this.Value.date);
        //console.log(this.Value.employeeInOutLine.length);
        //console.log(data.employee);
        // //console.log(this.employeeList.length);
          if(this.Value.employeeInOutLine.length==this.employeeList.length)
    {
      //console.log("Update Select All");
      this.allSelected = true;
    }
        this.updateEmployee = data.employeeInOutLine.map(
          d=> 
          {
            //console.log(d);
            //console.log(d.employee.employeeId);
            //console.log(d.employee.employeeName);
            return d.employee.employeeId
          }
        )
        //console.log(this.updateEmployee);

        this.employeeForm.patchValue({
          employee : this.updateEmployee,
          date:this.Value.date,
        });
        
       
        this.Value.employeeInOutLine.forEach((element,index) => {
          const control = this.employeeForm.get('tableRows') as FormArray;
          //console.log(control);
            this.addRow();   
        //    this.empName=element.employee.employeeName;            
            control.at(index).patchValue({
              employeeId :element.employee.employeeId,
              employeeName : element.employee.employeeName,
              empInOutLineId:element.empInOutLineId,
              timeIn : element.inTime.substring(0,5),
              timeOut :element.outTime.substring(0,5),
              totalTime :element.totalTime.substring(0,5)
              });
                //console.log(element.inTime.substring(0,5));
                //console.log(element.outTime.substring(0,5));
                //console.log(element.totalTime.substring(0,5));
        });
       
        }
      )
    }
  });
    
  }

  // deSelect()
  // {
  //   if(this.Value.employeeInOutLine.length==this.employeeList.length)
  //   {
  //     //console.log("Update Select All");
  //     this.allSelected = true;
  //   }
  // }
  ngAfterOnInit() {
    this.control = this.employeeForm.get('tableRows') as FormArray;
  }

  initiateForm(): FormGroup {
    return this.fb.group({ 
      employeeId:[''],
      empInOutLineId:[''],
      employeeName : [''],
      // timeIn:['',[Validators.required, Validators.pattern('^(?=.*?[1-9])[0-9()-]+$+"|"+^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]],
      // email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
      // timeIn:['',[Validators.required, Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]],
      // timeOut:['',[Validators.required, Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]],
      // totalTime:['',[Validators.required, Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]],
      timeIn:['',[Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]],
      timeOut:['',[Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]],
      totalTime:['',[Validators.pattern('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$')]],
    });
  }

  addRow() {
    const control =  this.employeeForm.get('tableRows') as FormArray;
    // const control1 = <FormArray>this.employeeForm.controls['tableRows'];
    control.push(this.initiateForm());
  }
 
  toggleAllSelection() {
    if (this.allSelected) {
      console.log("select");
      this.select.options.forEach((item: MatOption) => item.select());
      this.add();
    } else {
      console.log("unselect");
      this.isDisabled=true;
      // (this.employeeForm.get('tableRows') as FormArray).clear();
      // this.select.options.forEach((item: MatOption) => item.deselect());
      // console.log(this.select.options);
       } 
  }

  // toggleAllSelection($eve) {
  //   if (this.allSelected) {
  //     this.select.options.forEach((item: MatOption) => item.select());
  //   } else {
  //     //console.log("unselect")
  //     this.select.options.forEach((item: MatOption) => item.deselect());
  //     //console.log(this.select.options);
  //   }
  // }


  // toggleSelection = (data): void => {
  //   data.selected = !data.selected;
  // ​
  //   if (data.selected === true) {
  //     this.selectData.push(data);
  //   } else {
  //     const i = this.selectData.findIndex(value => value.item === data.item);
  //     this.selectData.splice(i, 1);
  //   }
  // ​
  //   this.selectControl.setValue(this.selectData);
  //   this.emitAdjustedData();
  // }

   optionClick(employeeId,event) {
     console.log(employeeId);
    //  console.log(event.target.value);
    //  console.log(event.target);
    //  console.log(event);
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
        console.log("status:"+newStatus);
      }
    });
    //console.log("status1:"+newStatus);
    this.allSelected = newStatus;
    console.log(this.allSelected);
    if(!event.selected)
    {
    const control =  this.employeeForm.get('tableRows') as FormArray;
    console.log(control.value);
    // this.add();
    //console.log(control.value.employeeId);
    // if(!control.value.employeeId.selected)
    // {
    //   //console.log(control);
    //   //console.log("unselected in OprionClick");
    //   this.delete(employeeId);
    // }
    // else
    // {
    //     //console.log("selected in oprionclick");
    // }
   }
  }
  add(){
    // this.isDisabled = false;
    console.log(this.employeeForm.value.employee);
    console.log(this.employeeForm.value.employeeName);
    let x = this.employeeForm.value.employee; 
    // x=JSON.parse(x);
    console.log(x);
    if(x.employee == ''){
      alert("Please select the options")
    }
    else{
      // this.select.close();
      //console.log("OK");
      //console.log(x);
      this.isRowData=x;
      console.log(this.id);
      console.log(this.isRowData);
      if(this.id)
      {
        console.log("Edit option");
        console.log(this.isRowData);
        console.log("row Data Length:"+this.isRowData.length);
        this.isRowData.forEach((element, index) => {
          console.log("element" + element);         
           const control = this.employeeForm.get('tableRows') as FormArray;
           console.log(control);
           console.log(
             "Control"+control.length);
           //console.log(k => k.employeeId);
            //console.log( "hsa " + !control.value.some(k => k.employeeId  == element));
            this.empLength=control.length;
            console.log("employee Length"+this.empLength);
           if(!control.value.some(k => k.employeeId  == element))
           {
            console.log("Control Length"+control.length);
            let  lastindex  = control.length -1;
               
             //console.log(control);
             console.log("Index:"+lastindex); 
             this.isSubmitted=false;  
             this.addRow();
             this.empName=element.employeeName;          
             console.log(this.empName);

            let empobj =   this.employeeList.find(e => e.employeeId  == element)
            console.log(empobj);
            console.log(this.employeeList);
            if(empobj)
            {
              setTimeout(() => {
              control.at(lastindex++).patchValue({
                employeeName : empobj.employeeName,
                employeeId :empobj.employeeId
                });
              },2);
              console.log(lastindex++);
                // console.log("timeerror"+index);
                setTimeout(() => {
                document.getElementById("timeerror"+index).style.display="none";
                },2);
            }
            if(this.empLength>=0)
              {
                //console.log("Greater Then 0 and Equal");
                this.isDisabled=false;
              }
              else{
                  this.isDisabled=true;
              }
           }
        });
        
      }
      else
      {
        console.log(this.isRowData);
        console.log("row Data Length:"+this.isRowData.length);
        this.isRowData.forEach((element,index) => {
          //console.log(element);
          //console.log(index);+
          const control = this.employeeForm.get('tableRows') as FormArray;
          console.log(control);
          console.log("control Length:"+control.length);
          this.empLength=control.length;
           if(!control.value.some(k => k.employeeId  == element))
           {
          //  console.log(control.length);
            let  lastindex  = control.length -1 ;
             console.log("Index:"+lastindex);
           //  console.log(control);
             this.isSubmitted=false;
             this.addRow();   
             this.empName=element.employeeName;  
             console.log(this.empName);        
             //console.log(lastindex++);
             let empobj =   this.employeeList.find(e => e.employeeId  == element)
             console.log(empobj);
             console.log(this.employeeList);
             if(empobj)
             {
              setTimeout(() => {
                control.at(lastindex++).patchValue({
                  employeeName : empobj.employeeName,
                  employeeId :empobj.employeeId
                  });
              }, 2);
              console.log(lastindex++);
              
                //console.log("timeerror"+index);
                 setTimeout(() => {
                  //console.log("if timeout");
                  document.getElementById("timeerror"+index).style.display="none";
                  //console.log("hidden");
                 }, 2);
             }
              if(this.empLength>=0)
              {
                //console.log("Greater Then 0 and Equal");
                this.isDisabled=false;
              }
              else{
                  this.isDisabled=true;
              }
           }
          //  else
          //  {
          //   //  //console.log("timeerror"+index);
          //    setTimeout(() => {
          //     //console.log("else timeout");
          //    document.getElementById("timeerror"+index).style.display="none";
          //   //  //console.log("Timerror Id not come Hidden");
          //      }, 2);
          //  }
          //  //console.log(control);
          //   this.addRow();   
          //   this.empName=element.employeeName;
          //   //console.log("Empname");
          //   //console.log(this.empName);
          //   control.at(index).patchValue({
          //    employeeName : element.employeeName,
          //    employeeId :element.employeeId
          //    });
          //     //console.log("timeerror"+index)
          //     setTimeout(() => {
          //      document.getElementById("timeerror"+index).style.visibility="hidden";
          //     }, 2);
        });
      }
       
    }
  }

  minouttime(event , index : any) {
   //console.log(event);
   //console.log("start");
  }

  get getFormControls() {
    const control = this.employeeForm.get('tableRows') as FormArray;
    
    return control;
  }
  // Submit 
  save()
  {
    this.isSubmitted=true;
    if(this.id)
    {
      if(this.employeeForm.invalid){
        //console.log("form invalid in Update")
        return
      }
      //6console.log("Update In out Time");
      const control = this.employeeForm.get('tableRows') as FormArray;
      //console.log(control);
      this.touchedRows = control.controls.filter(row => row).map(row => row.value);
      this.touchedRows.forEach( (element) => {
          console.log("Update Timings");
          console.log(element.timeIn);
          console.log(element.timeOut);
          console.log(element.totalTime);
          console.log(element.timeIn.length);
          console.log(element.timeOut.length);
          console.log(element.totalTime.length);
          console.log(element.empInOutLineId);
          // let enddate  =  this.datepipe.transform(element.enddate, 'YYYY-MM-dd');
           if(element.timeIn.length!=0&&element.timeOut.length!=0)
           {
            //  if(element.timeOut)
            //  {
              console.log("TimeIn and TimeOut");
              var updateLineData = {
                empInOutLineId:element.empInOutLineId,
                inTime:element.timeIn+":00",
                outTime:element.timeOut+":00",
                totalTime:element.totalTime+":00",
                status: {
                      listTypeValueId: 1,
                      },
                employee:
                {
                  employeeId:element.employeeId
                }
              }
              // var updateLineData = {
                //   empInOutLineId:element.empInOutLineId,
                //   inTime:element.timeIn+":00",
                //   outTime:element.timeOut+":00",
                //   totalTime:element.totalTime+":00",
                //   status: {
                //     listTypeValueId: 1,
                //     }, 
                //   employee:
                //   {
                //     employeeId:element.employeeId
                //   }
                // }
              console.log(updateLineData);
              this.emparray.push(updateLineData);
            //  }
            //  console.log("null outtime");
            // var linedata = {
            //   inTime:element.timeIn+":00",
            //   outTime:"00"+":00"+":00",
            //   totalTime:"00"+":00"+":00",
            //   employee:
            //   {
            //     employeeId:element.employeeId
            //   }
            // }
            // console.log(linedata);
            // this.emparray.push(linedata);
           }
           else if(element.timeIn.length!=0&&element.timeOut.length==0)
           {
               console.log("outime null");
               var updateLineData = {
                empInOutLineId:element.empInOutLineId,
                inTime:element.timeIn+":00",
                outTime:"00"+":00"+":00",
                totalTime:"00"+":00"+":00",
                status: {
                  listTypeValueId: 1,
                  },
                employee:
                {
                  employeeId:element.employeeId
                }
              }
              console.log(updateLineData);
              this.emparray.push(updateLineData);
           }
           else if(element.timeIn.length==0&&element.timeOut.length!=0)
           {
            console.log("inTime null");
            var updateLineData = {
              empInOutLineId:element.empInOutLineId,
              inTime:"00"+":00"+":00",
              outTime:element.timeOut+":00",
              totalTime:"00"+":00"+":00",
              status: {
                listTypeValueId: 1,
                },
              employee:
              {
                employeeId:element.employeeId
              }
            }
            console.log(updateLineData);
            this.emparray.push(updateLineData);
           }
    
           else
           {
            console.log("NLLL in and outtime");
            var updateLineData = {
              empInOutLineId:element.empInOutLineId,
              inTime:"00"+":00"+":00",
              outTime:"00"+":00"+":00",
              totalTime:"00"+":00"+":00",
              status: {
                listTypeValueId: 1,
                },
              employee:
              {
                employeeId:element.employeeId
              }
            }
            console.log(updateLineData);
            this.emparray.push(updateLineData);
           }
        
        //console.log(element);
        //console.log(element.employeeId);
        // let enddate  =  this.datepipe.transform(element.enddate, 'YYYY-MM-dd');
        // var updateLineData = {
        //   empInOutLineId:element.empInOutLineId,
        //   inTime:element.timeIn+":00",
        //   outTime:element.timeOut+":00",
        //   totalTime:element.totalTime+":00",
        //   status: {
        //     listTypeValueId: 1,
        //     }, 
        //   employee:
        //   {
        //     employeeId:element.employeeId
        //   }
        // }
        // //console.log("Update");
        // //console.log(updateLineData);
        // this.emparray.push(updateLineData);
      })

      if(this.deletedlines.length != 0)
      {
        this.deletedlines.forEach( (element) => {
          //console.log(element);
          if(element.empInOutLineId)
          {
            var updateLineData = {
              empInOutLineId:element.empInOutLineId,
              inTime:element.timeIn+":00",
              outTime:element.timeOut+":00",
              totalTime:element.totalTime+":00",
              status: {
                listTypeValueId: 4,
                }, 
              employee:
              {
                employeeId:element.employeeId
              }
            }
          }
          //console.log(updateLineData);
          this.emparray.push(updateLineData);
        })   
      }
      //console.log(this.employeeForm.value.date);
      //console.log(this.employeeForm.value.employee);
      let caldate = this.datepipe.transform(this.employeeForm.value.date, 'YYYY-MM-dd');
      this.chooseDate=caldate;
      //console.log(this.chooseDate);
      //console.log(caldate);
      var updateData ={ 
          empInOutHeaderId:this.id,
          date:caldate,
          employeeInOutLine: this.emparray
       }
        //console.log(updateData);
      // this.GlobalserviceService
      // .updateEmployeeInOut(headerid,updatedata).subscribe
       
      this.GlobalserviceService
      .updateEmployeeInOut(this.id,updateData)
      .subscribe((data:any) =>
      {
        //console.log("In And Out Time Updated Sucessfully");
        //console.log(data);
        this.success=true;
        this.message=data.description;
        if(data.statusCode == 409){
          this.message=data.description;
          //console.log(this.message);
          Swal.fire({
            text:data.description,
            icon:'error'
          })
        }
        else{
          Swal.fire({
            text:data.description,
            icon:'success'
          })
          setTimeout(()=>{
            this.router.navigate(["Home/empInOutList"])
            },2000)
        }
        // Swal.fire({
        //   text:data.description,
        //   icon:'success'
        // })
        // setTimeout(()=>{
        //   this.router.navigate(["Home/empInOutList"])
        //   },2000)
      }
      ,(err)=>
      {
        this.error=true;
        this.emparray=[];
        this.message=err.description;
        //console.log( this.message);
        if(err.statusCode == 409){
          this.message=err.description;
          //console.log(this.message);
          Swal.fire({
            text:err.description,
            icon:'error'
          })
        }
        setTimeout(()=> {
          this.error=false
        },2000)
      })
    }
    
       //above Edit code
      //submit CRETAE in below
    else
    {
      console.log("create In out Time");
      if(this.employeeForm.invalid){
        console.log("form invalid in Create");
        return
      }
      //console.log("submit");
      const control = this.employeeForm.get('tableRows') as FormArray;
      console.log(control);

        // this.findInvalidControls();
      this.touchedRows = control.controls.filter(row => row).map(row => row.value);
      console.log(this.touchedRows);
      //console.log(this.employeeForm);
      //console.log(this.employeeForm.value.date);
      //console.log(this.employeeForm.value.employee);
      this.touchedRows.forEach( (element) => {
      console.log(element.timeIn);
      console.log(element.timeOut);
      console.log(element.totalTime);
      console.log(element.timeIn.length);
      console.log(element.timeOut.length);
      console.log(element.totalTime.length);
      // let enddate  =  this.datepipe.transform(element.enddate, 'YYYY-MM-dd');
       if(element.timeIn.length!=0&&element.timeOut.length!=0)
       {
        //  if(element.timeOut)
        //  {
          console.log("TimeIn and TimeOut");
          var linedata = {
            inTime:element.timeIn+":00",
            outTime:element.timeOut+":00",
            totalTime:element.totalTime+":00",
            employee:
            {
              employeeId:element.employeeId
            }
          }
          console.log(linedata);
          this.emparray.push(linedata);
        //  }
        //  console.log("null outtime");
        // var linedata = {
        //   inTime:element.timeIn+":00",
        //   outTime:"00"+":00"+":00",
        //   totalTime:"00"+":00"+":00",
        //   employee:
        //   {
        //     employeeId:element.employeeId
        //   }
        // }
        // console.log(linedata);
        // this.emparray.push(linedata);
       }
       else if(element.timeIn.length!=0&&element.timeOut.length==0)
       {
           console.log("outime null");
           var linedata = {
            inTime:element.timeIn+":00",
            outTime:"00"+":00"+":00",
            totalTime:"00"+":00"+":00",
            employee:
            {
              employeeId:element.employeeId
            }
          }
          console.log(linedata);
          this.emparray.push(linedata);
       }
       else if(element.timeIn.length==0&&element.timeOut.length!=0)
       {
        console.log("inTime null");
        var linedata = {
          inTime:"00"+":00"+":00",
          outTime:element.timeOut+":00",
          totalTime:"00"+":00"+":00",
          employee:
          {
            employeeId:element.employeeId
          }
        }
        console.log(linedata);
        this.emparray.push(linedata);
       }

       else
       {
        console.log("NLLL in and outtime");
        var linedata = {
          inTime:"00"+":00"+":00",
          outTime:"00"+":00"+":00",
          totalTime:"00"+":00"+":00",
          employee:
          {
            employeeId:element.employeeId
          }
        }
        console.log(linedata);
        this.emparray.push(linedata);
       }
      })
      //console.log(this.employeeForm.value.date);
      //console.log(this.employeeForm.value.employee);
      let caldate = this.datepipe.transform(this.employeeForm.value.date, 'YYYY-MM-dd');
      this.chooseDate=caldate;
      //console.log(this.chooseDate);
      //console.log(caldate);
      var data ={ 
        //  date:this.employeeForm.value.date,
          date:caldate,
        // //console.log(date);
        employeeInOutLine: this.emparray
        }
      //console.log(data);
      this.GlobalserviceService
      .employeeInOutCreate(data)
      .subscribe((data:any) =>
      {
        //console.log("In And Out Time Created Sucessfully");
        //console.log(data);
        this.success=true;
        this.message=data.description;
        Swal.fire({
          text:data.description,
          icon:'success'
        })
        setTimeout(()=>  {
          this.router.navigate(["Home/empInOutList"])
        },2000)
      }
      ,err=>
      {
        this.error=true;
        this.emparray=[];
        //console.log(this.emparray);
        this.message=err.error.description;
        //console.log(this.message);
        //console.log("Date already Exist");
        Swal.fire({
          text:err.error.description,
          icon:'error'
        })
        setTimeout(()=>{
          this.error=false
        },2000)
      })
    }
  }

  close()
  {
    this.allSelected=false;
    //console.log(this.allSelected);
    const control = this.employeeForm.get('tableRows') as FormArray;
    //console.log("close dropdownbox");
    this.editDelete=control.value;
    //console.log(this.editDelete);
    this.updateEmployee=this.editDelete;
    //console.log(this.updateEmployee);
     let updateEmployee = this.editDelete.map(
     d=> 
     {
       return d.employeeId
     }
   )
   //console.log(this.updateEmployee);

    this.employeeForm.patchValue({
      employee : updateEmployee,
    })
    this.select.close();
  }

  cancel()
  {
    //console.log("Cancel");
    this.router.navigate(["Home/empInOutList"])
    // window.location.reload();
  }


  changepage(){
    //console.log("Search");
    if(this.search.length!= 0)
    {
       this.p = 1;
      // this.taskEmpList.length=0;
      // //console.log(this.taskEmpList.length);
      // if(this.taskEmpList.length == 0)
      //     {
      //       this.nodata = true;
      //       //console.log("nodata")
      //     }
      // else
      // {
      //   this.p = 1;
        //console.log(this.p);
        //console.log("success Data");
    }
    else{
       this.p = this.beforesearchpage;
      // this.nodata = true;
      //console.log("nodata");
    }
  }

  inout(event, row: number)  
  {
    //console.log(row);
     console.log("InOut Method");
    //console.log(event.target.value);
    this.timeOutData=(<HTMLInputElement>document.getElementById("outtime"+row)).value;
    var inData= (<HTMLInputElement>document.getElementById("intime"+row)).value;
    //console.log(inData);
    //console.log(this.timeOutData);
     document.getElementById("timeerror"+row).style.display="none";
     document.getElementById("inouterror"+row).style.display="none";

    var pattern = new RegExp('^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
    var inTime=pattern.test(inData);
    var outTime=pattern.test(this.timeOutData);
    console.log("InTime Patern Result"+inTime);
    console.log("outTime Pattern Result"+outTime); 

    //  if(inTime==true && outTime==true) 
      if(inTime && outTime)
    // if(inData&&this.timeOutData)
    {
      const control = this.employeeForm.get('tableRows')['controls'] as FormArray;
      //console.log(control);
      // tableRows: this.fb.array([]),
      // //console.log(this.employeeForm.tableRows.control.);
      //  if(control.at(row).timeIn.errors.status="INVALID") 
      //    {
      //      //console.log("form invalid")
      //      return
      //   }
      //console.log(inData);
      //console.log(this.timeOutData);
      // this.checkTime=false;
      // if(inData!=null ||this.timeOutData==null)
      // {
      //  //console.log("Hidden error");
      //  this.checkTime=true;
    // document.getElementById("timeerror"+row).style.visibility="hidden";
    if(inData >this.timeOutData || inData==this.timeOutData)
    {
      console.log("InTime is greater than OutTime"+"OR"+"'InTime is equal to OutTime'");
      // this.checkTime=true;
      document.getElementById("timeerror"+row).style.display="block";
    }
    else
    {
      // this.checkTime=false;
      var vaild=document.getElementById("timeerror"+row).style.display="none";
      console.log(inData);
      console.log(this.timeOutData);
      var timeIn=inData.split(":");
      var timeOut=this.timeOutData.split(":");
      console.log(timeIn[0]);
      console.log(timeOut[0]);
       var start=timeIn[0];
       console.log(start);
       var end=timeOut[0];
       console.log(end);
      // convert String to Integer
      var a = parseInt(start);
      console.log(a);
      var b = parseInt(end);
      console.log(b);
      if(a>=1&&b>=1)
      {
        document.getElementById("inouterror"+row).style.display="none";
        console.log(" Calculate TimeDifference");
        this.timeDifference(inData, this.timeOutData,row);
      }
      else
      {
        document.getElementById("inouterror"+row).style.display="block";
        // document.getElementById("timeerror"+row).style.display="block";
        console.log("Dont Calculate TimeDifference");
        control.at(row).patchValue({
          totalTime:''
          });
        console.log("clear TotalTime");
        console.log(this.totalTime);
      }
      //console.log(inData);
      //console.log(this.timeOutData);
      //console.log("Time Diff");
      //console.log("Outime is greater than Intime");
     }
  //  } 
  }
  else
  {
    const control = this.employeeForm.get('tableRows')['controls'] as FormArray;
    console.log("Indata or outdata is null");
    control.at(row).patchValue({
      totalTime:''
      });
    console.log("clear TotalTime");
    console.log(this.totalTime);
  }

  }

  // clear(){
  //   console.log("clear TotalTime");
  //   this.totalTime='';
  // }
      
  

      timeDifference(inData,timeOutData,i)
    {
      if(inData!=0 && timeOutData!=0)
      {
    //console.log("Hours Calculation");
    inData = inData.split(":");
    //console.log(inData);
    timeOutData = timeOutData.split(":");
    //console.log(timeOutData);
    var startDate = new Date(0, 0, 0, inData[0], inData[1], 0);
    //console.log(startDate);
    var endDate = new Date(0, 0, 0, timeOutData[0], timeOutData[1], 0);
    //console.log(endDate);
    var diff = endDate.getTime() - startDate.getTime();
    //console.log(diff);
    var hours = Math.floor(diff / 1000 / 60 / 60);
    //console.log(hours);
    diff -= hours * 1000 * 60 * 60;
    //console.log(diff);
    var minutes = Math.floor(diff / 1000 / 60);
    //console.log(minutes);
     var hourstring ;
     var minutestring;
      if(hours < 10){
        hourstring = "0"+hours;
    }else{
      hourstring = hours;
    }
    if(minutes < 10){
      minutestring = "0"+minutes;
    }else
    {
      minutestring = minutes;
    }

    // var seconds = Math.floor(diff / 1000);
    this.diffTime=hourstring+":"+minutestring;
    //console.log("success");
    //console.log(this.diffTime);
    const control = this.employeeForm.get('tableRows') as FormArray;
    //console.log(control);
    //console.log("setValue");
    //console.log(i);
      //console.log(this.empName);
       control.at(i).patchValue({
       totalTime:this.diffTime
  });
      }
      else{
        //console.log("data Null");
      }
   }

// delete(index: number) {
//   const control =  this.employeeForm.get('tableRows') as FormArray;
//   let employee =  control.at(index).value;
//   //console.log( employee);
//   control.removeAt(index);
//   //console.log("employee.employeeId" + employee.employeeId);
//   //console.log(this.isRowData);

//   const rowindex = this.isRowData.indexOf(employee.employeeId);
//   //console.log("rowindex" + rowindex);

//   if (rowindex > -1) { 
//     this.isRowData.splice(index, 1); 
//      this.employeeForm.patchValue({
//       employee  : this.isRowData
//      })
//   }
// }

// mat Sort Function
sortData(sort: Sort) {
  //console.log(this.getFormControls);
  const data = this.emparraysort.slice();
   //console.log(data);
  if (!sort.active || sort.direction === '') {
    this.emparraysort  = data;
    return;
  }
    const sortcontrol = this.getFormControls;

  this.emparraysort = sortcontrol.controls.sort((a, b) => {
    const isAsc = sort.direction === 'asc';
    switch (sort.active) {
     
      case 'employeeName':
        //console.log("employeeName");
        return this.compare(a.value.employeeName, b.value.employeeName, isAsc);
      
        case 'timeIn':
          //console.log("timeIn");
          return this.compare(a.value.timeIn, b.value.timeIn, isAsc);

          case 'timeOut':
          //console.log("timeOut");
          return this.compare(a.value.timeOut, b.value.timeOut, isAsc);

          case 'totalTime':
          //console.log("totalTime");
          return this.compare(a.value.totalTime, b.value.totalTime, isAsc);
      default:
        return 0;
    }
  });

  //console.log(this.emparraysort);
}

compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

showChanges()
    {
      //console.log("show Change");
      this.p=1;
    }

// delete(group: any) {
//   //console.log("delete");

//   if(this.allSelected == true)
//   {
//     this.allSelected = false;
//   }
  
//    //console.log(group);

//   if(this.id)
//   {
//   //console.log("update Row Delete");
//   const control = this.employeeForm.get('tableRows') as FormArray;

//   let index = control.controls.findIndex( // Find item from controls.
//   (x) => x.value.employeeId == group.employeeId.value
// );

//   let employee =  control.at(index).value;
//    if(employee.empInOutLineId){
//     this.deletedlines.push(employee);
//    }

   
//   //console.log(employee);
//   control.removeAt(index);
//   //console.log("index" + index);
//   //console.log("employee.employeeId" + employee.employeeId);
//   //console.log(control);
//   //console.log(control.value);

//   this.empLength=control.length;
//   //console.log(this.empLength);
//   if(this.empLength>0)
//   {
//     //console.log("Delete Greater Then 0 and Equal");
//     this.isDisabled=false;
//   }
//   else{
//     this.isDisabled=true;
//     //console.log("Delete o");
//   }
  
//      this.editDelete=control.value;
//      //console.log(this.editDelete);
//     //  //console.log("Before EmpData:"+this.updateEmployee);
//     //  let delEmp=this.editDelete.splice(index, 1);
//     //  //console.log(delEmp);
//      this.updateEmployee=this.editDelete;
//      //console.log(this.updateEmployee);
//      this.updateEmployee = this.editDelete.map(
//       d=> 
//       {
//         //console.log(d);
//         //console.log(d.employeeId);
//         //console.log(d.employeeName);
//         return d.employeeId
//       }
//     )
//     //console.log(this.updateEmployee);

//  this.employeeForm.patchValue({
//    employee : this.updateEmployee,
//  });
//   // this.employeeForm.patchValue({
//   //   employee: this.isRowData,
//   // });

//   // this.employeeForm.patchValue({
//   //   employee : employee,
//   //   date:this.Value.date,
//   // });
//   }
//   else
//   {
//     this.isDisabled=false;
//   //console.log("create Row Delete");
//   const control = this.employeeForm.get('tableRows') as FormArray;
//   //console.log(control);
//   let index = control.controls.findIndex( // Find item from controls.
//   (x) => x.value.employeeId == group.employeeId.value
   
// );

//  //console.log("index : " + index);

//   let employee =  control.at(index).value;
//   //console.log(employee);
   
//   control.removeAt(index);
//   //console.log("employee.employeeId" + employee.employeeId);
//   //console.log(control);
//   let del=this.isRowData.splice(index, 1);
//   //console.log(del);
//   //console.log(this.isRowData);
//   //console.log(this.isRowData.length);
//   this.empLength=this.isRowData.length;
//   //console.log(this.empLength);
//   // if(this.empLength>=0)
//   // {
//   //   this.isDisabled=false;
//   //   //console.log("Delete Greater then 0 or equal")
//   // }
//   // else{
//   //   this.isDisabled=true;
//   //   //console.log("Delete Below then 0")
//   // }
//    if(this.empLength>0)
//     {
//         //console.log("Delete Greater Then 0 and Equal");
//         this.isDisabled=false;
//     }
//     else
//     {
//           this.isDisabled=true;
//           //console.log("Delete o");
//     }
//   // let emp = this.Value.employeeInOutLine.map(
//   //    d=> 
//   //   {
//   //     //console.log(d);
//   //     //console.log(d.employee.employeeId);
//   //     //console.log(d.employee.employeeName);
//   //     let del=this.isRowData.splice(index, 1);
//   //     //console.log(del);
//   //     //console.log(this.isRowData);
//   //     return d.employee.employeeId
//   //   }
//   // )

//   //  //console.log(emp);

//   this.employeeForm.patchValue({
//     employee : this.isRowData,
//   });

  

//   }
//   // this.isRowData=del;
//   // //console.log(this.isRowData);


//   // const rowindex = this.isRowData.indexOf(employee.employeeId);
//   // //console.log("rowindex" + rowindex);

//   // if (rowindex > -1) { 
//   //   this.isRowData.splice(index, 1); 
//   //    this.employeeForm.patchValue({
//   //     employee  : this.isRowData
//   //    })
//   // }
// }

//12 Hours Aam and Pm Options
//   inout(event, row: number)
//   {
//     //console.log(row);
//     //console.log("Out");
//     //console.log(event.target.value);

//     this.timeOutData=(<HTMLInputElement>document.getElementById("outtime"+row)).value;
//     var inData= (<HTMLInputElement>document.getElementById("intime"+row)).value;
//     //console.log(inData);
//     //console.log(this.timeOutData);
//    //Indata Value
//     var timeSplit = inData.split(':'),
//     hours,
//     minutes,
//     meridian;
//   hours = timeSplit[0];
//   minutes = timeSplit[1];
//   if (hours > 12) {
//     meridian = 'PM';
//     hours -= 12;
//   } else if (hours < 12) {
//     meridian = 'AM';
//     if (hours == 0) {
//       hours = 12;
//     }
//   } else {
//     meridian = 'PM';
//   }
//   //console.log("inData");
//   //console.log(hours + ':' + minutes + ' ' + meridian);
//   const control = this.employeeForm.get('tableRows') as FormArray;
//   var InTimeValue1=hours + ':' + minutes + ' ' + meridian;
//   //console.log(InTimeValue1);
//   control.at(row).patchValue({
//     timeIn:InTimeValue1
// });
// //console.log("timeIn");

//   //OutTime Value
//   var timeSplit1 = this.timeOutData.split(':'),
//     hours,
//     minutes,
//     meridian;
//   hours = timeSplit1[0];
//   minutes = timeSplit1[1];
//   if (hours > 12) {
//     meridian = 'PM';
//     hours -= 12;
//   } else if (hours < 12) {
//     meridian = 'AM';
//     if (hours == 0) {
//       hours = 12;
//     }
//   } else {
//     meridian = 'PM';
//   }
//   //console.log("OutData");
//   //console.log(hours + ':' + minutes + ' ' + meridian);
//   // const control = this.employeeForm.get('tableRows') as FormArray;
//   var outTimeValue1=hours + ':' + minutes + ' ' + meridian;
//   //console.log(outTimeValue1);
//   control.at(row).patchValue({
//     timeOut:outTimeValue1
//    });
//   //console.log("outtime");
//   }

// function Validate(event) {
//   var regex = new RegExp("^[0-9-!@#$%&*?]");
//   var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
//   if (!regex.test(key)) {
//       event.preventDefault();
//       return false;
//   }
// } 
}
