<div class="root">
    <div class="container">
        <div class="col-md-12 p-0">
            <div class="row log">
                <div class="col-md-6 hidden p-0">
                    <div class="left-content">
                        <h1 class="main-heading">Leave Management System is used to Track Employee Leave</h1>
                        <P class="sub-content">Managing Employee Time-off requests, Work from home requests, Business travels and Permissions.</P>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="">
                        <mat-card>
                            <mat-card class="login">
                                <h1 style="margin: -9px 0px 0px 12px;font-size: medium;">Login</h1>
                            </mat-card>
                            <div class="container">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <div class="form-group">
                                        <mat-form-field style="width: 100%" appearance="outline">
                                            <mat-label>Mail-Id</mat-label>
                                            <mat-icon matPrefix>account_box</mat-icon>
                                            <input id="password1" type="text" formControlName="username" [errorStateMatcher]="matcher"
                                                placeholder="ex@jesperapps.com"
                                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }" matInput>
                                                <mat-error *ngIf="submitted && f.username.hasError('pattern') && !f.username.hasError('required')">
                                                    Please enter a valid email address
                                                </mat-error>
                                                <mat-error *ngIf=" submitted && f.username.hasError('required')">
                                                    Email is <strong>required</strong>
                                                </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="form-group">
                                        <mat-form-field style="width: 100%" appearance="outline" class="md-10">
                                            <mat-icon matPrefix>lock</mat-icon>
                                            <mat-label>Password</mat-label>
                                            <input id="password1" type="password" formControlName="password" [type]="hide ? 'password' : 'text'"
                                                placeholder="enter password"
                                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" matInput
                                                showHideInput>
                                                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                </button>
                                            <mat-error *ngIf="submitted && f.password.hasError('required')">
                                                Password is <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>

                                        <div class="">
                                            <div class="float-right">
                                                <a routerLink="/forget-password">Forgot Password?</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="float-right">
                                        </div>
                                        <button [disabled]="loading" type="submit" class="btn btn-primary"> Login</button>
                                    </div>
                                </form>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
