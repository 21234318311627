import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-mytask',
  templateUrl: './mytask.component.html',
  styleUrls: ['./mytask.component.css']
})
export class MytaskComponent implements OnInit {

  dataSource:any = [];
  filterData:any = [];
  startDate:any;
  endDate:any;
  TaskForm:FormGroup;
  displayedColumns:string[] = ['position','name','weight','symbol'];

  constructor(private fb:FormBuilder) { }

  ngOnInit(): void {
    this.dataSource = [
      {position: 1, name: 'Leave Application', weight: 1.0079, symbol: '2021-01-01'},
      {position: 2, name: 'Leave Application', weight: 4.0026, symbol: '2021-01-04'},
      {position: 3, name: 'Leave Application', weight: 6.941, symbol: '2021-01-12'},
      {position: 4, name: 'Leave Application', weight: 9.0122, symbol: '2021-01-15'},
      {position: 5, name: 'Leave Application', weight: 10.811, symbol: '2021-01-18'},
      {position: 6, name: 'Leave Application', weight: 12.0107, symbol: '2021-01-30'},
    ];
    this.TaskForm = this.fb.group({
      startDate:[''],
      endDate:['']
    })
  }


  onSubmit() {
    let x = this.TaskForm.value;
    let c = new Date(x.startDate);
    let d = new Date(x.endDate);
    while (c <= d) {
      this.filterData.push(new Date(c));
      c.setDate(c.getDate()+1);
    }
      this.dataSource = this.dataSource.filter(
        data =>{
          if(x.startDate){
            return data.symbol >= new Date(x.startDate).toISOString().replace(/T.*$/, '') &&
            data.symbol <= new Date(x.endDate).toISOString().replace(/T.*$/, '');
          }
          return true;
        }
      )
  }
}
