<span>
  <h4 class="dynamic-title">Apply Work From Home</h4>
</span>
<div class="col-sm-9">
  <mat-card>
    <!-- <mat-card class="header">
      <p> <span class="material-icons">
          add_business
        </span>Apply Work From Home </p>
    </mat-card> -->
    <ng-container *ngIf="isLoading; else elseLoading">
      <div class="loading">
        <h4 class="text-muted">loading....</h4>
        <br>
        <!-- <div class="spinner-grow text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div> -->
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <ng-template #elseLoading>
      <form action="" [formGroup]="workfromHomeFrom" (ngSubmit)="applyWFH()">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">*Enter details to apply Work From Home*</h3>
          </div>
        </div>
        <!-- first approver -->
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label class="form-label">First Approver</mat-label>
          <mat-icon class="form-icon" style=" color: #6d6b6b;
transform: translateY(0px);" matPrefix>mail</mat-icon>
          <input type="text" matInput [value]="first_approver" [errorStateMatcher]="matcher" readonly>
        </mat-form-field>
        <!-- second Approver -->
        <mat-form-field class="example-full-width" appearance="outline" *ngIf="secondError">
          <mat-label class="form-label">Second Approver</mat-label>
          <mat-icon class="form-icon" style=" color: #6d6b6b;
transform: translateY(0px);" matPrefix>account_box</mat-icon>
          <input matInput type="text" [value]="second_approver" readonly>
        </mat-form-field>

        <div class="row">
          <div class="col-md-6">
            <!-- startDate -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">From Date</mat-label>
                <mat-icon class="form-icon" style=" color: #6d6b6b;
                        transform: translateY(0px);" matPrefix>today</mat-icon>
                <!-- [matDatepickerFilter]="myFilter" -->
                <input matInput (click)="picker.open()" [matDatepickerFilter]="myFilter"
                  (dateChange)="someMethodName($event)" [errorStateMatcher]="matcher" formControlName="startDate"
                  [matDatepicker]="picker" placeholder="select start date"  (dateInput)="nofoDays($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker touchUi></mat-datepicker>
                <mat-error *ngIf=" f.startDate.hasError('required')">
                  Please select <strong>From Date</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <!-- endDate -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">To Date</mat-label>
                <mat-icon class="form-icon" style="color: #6d6b6b;
                        transform: translateY(0px);" matPrefix >today</mat-icon>
                <input matInput (click)="a.open()" [matDatepickerFilter]="myFilter" (dateInput)="nofoDays($event)"
                [min]="min" [errorStateMatcher]="matcher" formControlName="endDate" [matDatepicker]="a"
                  placeholder="select end date" placeholder="select end date" [disabled]="endDate_disable">
                <mat-datepicker-toggle matSuffix [for]="a"></mat-datepicker-toggle>
                <mat-datepicker #a touchUi></mat-datepicker>
                <mat-error *ngIf=" f.endDate.hasError('required')">
                  Please select <strong>To Date</strong>
                </mat-error>
                <mat-error *ngIf="show_error_msg">
                  To date should be greater than from date.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- nofoDays -->
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label class="form-label">No.of Days</mat-label>
          <mat-icon class="form-icon" style=" color: #6d6b6b;
        transform: translateY(0px);" matPrefix>today</mat-icon>
          <input type="text" matInput [value]="noOFDays" readonly>
        </mat-form-field>
        <!-- reason -->
        <mat-form-field class="example-full-width form-textarea" appearance="outline">
          <mat-label class="form-label">Reason</mat-label>
          <mat-icon class="form-icon" style=" color: #6d6b6b;
          transform: translateY(15px);" matPrefix>insert_comment</mat-icon>
          <textarea matInput type="text" formControlName="reason" placeholder="Enter reason!!!!"
            [errorStateMatcher]="matcher" rows="2"></textarea>
          <mat-error *ngIf=" f.reason.hasError('required')">
            Please enter <strong>leave reason</strong>
          </mat-error>
        </mat-form-field>
        <!-- buttons -->
        <div class="form-group">
          <div class="d-flex">
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="cancel-button" color="accent" type="button" (click)="Cancel()">
                <span class="material-icons-outlined cancel-button-icon">close</span>
                Cancel
              </button>
            </div>
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="apply-button" color="primary" type="submit">
                <span class="material-icons-outlined apply-button-icon">done</span>
                &nbsp;Apply&nbsp;
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </mat-card>
</div>
