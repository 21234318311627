import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { leavedenyreason } from '../withdraw-list/withdraw-list.component';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent  implements OnInit {

  list_count = 0;
  data: any[] = []
  isLoading:Boolean;
  noData=false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['sno','permissionDate', "permissionHours","reason", "workFromHomeStatus", 'star',];
  dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  changeasDenyReason: boolean=false;
  isMenuOpen: boolean = false;
  clickedbusinessTravelId: any;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog,
    private renderer: Renderer2) {
    // this.renderer.listen('window', 'click',(e:Event)=>{
    //   if(e.target !== this.toggleButton.nativeElement && e.target!==this.menu.nativeElement){
    //       this.isMenuOpen=false;
    //       console.log(this.isMenuOpen);
    //   }
    // });
  }
  ngOnInit() {
    let x = JSON.parse(localStorage.getItem("data"))
    let id = x.employeeId;
    console.log(id)
    // console.log("success");
    this.gs.get_permissionList(id).subscribe((d: any) => {
      this.loader=false;
      console.log(d.length);
      if(d.length==0){
        console.log("noData");
      }
      if(d.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
      console.log(d)
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
      this.list_count = this.data.length
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  denyReason(e){
    console.log(e);
    this.clickedbusinessTravelId = e;
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
    if(this.isMenuOpen==true){
      this.changeasDenyReason=true;
      console.log(this.changeasDenyReason);
    }
    else{
      this.changeasDenyReason=false;
      console.log(this.changeasDenyReason);
    }
  }
  cancel(e){
    console.log(e)
    e['permissionStatus'] = 8
    Swal.fire({
      title: 'Are you sure? Do you want to Cancel Permission Request!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor:'#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true
        this.gs.Permission_Status(e.permissionId, e).subscribe(
          d => {
            Swal.fire({
              text: 'Cancelled',
              icon: 'success'
            })
            this.isLoading = false
            window.location.reload()
          },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
            this.isLoading = false
          }
        )
      }
    })
  }


  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
    if (i == 'Cancelled') {
      return '#e40000'
    }
  }


  reason(i) {
    this.dialog.open(Permission, {
      width:'300px',
      data: i.permissionReason
    });
  }
  showdenyreason(i){
    console.log(i)
    let x = i;
    this.dialog.open(leavedenyreason, {
      data: x
    });
  }

}

@Component({
  selector: 'permission',
  templateUrl: 'permission.html',
})
export class Permission implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  ngOnInit(){
  }
}
