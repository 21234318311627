import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Reason, ShowLeave } from '../withdraw-list/withdraw-list.component';

@Component({
  selector: 'app-all-withdraw',
  templateUrl: './all-withdraw.component.html',
  styleUrls: ['./all-withdraw.component.css']
})
export class AllWithdrawComponent implements OnInit {
  list_count = 0
  data: any[] = []
  noData=false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeName', 'leaveType', "startDate", 'endDate', 'withdrawDays', 'status', 'star',];
  dataSource: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog) {
  }
  ngOnInit() {
    let x: any = JSON.parse(localStorage.getItem('data'))
    let mail = x.organizationEmail;
    this.gs.getAll_withdrawList(mail).subscribe((d: any) => {

      if(d.length==0)
      {
      console.log("error");
      this.noData=true;
      }

      d.forEach(e => {
        if (e.leaveWithdraw.withdrawStatus == 3) {
          e.leaveWithdraw.withdrawStatus = "Pending"
        }
        if (e.leaveWithdraw.withdrawStatus == 4) {
          e.leaveWithdraw.withdrawStatus = "Deleted"
        }
        if (e.leaveWithdraw.withdrawStatus == 5) {
          e.leaveWithdraw.withdrawStatus = "Approved"
        }
        if (e.leaveWithdraw.withdrawStatus == 6) {
          e.leaveWithdraw.withdrawStatus = "Denied"
        }
        if (e.leaveWithdraw.withdrawStatus == 7) {
          e.leaveWithdraw.withdrawStatus = "Withdrawn"
        }
        if (e.leaveWithdraw.withdrawStatus == 8) {
          e.leaveWithdraw.withdrawStatus = "Cancelled"
        }
      })
      this.data = d;
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.employee.employeeName + data.leaveType.leaveName
        + data.leaveWithdraw.startDate + data.leaveWithdraw.endDate
        + data.leaveWithdraw.withdrawDays + data.leaveWithdraw.withdrawStatus;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.list_count = this.data.length;
      console.log("List Count:"+this.list_count);
    },
    (error) =>
     {
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
       {
         this.noData=true;
         console.log("204");
       }
     }
   )
  }
  withDrawStatus(i) {
    if (i.leaveWithdraw == null) {
      return
    }
    if (i.leaveWithdraw.withdrawStatus == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Approved') {
      return '#28a745'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Denied') {
      return '#f44336'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Withdrawn') {
      return '#20B2AA'
    }    
    if (i.leaveWithdraw.withdrawStatus == 'Cancelled') {
      return '#e40000'
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  Reason(i) {
    this.dialog.open(Reason, {
      data: i.leaveWithdraw.withdrawReason
    });
  }
  show_leave(i) {
    this.dialog.open(ShowLeave, {
      data: i
    });
  }
}
