import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Validators } from '@angular/forms';
import { DatePipe} from '@angular/common';

@Component({
  selector: 'app-businesstravel-all-list',
  templateUrl: './businesstravel-all-list.component.html',
  styleUrls: ['./businesstravel-all-list.component.css']
})
export class BusinesstravelAllListComponent implements OnInit {
  list_count = 0; 
  noData=false;
  data: any[] = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // displayedColumns =
  //   ['employeeNumber', 'employeeName', 'organizationEmail', 'contact', 'startDate', 'endDate', 'noOfLeaveDays', 'leaveStatus'];
  displayedColumns =
    ['employeeNumber','employeeName', 'organizationEmail','startDate', 'endDate', 'noOfLeaveDays', 'leaveStatus'];
  dataS: any;
  loader=true;
  leaveReport:FormGroup;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  startdate: any;
  enddate: string;
  submitted: boolean = false;
  min: string;
  show_EMsg:boolean = false;
  startdate_required:boolean = false;
  enddate_required:boolean = false;
  max: string;
  employeelist: any;
  employeeId: any;
  orgId:any;
  all_null:boolean = false;
  constructor(private gs: GlobalserviceService, 
    private router: Router,
    private fb: FormBuilder,
    public datepipe:DatePipe) {
  }
  ngOnInit() {
    let x = JSON.parse(localStorage.getItem('data'));
    let mail = x.organizationEmail

    this.gs.activeemployeelist().subscribe(
      (d:any)=>{
        console.log(d);
        this.employeelist = d;
      })

    this.leaveReport = this.fb.group({
      startdate:[''],
      enddate:[''],
      employeeId:[''],
    });

    // this.gs.getEmpLEaveList(mail).subscribe(
      this.orgId=x.organization.organizationId;
      console.log(this.orgId);
      this.gs.getBusinesstravelList(this.orgId).subscribe(
      (d: any) => {

        this.loader=false;
        if(d.length==0)
        {
          this.noData =true;
          console.log("NoData");
        }

        this.data = d
        this.dataS = new MatTableDataSource<any>(d);
        console.log(this.dataS);
        this.dataS.paginator = this.paginator;
        this.dataS.filterPredicate = (data, filter) => {
          const dataStr = data.employee.employeeName + data.employee.organizationEmail
          + data.employee.employeeNumber + data.employee.mobileNumber
          +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
          return dataStr.trim().toLowerCase().indexOf(filter) != -1;
        }
        this.list_count = this.data.length;
      },
      (error) =>
      {
        this.loader=false;
        this.noData= true;
        console.log("error");
        if(error.statusCode==204)
        {
           this.noData=true;
           console.log("204");
        }
      }
      )

    this.gs.getEmpLEaveList(mail).subscribe(d => { })
  }
  startDate(event){
    this.startdate_required=false;
    console.log("start Date:"+event.value);
    this.show_EMsg = false;
    this.min = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    if(this.max != null){
      if(this.max < this.min){
        this.show_EMsg = true;
      }
    }
  }
  EndDate(event){
    this.enddate_required=false;
    this.show_EMsg = false;
    console.log("start Date:"+event.value);
    this.max = this.datepipe.transform(event.value, 'yyyy-MM-dd');
  }
  isselected(event){
    // console.log(event);
    // this.isDisabled = true;
    // this.submitted=false;
  }
  search(){
    this.submitted = true;
    console.log(this.leaveReport.value);
    if(this.leaveReport.invalid){
      return;
    }
    this.startdate = this.datepipe.transform(this.leaveReport.value.startdate,'yyyy-MM-dd');
    console.log(this.startdate);
    this.enddate = this.datepipe.transform(this.leaveReport.value.enddate,'yyyy-MM-dd');
    console.log(this.enddate);
    this.employeeId = this.leaveReport.value.employeeId;
    console.log(this.employeeId);

    if(this.startdate == null && this.enddate == null){
      if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
        this.all_null=true;
        setTimeout(() => {
          this.all_null = false;
        }, 2000)
        return
      }
    }
    if(this.startdate!=null && this.enddate == null){
      this.enddate_required = true;
      return
    }
    else if(this.enddate !=null && this.startdate == null){
      this.startdate_required = true;
      return
    }
    else{
      this.enddate_required=false;
      this.startdate_required=false;
    }
    this.loader=true;
    if(this.startdate != null && this.enddate != null){
      console.log(this.employeeId=='');
      if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
        this.gs.businessreportfilter(this.startdate,this.enddate,this.orgId).subscribe(
          (d:any) => {
            console.log(d);
            this.loader=false;
            this.noData =false;
            if(d.length==0)
            {
              this.noData = true;
              console.log("NoData");
            }
            if(d.statusCode == 204){
              this.noData = true;
              console.log("NoData");
            }
            this.data = d
            this.dataS = new MatTableDataSource<any>(d);
            this.dataS.paginator = this.paginator;
            this.dataS.filterPredicate = (data, filter) => {
              const dataStr = data.employee.employeeName + data.employee.organizationEmail
              + data.employee.employeeNumber + data.employee.mobileNumber
              +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
              return dataStr.trim().toLowerCase().indexOf(filter) != -1;
            }
            this.list_count = this.data.length;
          },
          (error) =>
          {
            this.loader=false;
            this.noData= true;
            console.log("error");
            if(error.statusCode==204)
            {
               this.noData=true;
               console.log("204");
            }
          }
          )
      }
      else{
        this.gs.businesslistBystartdateenddateempId(this.startdate,this.enddate,this.employeeId).subscribe(
          (d:any) => {
            console.log(d);
            this.loader=false;
            this.noData =false;
            if(d.length==0)
            {
              this.noData = true;
              console.log("NoData");
            }
            if(d.statusCode == 204){
              this.noData = true;
              console.log("NoData");
            }
            this.data = d
            this.dataS = new MatTableDataSource<any>(d);
            this.dataS.paginator = this.paginator;
            this.dataS.filterPredicate = (data, filter) => {
              const dataStr = data.employee.employeeName + data.employee.organizationEmail
              + data.employee.employeeNumber + data.employee.mobileNumber
              +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
              return dataStr.trim().toLowerCase().indexOf(filter) != -1;
            }
            this.list_count = this.data.length;
          },
          (error) =>
          {
            this.loader=false;
            this.noData= true;
            console.log("error");
            if(error.statusCode==204)
            {
               this.noData=true;
               console.log("204");
            }
          }
          )
      }
    }
    else {
      this.gs.businesslistByempId(this.employeeId).subscribe(
        (d:any) => {
          console.log(d);
          this.loader=false;
          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            const dataStr = data.employee.employeeName + data.employee.organizationEmail
            + data.employee.employeeNumber + data.employee.mobileNumber
            +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.list_count = this.data.length;
        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
        }
        )
    }

  }

  Clear(){
    this.noData =false;
    this.enddate_required = false;
    this.show_EMsg = false;
    this.startdate_required = false;
    this.all_null=false;
      this.gs.getBusinesstravelList(this.orgId).subscribe(
      (d: any) => {
        this.loader=false;
        if(d.length==0)
        {
          this.noData =true;
          console.log("NoData");
        }

        this.data = d
        this.dataS = new MatTableDataSource<any>(d);
        console.log(this.dataS);
        this.dataS.paginator = this.paginator;
        this.dataS.filterPredicate = (data, filter) => {
          const dataStr = data.employee.employeeName + data.employee.organizationEmail
          + data.employee.employeeNumber + data.employee.mobileNumber
          +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
          return dataStr.trim().toLowerCase().indexOf(filter) != -1;
        }
        this.list_count = this.data.length;
      },
      (error) =>
      {
        this.loader=false;
        this.noData= true;
        console.log("error");
        if(error.statusCode==204)
        {
           this.noData=true;
           console.log("204");
        }
      }
      )
  }


  applyFilter(event: Event) {
    // const filterValue = (event.target as HTMLInputElement).value;
    // const searchDate = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
    // console.log(searchDate);
    // this.dataS.filter = searchDate.trim().toLowerCase();

    // if (this.dataS.paginator) {
    //   this.dataS.paginator.firstPage();
    // }
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    console.log("Search");
    // if(isNaN(s) && !isNaN(Date.parse(s)))
    // return true;
    // else return false;
    if(!isNaN(Date.parse(filterValue)))
    {
       console.log("date");
       const searchDate = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
       console.log(searchDate);
       this.dataS.filter = searchDate.trim().toLowerCase();
       if (this.dataS.paginator) {
        this.dataS.paginator.firstPage();
      }
    }
    else
    {
      console.log("Number");
      this.dataS.filter = filterValue.trim().toLowerCase();
      console.log(this.dataS.filter);
      // this.dataSource.filter = filterValue .trim().toLowerCase();
      if (this.dataS.paginator) {
        this.dataS.paginator.firstPage();
      }
    }
  }
  test(i) {
    if (i.businessTravelStatusName == null) {
      return
    }
    if (i.businessTravelStatusName == 'Pending') {
      return '#8313ff'
    }
    if (i.businessTravelStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.businessTravelStatusName == 'Denied') {
      return '#f44336'
    }
    if (i.businessTravelStatusName == 'Cancelled') {
      return '#e40000'
    }
  }
}
