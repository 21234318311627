<!-- <ng-container *ngIf="isLoading; else elseLoading">
  <div class="loading">
    <h4 class="text-muted">loading....</h4>
    <br>
    <div class="spinner-grow text-secondary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-dark" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container> -->

<!-- <ng-container *ngIf="loader; else elseLoading" class="loading" align="center"> -->
     <!-- <div class="loading" align="center" *ngIf="loader; else elseLoading">  -->
        <!-- <img src="assets/img/Loader3.gif" alt="LoaderImage"> -->
     <!-- </div>  -->
<!-- </ng-container>  -->
<span>
    <h4 class="dynamic-title">Manage Leave Request</h4>
</span>
<ng-container *ngIf="loader; else elseLoading" class="loading">
    <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 

<ng-template #elseLoading>
<div class="container-fluid">
    <!-- <div class="float-left">
        <button mat-raised-button style="transform: translateY(10px);">
            <mat-icon>list</mat-icon>
            MyLeaveList
        </button>
    </div> -->
    <div class="float-right" style="position: relative;top: 6px;">

        <!-- <mat-form-field style="    line-height: 1.6;margin: 0 0 0 8px;  ">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. MK">
      </mat-form-field> -->

      <!-- <div class="form-group search-input topnav-right">                                                                     
        <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
        <button mat-raised-button class="search-btn">
          <mat-icon class="search-icon">search</mat-icon>
        </button>                                                                                                 
      </div> -->

        <mat-form-field appearance="outline">
            <mat-select [(ngModel)]="filter_Data">
                <mat-option *ngFor="let item of filterData" [value]="item">{{item}}</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-raised-button class="search-btn" (click)="get_user_data(filter_Data)">
            <mat-icon class="search-icon">search</mat-icon>
        </button>

    </div>
    <span> </span>
</div>
<ng-container>
    <div class="example-container mat-elevation-z8 main-block">
        <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count > 0" >
            <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="text-center"><b>&nbsp;S.No&nbsp;</b></th>
                <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}</td>
            </ng-container>
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef ><b>From&nbsp;Date</b></th>
                <td mat-cell  *matCellDef="let element" style="white-space: nowrap;">{{element.startDate | date}}</td>
            </ng-container>
            <ng-container matColumnDef="endDate" >
                <th mat-header-cell *matHeaderCellDef ><b>To&nbsp;Date</b></th>
                <td mat-cell *matCellDef="let element" style="white-space: nowrap;">{{element.endDate | date}}</td>
            </ng-container>
            <ng-container matColumnDef="leaveType" >
                <th mat-header-cell *matHeaderCellDef ><b>Leave&nbsp;Type</b></th>
                <td mat-cell *matCellDef="let element">{{element.leaveType.leaveName}}</td>
            </ng-container>
            <ng-container matColumnDef="noOfLeaveDays">
                <th mat-header-cell *matHeaderCellDef class="text-center"><b>No.of&nbsp;Days</b></th>
                <td mat-cell class="text-center" *matCellDef="let element">{{element.noOfLeaveDays}}</td>
            </ng-container>
            <!-- <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%"><b>Reason</b></th>
                <td mat-cell *matCellDef="let element">{{element.leaveReason}}</td>
            </ng-container> -->

            <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%"><b>Leave&nbsp;Reason</b></th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <span class="reason-span" *ngIf="changeasDenyReason==false">{{element.leaveReason}}</span>
      
                  <span class="reason-span" *ngIf="changeasDenyReason==true && clickedbusinessTravelId != i && (isMenuOpen==true || isMenuOpen==false)">{{element.leaveReason}}</span>
                  
                  <span class="reason-span" *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==false">{{element.leaveReason}}</span>
      
                  <span class="denied-span menu" #menu *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">Denied Reason</span>
                  <span class="reason-span-deny menu" #menu *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">{{element.leavedenyReason}}</span>
                </td>
            </ng-container> 
            <!-- <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%"><b>Reason</b></th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <span class="reason-span">{{element.leaveReason}}</span>
                  <span class="denied-span menu" #menu *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">Denied Reason</span>
                  <span class="reason-span-deny menu" #menu *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">{{element.leavedenyReason}}{{i}}{{clickedbusinessTravelId}}</span>
                </td>
            </ng-container>  -->

            <!-- <ng-container matColumnDef="submittedOn">
                <th mat-header-cell *matHeaderCellDef>
                    <b>SUBMITTED&nbsp;ON</b>
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.submittedOn }} </td>
            </ng-container> -->
            <ng-container matColumnDef="leaveStatus">
                <th mat-header-cell *matHeaderCellDef>
                  <b>Status</b>
                </th>
                <td mat-cell *matCellDef="let element; let i = index" style="white-space: nowrap;" [style.color]="test(element)">
                  {{element.leaveStatusName}} 
                  <span *ngIf="element.leaveStatusName=='Approved'" class="material-icons-outlined" 
                    style="font-size: 16px;transform: translate(0px, 4px);">check_circle</span>
                  <a *ngIf="element.leaveStatusName=='Denied'" class="material-icons-outlined" #toggleButton (click)="denyReason(i)"
                    style="font-size: 16px;transform: translate(0px, 4px);">error_outline</a>
                  <a *ngIf="element.leaveStatusName=='Denied'"
                   style="font-size: 20px;">*</a>
                </td>
              </ng-container>

            <!-- <ng-container matColumnDef="leaveStatus">
                <th mat-header-cell *matHeaderCellDef class="text-center"><b>Leave&nbsp;Status</b></th>
                <td mat-cell class="text-center" *matCellDef="let element" [style.color]="test(element)"> {{element.leaveStatusName }} </td>
            </ng-container> -->

            <ng-container matColumnDef="withdraw">
                <th mat-header-cell *matHeaderCellDef class="text-center"><b>Withdraw&nbsp;Status</b></th> 
                <td mat-cell class="text-center" *matCellDef="let element" [style.color]="withDrawStatus(element)">
                    <ng-container *ngIf="element.leaveWithdraw.withdrawStatus == undefined;else condition">{{"--"}}</ng-container>
                    <ng-template #condition>{{element.leaveWithdraw.withdrawStatus}}</ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="star">
                <th mat-header-cell *matHeaderCellDef class="text-center"></th>
                <td mat-cell *matCellDef="let element" class="text-center">
                    <button *ngIf="!(element.leaveStatus == 8 || element.leaveStatus == 6)" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" >
                        <mat-icon style="font-size: 17px;">more_vert</mat-icon>
                    </button>
                    <!-- <button  mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" > -->
                        <mat-icon  *ngIf="(element.leaveStatus == 6 || element.leaveStatus == 8)" style="font-size: 17px;"> -- </mat-icon>
                    <!-- </button> -->
                    <mat-menu #menu="matMenu" class="custom-style"> 
                        <!-- <a mat-menu-item (click)="delete(element)" *ngIf="element.leaveStatus != 'Pending'" >
                    <mat-icon>cancel_presentation</mat-icon>
                    <span>Cancel</span>
                  </a> -->

                        <ng-container *ngIf="element.leaveWithdraw.withdrawStatus == undefined && element.leaveStatusName != 'Cancelled'
                       && element.leaveStatusName != 'Denied' && element.leaveStatusName != 'Pending'">
                            <a mat-menu-item (click)="openWithdrawDialog(element)">
                                <mat-icon class="cancel-trip-icon material-icons-outlined" style="color: brown;">compare_arrows</mat-icon>
                                <span class="cancel-trip-text">Withdraw</span>
                            </a>
                            <!-- <a mat-menu-item (click)="Share(element)" disabled>
                                <mat-icon class="cancel-trip-icon material-icons-outlined" style="color: rgb(93, 186, 248);">share</mat-icon>
                                <span class="cancel-trip-text">Share</span>
                            </a> -->
                        </ng-container>
                        <ng-container *ngIf="element.leaveStatusName == 'Pending'">
                          <a mat-menu-item (click)="cancel(element)">
                              <mat-icon class="cancel-trip-icon material-icons-outlined">clear</mat-icon>
                              <span class="cancel-trip-text">Cancel</span>
                          </a>
                        </ng-container>
                        <ng-container *ngIf="element.leaveStatusName == 'Withdrawn'">
                            <a mat-menu-item>
                                <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:royalblue;">playlist_add_check</mat-icon>
                                <span class="cancel-trip-text">Withdrawn</span>
                            </a>
                        </ng-container>
                        <ng-container
                            *ngIf="element.leaveWithdraw.withdrawStatus != undefined && element.leaveWithdraw.withdrawStatus != null">
                            <a mat-menu-item (click)="showWithdrawDetails(element)">
                                <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:royalblue;">details</mat-icon>
                                <span class="cancel-trip-text">Show Withdraw Details</span>
                            </a>
                        </ng-container>

                        <!-- <a mat-menu-item (click)="reason(element)">
                          <mat-icon>cancel</mat-icon>
                          <span>Cancel</span>
                        </a> -->
                        <!-- <a mat-menu-item (click)="reason(element)">
                            <mat-icon class="cancel-trip-icon material-icons-outlined">chat</mat-icon>
                            <span class="cancel-trip-text">Leave Reason</span>
                        </a> -->
                        <a *ngIf="element.leaveWithdraw.leavewithdrawdenyReason != undefined && element.leaveWithdraw.leavewithdrawdenyReason != null"
                        mat-menu-item (click)="showdenyreason(element.leaveWithdraw.leavewithdrawdenyReason)">
                            <mat-icon class="cancel-trip-icon material-icons-outlined">do_not_disturb</mat-icon>
                            <span class="cancel-trip-text">Deny Reason</span>
                        </a>
                        <!-- <a *ngIf="element.leaveStatus == 6"
                        mat-menu-item (click)="showdenyreason(element.leavedenyReason)">
                            <mat-icon class="cancel-trip-icon material-icons-outlined">do_not_disturb</mat-icon>
                            <span class="cancel-trip-text">Deny Reason</span>
                        </a>   -->
                    </mat-menu>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="container" *ngIf="noData">
            <div class="alert alert-primary" style="width: 100%;">
                No Leave List
            </div>
        </div>
    </div> 
</ng-container>
</ng-template>
<div [hidden]="loader">
    <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>

