import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

// import { Workbook } from 'exceljs';
// import * as fs from 'file-saver';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  exportdatalist :any = [];
  employeeName:string;
  attValues:any;
  constructor() { }
  public exportAsExcelFile(attendence,objkey,attvalues,excelFileName: string): void {
    console.log(attendence);
    console.log(objkey);
    console.log(attvalues);
  //   const header = ["Employee",
  //   objkey.forEach(element => {
  //     console.log(element);
  //   })
  // ]
  // attendence.forEach(element => {
  //   // const data=[
  //   //   EmployeeName:element.employee,
  //   // ]
  //   var data = {
  //     employee : element.employeeName,
  //     date  :  attvalues
  //   };
  //   this.exportdatalist.push(data);
  // });
  // // const data=[
  // //   EmployeeName:element.employee,
  // // ]

  //  //Create workbook and worksheet
  //  let workbook = new Workbook();
  //  let worksheet = workbook.addWorksheet('Car Data');

  //  //Add Header Row
  //  let headerRow = worksheet.addRow(header);
  //  console.log(headerRow);


  //  workbook.xlsx.writeBuffer().then((data) => {
  //   let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //   fs.saveAs(blob, 'CarData.xlsx');
  // })
   
  // //   const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(attendence);
  // //   const myworkbook: XLSX.WorkBook = { Sheets: { 'TimeSheet': myworksheet }, SheetNames: ['TimeSheet'] };
  // //   const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
  // //   this.saveAsExcelFile(excelBuffer, excelFileName);
  // // }

  // // private saveAsExcelFile(buffer: any, fileName: string): void {
  // //   const data: Blob = new Blob([buffer], {
  // //     type: EXCEL_TYPE
  // //   });
  // //   FileSaver.saveAs(data, fileName + '_exported'+ EXCEL_EXTENSION);
  // // }
   //Create workbook and worksheet
   let workbook = new Workbook();
   let worksheet = workbook.addWorksheet('Attendence Data');

   

    // const header = ["Employee","Date"]
     var  column= ["Employee" ];
     console.log(objkey.length);
     for(var i=0;i<=objkey.length-1;i++)
     {
       console.log(i);
       let headerdate=objkey.at(i);
       console.log(headerdate);
       column.push(headerdate);
     }
     console.log(column);
    //  console.log(column.length);
    //  for(var l=0;l<=column.length-1;l++)
    //  {
    //    console.log("column For"+l);
    //    worksheet.getColumn(l).width = 30;
    //  }

     
      // Title For Excel
   const title = 'Attendence Report';
   let titleRow = worksheet.addRow([title]);
   titleRow.font = { name: 'Times New Roman', family: 4, size: 14, bold: true }

   let headerRow = worksheet.addRow(column);
    // Cell Style : Fill and Border
    // headerRow.eachCell((cell, number) => {
    //   cell.fill = {
    //     type: 'pattern',
    //     pattern: 'solid',
    //     fgColor: { argb: 'FFFFFF00' },
    //     bgColor: { argb: 'FF0000FF' }, 
    //   }
    //   cell.style["text-align"] = "right";
    //   cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    // })


   
    var Data =[];
    console.log(attendence.length);
    for(var j=0;j<=attendence.length-1;j++)
     {
      this.employeeName="";
      console.log(this.employeeName);
       console.log(j);
       let empName=attendence.at(j);
       console.log(empName);
       console.log(empName.employee);
       this.employeeName=empName.employee;
       console.log(this.employeeName);
       console.log(empName.date);
       console.log(empName.date.length);
       Data.push(this.employeeName);
       // Data.push(final);
       console.log(this.employeeName);
       console.log(Data);
      //  Data.push(empName.employee);
       for(var k=0;k<=empName.date.length-1;k++)
       {
          console.log(k);
          let attData=empName.date.at(k);
          // console.log(empName.date);
          console.log(attData);
          this.attValues=attData;
          console.log(this.attValues);
          Data.push(this.attValues);
          console.log(Data);
       }
          //  Data.push(this.attValues);
           console.log(Data);
          worksheet.addRow(Data);
          Data=[];
          console.log(Data);
         }
        
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'Attendence.xlsx');
    })

}


}



