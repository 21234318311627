import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Sort} from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import { ExcelService} from 'src/app/excel.service';
import { Workbook } from 'exceljs';


@Component({
  selector: 'app-employee-attendence',
  templateUrl: './employee-attendence.component.html',
  styleUrls: ['./employee-attendence.component.css']
})
export class EmployeeAttendenceComponent implements OnInit {
  year:any;
  Data:any=[];
  employeeAttendence:FormGroup;
  key:any;
  month:any;
  noData=false;
  objvalue:any;
  objkey:any = [];
  attvalues:any = [];
  dataSource:any;
  list_count:any;
  finalData:any=[];
  linedata:any = [];
  empdetails:any= [];
  attendence :any = [];
  headers;
  beforepage:any;
  attLength:any;
  p:number =1;
  itempages : any = 5 ;
  isSubmitted=false;
  dataSearch:any;
  beforesearchpage :any;
  stDate:any;
  minDate:any;
  edDate:any;
  todayDate:any;
  isStartDate=false;
  isEndDate=false;
  submitted=false;
  enddate_required:boolean = false;
  startdate_required:boolean = false;
  startdate_validation: boolean = false;
  startmin: string;
  endate: any;
  startdate: any;
  exportdatalist :any = [];
  dates:any;
  
  isDisabled = true;
  // listData:any=[];
  
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private globalserviceService:GlobalserviceService,
    private fb: FormBuilder,
    private datepipe:DatePipe,private excelservice :  ExcelService) { }

  ngOnInit(): void {
    this.isDisabled = true;
    let today=new Date();
    console.log("today:"+today);
    this.todayDate =new Date();
    console.log(this.todayDate);
    this.year=today.getFullYear();
    this.employeeAttendence = this.fb.group({
      // attendence:[''],
      startdate:[''],
      enddate:[''],
      month:['']
    });
  }
  // StartDate
  startDate(event)
  {
    if(event.value==null)
    {
      console.log(event.value+"Start Date Null");
      this.isDisabled = true;
    }
    console.log("start Date:"+event.value);
    console.log("start Date:"+event.value);
    let startDate = this.datepipe.transform(this.employeeAttendence.value.startdate, 'YYYY-MM-dd');
    console.log(startDate);
    this.stDate=startDate;
    console.log(this.stDate); 
  }

  // endDate
  endDate(event)
  {
    if(event.value==null)
    {
      console.log(event.value+"End Date Null");
      this.isDisabled = true;
    }
    console.log("end date:"+event.value);
  }

  change(event){
    console.log("event");
    // console.log(index);
    console.log(event.value);
    this.month=event.value;
    console.log(this.month);

    this.employeeAttendence.patchValue({
      startdate : null,
      enddate :null
    })
    
    if(this.month != 0)
    {
      
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), this.month-1, 1);
   //     console.log(firstDay);
        var lastDay = new Date(date.getFullYear(), this.month, 0);
    //    console.log(lastDay);
        let monthstartDate = this.datepipe.transform(firstDay,'YYYY-MM-dd');
    //    console.log(monthstartDate);
            let monthDate=monthstartDate;
    //    console.log(this.monthDate);
        let monthLastDate = this.datepipe.transform(lastDay,'YYYY-MM-dd');
   //     console.log(monthLastDate);
        let monthEndDate=monthLastDate;
    //    console.log(this.monthEndDate);
        this.todayDate = monthEndDate;
        this.endate =   monthEndDate;
        this.startdate = monthstartDate;
    }else
    {
      this.todayDate =new Date();
      this.endate =new Date();
    }

  }

  showChanges() {
    console.log("show Change");
    this.p=1;
  }

    // mat Sort Function
    sortData(sort: Sort) {
      console.log("ascending and Decending data");
      const data = this.attendence.slice();
      if (!sort.active || sort.direction === '') {
        this.attendence  = data;
        return;
      }
  
      this.attendence = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
         case 'employee':
            return this.compare(a.employee, b.employee, isAsc);
          default:
            return 0;
        }
      });
    }
  
    compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
   

    changepage(){
      console.log("Search");
      if(this.dataSearch.length!= 0)
      {
         this.p = 1;
          console.log(this.p);
          console.log("success Data");
      }
      else{
         this.p = this.beforesearchpage;
         console.log("nodata");
      }
    }
  isselected(event){
    console.log(event);
    this.isDisabled = true;
    this.submitted=false;
  }
  date(date){ 
    // if(date.value==null)
    // {
    //   console.log(date.value+"Start Date Null");
    //   this.isDisabled=false;
    // }
    console.log(date);
    // console.log(startdate);
    console.log(date.target.value);
    let startDate = this.datepipe.transform(date.target.value, 'YYYY-MM-dd');
    console.log(startDate);
    this.startmin=startDate;
    console.log(this.startmin);
    if(date.value!=null ){
      this.submitted = false;
      this.startdate_required =false;
    }
    console.log(this.employeeAttendence.value.enddate);//3
    console.log(date.value);//4
    if(this.employeeAttendence.value.enddate){
      if(date.value<=this.employeeAttendence.value.enddate){
        console.log("enddate");
        this.startdate_validation=false;
      }
      else if(date.value>this.employeeAttendence.value.enddate){
        this.startdate_validation=true;
      }
    }
  }
  datechange(date){
    // if(date.value==null)
    // {
    //   console.log("Date Empty"+date);
    //   this.isDisabled=false;
    // }
    
    if(date.value!=null ){
      this.submitted = false;
      this.enddate_required =false;
      
    } 
    console.log(this.employeeAttendence.value.startdate);
    console.log(date.value);
    if(date.value>=this.employeeAttendence.value.startdate){
      console.log("endadte");
      this.startdate_validation=false;
    }
  }

  search()
  {
    this.isSubmitted=true;
    this.attendence.length = 0;
     console.log(this.headers);
     this.Data.length = 0;
    let startDate = this.datepipe.transform(this.employeeAttendence.value.startdate, 'YYYY-MM-dd');
    console.log(startDate);
    this.stDate=startDate;
    this.minDate=startDate;
    console.log(this.minDate);
    console.log("Final Start Date"+this.stDate);
    let endDate = this.datepipe.transform(this.employeeAttendence.value.enddate, 'YYYY-MM-dd');
    console.log(endDate);
    this.edDate=endDate;
    console.log("Final end Date"+this.edDate);

    console.log(this.employeeAttendence);
    if((this.employeeAttendence.value.month == 0 || this.employeeAttendence.value.month == null)  
      && (this.employeeAttendence.value.startdate== '' || this.employeeAttendence.value.startdate == null)
      && (this.employeeAttendence.value.enddate == '' || this.employeeAttendence.value.enddate == null))
    {
      this.submitted=true;
    }
    else if((this.employeeAttendence.value.month == 0  || this.employeeAttendence.value.month == null) 
      || ((this.employeeAttendence.value.startdate== ''|| this.employeeAttendence.value.startdate == null) 
      && (this.employeeAttendence.value.enddate == ''|| this.employeeAttendence.value.enddate == null)))
    {
      this.submitted=false;
    }

    // if(this.employeeAttendence.controls.month.value == '' && this.employeeAttendence.controls.startdate.value== '' &&
    //   this.employeeAttendence.controls.enddate.value == '' ){
    //     this.submitted=true;
    // }    else if(this.employeeAttendence.controls.month.value == '' || this.employeeAttendence.controls.startdate.value== '' ||
    // this.employeeAttendence.controls.enddate.value == ''){
    //   this.submitted=false;
    // }


    if(this.employeeAttendence.invalid){
      console.log("invalid");
      return
    }

    if(this.employeeAttendence.value.month==0 && this.stDate==null && this.edDate ==null){
      console.log("invalid");
      this.startdate_required =false;
      this.enddate_required = false;
      return
    }

  
    if(this.employeeAttendence.value.month!=0 && this.stDate!=null && this.edDate ==null){
      console.log("invalid");
      this.enddate_required = true;
      return
    }

    if(this.employeeAttendence.value.month!=0 && this.stDate==null && this.edDate !=null){
      console.log("invalid");
      this.startdate_required = true;
      return
    }

    let monthyear=this.year+"-"+this.month; 
    console.log(monthyear);
    // console.log("this.attendence.length" + this.attendence.length);

    if(this.employeeAttendence.value.month!=0 &&(this.stDate!=null && this.edDate!=null))
    {
    console.log("Both Month and Date Functions");
    //  if(this.employeeAttendence.value.month!=0 &&(this.stDate!=null && this.edDate!=null))
     this.globalserviceService.GetAttendenceList(this.stDate,this.edDate)
  .subscribe((d: any) =>{
   // this.attendence = [];
   this.attendence = [];
   console.log("sucess");
   this.noData =false;
   console.log(d);
   console.log("id");
   this.Data=d;
   if(d.statusCode==204)
   {
     // let err=this.Data.length=0;
     this.attLength=0;
     console.log(this.attLength);
     // this.attendence = [];
     this.objkey=0;
     this.noData=true;
     return
   }
   //  console.log(this.Data.employeeInOutLine);
   //  console.log(this.Data.employeeInOutLine.index);
   // if(d.statusCode)
   // {
   // }
   else
   {
     this.Data.forEach((element,index)=> {
       this.finalData=element;
       this.key=element.keyvalue;    
       this.objkey=Object.keys(this.key);
       let objvalue = (Object.values(this.key));
       console.log(objvalue);
       this.attvalues=objvalue;
          console.log(this.attvalues);
         var empdata = {
           employee : element.employeeName,
           date  :  objvalue
         };
       this.attendence.push(empdata);
     });
     console.log(this.attendence);
     this.isDisabled = false;
     this.attLength=this.Data.length;
     console.log(this.attLength);
   }
},
(error) =>{
  console.log("error");
  if(error.status==404){
    this.list_count =0;
    this.noData=true;
    console.log("404");
  }
}) 
  }
        
    else if(this.employeeAttendence.value.month!=0 &&(this.stDate==null && this.edDate==null))
    {
      console.log("Month Function");
    this.globalserviceService.getMonthEmpList(monthyear).subscribe((d: any) =>
    {
      this.attendence = [];
      console.log("sucess");
      this.noData =false;
      console.log(d);
      console.log("id");
      this.Data=d;
      if(d.statusCode==204)
      {
        // let err=this.Data.length=0;
        this.attLength=0;
        console.log(this.attLength);
        // this.attendence = [];
        this.objkey=0;
        this.noData=true;
        return
      }
      //  console.log(this.Data.employeeInOutLine);
      //  console.log(this.Data.employeeInOutLine.index);
      // if(d.statusCode)
      // {
      // }
      else
      {
        this.Data.forEach((element,index)=> {
          this.finalData=element;
          console.log(this.finalData);
          this.key=element.keyvalue; 
          console.log(this.key);   
          this.objkey=Object.keys(this.key);
          console.log(this.objkey);
          let objvalue = (Object.values(this.key));
          console.log(objvalue);
          this.attvalues=objvalue;
          console.log(this.attvalues);
            var empdata = {
              employee : element.employeeName,
              date  :  objvalue
            };
          this.attendence.push(empdata);
        });
        console.log(this.attendence);
        this.isDisabled = false;
        this.attLength=this.Data.length;
        console.log(this.attLength);
      }
    
    })
  }
     else if(this.employeeAttendence.value.month==0 ||this.employeeAttendence.value.month==undefined)
    {
      console.log("startDate and EndDate function");
      if(this.employeeAttendence.value.startdate!= '' || this.employeeAttendence.value.enddate != ''){
        if(this.employeeAttendence.value.startdate== '' || this.employeeAttendence.value.startdate == null){
          this.startdate_required=true;
          this.enddate_required=false;
        }
        else if(this.employeeAttendence.value.enddate == '' || this.employeeAttendence.value.enddate == null){
          this.startdate_required=false;
          this.enddate_required=true;
        }
      }
      if(startDate>endDate){
        console.log("if");
        this.startdate_validation=true;
        return
      }
      else{
        console.log("else");
        this.startdate_validation=false;
      }
      if(this.stDate!=null)
      {
         if(this.edDate!=null)
         {
          this.globalserviceService.GetAttendenceList(this.stDate,this.edDate)
       .subscribe((d: any) =>{
        // this.attendence = [];
        this.attendence = [];
        console.log("sucess");
        this.noData =false;
        console.log(d);
        console.log("id");
        this.Data=d;
        if(d.statusCode==204)
        {
          // let err=this.Data.length=0;
          this.attLength=0;
          console.log(this.attLength);
          // this.attendence = [];
          this.objkey=0;
          this.noData=true;
          return
        }
        //  console.log(this.Data.employeeInOutLine);
        //  console.log(this.Data.employeeInOutLine.index);
        // if(d.statusCode)
        // {
        // }
        else
        {
          this.Data.forEach((element,index)=> {
            this.finalData=element;
            this.key=element.keyvalue;    
            this.objkey=Object.keys(this.key);
            let objvalue = (Object.values(this.key));
            console.log(objvalue);
            this.attvalues=objvalue;
            console.log(this.attvalues);
              var empdata = {
                employee : element.employeeName,
                date  :  objvalue
              };
            this.attendence.push(empdata);
          });
          console.log(this.attendence);
          this.isDisabled = false;
          this.attLength=this.Data.length;
          console.log(this.attLength);
        }
     },
     (error) =>{
       console.log("error");
       if(error.status==404){
         this.list_count =0;
         this.noData=true;
         console.log("404");
       }
     }) 
         } 
         else
         {
          // this.isEndDate=true;
          console.log("end Date Null");
          }
      }
      else
      {
        // this.isStartDate=true;
        console.log("start Date Null");
      }
      
    } 
    else{
      // this.isSubmitted=true;
      // this.isStartDate =false;
      console.log("StartDate and EndDate vaildation error");
    }
  }

  export()
  {
    console.log("export");
      this.exportdatalist = [];
      console.log(this.attendence);
      // this.objkey.forEach(element => {
      //     console.log(element);
      //     // this.dates=element;
      //     // console.log(this.dates);
      //     });

        //   const title = 'Attendence Report';
        //   const header = ["Employee", this.dates]
        //   let workbook = new Workbook();
        //   let worksheet = workbook.addWorksheet('Attendence Data');

        //  // Add new row
        //  let titleRow = worksheet.addRow([title]);
        //  //Add Header Row
        //  let headerRow = worksheet.addRow(header);

        //  // Cell Style : Fill and Border
        //       headerRow.eachCell((cell, number) => {
        //         cell.fill = {
        //           type: 'pattern',
        //           pattern: 'solid',
        //           fgColor: { argb: 'FFFFFF00' },
        //           bgColor: { argb: 'FF0000FF' }
        //         }
        //         cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        //       });
        
      // this.attendence.forEach(element => {
      //    console.log(element.employee);
      //    console.log(element.date);
      //    console.log(this.attvalues);
      //     var attendenceData = 
      //     {
      //         EmployeeName:element.employee,
      //         Date:element.date
      //         // Date:this.attvalues.forEach(element => {
      //         //   console.log(element);
      //         // })
      //         // Project : element.project.projectName,
      //         // Task  :   element.task.taskName,
      //         // Date :     element.date,
      //         // Description : element.description,
      //         // WorkedHours : element.workedHours,
      //         // TimeSheetStatus : element.taskDetailStatusName
      //     }
      //     this.exportdatalist.push(attendenceData);
      // });this.attvalues=objvalue;
      console.log(this.attvalues);
      this.excelservice.exportAsExcelFile(this.attendence,this.objkey,this.attvalues, "TimeSheet")
  }


  // const title = 'Attendence Report';
  // const header = ["Employee", "element"]

}



