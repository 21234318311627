import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-timings-import',
  templateUrl: './employee-timings-import.component.html',
  styleUrls: ['./employee-timings-import.component.css']
})
export class EmployeeTimingsImportComponent implements OnInit {
  employeeForm:FormGroup;
  isSubmitted: boolean = false;
  today: Date;
  fileInputLabel: string;
  empinoutfile :any;
  empinoutData:any;
  sucess_msg:any;
  error_msg:any;
  isDate:any;
  success:any=false;
  error:any=false;
 
  constructor(private fb: FormBuilder,
    private GlobalserviceService : GlobalserviceService,
    private datepipe : DatePipe,private router:Router,
    private dialogRef : MatDialogRef<EmployeeTimingsImportComponent>
  ) { }

  ngOnInit(): void {
    this.employeeForm = this.fb.group({
      date:['',Validators.required],
      employeetimings:['',Validators.required]
    })
    this.today = new Date();
  }
  save(){
    console.log("Submit");
    this.isSubmitted=true;
    console.log(this.employeeForm);
    if(this.employeeForm.invalid){
      return
    }
    this.empinoutData = JSON.parse(localStorage.getItem('data'));
    console.log(this.empinoutData);
    let employeeId = this.empinoutData.employeeNumber;
    console.log(employeeId);
    // let organizationId = this.empinoutData.organization.organizationId;
    // console.log(organizationId);
    const formData = new FormData();
    formData.append("file", this.employeeForm.get('employeetimings').value);
    this.isDate = this.datepipe.transform(this.employeeForm.value.date , 'YYYY-MM-dd');
    console.log("Date import:"+this.isDate);
    formData.append('date', this.isDate);
    formData.append('organizationId',"1");

    this.GlobalserviceService.uploadFile(formData).subscribe((result: any) =>
    {
      if(result.statusCode == 200)
      {
        //this.loader = true;
        console.log("Create IN Out");
        this.success = true;
        this.sucess_msg = result.description;
        Swal.fire({
          text:result.description,
          icon:'success'
        })
        this.dialogRef.close();
        setTimeout(()=> {
        this.router.navigate(["Home/empInOutList"]);
        },2000)
      }
      else
      {
        this.error = true;
        this.sucess_msg = result.description;
        console.log("Date already Exist");
        Swal.fire({
          text:result.description,
          icon:'error'
        })
        setTimeout(()=> {
            this.error=false
        },2000)
        // this.employeeForm.patchValue({
        //   employeetimings:['']
        // })
        // window.location.reload();
      }
    }, error => {
      console.log(error);
      this.error = true;
      this.sucess_msg = error.error.description;
      console.log("Time Invaild Exist");
      Swal.fire({
        text: error.error.description,
        icon:'error'
      })
      setTimeout(()=> {
          this.error=false
      },2000)
      this.employeeForm.patchValue({
        employeetimings:['']
      })
    });
  }
  // getFileDetails(event){
  //   console.log(event);
  // }
  onFileSelect(event)
  {
    console.log(event);
    if (event.target.files.length > 0)
    {
      const file = event.target.files[0];
        this.fileInputLabel = file.name;
        console.log(this.fileInputLabel);
        this.employeeForm.get('employeetimings').setValue(file);
      ///}
    }
  }

}
