
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MyErrorStateMatcher } from 'src/app/Authenticaton/login/login.component';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatSelect } from '@angular/material/select'; 
import Swal from 'sweetalert2';
import { MatOption } from '@angular/material/core';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export class LeaveType {
  leaveTypeId: any;
  leaveName: string;
  noOfDays: any;
  createdOn: any;
  status: any;
 
}

@Component({
  selector: 'app-leave-type-list',
  templateUrl: './leave-type-list.component.html',
  styleUrls: ['./leave-type-list.component.css']
})
export class LeaveTypeListComponent implements OnInit {


  
  isLoading: boolean;
  
  leaveTypeForm: FormGroup;
  endDate_disable: boolean;
  matcher = new MyErrorStateMatcher();
  myFilter: any;
  noOFDays: any = 0;
  min: any;
  WorkingDays: any;
  empId: any;
  type: LeaveType[] = [];
  employeeList: any[] = [];
  isSelectAll : boolean = false;
  leaveTypeId: any;

  // employeeNumber:any;

  select: any;
  allSelected: boolean = false;
  filteredEmployees: any;
  auto: any;
  selectedEmployees: any[] = [];
  
  @ViewChild(MatSelect) matSelect: MatSelect;
  form: FormGroup;
 
  selectedEmployeeControl = new FormControl();
  searchControl = new FormControl('');
  filteredEmployeeList: any[] = [];
  submitted: any;
compareFn: any;

  
  
 
 constructor(private gs: GlobalserviceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) { 
    
    }

  ngOnInit(): void {
    
    this.isLoading = false;

    

    this.endDate_disable = true;
    
    this.gs.getLeaveType().subscribe(
      (d: any) => {
        d.map(
          e=>{
            if(e.status== 1){
              this.type.push(e)
            }
          }
        )
      }
    )
    // OverAll Employee Active and Inactive List in Dropdown
    // this.gs.getEmpList().subscribe(
    //   (e: any[]) => {
    //     console.log("Employee List");
    //     e.forEach(element => {
    //       if(element.status==1)
    //       {
    //         this.employeeList.push(element);
    //       }
    //     });
    //     console.log("Employee List"+this.employeeList);
    //   }
    // )

    // OverAll Employee Active List in Dropdown
    
    this.gs.getEmpList1().subscribe(
      (e: any[]) => {
        this.employeeList=e;
        console.log("Employee List"+this.employeeList);
      }
    )

   
    let x = JSON.parse(localStorage.getItem('data'));
    this.empId = x.employeeId;

    this.leaveTypeForm = this.fb.group({
      status: [''],
      employee: ['', Validators.required],
      // employeeNumber: [''],
      leaveType: ['', Validators.required],
      noOfDays:[''],

    })
    this.gs.getEmpListById(this.empId).subscribe(
      (d: any) => {
        //working days
        this.WorkingDays = d.noOfWorkingDays;
        // get date
        const sample_date_data: any[] = [];
        this.gs.get_leave_date(this.empId).subscribe((a: any) => {
          a.forEach(element => {
            let x = element.split("-");
            sample_date_data.push(new Date(`'${x[0]}/${x[1]}/${x[2]}'`).getTime())
          });
          let x = sample_date_data;
        })
        if (this.WorkingDays == 5) {
          this.myFilter = (a: Date): boolean => {
            const day = a.getDay();
            const time = a.getTime();
            // Prevent Saturday and Sunday from being selected.
            return day !== 0 && day !== 6 && !sample_date_data.find(x => x == time);
          }
        } else {
          this.myFilter = (a: Date): boolean => {
            const day = a.getDay();
            const time = a.getTime()
            // Prevent Saturday and Sunday from being selected.
            return day !== 0 && !sample_date_data.find(x => x == time);
          }
        }
      }
    );

   }

  
get f() {
    return this.leaveTypeForm.controls;
  }

  _Employee(){
    return this.f.employee.hasError('required') ? 'Please select Employees' :''
  }

  

  toggleAllSelection() {
    
    if (this.allSelected) {
      
      this.leaveTypeForm.get('employee').patchValue([...this.employeeList.map(item => item.employeeId)]);
    } else {
      
      this.leaveTypeForm.get('employee').patchValue([]);
    }
  }
 
  
  optionClick() {
    
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }



  
  add() {
    const selectedEmployeeIds = this.leaveTypeForm.get('employee').value;

    if (selectedEmployeeIds.length === 0) {
     
      Swal.fire({
        text: 'Please select at least one employee.',
        icon: 'warning'
      });
      return; 
    }

    this.selectedEmployees = selectedEmployeeIds.map(id => {
      const selectedEmployee = this.employeeList.find(emp => emp.employeeId === id);
      return selectedEmployee ? selectedEmployee.employeeName : '';
    });

    // Concatenate selected employee names and add them to the employee name field
    const employeeNameField = this.leaveTypeForm.get('employeeName');
    if (employeeNameField) {
      const currentEmployeeNames = employeeNameField.value ? employeeNameField.value.split(', ') : [];
      const updatedEmployeeNames = [...currentEmployeeNames, ...this.selectedEmployees];
      employeeNameField.setValue(updatedEmployeeNames.join(', '));
    }
    this.matSelect.close();
  }

 
  
cancel(){
    this.leaveTypeForm.reset();
    this.matSelect.close();
    this.allSelected = false;
  }

  
 
  
 

  nofoDays(i) {
    let start = new Date(this.f.startDate.value);
    let end = new Date(this.f.endDate.value)
    // Copy date objects so don't modify originals
    var s: any = new Date(+start);
    var e: any = new Date(+end);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12, 0, 0, 0);
    e.setHours(12, 0, 0, 0);

    // Get the difference in whole days
    var totalDays = Math.round((e - s) / 8.64e7);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    var days = wholeWeeks * this.WorkingDays + 1;

    // If not even number of weeks, calc remaining weekend days
    if (totalDays % 7) {
      s.setDate(s.getDate() + wholeWeeks * 7);
      if (this.WorkingDays == 5) {
        while (s < e) {
          s.setDate(s.getDate() + 1);

          // If day isn't a Sunday or Saturday, add to business days
          if (s.getDay() != 0 && s.getDay() != 6) {
            ++days;
          }
        }
      }
      else {
        while (s < e) {
          s.setDate(s.getDate() + 1);

          // If day isn't a Sunday or Saturday, add to business days
          if (s.getDay() != 0 && s.getDay() != 7) {
            ++days;
          }
        }
      }
    }
    this.noOFDays = days;
    return days;
  }
  // date validation
  someMethodName(date: any) {
    this.min = new Date(date.value)
    this.endDate_disable = false
  }

  // empValue(e){
  //  this.employeeNumber = e;
  // }

  onSubmit(data) {
    if (this.leaveTypeForm.invalid) {
      return
    }
    let leaveRequests = [];
    let formData = this.leaveTypeForm.value;

    formData.employee.forEach(employeeId => {
      let leaveRequest = {
        status: 1,
        employee: {
          employeeId: employeeId
        },
        leaveType: {
          leaveTypeId: formData.leaveType
        },
        noOfDays: this.noOFDays
      };
      leaveRequests.push(leaveRequest);
    });
    

   
    
    this.isLoading = true;
    this.gs.leaveBalance(leaveRequests).subscribe(
      (d: any) => {
        Swal.fire({
          text: d.description,
          icon: 'success'
        })
        this.isLoading = false;
      },
      err => {
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        })
        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
        this.router.navigate(['/Home']);
      }
    )
  }


    individualCheckboxChanged() {
      const selectedEmployeeIds = this.leaveTypeForm.get('employee').value;
      if (selectedEmployeeIds.length === this.employeeList.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    }
      
   

}

  


