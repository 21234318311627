import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Console } from 'console';
import { MyErrorStateMatcher } from 'src/app/Authenticaton/login/login.component';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-leave-adjustment',
  templateUrl: './leave-adjustment.component.html',
  styleUrls: ['./leave-adjustment.component.css']
})
export class LeaveAdjustmentComponent implements OnInit {
  isLoading: boolean;
  leaveForm:FormGroup;
  employeeIdList:any = [];
  employeeValue:any;
  leaveTypeList:any = [];
  leaveDays:any;
  length:any;

  constructor(private gs: GlobalserviceService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.length = 4;
    this.isLoading = false;

    // this.gs.getEmpList().subscribe(
    //   (data:any) =>{
    //     this.employeeIdList = data;
    //   }
    // )

    // OverAll Employee Active List in Dropdown
    this.gs.getEmpList1().subscribe(
      (e: any[]) => {
        console.log(e);
        this.employeeIdList=e;
        console.log("Active Employee Status");
        console.log(this.employeeIdList);
      }
    )

    // LeaveType
    // this.gs.getLeaveType().subscribe(
    //   (d: any) => {
    //     d.map(
    //       e=>{
    //         if(e.status== 1){
    //           this.leaveTypeList.push(e);
    //         }
    //         console.log(this.leaveTypeList);
    //       }
    //     )

    //   }
    // )

    this.leaveForm = this.fb.group({
      employeeId:['',Validators.required],
      employeeName:[''],
      leaveBalance:['',Validators.required],
      leaveadjustmenttype:['',Validators.required],
      leaveadjustmentdays:['',Validators.required],
      leaveadjustmentreason:['',Validators.required]
    })
  }

  get f(){
    return this.leaveForm.controls;
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  leave(d){
    this.leaveDays = d;
  }

  empValue(e,id){
    this.employeeValue = e;
    let x = this.leaveForm.value;
    x.leaveBalance = ''
    this.gs.getLeaveBalanceById(id).subscribe(
      (data:any) =>{
        this.leaveTypeList = data;
      }
    )
  }

  leaveData(e){
    let x = this.leaveForm.value;
    if(x.leaveadjustmenttype == "add"){
      if(e.target.value >= 22){
        Swal.fire({
          text:'More than 21 days not accepted',
        })
      }
    }

    if(x.leaveadjustmenttype == "detect"){
      if(e.target.value > this.leaveDays){
        Swal.fire({
          text:'Adjustment Days are more than leave balance',
        })
      }
    }

  }

  onSubmit(){
    if(this.leaveForm.invalid){
      return
    }
    let x = this.leaveForm.value;


    if(x.leaveadjustmenttype == "add"){
      if(x.leaveadjustmentdays >= 22){
        Swal.fire({
          text:'More than 21 days not accepted'
        })
        return
      }
    }

    if(x.leaveadjustmenttype == "detect"){
      if(x.leaveadjustmentdays > this.leaveDays){
        Swal.fire({
          text:'Adjustment Days are more than leave balance'
        })
        return
      }
    }

    x['employee'] = {
      employeeId:x.employeeId
    }

    x['leaveBalance'] = {
      'leaveBalanceId' : Number(x.leaveBalance)
    }

    x['leaveadjustmentdays'] = Number(x.leaveadjustmentdays)

    // console.log(JSON.stringify(x))
    this.isLoading = true;

    this.gs.addAdjustment(x).subscribe(
      (data:any) =>{
        Swal.fire({
          text:'LeaveBalanceAdjustment Created Successfully',
          icon:'success'
        })
        this.isLoading = false;
        this.router.navigate(['./adjustment-list'])
      },
      (error:any)=>{
        console.log(error)
        Swal.fire({
          text:error.error.message,
          icon:'error'
        })
        this.isLoading = false;
      }
    )
  }
}
