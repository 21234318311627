<style>
  .full-width{
    width:100%;
  }
</style>
<div class="container">
  <h2 align="center">Calendar</h2>
  <form [formGroup]="calendarForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Calendar Name</mat-label>
      <input matInput formControlName="calendarName">
      <mat-error *ngIf="f.calendarName.hasError('required')">
        <span>Calendar Name is required</span>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Organization Name</mat-label>
      <mat-select formControlName="organization">
        <mat-option *ngFor="let org of organization" [value]="org.organizationId" (click)="change(org.organizationId)">
          {{org.organizationName}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.organization.hasError('required')">
        <span>Organization is required</span>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Branch Name</mat-label>
      <mat-select formControlName="branch">
        <mat-option *ngFor="let org of branchList" [value]="org.branchId">
          {{org.branchname}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.branch.hasError('required')">
        <span>Branch is required</span>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Year</mat-label>
      <mat-select formControlName="year">
        <mat-option *ngFor="let y of year" [value]="y.yearId">
          {{y.yearName}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.year.hasError('required')">
        <span>Year is required</span>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group" *ngIf="data.calendarStatus == 2">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Calendar Status</mat-label>
      <mat-select formControlName="calendarStatus">
        <mat-option value="1">Active</mat-option>
        <mat-option value="2">InActive</mat-option>
      </mat-select>
      <mat-error *ngIf="f.calendarStatus.hasError('required')">
        <span>Calendar Status is required</span>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group" align="center">
    <button mat-stroked-button color="accent" style="width: 45%; margin: 0 0 0 8px" type="button"
    mat-dialog-close>
      Cancel
    </button>
    <button mat-stroked-button color="primary" style="width: 45%; margin: 0 0 0 8px" type="submit">
      <span class="material-icons"> beenhere </span>
      {{data.calendarId ? 'Update' :'Create'}}
    </button>
  </div>
</form>

</div>
