<span>
  <h4 class="dynamic-title">Withdraw List</h4>
</span>
<div class="container-fluid">
  <div class="float-left">
    <!-- <button mat-raised-button style="transform: translateY(10px);" [routerLink]="['/Home/empLeaveList']">
      <span class="material-icons">keyboard_arrow_left</span>
      Back</button> -->
      <button mat-stroked-button class="apply-button" [routerLink]="['/Home/empLeaveList']" color="primary" style="margin: 10px 0px;">
        <mat-icon class="material-icons-outlined apply-button-icon">keyboard_arrow_left</mat-icon> Back
      </button>

    <!-- <button mat-raised-button style="transform: translateY(10px);">
      <span class="material-icons">list</span> <span>Employee Withdraw List</span>
    </button> -->
  </div>
  <div class="float-right">
    <!-- <button mat-raised-button>
      <mat-icon>search</mat-icon>
    </button>
    <mat-form-field style="    line-height: 1.6;margin: 0 0 0 8px;  ">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. MK">
    </mat-form-field> -->
    <div class="form-group search-input topnav-right">                                                                     
      <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
      <button mat-raised-button class="search-btn">
          <mat-icon class="search-icon">search</mat-icon>
      </button>                                                                                                 
    </div>
  </div>
  <span> </span>
</div>

<ng-container>
  <div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count>0">
      <ng-container matColumnDef="employeeName">
        <th mat-header-cell *matHeaderCellDef > <b>Emp&nbsp;Name</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.employee.employeeName | titlecase}} </td>
      </ng-container>

      <ng-container matColumnDef="leaveType">
        <th mat-header-cell *matHeaderCellDef > <b>Leave&nbsp;Type</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.leaveType.leaveName}} </td>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef> <b>From&nbsp;Date</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.leaveWithdraw.startDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef> <b>To&nbsp;Date</b> </th>
        <td mat-cell *matCellDef="let element"> {{element.leaveWithdraw.endDate | date}} </td>
      </ng-container>

      <ng-container matColumnDef="withdrawDays">
        <th mat-header-cell *matHeaderCellDef class="text-center"> <b>No.of&nbsp;Days</b> </th>
        <td mat-cell *matCellDef="let element" class="text-center"> {{element.leaveWithdraw.withdrawDays}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> <b>Status</b> </th>
        <td mat-cell *matCellDef="let element" [style.color]="withDrawStatus(element)">
          {{element.leaveWithdraw.withdrawStatus}} </td>
      </ng-container>

      <ng-container matColumnDef="star" >
        <th mat-header-cell *matHeaderCellDef class="text-center"></th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon style="font-size: 17px;">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item (click)="show_leave(element)">
              <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#5E06D0">menu</mat-icon>
              <span class="cancel-trip-text" style="color:#5E06D0">Show Leave List</span>
            </a>
            <a mat-menu-item (click)="Reason(element)">
              <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#20b2aa">rate_review</mat-icon>
              <span class="cancel-trip-text" style="color:#20b2aa">WithDraw Reason</span>
            </a>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="container" style="padding-top:15px;" *ngIf="noData"> 
      <div class="alert alert-primary" align="center" style="width: 100%">
        <!-- <h1 align="center">No Withdraw list</h1> -->
         No Withdraw list
      </div>
    </div>
  </div>
</ng-container>
 <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons>
 </mat-paginator>
<!-- <ng-template #elseTemplate>
  <div class="container" style="padding-top:15px;">
    <div class="alert alert-primary" align="center" style="width: 100%">
       <h1 align="center">No Withdraw list</h1>
      No Withdraw list
    </div>
  </div>
</ng-template> -->

