<div class="container-fluid p-3">
  <mat-card class="mat-card pl-0 pr-0">
    <mat-card class="header">
      <mat-icon class="material-icons-outlined logo">lock_clock</mat-icon>
      <!-- <mat-icon class="material-icons-outlined" id="log1">add</mat-icon> -->
      <span>Record Timings</span>
    </mat-card>
     <form [formGroup]="employeeForm" (ngSubmit)="save()" class="form">
      <div class="row search-form">
        <div class="col-md-3" id="loginform" *ngIf="!empData">
          <mat-form-field class="example-full-width" appearance="outline" style="width:100%;display: inline;">
            <mat-label class="">Employee Name<span class="text-danger"> *</span></mat-label> 
            <mat-select formControlName="employee"
              placeholder="Select Employee Name" #select multiple>
              <div class="select-all">
                <mat-checkbox
                  [(ngModel)]="allSelected"
                  [ngModelOptions]="{standalone: true}"
                  type="checkbox"
                  (change)="toggleAllSelection()">
                  Select All</mat-checkbox>
              </div>
              <!-- <mat-option  (click)="optionClick(emp.employeeId,$event)"  (click)="add()" *ngFor="let emp of employeeList">
                {{emp.employeeName}}
              </mat-option> -->
              <mat-option *ngFor="let emp of employeeList" [value]="emp.employeeId" (click)="optionClick(emp.employeeId,$event)" (click)="add()">
                {{emp.employeeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field>
            <mat-label>Favorite food</mat-label>
            <mat-select  #select multiple>
              <div class="select-all">
                  <mat-checkbox [(ngModel)]="allSelected"
                                  [ngModelOptions]="{standalone: true}"
                                  (change)="toggleAllSelection()">Select All</mat-checkbox>
              </div>
              <mat-option (click)="optionClick()" *ngFor="let food of foods" [value]="food.value">
                {{food.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          <div *ngIf="(isSubmitted && employeeForm.controls['employee'].invalid) || employeeForm.controls['employee'].dirty || employeeForm.controls['employee'].touched">
            <small *ngIf="employeeForm.controls['employee'].errors?.required"  id="req" style="color:red;font-size:12px;position: relative;bottom:20px;">Employee name required</small>
          </div>
        </div>
        <!-- <div class="">
          <div class="example-button-container sp3" style="margin-top: 10px;">
            <button mat-mini-fab color="primary" type="button" (click)="add()" class="add-icon" aria-label="Example icon button with a filter list icon">
              <mat-icon class="material-icons-outlined" style="font-size:20px;">add</mat-icon>
            </button>
          </div>
        </div> -->
        <div class="col-md-3" id="r2">
          <mat-form-field class="example-full-width" appearance="outline" style="width:100%;display: inline;">
            <mat-label>Select Date<span class="text-danger"> *</span></mat-label> 
            <input matInput (click)="picker.open()"
              formControlName="date" placeholder="Choose a Date"
              [max]="today" [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <div *ngIf="(isSubmitted && employeeForm.controls['date'].invalid) || employeeForm.controls['date'].dirty || employeeForm.controls['date'].touched">
            <small *ngIf="employeeForm.controls['date'].errors?.required"  id="req" style="color:red;font-size:12px;position: relative;bottom:20px;">Date Required.</small>
          </div>
        </div>
      </div>
      <div>
        <div class="row content2">
          <!-- <div class="col-md-2 mb-1" *ngIf="empLength>5">
            <div class="select-pagination">
                <span class="show-text">Show : </span>
                <select class="select_list new-select_list"  [(ngModel)]="itempages"   [ngModelOptions]="{standalone: true}" (change)="showChanges()">
                    <option class="form-control" value="5" selected>5</option>
                    <option class="form-control" value="10">10</option>
                    <option class="form-control" value="15">15</option>
                    <option class="form-control" value="20">20</option>
                    <option class="form-control" value="25">25</option>
                </select>
            </div>
          </div> -->
           
          <div class="col-md-12 mb-2 float-right" *ngIf="empLength>=5">
            <div class="dataTables_filter">
              <label class="show-text mb-0">Search :
                <input type="search" class="form-control input" [(ngModel)]="search" (keyup) = "changepage()"  
                [ngModelOptions]="{standalone: true}"> 
              </label>
            </div>
          </div>
        </div>
        <div class="table-part">
          <table class="table table-bordered table-responsive" matSort (matSortChange)="sortData($event)" style="width:100%">
            <thead style="width:100%">
              <tr>
                <th id="size1" mat-sort-header="employeeName" style="width:34%;" class="text-left">Employee&nbsp;Name</th>
                <th id="size2" mat-sort-header="timeIn" style="width:22%">&nbsp;In&nbsp;Time&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th id="size3"mat-sort-header="timeOut" style="width:22%">&nbsp;Out&nbsp;Time&nbsp;&nbsp;&nbsp;&nbsp;</th>
                <th id="size4"mat-sort-header="totalTime" style="width:22%">Total&nbsp;Time</th>
                <!-- <th id="size5" style="width:10%">Action</th> -->
              </tr>
            </thead>
            <tbody style="width:100%">   
              <ng-container formArrayName="tableRows" *ngFor="let group of getFormControls.controls  | employeepipe:search let i=index">
                <tr [formGroupName]="i">
                  <td class="text-capitalize">{{group.controls.employeeName.value}}</td>
                  <td>
                    <!-- <input type="time" name="time" placeholder="hrs:mins" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" class="inputs time" required> -->
                    <input type="text" [attr.id]="'intime' + i" class="form-control" id="intime"  formControlName="timeIn"  (keyup)="inout($event,i)" (change)="inout($event,i)">
                    <div *ngIf="(isSubmitted && group['controls'].timeIn.invalid) || group['controls'].timeIn.dirty">
                      <!-- <small  *ngIf="group['controls'].timeIn.errors?.required" class="text-danger float-left" id="vaild">Please enter in-time</small> -->
                      <small  *ngIf="group.controls['timeIn'].errors?.pattern" id="err" style="color:red;font-size:12px;"> Please enter vaild in-time </small>
                    </div>
                    <span [attr.id]="'inouterror' + i" style="color:red;font-size:12px;"
                    [ngStyle] ="{'display':id?'none':'none'}" class="float-left">Give vaild time</span>
                    <!-- <div *ngIf="(isSubmitted && group['controls'].timeIn.invalid) || group['controls'].timeIn.dirty">
                      <small *ngIf="group['controls'].timeIn.errors?.required" class="text-danger"  id="vaild">* InTime Required</small>
                     </div> -->
                  </td>
                  <td>
                    <input type="text" [attr.id]="'outtime' + i" class="form-control" formControlName="timeOut" (keyup)="inout($event,i)" (change)="inout($event,i)">
                    <div *ngIf="(isSubmitted && group['controls'].timeOut.invalid) || group['controls'].timeOut.dirty">
                      <!-- <small *ngIf="group['controls'].timeOut.errors?.required" class="text-danger float-left" id="vaild">Please enter out-time</small> -->
                      <small  *ngIf=" group.controls['timeOut'].errors?.pattern" id="err" style="color:red;font-size:12px;">Please enter vaild outTime</small>
                    </div>
                    
                    <!-- <div  *ngIf=" group.controls['timeOut'].errors?.pattern" id="err" style="color:red;font-size:12px;">
                      Please enter vaild outTime
                     </div> -->
                    <span [attr.id]="'timeerror' + i" style="color:red;font-size:12px;"
                    [ngStyle] ="{'display':id?'none':'none'}" class="float-left">Give vaild time</span>
                    <!-- <span [attr.id]="'timeerror' + i" style="color:red;font-size:14px;"
                    [ngStyle] ="{'display':id?'none':'none'}"
                    >*Give Vaild Time</span> -->
                  </td>
                  <td>
                    <input type="text" class="form-control" id="totaltime"  formControlName="totalTime" readonly>
                  </td>
                  <!-- <td>
                    <mat-icon class="material-icons-outlined" style="color:red;font-size: 19px;line-height: 1.8;" (click)="delete(group.controls)">delete</mat-icon> 
                  </td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
        <!-- Pagination code -->
        <!-- <div class="col-md-12 text-right">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
       </div> -->
        <!-- <div class="col-md-12 text-right" id="page" *ngIf="empLength>5">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div> -->

        <div class="d-flex justify-content-end" id="r1">
          <button mat-stroked-button color="accent" type="button" (click)="cancel()"
            class="form-button mr-1">Cancel
          </button>
          <div>
            <button mat-stroked-button color="primary" class="form-button disable-new" type="submit" [disabled]="isDisabled">
              <!-- <span class="material-icons-outlined" style="font-size:16px;">beenhere</span> {{id?"Update":"Create"}} -->
              <span class="material-icons-outlined" style="font-size:16px;">beenhere</span>Submit
            </button>
          </div>
        </div>
      </div>
   </form>
  </mat-card>
</div>
<!-- sucess Dialog  box -->
<!-- <div class="text-center" *ngIf="success">
   <div class="alert alert-success" role="alert">
      <strong>Success!</strong><br>{{message}}
   </div>
</div> --> 
<!-- error Meaaage -->
<!-- <div *ngIf="error" class="alert alert-danger alert-dismissible">
  <strong>Error!</strong><br>{{message}}
</div> -->