<style>
  .full-width{
    width:100%;
  }
</style>
<div class="container">
  <div class="form-group">
    <form [formGroup]="holidayFrom" (ngSubmit)="onSubmit()">
      <div class="form-group" *ngIf="!data.id">
        <mat-form-field class="full-width" >
          <mat-label>Leave Type</mat-label>
          <mat-select formControlName="leaveType">
            <mat-option value="0" (click)="holiClick(0)">Holiday</mat-option>
            <mat-option value="1" (click)="holiClick(1)">Exception</mat-option>
          </mat-select>
          <mat-error *ngIf="f.leaveType.hasError('required')">
            Leave Type is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="full-width">
          <mat-label>Holiday Name</mat-label>
          <input matInput formControlName="holidayName" />
          <mat-error *ngIf="f.holidayName.hasError('required')">
            Please enter <strong>holidayName</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group" *ngIf="holiday">
        <mat-form-field class="full-width">
          <mat-label>Holiday Type</mat-label>
          <mat-select formControlName="holidaytype">
            <mat-option *ngFor="let type of holidayType" [value]="type.listTypeValueId">
              {{type.listTypeValueName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.holidaytype.hasError('required')">
            <span>Holiday Type is required</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group" *ngIf="exception">
        <mat-form-field class="full-width">
          <mat-label>Holiday Type</mat-label>
          <mat-select formControlName="holidaytype">
            <mat-option *ngFor="let type of exceptionType" [value]="type.listTypeValueId">
              {{type.listTypeValueName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.holidaytype.hasError('required')">
            <span>Holiday Type is required</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="full-width">
          <mat-label>Date</mat-label>
          <input
            matInput
            (click)="picker.open()"
            formControlName="holidayDate"
            [disabled] = "dateDisable"
            [matDatepickerFilter]="myFilter"
            [matDatepicker]="picker"
            placeholder="select start date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi></mat-datepicker>
          <mat-error *ngIf=" f.holidayDate.hasError('required')">
            Please select <strong>Holiday Date</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group" *ngIf="data.id">
        <mat-form-field class="full-width">
          <mat-label>Holiday Status</mat-label>
          <mat-select formControlName="holidayStatus">
            <mat-option value="1">
              Active
            </mat-option>
            <mat-option value="2">
              InActive
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.holidayStatus.hasError('required')">
            <span>Holiday Status is required</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <button mat-stroked-button color="accent" style="margin: 0 0 0 5px" type="button"
        mat-dialog-close>
          Cancel
        </button>
        <button mat-stroked-button color="primary" style="margin: 0 0 0 5px" type="submit">
          <span class="material-icons"> beenhere </span>
          {{data.id ? 'Update' :'Create'}}
        </button>
        <!-- <button mat-stroked-button color="warn" style="margin: 0 0 0 5px" type="button"
         *ngIf="data.id" (click)="delete(data)">
          <span class="material-icons"> delete </span>
          Delete
        </button> -->

      </div>

    </form>
  </div>
</div>
