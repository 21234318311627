<h1 mat-dialog-title>Time Entry</h1>
<form [formGroup]="taskForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>&nbsp;Date</mat-label>
        <input matInput (click)="picker.open()"
          [matDatepicker]="picker" placeholder="select date" formControlName="date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker touchUi></mat-datepicker>
        <mat-error *ngIf="f.date">{{date()}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Hours</mat-label>
        <input matInput placeholder="Enter hours" formControlName="hour" (keypress)="noNumber($event)" maxlength="2">
        <mat-error *ngIf="f.hour">{{hour()}}</mat-error>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="fill">
        <mat-label>&nbsp;Task Name</mat-label>
        <mat-select placeholder="Select Leave Type" formControlName="taskName"  #employ>
          <mat-option *ngFor="let e of data" [value]="e.symbol">
            {{e.symbol}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.taskName">{{taskName()}}</mat-error>
      </mat-form-field>

    <div class="form-group">
      <textarea placeholder="Description" style="width:100%;min-height: 100px;"
      formControlName="description"></textarea>
    </div>

  </div>
  <div mat-dialog-actions>
    <button mat-stroked-button color="warn" type="reset" mat-dialog-close>Cancel</button>
    <button mat-raised-button  type="submit" color="primary" cdkFocusInitial>Save</button>
  </div>
</form>
