<span>
  <h4 class="dynamic-title">Add/Manage Branch</h4>
</span>
<ng-container *ngIf="loader; else elseLoading" class="loading">
  <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 

<ng-template #elseLoading>
   <ng-container>
    <div class="container-fluid">
      <div class="float-left">
        <!-- <button mat-raised-button style="transform: translateY(10px);margin-bottom: 30px;" (click)="addBranch()" >
          <mat-icon>home_work</mat-icon>
          Branch
        </button>  -->
        <button mat-stroked-button class="apply-button" (click)="addBranch()" color="primary" >
          <mat-icon class="material-icons-outlined apply-button-icon">business</mat-icon>
          Create Branch</button>
      </div>
      <div class="float-right">
        <!-- <button mat-raised-button>
          <mat-icon>search</mat-icon>
        </button>
        <mat-form-field style="line-height: 1.6;margin: 0 0 0 8px;">
          <mat-label>Search</mat-label>
          <input matInput (keyup)="applyFilter($event)">
        </mat-form-field> -->

        <div class="form-group search-input topnav-right">                                                                     
          <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
          <button mat-raised-button class="search-btn">
            <mat-icon class="search-icon">search</mat-icon>
          </button>                                                                                                  
        </div>
      </div>
      <span> </span>
    </div>
    <div class="example-container mat-elevation-z8">
      <table  mat-table [dataSource]="dataSource" matSort *ngIf="list_count>0">
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            <b>S.No</b>
          </th>
          <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}</td>
        </ng-container> 
        <ng-container matColumnDef="BranchName">
          <th mat-header-cell *matHeaderCellDef> 
            <b>Branch Name</b>
          </th>
          <td mat-cell *matCellDef="let element"> {{element.branchname}} </td>
        </ng-container>
  
        <ng-container matColumnDef="Location">
          <th mat-header-cell *matHeaderCellDef> 
            <b>Branch Address</b> 
          </th>
          <td mat-cell *matCellDef="let element"> {{element.branchlocation}} </td>
        </ng-container>
  
        <ng-container matColumnDef="Status" >
          <th mat-header-cell *matHeaderCellDef> 
            <b>Status</b> 
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="statuscolor(element.branchstatusname)"> {{element.branchstatusname}} </td>
        </ng-container>

        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            <b>Action</b>
          </th>
          <td mat-cell *matCellDef="let element" class="text-center">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon style="font-size: 17px;">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <a mat-menu-item (click)="edit(element)">
                <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#1A83FF">create</mat-icon>
                <span class="cancel-trip-text" style="color:#1A83FF">Edit</span>
              </a>
              <!-- <a mat-menu-item (click)="delete(element)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </a> -->
              <a mat-menu-item (click)="statusupdate(element)">
                <mat-icon *ngIf="element.branchstatusname=='Active'"class="cancel-trip-icon material-icons-outlined" style="color:#FF7171">block</mat-icon>
                <span *ngIf="element.branchstatusname=='Active'" style="color:#FF7171">In-Active</span>
                <mat-icon *ngIf="element.branchstatusname=='In-Active'"class="cancel-trip-icon material-icons-outlined" style="color:#01E325">done</mat-icon>
                <span *ngIf="element.branchstatusname=='In-Active'" style="color:#01E325">Active</span>
              </a>
            </mat-menu>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      </table>
       <div class="container"  *ngIf="noData">
         <div class="alert alert-primary"  align="center" style="width: 100%">
           No Branch list
         </div>
       </div>
    </div>
</ng-container>
</ng-template>
<div [hidden]="loader">
  <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>