import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-designation',
  templateUrl: './add-designation.component.html',
  styleUrls: ['./add-designation.component.css']
})
export class AddDesignationComponent implements OnInit {
  designationForm: FormGroup;
  isSubmitted: boolean = false;
  designationdata: any = {};
  designationlist: any = [];

  constructor(private fb: FormBuilder,
    private GlobalserviceService: GlobalserviceService,
    private datepipe: DatePipe,
    private router: Router,
    private dialogRef: MatDialogRef<AddDesignationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.designationForm = this.fb.group({
      designation: ['',[Validators.required,Validators.pattern(/^[a-zA-Z]+(([. ][a-zA-Z ])?[a-zA-Z]*)*$/)]],
      description: ['']
    })

    console.log(this.data);
    if(this.data) {
      this.GlobalserviceService.getdesignationListById(this.data).subscribe
      ((data: any) => {
        this.designationdata = data;
        console.log(this.designationdata);
        this.designationForm.patchValue({
          designation: this.designationdata.listTypeValueName,
          description: this.designationdata.description,
        })
        console.log(this.designationForm);
      })
    }
  }
  save() {
    console.log("Submit");
    this.isSubmitted = true;
    if(this.designationForm.invalid){
      return
    }
    if(this.data){
      var updatedesignation = {
        listTypeValueId: this.designationdata.listTypeValueId,
        listTypeValueName: this.capitalizeWordsName(this.designationForm.value.designation),
        description: this.designationForm.value.description,
        listTypes: 4,
        status: this.designationdata.status
      }
      console.log(updatedesignation);

      this.GlobalserviceService.updatedesignation(this.designationdata.listTypeValueId, updatedesignation).subscribe
        ((d: any) => {
          Swal.fire({
            text: d.description,
            icon: 'success'
          })
          this.dialogRef.close(d);
        },
          err => {
            Swal.fire({
              text: err.error.description,
              icon: 'error'
            })
          }
        )
    }
    else {
      let createdesignation = {
        listTypeValueName: this.capitalizeWordsName(this.designationForm.value.designation),
        description: this.designationForm.value.description,
        listTypes: 4,
        status: 2
      }
      console.log(createdesignation);
      this.GlobalserviceService.createdesignation(createdesignation).subscribe
        ((d: any) => {
          Swal.fire({
            text: d.description,
            icon: 'success'
          })
          this.dialogRef.close(d);
        },
          err => {
            Swal.fire({
              text: err.error.description,
              icon: 'error'
            })
          }
        )
    }
  }

  capitalizeWordsName(text){
    if (!text) return text;
    return text.replace(/(?:^|\s)\S/g,(res)=>{ return res.toUpperCase();})
  };
}

