import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/common';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

@Component({
  selector: 'app-dashboard1',
  templateUrl: './dashboard1.component.html',
  styleUrls: ['./dashboard1.component.css']
})
export class Dashboard1Component implements OnInit {

  calendarOptions: CalendarOptions
  calendarList:any = []
  calendarName:any;
  calendarId:any
  userType:any;
  branchId:any

  constructor(private gs:GlobalserviceService) { }

  ngOnInit(): void {

    let data = JSON.parse(localStorage.getItem('data'))
    this.userType = data.userType.userType
    this.branchId = data.branch.branchId;

    this.calendarOptions = {
      droppable: true,
      // weekends: true,
      editable: true,
      selectable: true,
      selectMirror: true,
      dayMaxEvents: true,
      themeSystem: "bootstrap4",
      // themeName: 'Materia',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth'
      },
      initialView: 'dayGridMonth',
      displayEventTime: false,
    };

    this.fetchData()
  }

  fetchData(){
    this.gs.getCalByBranch(this.branchId).subscribe(
      (d: any) => {
        d.map(
          e => {
            let date = new Date()
            if(e.year.yearName == date.getFullYear()){
              this.calendarId = e.calendarId
            }
          }
        )

        this.gs.getCalendar().subscribe(
          (x: any) => {
            x.map(e=>{
                this.calendarList.push(e)
            })
            this.calendarList.map(
              e=>{
                if(e.calendarId == this.calendarId){
                  e.checked = true
                  this.calendarName = e.calendarName
                }
                else{
                  e.checked = false
                }
              }
            )
          }
        )
      })
  }

}
