<span>
  <h4 class="dynamic-title">Designation</h4>
</span>
<ng-container *ngIf="loader; else elseLoading" class="loading">
  <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 
<ng-template #elseLoading>
  <div class="container-fluid">
    <div class="float-left" style="position: relative;top: 6px;">
      <button mat-stroked-button class="apply-button" (click)="Add()" color="primary">
        <mat-icon class="material-icons-outlined apply-button-icon">add</mat-icon>
        Add Designation</button>
    </div>
    <div class="float-right" style="position: relative;top: 6px;">
      <div class="form-group search-input topnav-right">                                                                     
        <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
        <button mat-raised-button class="search-btn">
          <mat-icon class="search-icon">search</mat-icon>
        </button>                                                                                                
      </div>
    </div>
  </div>

  <ng-container>
    <div class="col-md-12 full-content">
      <!-- <mat-card class=""> -->
        <!-- <div class="row">
          <div class="col-md-6">
            <mat-card class="header">
              <mat-icon class="material-icons-outlined" id="logo">work_outline</mat-icon>
              <span class="attendance">Designation</span>
            </mat-card>
            <div class="float-left">
              <button mat-raised-button>
                <span style="display: flex;">
                  <mat-icon class="material-icons-outlined" id="logo" style="position: relative;top: 4px;">work</mat-icon>
                  <span class="attendance">Designation</span>
                </span>
              </button>
            </div>
          </div>
        </div> -->
        <!-- <div class="row" style="padding: 10px 0px;">
          <div class="col-sm-6 mb-2">
            <div class="select-pagination">
              <span>Show : </span>
              <select class="select_list new-select_list" [(ngModel)]="itempages">
                <option class="form-control" value="5"> 5</option>
                <option class="form-control" value="10">10</option>
                <option class="form-control" value="15">15</option>
                <option class="form-control" value="20">20</option>
                <option class="form-control" value="25">25</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6 mb-2">
            <mat-card class="header float-right" style="transform: translate(0px);" (click)="Add()">
              <span style="display: flex;">
                <mat-icon class="material-icons-outlined" id="logo">add</mat-icon>
                <span class="attendance">Designation</span>
              </span>
            </mat-card>
          </div>
        </div> -->
        <ng-container>
          <div class="example-container mat-elevation-z8 main-block">
            <table class="table-responsive" mat-table [dataSource]="dataSource" matSort *ngIf="list_count>0">
              <ng-container matColumnDef="sno">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                  <b>S.No</b>
                </th>
                <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}</td>
              </ng-container>  
              <ng-container matColumnDef="designation">
                <th mat-header-cell *matHeaderCellDef>
                  <b>{{'Designation'}}</b>
                </th>
                <td mat-cell *matCellDef="let element">{{element.listTypeValueName}}</td>
              </ng-container>
              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                  <b>{{'Description'}}</b>
                </th>
                <td mat-cell *matCellDef="let element">{{element.description}} </td>
              </ng-container> 
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-center">
                  <b>{{'Action'}}</b>
                </th>
                <td mat-cell *matCellDef="let element" class="text-center">
                  <mat-icon class="material-icons-outlined edit-icon"
                  (click)="edit(element.listTypeValueId)">mode_edit</mat-icon>
                </td>
              </ng-container> 

              <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
            </table>
            <div class="container" *ngIf="noData">
                <div class="alert alert-primary" style="width: 100%; justify-content: center;">
                  No Designation Found
                </div>
            </div>
          </div>
        </ng-container>
        <!-- <table class="table table-bordered table-responsive">
          <thead>
            <th>Designation</th>
            <th>Description</th>
            <th class="text-center">Action</th>
          </thead>
          <tbody>
            <tr
              *ngFor="let designation of designationlist | paginate: { itemsPerPage: itempages, currentPage: p } let i=index">
              <td>{{designation.listTypeValueName}}</td>
              <td>{{designation.description}}</td>
              <td class="text-center">
                <mat-icon class="material-icons-outlined edit-icon"
                  (click)="edit(designation.listTypeValueId)">mode_edit</mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="container" *ngIf="noData">
          <div class="alert alert-primary" style="width: 100%;">
            No Data
          </div>
        </div> -->
      <!-- </mat-card> -->
    </div>
  </ng-container>
</ng-template>
<div [hidden]="loader">
  <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>
<!-- <div class="col-md-12 text-right" id="page">
  <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div> -->