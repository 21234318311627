<form [formGroup]="employeeForm">
    <div class="d-flex">
        <div class="title-block">
            <mat-card class="header">
                <mat-icon class="material-icons-outlined logo">lock_clock</mat-icon>
                <span>Record Timings</span>
            </mat-card>
            <!-- <h2 class="title">Create Employee Timings</h2> -->
        </div>
        <div class="concel-block">
            <mat-icon class="material-icons-outlined cancel-icon" mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" id="loginform">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Select Date<span class="text-danger"> *</span></mat-label> 
                <input matInput (click)="picker.open()"
                    formControlName="date" placeholder="Choose a Date"
                    [max]="today" [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div *ngIf="(isSubmitted && employeeForm.controls['date'].invalid) || employeeForm.controls['date'].dirty || employeeForm.controls['date'].touched">
                <small *ngIf="employeeForm.controls['date'].errors?.required"  id="req" style="color:red;font-size:12px;">Date required</small>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" id="loginform">
            <label class="form-control-label">Employee Timings Attachment<span class="text-danger"> *</span></label>
            <input type="file" class="form-control email-input" (change)="onFileSelect($event)" formControlName="employeetimings" placeholder=""          
             accept=".xls,.xlsx" multiple>
            <div *ngIf="(isSubmitted && employeeForm.controls['employeetimings'].invalid) || employeeForm.controls['employeetimings'].dirty || employeeForm.controls['employeetimings'].touched">
                <small *ngIf="employeeForm.controls['employeetimings'].errors?.required"  id="req" style="color:red;font-size:12px;">Please upload employee timings attachment</small>
            </div>
            <span class="text-muted import-note">Note : Upload format is .xls / xlsx.</span>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <span class="text-muted">
                *** Please download the sample file and import the data as mentioned format. ***
            </span>  
            <a class="download-sample text-danger" href="assets/sample_doucments/Employee_Timing.xlsx" download="Employee_Timings.xlsx">
                Download Sample
            </a> 
        </div>
    </div>
    <div class="row float-right">
        <div class="col-md-12 pt-1" id="loginform">
            <button mat-stroked-button color="primary" class="form-button" type="submit" (click)="save()">Create</button>
        </div>
    </div>
</form>

