<style>
  .container{
    padding:10px;
  }
  .button{
    float: right;
    margin-top:20px;
    outline: none;
  }
</style>
<div class="container">
  <h4>{{data}}</h4>
  <button mat-stroked-button type="button" color="primary" class="button" (click)="cancel()" align="end">Cancel</button>
</div>
