<ng-container *ngIf="loader; else elseLoading" class="loading">
    <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 
<ng-template #elseLoading>
<div class="container">
    <div class="float-left">
        <button mat-raised-button  style="transform: translateY(10px);">
            <span class="material-icons ">timelapse</span>   Leave Balance
        </button>
    </div>
    <div class="float-right">
      <button mat-raised-button >
        <mat-icon>search</mat-icon>
      </button>
      <mat-form-field style="line-height: 1.6;margin: 0 0 0 8px;">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilter($event)">
      </mat-form-field>
    </div>
    <span></span>
</div>
<ng-container>
<div class="example-container mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort  *ngIf="list_count > 0">
        <ng-container matColumnDef="leaveName" sticky>
            <th mat-header-cell *matHeaderCellDef > <b>LEAVE&nbsp;TYPE&nbsp;NAME</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.leaveType.leaveName | titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="noOfDays">
            <th mat-header-cell *matHeaderCellDef > <b>NO&nbsp;OF&nbsp;DAYS</b> </th>
            <td mat-cell *matCellDef="let element"> {{element.noOfDays}} </td>
        </ng-container>

        <!-- <ng-container matColumnDef="star" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button  aria-label="Example icon-button with a menu"
                    disabled>
                    <mat-icon>more_vert</mat-icon>
                </button>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="container" *ngIf="noData">
        <div class="alert alert-primary" align="center" style="width: 100%;">
            No Leave Balance List
        </div>
    </div>
    </div>
   </ng-container>
</ng-template>
<div [hidden]="loader">
    <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>
<!-- <mat-paginator  style="bottom: 0; position: sticky;" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->
