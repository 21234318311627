import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/components/employee/employee-list/employee-list.component';
import { TaskDialogComponent } from '../task-dialog/task-dialog.component';

@Component({
  selector: 'app-resource-task-list',
  templateUrl: './resource-task-list.component.html',
  styleUrls: ['./resource-task-list.component.css']
})
export class ResourceTaskListComponent implements OnInit {

  displayedColumns:string[] = ['position','name','weight','symbol'];
  dataSource:any[];
  taskName:any;

  constructor(private dialog:MatDialog) { }

  ngOnInit(): void {

    this.dataSource = [
      {position: 1, name: 'Leave Application', weight: 1.0079, symbol: 'H'},
      {position: 2, name: 'Leave Application', weight: 4.0026, symbol: 'He'},
      {position: 3, name: 'Leave Application', weight: 6.941, symbol: 'Li'},
      {position: 4, name: 'Leave Application', weight: 9.0122, symbol: 'Be'},
      {position: 5, name: 'Leave Application', weight: 10.811, symbol: 'B'},
      {position: 6, name: 'Leave Application', weight: 12.0107, symbol: 'C'},
    ];
  }

  openDialog(i){

    const dialogRef = this.dialog.open(TaskDescription, {
      width: '350px',
      data :i,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }


}

@Component({
  selector: 'taskDescription',
  templateUrl: 'taskDescription.html',
})
export class TaskDescription {

  constructor(
    public dialogRef: MatDialogRef<TaskDescription>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
