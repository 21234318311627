import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogDataExampleDialog } from '../employee/employee-list/employee-list.component';
import { Reason } from '../withdraw-list/withdraw-list.component';
import { DenyreasonComponent } from '../denyreason/denyreason.component';
import { ResourceLoader } from '@angular/compiler';
import { MainLayoutComponent } from 'src/app/main-layout/main-layout.component';



@Component({
  // providers:[MainLayoutComponent ],
  selector: 'app-leave-req',
  templateUrl: './leave-req.component.html',
  styleUrls: ['./leave-req.component.css'],
  // template: '<app-main-layout  [receivedData]="isData"></app-main-layout>'
})
export class LeaveReqComponent implements OnInit 
{
  data: any[] = []
  isData = 0;
  leaveData=false;
  isLoading:Boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber', 'employeeName', 'organizationEmail', 'startDate', 'endDate', 'noOfLeaveDays','reason','leaveStatus','star'];
  dataSource: any;
  loader=true;
  // call child method in parent components
  // @ViewChild(MainLayoutComponent) mainLayoutComponent: MainLayoutComponent;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  mail: any;
  constructor(private gs: GlobalserviceService, private mainLayComponent: MainLayoutComponent, 
    private router: Router, public dialog: MatDialog) {
  }
  ngOnInit() {
    let x = JSON.parse(localStorage.getItem('data'));
    let user_type = x.userType;
    let mail = x.organizationEmail;
    this.mail=mail;
    console.log(this.mail);
    this.gs.getleaveReqPending(mail).subscribe(
      (d: any) => {
        this.loader=false;
        if(d.length==0)
        {
          this.leaveData=true;
          console.log("NOData");
        }
        this.data = d;
        console.log(this.data)
        let x = JSON.parse(localStorage.getItem('data'));
        this.isData = this.data.length
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.filterPredicate = (data, filter) => {
          const dataStr = data.employee.employeeNumber + data.employee.employeeName
          + data.employee.organizationEmail + data.employee.organizationEmail + data.employee.mobileNumber
          + data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
          return dataStr.trim().toLowerCase().indexOf(filter) != -1;
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // location.reload();
      },
      (error:any) =>
      {
        console.log(error);
        console.log("error");
        if(error.error.statusCode==204)
        {
          this.loader=false;
          this.leaveData=true;
           console.log("204");
        }
      }
      )
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  edit(i) {
    this.router.navigate(['../../employee-edit', i.employeeId])
  }

  leaveStatus(i){
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
  }


  // public callMe(): void {
  //   this.mainLayComponent.leaveRequest();
  // }

  approve(i) 
  {
    if (i.applyTo == 0) {
      i['applyTo'] = [];
    }
    else {
      i['applyTo'] = [i.employee.organizationEmail];
    }
    if (i.cc == 0) {
      i['cc'] = [];
    }
    else {
      i['cc'] = [i.employee.personalEmail];
    }

    Swal.fire({
      title: 'Are you sure? Do you want to Approve',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Approve'
    }).then((result) => {
      if (result.value) {
        i['leaveStatus'] = 5;
        this.isLoading = true;
        this.gs.UpdateLeaveStatus(i.leaveRequestId, i).subscribe(d => {
          Swal.fire(
            'Approved!',
          )
          this.isLoading = false;
          // this.mainLayComponent.leaveRequest();
          this.mainLayComponent.notifytest = 100;
          // this.afterRefresh();
           window.location.reload();
        },
          err => {
            Swal.fire(
              'Error',
            )
            this.isLoading = false
          },
          // () => {
          //   setTimeout(() => {
          //     // window.location.reload()
          //   }, 1000);
          //   this.isLoading = false
          // }
        );

      }
      //
    })
  }

  denied(i) {
    if (i.applyTo == 0) {
      i['appplyTo'] = [];
    }
    else {
      i['applyTo'] = [i.employee.organizationEmail];
    }
    if (i.cc == 0) {
      i['cc'] = [];
    }
    else {
      i['cc'] = [i.employee.personalEmail];
    }

    // leaveStatus
    Swal.fire({
      title: 'Are you sure? Do you want to Deny',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Deny'
    }).then((result) => {
      if (result.value) {
        i['leaveStatus'] = 6
        if (result.value == true) {
          const dialogRef = this.dialog.open(DenyreasonComponent, {
            data: "",
            width: '500px',
            disableClose: true,
          })
          dialogRef.afterClosed().subscribe(result => {

            if (result) {

              i['leavedenyReason'] = result.denyReason;
              this.isLoading = true
              this.gs.UpdateLeaveStatus(i.leaveRequestId, i).subscribe(d => {
                Swal.fire({
                  text:'Denied',
                  icon:'success'
                })
                this.isLoading = false;
                this.mainLayComponent.leaveRequest();
                this.mainLayComponent.notifytest = 100;
                // this.callChildMethod(); 
                 window.location.reload();
                //  this.afterRefresh();
              },
                err => {
                  Swal.fire({
                    text:err.error.message,
                    icon:'error'
                  })
                  this.isLoading = false
                }
              );
            }
          })
        }
      }
      //
    })
  }
  // dialog
  openDialog(i) {
    let id = i.employee.employeeId;
    let leave_list_ofEmp: any = []
    this.gs.getMyleave(id).subscribe((a: any) => {
      a.forEach(element => {
        leave_list_ofEmp.push(element)
      });
    })
    let v = leave_list_ofEmp;
    const x = this.dialog.open(DialogDataExampleDialog, {
      data: v
    });
    x.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  reason(i) {
    this.dialog.open(Reason, {
      data: i.leaveReason
    });
  }

  afterRefresh()
  {
    console.log("Refresh:"+this.mail);
    this.gs.getleaveReqPending(this.mail).subscribe(
      (d: any) => {
        this.loader=false;
        if(d.length==0)
        {
          this.leaveData=true;
          console.log("NOData");
        }
        this.data = d;
        console.log(this.data);
        let x = JSON.parse(localStorage.getItem('data'));
        this.isData = this.data.length;
        console.log(this.isData);
        this.mainLayComponent.updateNotify(this.isData);
        console.log(this.mainLayComponent.notify);
        // this.mainLayComponent.ngOnInit();
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.filterPredicate = (data, filter) => {
          const dataStr = data.employee.employeeNumber + data.employee.employeeName
          + data.employee.organizationEmail + data.employee.organizationEmail + data.employee.mobileNumber
          + data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
          return dataStr.trim().toLowerCase().indexOf(filter) != -1;
        }
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // location.reload();
      },
      // (error) =>
      // {
      //   console.log(error);
      //   this.loader=false;
      //    this.isData=0;
      //   this.leaveData=true;
      //   console.log("error");
      //   if(error.statusCode==204)
      //   {
      //     this.isData=0;
      //     this.leaveData=true;
      //     console.log("204");
      //   }
      // }
      (error:any) =>
      {
        console.log(error);
        console.log("error");
        if(error.error.statusCode==204)
        {
          this.isData=0;
          this.loader=false;
          this.leaveData=true;
           console.log("204");
        }
      }
      )
  }
 
  // callChildMethod() 
  // {
  //   console.log("Child Method Call"+this.mainLayoutComponent);
  //   if(this.mainLayoutComponent)
  //   {
  //     console.log("Implement");
  //     this.mainLayoutComponent.leaveRequest();
  //   }
  //  else{
  //   console.log("not Implement");
  //  }
  // }

  // ngAfterViewInit()
  //  {
  //   // if (this.mainLayoutComponent) {
  //   //   this.mainLayoutComponent.leaveRequest();
  //   // } else {
  //   //   console.error('MainLayoutComponent is undefined.');
  //   // }
  // }

}


@Component({
  selector: 'dialog',
  templateUrl: 'dialog.html',
})
export class dialog implements OnInit {
  isData: boolean
  x = this.data
  displayedColumns: string[] = ['leaveRequestId'];
  dataSource = this.data;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any[]) {

    this.isData = false;
    if (this.x.length > 0) {
      this.isData = true
    }
  }
  // data = this.data
  ngOnInit() {
  }
}
