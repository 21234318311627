import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  @Output() valueChange = new EventEmitter();
  left = "0px"
  constructor() { }

  ngOnInit(): void {

  }

}
