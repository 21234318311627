import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';

@Component({
  selector: 'app-denyreason',
  templateUrl: './denyreason.component.html',
  styleUrls: ['./denyreason.component.css']
})
export class DenyreasonComponent implements OnInit {
  denyreasonform: FormGroup;

  constructor(private fb: FormBuilder, private gs: GlobalserviceService, @Inject(MAT_DIALOG_DATA) public data: any[], public dialog: MatDialog, public dialogRef: MatDialogRef<DenyreasonComponent>) { }

  ngOnInit(): void {
    this.denyreasonform = this.fb.group({
      denyReason: ['', Validators.required],
    });
  }

  get f() {
    return this.denyreasonform.controls;
  }

  onSubmit(data) {
    if (this.denyreasonform.valid) {
      console.log(data);
      this.data = data;
      this.dialogRef.close(data);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

}
