<span>
  <h4 class="dynamic-title">Leave Balance</h4>
</span>
<div class="col-sm-11">
  <mat-card class="mat-card" style="margin-top: 20px; margin-left: 36px; margin-right: -45px;">
    <!-- <mat-card class="header">
      <p> <span class="material-icons">
          perm_identity
        </span>Leave Balance</p>
    </mat-card> -->
    <ng-container *ngIf="isLoading; else elseTemplate">
      <div class="loading">
        <h4 class="text-muted">loading....</h4>
        <br>
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <form (ngSubmit)="onSubmit(leaveTypeForm.value)" [formGroup]="leaveTypeForm">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">* Create Leave Balance *</h3>
          </div>
        </div>
        

        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline" >
            <!-- <label class="form-control-label">Employee Name<span class="text-danger">*</span></label> -->
            <mat-label class="form-label" >Employee <span class="text-danger">*</span></mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>account_box</mat-icon>
            <mat-select  #select multiple formControlName="employee" placeholder="Select Employee Name" class="email-input select-placeholder">
              <div class="select-all" style="margin-left: 15px;" >
                <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{ standalone: true }" (change)="toggleAllSelection()" >Select All</mat-checkbox>
              </div>
              <mat-option style="color: black;" *ngFor="let e of employeeList" [value]="e.employeeId " (click)="individualCheckboxChanged()">                
                {{e.employeeNumber}} - {{e.employeeName}}
              </mat-option>
              <button mat-raised-button color="primary" style="margin: 0 18px 18px 18px" (click)="add()" class="btn create-button">Add</button>
              <button mat-raised-button color="warn" (click)="cancel()" style="float: none;"  class="btn cancel-button">Cancel</button>
            </mat-select>
            
            <mat-error *ngIf="f.employee.hasError('required')">
              Please select <strong>Employee</strong>
            </mat-error>
          </mat-form-field>
        </div>

        

        <!-- <div class="form-group">
          <mat-form-field   class="example-full-width" appearance="outline">
            <mat-label class="form-label" >Employee <span class="text-danger">*</span></mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; " matPrefix>account_box</mat-icon>
            
            <mat-select placeholder="Select Employee Name" #select multiple formControlName="employee" [errorStateMatcher]="matcher">
              <div class="select-all" style="margin-left: 15px;">
                <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{ standalone: true }" (change)="toggleAllSelection()" >Select All</mat-checkbox>
              </div>
              <mat-option *ngFor="let e of employeeList" [value]="e.employeeId " (click)="individualCheckboxChanged()">
                 {{e.employeeNumber}} - {{e.employeeName}}
              </mat-option>
              <button mat-raised-button color="primary" style="margin: 0 18px 18px 18px" (click)="add()" class="btn create-button">Add</button>
              <button mat-raised-button color="warn" (click)="cancel()" style="float: none;"  class="btn cancel-button">Cancel</button>
            </mat-select>
            <mat-error *ngIf="f.employee.hasError('required')">
              Please select <strong>Employee</strong>
            </mat-error>
          </mat-form-field>
        </div>
           -->
         
         <div class="form-group">
          <mat-form-field   class="example-full-width" appearance="outline">
            <mat-label class="form-label" >Leave Type</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>filter_alt</mat-icon>
            <mat-select placeholder="Select Leave Type" formControlName="leaveType" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let t of type" [value]="t.leaveTypeId">
                {{t.leaveName}} <em> * </em>bal: {{t.noOfDays}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf=" f.leaveType.hasError('required')">
              Please select <strong>Leave Type</strong>
            </mat-error>
          </mat-form-field>
        </div>


        
        <div class="form-group">
          <div class="d-flex">
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="cancel-button" color="accent" type="button"
              [routerLink]="['']">
                <span class="material-icons-outlined cancel-button-icon">close</span>
                Cancel
              </button>
            </div>
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="apply-button" color="primary" type="submit">
                <span class="material-icons-outlined apply-button-icon">done</span>
                Apply
              </button>
            </div>
          </div>
        </div>
        <!-- <br> -->
      </form>
    </ng-template>
  </mat-card>
  <br>

</div>
