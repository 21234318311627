<div class="container">
  <mat-card>
    <form [formGroup]="TaskForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>&nbsp;Start Date</mat-label>
          <input matInput (click)="picker.open()"
            [matDatepicker]="picker" placeholder="select Start date" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>&nbsp;End Date</mat-label>
          <input matInput (click)="pick.open()"
            [matDatepicker]="pick" placeholder="select End date" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="pick"></mat-datepicker-toggle>
          <mat-datepicker #pick touchUi></mat-datepicker>
        </mat-form-field>
      </div>

      <button type="submit" mat-stroked-button color="primary">Submit</button>
    </form>
  </mat-card>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery </th>
      <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef mat-sort-header > Joined Date </th>
      <td mat-cell *matCellDef="let element">{{element.symbol}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

