import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.css']
})
export class TaskDialogComponent implements OnInit {

  taskForm:FormGroup;

  constructor( public dialogRef: MatDialogRef<TaskDialogComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               private fb:FormBuilder) { }

  ngOnInit(): void {
     this.taskForm = this.fb.group({
       date:['',Validators.required],
       hour:['',Validators.required],
       taskName:['',Validators.required],
       description:['',Validators.required]
     })
  }

  get f(){
    return this.taskForm.controls;
  }

  date() {
    return this.f.date
      .hasError('required')
      ?
      'Date is required' : ''
  }

  hour() {
    return this.f.hour
      .hasError('required')
      ?
      'Hour is required' : ''
  }

  taskName() {
    return this.f.taskName
      .hasError('required')
      ?
      'TaskName is required' : ''
  }

    // keys
    noNumber(event: any) {
      const pattern = /[0-9]|\./;
      const inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode !== 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }
    }


  onSubmit(){
    if(this.taskForm.invalid){
      return
    }
    // let x = this.taskForm.value;
    // if(x.description == ''){
    //   alert('Description is required')
    // }
    if(this.taskForm.valid){
      this.dialogRef.close(this.taskForm.value);
    }
  }

}
