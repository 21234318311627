import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-branchlist',
  templateUrl: './branchlist.component.html',
  styleUrls: ['./branchlist.component.css']
})
export class BranchlistComponent implements OnInit {

  data: any[] = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['sno','BranchName','Location','Status', 'Action'];
  dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  list_count: number;
  isLoading:boolean;
  noData=false;
  loader=true;
  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog) 
  {

  }

  ngOnInit() {
    let x = JSON.parse(localStorage.getItem("data"))
    let id = x.employeeId;
    this.fetchdata();
  }
  fetchdata(){
    console.log("Load data");
    this.gs.getBranch().subscribe((d: any) => {
      this.loader=false;
      if(d.length==0)
      {
        this.noData=true;
        console.log("NoData");
      }    
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
       this.dataSource.filterPredicate = (data, filter) => {
        const dataStr = data.branchname + data.branchlocation + data.branchstatus;
        return dataStr.trim().toLowerCase().indexOf(filter) != -1;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.list_count = this.data.length
    },

    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }
  // status color
  statuscolor(i:any) {
    if (i == null) {
      return
    }
    if (i == 'Active') {
      return '#28a745'
    }
    if (i == 'In-Active') {
      return '#f44336'
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  addBranch()
  {
    this.router.navigate(['/branch',"branch"])
  }
  edit(branch)
  {
    this.router.navigate(['../../branch-edit',branch.branchId])
  }

  statusupdate(i){
    console.log(i);
    console.log(i.branchstatusname=='Active');
    if(i.branchstatusname=='Active'){
      Swal.fire({
        title: 'Are you sure? Do you want to in-activate',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3f51b5',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'In-Active'
      }).then((result) => {
        if (result.value) {
          this.gs.branchstatusupdate(i.branchId,2).subscribe(d => {
            // Swal.fire(
            //   'done!',
            // )
            // this.data.splice(i, 1)
            // this.dataSource = new MatTableDataSource<any>(this.data);
            this.fetchdata();
          },
            () => {
              setTimeout(
                e => {
                  this.data
                }, 50
              )
            }
          );
        }
      })
    }
    else if (i.branchstatusname=='In-Active'){
      Swal.fire({
        title: 'Are you sure? Do you want to Activate',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3f51b5',
        cancelButtonColor: '#f44336',
        confirmButtonText: 'Active'
      }).then((result) => {
        if (result.value) {
          this.gs.branchstatusupdate(i.branchId,1).subscribe(d => {
            // Swal.fire(
            //   'done!',
            // )
            // this.data.splice(i, 1)
            //this.dataSource = new MatTableDataSource<any>(this.data);
            this.fetchdata();
          },
            () => {
              setTimeout(
                e => {
                  this.data
                }, 50
              )
            }
          );
        }
      })
    }
    

  }
  delete(branch)
  {
      console.log(branch);
    this.isLoading = true
    this.gs.deleteBranch(branch.branchId).subscribe(
      (d:any)=>{
        Swal.fire({
          text:d.description,
          icon:'success'
        })
        this.isLoading = false;
        window.location.reload()
      },
      err=>{
        Swal.fire({
          text:err.error.message,
          icon:'error'
        })
        this.isLoading = false
      }
    )
  }

}
