import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { EmployeeTimeSettingsComponent } from '../employee-time-settings/employee-time-settings.component';
import { MatDialog } from '@angular/material/dialog';
import { AddDesignationComponent } from '../add-designation/add-designation.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})
export class DesignationComponent implements OnInit {
  designationlist: any = [];
  noData : Boolean = false;
  p:number =1;
  itempages : any = 5 ;
  Data:any=[];
  dataSource: any;
  loader=true;
  list_count = 0;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =['sno','designation','description','action'];
  constructor(private GlobalserviceService: GlobalserviceService,
    private httpClient: HttpClient,
    private router: Router,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.fetchdata();
  }
  fetchdata(){
    this.GlobalserviceService.getdesignationList().subscribe
      ((data: any) => {
        this.loader=false;
        this.designationlist = data;
        this.Data=data;
        this.dataSource = new MatTableDataSource<any>(this.Data);
        this.dataSource.paginator = this.paginator;
        this.list_count = this.Data.length;
        if(this.designationlist.length == 0){
          this.noData=true;
        }
      },
      (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
      )
  }
  applyFilter(event: Event) {
    console.log(event);
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  edit(designationId) {
    console.log("create");
    const dialogRef = this.dialog.open(AddDesignationComponent, {
      width: '400px',
      height: 'fit-content',
      data: designationId
    })
    dialogRef.afterClosed().subscribe(
      (res) => {
        setTimeout(() => {
          this.fetchdata();
        }, 1000)
      }
    )
  }
  Add() {
    console.log("create");
    const dialogRef = this.dialog.open(AddDesignationComponent, {
      width: '400px',
      height: 'fit-content',
    })

    dialogRef.afterClosed().subscribe(
      (res) => {
        setTimeout(() => {
          this.fetchdata();
        }, 1000)
      }
    )

  }


  showChanges() {
    console.log("show Change");
    this.p=1;
  }

}

