import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router, ActivatedRoute } from '@angular/router'
import Swal from 'sweetalert2';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css']
})
export class BranchComponent implements OnInit {

  isLoading:boolean;
  organizationList:any = [];
  branchList:any = [];
  branchForm:FormGroup;
  branchobject:any;
  id: any;
  frombranch :any;
  button_label: string = 'Create';

  constructor(private gs:GlobalserviceService,
              private fb:FormBuilder,
              private router:Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {

    
    this.gs.getOrganization().subscribe(
      (d:any)=>{
        this.organizationList = d
      }
    )

    this.branchForm = this.fb.group({
      organization:['',Validators.required],
      branchname:['',Validators.required],
      branchlocation:['',Validators.required]
    })


    this.route.paramMap.subscribe(d => {
      this.id = d.get('id');
      this.frombranch = d.get('frombranch');
      console.log("frombranch"+this.frombranch);
      console.log(this.frombranch != null || this.frombranch != undefined)
      if(this.id)
    {
       this.button_label = 'Update';
      this.gs.getBranchByID(this.id).subscribe((branch: any) => {
        console.log(branch);
        console.log(this.id);
        this.branchobject = branch;
        this.branchForm.patchValue({
          organization: branch.organization.organizationId,
          branchname:branch.branchname,
          branchlocation:branch.branchlocation,
        })
      
      })
    }
    })
    
  
    
  }

  get f(){
    return this.branchForm.controls
  }

  onSubmit(){
    if(this.branchForm.invalid){
      return
    }
    let x = this.branchForm.value;
    x['organization'] = {
      'organizationId':x.organization
    }
    
     if(this.id)
     {

      x['branchId'] = this.id;
      x['branchstatus'] = this.branchobject.branchstatus;
      console.log(x);
      this.gs.updateBranch(this.id,x).subscribe(
        (d:any)=>{
          Swal.fire({
            text:d.description,
            icon:'success'
          })
          this.isLoading = false;
          this.router.navigate(['/branch-list'])
        },
        err=>{
          Swal.fire({
            text:err.error.message,
            icon:'error'
          })
          this.isLoading = false
        }
      )
     
     }else{
      console.log(x)
      this.isLoading = true
      this.gs.createBranch(x).subscribe(
        (d:any)=>{
          Swal.fire({
            text:d.description,
            icon:'success'
          })
          this.isLoading = false;
          if(this.frombranch != null || this.frombranch != undefined)
          {
            console.log("if else");
            this.router.navigate(['/branch-list'])
          }else{
            this.router.navigate(['/employee'])
          }
        
        },
        err=>{
          Swal.fire({
            text:err.error.message,
            icon:'error'
          })
          this.isLoading = false
        }
      )
     }
   
  }

}
