import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2'
import { ErrorStateMatcher } from '@angular/material/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { UserType } from 'src/app/model/usertype.model';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  matcher = new MyErrorStateMatcher();
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  hide = true;
  error = '';
  index: any;
  private respdata: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private gs: GlobalserviceService
  ) { }

  ngOnInit(): void {
    this.gs.get_usertype().subscribe((a: UserType[]) => {
      // console.log(a);
    })
    let folder = { "subFolderName": "mfolder.hfolder" }
    this.gs.getfolder(folder).subscribe(
      d => {
        console.log(d)
      }
    )
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.pattern(/^[a-z]+[a-z0-9._]+@[a-z]+\.[a-z.]{2,5}$/)]],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.authService.logintest(this.f.username.value, this.f.password.value).subscribe(
      d => {
        localStorage.setItem('data', JSON.stringify(d));
        if (d) {
          Swal.fire({
            text: 'You Have Logged In Successfully',
            icon: 'success',
            showConfirmButton: false
          })
          setTimeout(()=> {
           Swal.close();
        },2000)
        }
        this.router.navigate(['/']);
      },
      err => {
        let x = err.error.message
        if (x == undefined) {
          Swal.fire(
            "Check Net:: Connection :: err"
          )
        } else {
          console.log(err.error.message)
          if (err) {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
          }
        }
      },
      () => {
        console.log('done')
      }
    )
  }
}
