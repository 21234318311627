import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, Renderer2, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
// import { DialogData } from '../employee/employee-list/employee-list.component';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, FormGroupName } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Reason } from '../withdraw-list/withdraw-list.component';
import { leavedenyreason } from '../withdraw-list/withdraw-list.component';
import { MultiselectComponent } from '../multiselect/multiselect.component';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ShareLeaveComponent } from '../share-leave/share-leave.component';

export interface DialogData {
  leaveType: any;
  leaveRequestId: any;
  applyTo: any;
  cc: any
}
@Component({
  selector: 'app-myleave-list',
  templateUrl: './myleave-list.component.html',
  styleUrls: ['./myleave-list.component.css']
})

export class MyleaveListComponent implements OnInit {
  data: any[] = [];
  filter_Data = "All"
  isLoading:Boolean;
  noData=false;
  filterData = [
    "All",
    "Approved",
    "Pending",
    "Denied",
    "Cancelled",
    "Withdrawn"
  ]
  list_count = 0;
  organizationEmail:any;
  personalEmail:any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    [
      'sno',
      'startDate',
      'endDate',
      'leaveType',
      'noOfLeaveDays',
      'reason',
      'leaveStatus',
      'withdraw',
      'star'];
  dataSource: any;
  loader=true;
  changeasDenyReason: boolean=false;
  isMenuOpen: boolean = false;
  clickedbusinessTravelId: any;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  //  dataSource = ELEMENT_DATA;
  lastclickedIndex:any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog,private renderer: Renderer2) {
    // this.renderer.listen('window', 'click',(e:Event)=>{
    //   if(e.target !== this.toggleButton.nativeElement && e.target!==this.menu.nativeElement){
    //       this.isMenuOpen=false;
    //       console.log(this.isMenuOpen);
    //   }
    // });
  }
  ngOnInit() {
    console.log("Sucess");
    let x = JSON.parse(localStorage.getItem('data'));
    let id = x.employeeId;
    console.log("Loader");
    this.gs.getMyleave(id).subscribe(
      (s: any) => {
        this.loader=false;
        console.log("Not Loader");
        if(s.length==0)
      {
        this.noData=true;
        console.log("NoData");
      }
      if(s.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
        this.data = s;
        console.log("Data");
        s.forEach(e =>{
          if(e.leaveWithdraw.withdrawStatus == 3){
            e.leaveWithdraw.withdrawStatus = "Pending"
          }
          if(e.leaveWithdraw.withdrawStatus == 4){
            e.leaveWithdraw.withdrawStatus = "Deleted"
          }
          if(e.leaveWithdraw.withdrawStatus == 5){
            e.leaveWithdraw.withdrawStatus = "Approved"
          }
          if(e.leaveWithdraw.withdrawStatus == 6){
            e.leaveWithdraw.withdrawStatus = "Denied"
          }
          if(e.leaveWithdraw.withdrawStatus == 7){
            e.leaveWithdraw.withdrawStatus = "Withdrawn"
          }
          if(e.leaveWithdraw.withdrawStatus == 8){
            e.leaveWithdraw.withdrawStatus = "Cancelled"
          }
        })
        this.dataSource = new MatTableDataSource<any>(this.data);
        console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.list_count = this.data.length
        console.log(this.list_count)

      },
      (error) =>
      {
        this.noData= true;
        this.loader=false;
        console.log("error");
        if(error.statusCode==204)
        {
           this.noData=true;
           console.log("204");
        }
       console.log("noData" + this.noData);
      }
    )
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deny(e){
    console.log(e);
    this.clickedbusinessTravelId = e;
    if(this.isMenuOpen==false){
      console.log("if");
      console.log(this.isMenuOpen);
      this.denyReason(e);
    }
    else{
      console.log("else");
      this.isMenuOpen=false;
      this.denyReason(e);
    }
  }
  denyReason(e){
    console.log(e);
    this.clickedbusinessTravelId = e;


    // console.log("lastclickedIndex",this.lastclickedIndex);
    // if(this.lastclickedIndex == this.clickedbusinessTravelId){
    //   this.isMenuOpen = true;
    //   console.log("if", this.isMenuOpen);
    // }
    // else{
    //   this.isMenuOpen = !this.isMenuOpen;
    //   console.log("else", this.isMenuOpen);
    // }
    // this.lastclickedIndex = this.clickedbusinessTravelId;
    // console.log("lastclickedIndex",this.lastclickedIndex);


    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
    if(this.isMenuOpen==true){
      this.changeasDenyReason=true;
      console.log(this.changeasDenyReason);
      // this.renderer.listen('window', 'click',(e:Event)=>{
      //   if(e.target !== this.toggleButton.nativeElement && e.target!==this.menu.nativeElement){
      //       this.isMenuOpen=false;
      //       console.log(this.isMenuOpen);
      //   }
      // });
      // this.isMenuOpen=false;
    }
    else{
      this.changeasDenyReason=false;
      console.log(this.changeasDenyReason);
    }


  }
  delete(i) {
    console.log(i);
    Swal.fire({
      title: 'Are you sure? Do you want to cancel',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'yes!'
    }).then((result) => {
      if (result.value) {

        i["leaveStatus"] = "Canceled"
        console.log(JSON.stringify(i))
        this.gs.deleteLeave(i.leaveRequestId, i).subscribe(d => {
          Swal.fire(
            'done!',
          )
          let x = JSON.parse(localStorage.getItem('data'));
          let id = x.employeeId
          this.gs.getMyleave(id).subscribe(
            (s: any) => {
              console.log(s)
              this.data = s
              this.dataSource = new MatTableDataSource<any>(this.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.list_count = this.data.length
              console.log(this.list_count)
              if (this.list_count < 1) {
                window.location.reload()
              }
            }
          )
        },
          err => {
            Swal.fire(
              'error!'
            )
          }
        );
      }
      //

    })
  }
  test(i) {
    if (i.leaveStatusName == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveStatusName == 'Approved') {
      return '#28a745'
    }
    if (i.leaveStatusName == 'Denied') {
      return '#f44336'
    }
    if (i.leaveStatusName == 'Withdrawn') {
      return '#20B2AA'
    }
    if (i.leaveStatusName == 'Cancelled') {
      return '#e40000'
    }
  }
  withDrawStatus(i) {
    if (i.leaveWithdraw == null) {
      return
    }
    if (i.leaveWithdraw.withdrawStatus == 'Pending') {
      return '#8313ff'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Approved') {
      return '#28a745'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Denied') {
      return '#f44336'
    }
    if (i.leaveWithdraw.withdrawStatus == 'Withdrawn') {
      return '#20B2AA'
    }    
    if (i.leaveWithdraw.withdrawStatus == 'Cancelled') {
      return '#e40000'
    }
  }
  showWithdrawDetails(i) {
    console.log(i)
    let x = i;
    this.dialog.open(WithdrawDetails, {
      // width: '100%',
      data: x,
      width:'450px'
    });
  }

  openWithdrawDialog(i) {
    console.log(i)
    let x = i;
    this.dialog.open(WithDrawDialog, {
      data: x
    });
  }

  cancel(e){
    e['leaveStatus'] = 8
    Swal.fire({
      title: 'Are you sure? Do you want to Cancel Leave Request!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor:'#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true
        this.gs.UpdateLeaveStatus(e.leaveRequestId, e).subscribe(
          d => {
            Swal.fire({
              text: 'Cancelled',
              icon: 'success'
            })
            this.isLoading = false;
            window.location.reload();
          },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
            this.isLoading = false
          }
        )
      }
    })
  }

  reason(i) {
    console.log(i)
    this.dialog.open(Reason, {
      data: i.leaveReason
    });
  }

  showdenyreason(i){
    console.log(i)
    let x = i;
    this.dialog.open(leavedenyreason, {
      data: x
    });
  }

  Share(i) {
    this.dialog.open(ShareLeaveComponent, {
      data: i,
      width: '500px',
    });
  }

  get_user_data(filter) {
    let x = JSON.parse(localStorage.getItem('data'));
    let id = x.employeeId
    this.gs.getMyleave(id).subscribe(
      (s: any) => {
        console.log(s)
        if (filter != "All") {
          this.data = s.filter(a => {
            return a.leaveStatusName == filter;
          });
          if (this.data.length < 1) {
            Swal.fire(
              `No ${filter} List Found`
            )
            let x = JSON.parse(localStorage.getItem('data'));
            let id = x.employeeId
            this.gs.getMyleave(id).subscribe(
              (s: any) => {
                s.forEach(e =>{
                  console.log(e.leaveWithdraw.withdrawStatus)
                  if(e.leaveWithdraw.withdrawStatus == 3){
                    e.leaveWithdraw.withdrawStatus = "Pending"
                  }
                  if(e.leaveWithdraw.withdrawStatus == 4){
                    e.leaveWithdraw.withdrawStatus = "Deleted"
                  }
                  if(e.leaveWithdraw.withdrawStatus == 5){
                    e.leaveWithdraw.withdrawStatus = "Approved"
                  }
                  if(e.leaveWithdraw.withdrawStatus == 6){
                    e.leaveWithdraw.withdrawStatus = "Denied"
                  }
                  if(e.leaveWithdraw.withdrawStatus == 7){
                    e.leaveWithdraw.withdrawStatus = "Withdrawn"
                  }
                  if(e.leaveWithdraw.withdrawStatus == 8){
                    e.leaveWithdraw.withdrawStatus = "Cancelled"
                  }
                })
                this.data = s
                this.dataSource = new MatTableDataSource<any>(this.data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.list_count = this.data.length;
              }
            )
          }
        } else {
          let x = JSON.parse(localStorage.getItem('data'));
          let id = x.employeeId
          this.gs.getMyleave(id).subscribe(
            (s: any) => {
              s.forEach(e =>{
                if(e.leaveWithdraw.withdrawStatus == 3){
                  e.leaveWithdraw.withdrawStatus = "Pending"
                }
                if(e.leaveWithdraw.withdrawStatus == 4){
                  e.leaveWithdraw.withdrawStatus = "Deleted"
                }
                if(e.leaveWithdraw.withdrawStatus == 5){
                  e.leaveWithdraw.withdrawStatus = "Approved"
                }
                if(e.leaveWithdraw.withdrawStatus == 6){
                  e.leaveWithdraw.withdrawStatus = "Denied"
                }
                if(e.leaveWithdraw.withdrawStatus == 7){
                  e.leaveWithdraw.withdrawStatus = "Withdrawn"
                }
                if(e.leaveWithdraw.withdrawStatus == 8){
                  e.leaveWithdraw.withdrawStatus = "Cancelled"
                }
              })
              this.data = s
              this.dataSource = new MatTableDataSource<any>(this.data);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.list_count = this.data.length;
            }
          )
        }
        s.forEach(e =>{
          console.log(e.leaveWithdraw.withdrawStatus)
          if(e.leaveWithdraw.withdrawStatus == 3){
            e.leaveWithdraw.withdrawStatus = "Pending"
          }
          if(e.leaveWithdraw.withdrawStatus == 4){
            e.leaveWithdraw.withdrawStatus = "Deleted"
          }
          if(e.leaveWithdraw.withdrawStatus == 5){
            e.leaveWithdraw.withdrawStatus = "Approved"
          }
          if(e.leaveWithdraw.withdrawStatus == 6){
            e.leaveWithdraw.withdrawStatus = "Denied"
          }
          if(e.leaveWithdraw.withdrawStatus == 7){
            e.leaveWithdraw.withdrawStatus = "Withdrawn"
          }
          if(e.leaveWithdraw.withdrawStatus == 8){
            e.leaveWithdraw.withdrawStatus = "Cancelled"
          }
        })
        this.dataSource = new MatTableDataSource<any>(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.list_count = this.data.length;
      }
    )
  }
}


//++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


// to view withdraw details

@Component({
  selector: 'withdraw-details',
  templateUrl: 'withdraw-details.html',
})
export class WithdrawDetails implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public withdraw_details: any
    , private fb: FormBuilder, private gs: GlobalserviceService) { }
  ngOnInit() {
    console .log(this.withdraw_details)
   }
}


// dialog component
/** Error when invalid control is dirty, touched, or submitted. */


export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'withdraw',
  templateUrl: 'withdraw.html',
})
export class WithDrawDialog implements OnInit {
  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() dateInput: EventEmitter<MatDatepickerInputEvent<any>>;

  //
  noOFDays: any;
  WorkingDays: any;
  myFilter: any;
  workDay:any=[];
  EndDate_disable: boolean = true
  EndDate_min: any;
  From_Date: any;
  End_Date: any;
  close_dialogue: boolean;
  withDrawForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  reportingTo:any
  reportingToSecond:any;
  organizationEmail:any;
  personalEmail:any;
  weekDay:boolean;
  halfDay:boolean;
  validate:boolean;
  notValidate:boolean;
  arrayData:any =[];
  branchId:any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any
    , private fb: FormBuilder, private gs: GlobalserviceService) { }

  ngOnInit() {

    this.close_dialogue = false

    this.From_Date = new Date(this.data.startDate);
    this.End_Date = new Date(this.data.endDate)


    this.withDrawForm = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],

      withdrawReason: ['', Validators.required],
      withdrawStatus: [3],
      noOfDays:['',Validators.required]
    });
    // working days filteration process
    const myData = JSON.parse(localStorage.getItem('data'));
    this.gs.getEmpListById(myData.employeeId).subscribe(
      (d:any)=>{
        this.branchId = d.branch.branchId
      }
    )
    this.gs.getEmpListById(myData.employeeId).subscribe(
      (a:any) =>{
        this.organizationEmail = a.organizationEmail;
        this.personalEmail = a.personalEmail;
        this.gs.getEmpListById(a.reportingTo).subscribe(
          (d:any) =>{
            this.reportingTo = d.organizationEmail
          }
        )
        this.gs.getEmpListById(a.reportingToSecond).subscribe(
          (e:any) =>{
            this.reportingToSecond = e.organizationEmail
          }
        )

        this.WorkingDays = a.noOfWorkingDays;
        if(this.branchId){
          this.gs.getCalByBranch(this.branchId).subscribe(
            (dato: any) => {
              dato.map(
                log => {
                  this.gs.getCalendarById(log.calendarId).subscribe(
                    (i: any) => {
                      var exception
                      let data
                      i.exceptions.map(
                        b => {
                          if(b.exceptionStatus == 1){
                            if(b.exceptionType != '41'){
                              exception = b.exceptionDate
                              data = new Date(b.exceptionDate)
                              data.setDate(data.getDate());
                              this.arrayData.push(data)
                            }
                          }

                        }
                      )

                      i.holiday.map(a => {
                        if(a.holidayStatus == 1){
                          data = new Date(a.holidayDate)
                          data.setDate(data.getDate());
                          this.arrayData.push(data)
                        }
                      })

                      let b = ["1","2","3","4","5","6","0"]
                      i.calendarWorkingDays.map(dot => {
                        var difference:any = b.filter(x => dot.workingdays.indexOf(x) === -1);
                        this.workDay = difference
                        this.myFilter = (d: Date): boolean => {
                          const day = new Date(d).getDay();
                          const time = new Date(d).getTime()
                          return day !== Number(difference[0]) && day !== Number(difference[1])
                          && day !== Number(difference[2]) && day !== Number(difference[3])
                          && day !== Number(difference[4]) && day !== Number(difference[5])
                          && day !== Number(difference[6])&& !this.arrayData.find(x => new Date(x).getTime() == time)
                        }
                      })
                    }
                  )
                }
              )

            }
          )
        }

      }
    )
    // this.WorkingDays = this.data.employee.noOfWorkingDays;
    let z = JSON.parse(localStorage.getItem('data'))
    const empId = z.employeeId;
    const mail = z.organizationEmail
    this.gs.employee_withdraw_list_action(empId, mail).subscribe(
      d => {
        console.log(d)
      }
    )
  }
  get f() {
    return this.withDrawForm.controls
  }

  someMethodName(date: any) {
    this.EndDate_disable = false;
    this.EndDate_min = new Date(date.value);
  }

  nofoDays(i) {
    let start = new Date(this.f.startDate.value);
    let end = new Date(this.f.endDate.value)

    let v = new Date(start).toISOString().replace(/T.*$/, '')
    let w = new Date(end).toISOString().replace(/T.*$/, '');

    if(v != w){
      this.weekDay = true;
      this.halfDay = false;
    }
    if(v == w){
      this.halfDay = true;
      this.weekDay = false;
      if(this.data.noOfLeaveDays == 0.5){
       this.validate = true;
       this.notValidate = false;
       this.withDrawForm.patchValue({
        noOfDays:0.5.toString()
       })
      }
      else{
        this.validate = false;
        this.notValidate = true;
      }
    }
    // Copy date objects so don't modify originals
    var s: any = new Date(+start);
    var e: any = new Date(+end);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12, 0, 0, 0);
    e.setHours(12, 0, 0, 0);

    // Get the difference in whole days
    var totalDays = Math.round((e - s) / 8.64e7);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    var days = wholeWeeks * this.WorkingDays + 1;

    // If not even number of weeks, calc remaining weekend days
    let dateArray = new Array()
    let result = new Array()
    while (start <= end) {
      dateArray.push(new Date(start));
      start.setDate(start.getDate() + 1);
    }
    dateArray.map(
      c => {
        if (this.arrayData) {
          this.arrayData.map(
            t => {
              let MyDate = new Date(c)
              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)

              let MyDate1 = new Date(t)
              let MyDateString1 = MyDate1.getFullYear() + '-' + ('0' + (MyDate1.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate1.getDate()).slice(-2)
              if (MyDateString1 == MyDateString) {
                result.push(t)
              }
            }
          )
        }
      }
    )

    // If not even number of weeks, calc remaining weekend days

    s.setDate(s.getDate() + wholeWeeks * 7);
    let dat = new Array()

    dateArray.map(
      e => {
        if(e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
        && e.getDay() != this.workDay[3]  && e.getDay() != this.workDay[4]  && e.getDay() != this.workDay[5]
        && e.getDay() != this.workDay[6]){
          dat.push(e)
        }
        // if (this.WorkingDays == 5){
        //   if (e.getDay() != 0 && e.getDay() != 6) {
        //     dat.push(e)
        //   }
        // }
        // else{
        //   if (e.getDay() != 0 && e.getDay() != 7) {
        //     dat.push(e)
        //   }
        // }

      }
    )

    let uniqueArray = result
      .map(function (date) { return date.getTime() })
      .filter(function (date, i, array) {
        return array.indexOf(date) === i;
      })
      .map(function (time) { return new Date(time); });

      let uniqueArray1 = new Array()

      uniqueArray.map(
        e=>{
          if(e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
        && e.getDay() != this.workDay[3]  && e.getDay() != this.workDay[4]  && e.getDay() != this.workDay[5]
        && e.getDay() != this.workDay[6]){
          uniqueArray1.push(e)
        }
            // if (this.WorkingDays == 5){
            //   if (e.getDay() != 0 && e.getDay() != 6) {
            //     uniqueArray1.push(e)
            //   }
            // }
            // else{
            //   if (e.getDay() != 0 && e.getDay() != 7) {
            //     uniqueArray1.push(e)
            //   }
            // }
        }
      )

      if (uniqueArray1.length > dat.length) {
        days = uniqueArray1.length - dat.length
      }
      if (dat.length > uniqueArray1.length) {
        days = dat.length - uniqueArray1.length
      }

    this.noOFDays = days;
    return days;

  }

  withdraw() {

    let x = this.withDrawForm.value;
    if(x.startDate != x.endDate){
      x['withdrawDays'] = this.noOFDays;
      x['halfday'] = "false"
      this.f.noOfDays.setValue('Leave')

    }
    if (this.withDrawForm.invalid) {
      Swal.fire({
        text: 'Please Fill All The Inputs && Withdraw',
        icon: 'info'
      })
      return
    }

    const leave_type = this.data.leaveType;
    const empId = this.data.employee.employeeId;
    const empName = this.data.employee.employeeName;


    this.close_dialogue = false
    // date time zone error
    let a = this.f.startDate.value;

    if (x) {
      let b = new Date(a);
      b.setMinutes(b.getMinutes() + 480);
      let s = b.toISOString()
      x['startDate'] = s
    }

    let c = this.f.endDate.value;
    if (c) {
      let d = new Date(c);
      d.setMinutes(d.getMinutes() + 480);
      let e = d.toISOString();
      x['endDate'] = e;
    }

    if(x.startDate == x.endDate){
      x['withdrawDays'] = Number(x.noOfDays);
      if(x.withdrawDays == 1){
        x['halfday'] = "false"
      }
      else{
        x['halfday'] = "true"
      }
      if (x.noOfDays == "Leave") {
        this.withDrawForm.controls['noOfDays'].setErrors({ 'required': true });
        return
      }
    }

    // x['withdrawDays'] = this.noOFDays
    x['leaveRequest'] = {
      leaveRequestId: this.data.leaveRequestId,
      leaveType: this.data.leaveType,
      applyTo: [this.organizationEmail],
      cc: [this.personalEmail],
      leavereportingTo: this.reportingTo,
      leavereportingToSecond: this.reportingToSecond,
      employee: {
        employeeId: empId,
        employeeName: empName
      }
    }
    // delete x.noOfDays
    // console.log(JSON.stringify(x))
    console.log(x);
    // return
    this.gs.withdraw(x).subscribe(
      d => {
        Swal.fire({
          text: 'Applied',
          icon: 'success'
        })
        window.location.reload()
      },
      err => {
        Swal.fire({
          text: err.error.message
        })
      }
    )
  }


}
