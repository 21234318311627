<span>
  <h4 class="dynamic-title">My Dashboard</h4>
</span>

<div class="container-fluid">
  <!-- Employee Dashboard -->
  <div class="row" *ngIf="userType=='Employee'">
    <div class="col-sm-12">
      <h1 class="greetings">Hi {{ User_Name | titlecase }}!</h1>
    </div>
  </div>
  <div *ngIf="userType=='Employee'">
    <div class="row">
      <div class="col-sm-12">
       <h4 class="summary">Summary</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <mat-card>
          <div style="display: flex;">
            <div class="title-card-right" style="position: relative;top: 4px;">
              <span class="block1-value">Total Leave</span>
              <span class="new-badge bc-green">
                <span class="block1-count">{{totalLeave}}</span>
              </span>
            </div>
            <div class="vl"></div>
            <div class="title-card-left" style="position: relative;top: 4px;">
              <span class="block1-value">Balance Leave</span>
              <span class="new-badge bc-orange">
                <span class="block1-count">{{noOfDays}}</span>
              </span>
            </div>
          </div>
        </mat-card>
        <div class="row">
          <div class="col-sm-12 mt-2 mt-3">
            <div align="center">
              <button type="button" class="apply-button" mat-raised-button color="primary" [routerLink]="['/apply']">
                <span class="apply-text">Apply Leave
                  <mat-icon class="material-symbols-outlined apply-icon">expand_less</mat-icon>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <mat-card>
          <div style="display: flex;">
            <div class="title-card-right" style="position: relative;top: 4px;">
              <span class="block1-value">Total Permission</span>
              <span class="new-badge bc-green">
                <span class="block1-count">{{maximumPermissionHours}}Hr</span>
              </span>
            </div>
            <div class="vl"></div>
            <div class="title-card-left" style="position: relative;top: 4px;">
              <span class="block1-value">Balance Permission</span>
              <span class="new-badge bc-blue">
                <span class="block1-count">{{balancePermission}}Hr</span>
              </span>
            </div>
          </div>
        </mat-card>
        <div class="row">
          <div class="col-sm-12 mt-3">
            <div align="center">
              <button type="button" class="apply-button" mat-raised-button color="primary" [routerLink]="['/permission']">
                <span class="apply-text">Apply Permission
                  <mat-icon class="material-symbols-outlined apply-icon">expand_less</mat-icon>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="userType=='Employee'">
    <div class="col-sm-6 responsive-814">
      <div class="row">
        <div class="col-sm-12">
         <h4 class="mystats">My Status</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-1" style="max-height: 125px;min-height: 110px;">
          <mat-card class="statuscard-hover">
            <h5 class="block4-title">Leave Request</h5>
            <span class="b4">
              <span class="material-icons-outlined block4-icon">receipt_long</span>
            </span>
            <h4 class="" style="display: flex;">
              <span class="badge badge-approved">Approved</span>
              <span class="badge" style="color:#656B76;text-align: right">{{leaveApproveCount}}</span>
            </h4>
            <h4 class="" style="display: flex;margin: 0;">
              <span class="badge badge-denied">Denied</span>
              <span class="badge" style="color:#656B76;text-align: right">{{leaveDeniedCount}}</span>
            </h4>
          </mat-card>
        </div>
        <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-1" style="max-height: 125px;min-height: 110px;">
          <mat-card class="statuscard-hover">
            <h5 class="block4-title">Work From Home</h5>
            <span class="b4">
              <span class="material-icons-outlined block4-icon">work</span>
            </span>
            <h4 class="" style="display: flex;">
              <span class="badge badge-approved">Approved</span>
              <span class="badge" style="color:#656B76;text-align: right">{{workApproveCount}}</span>
            </h4>
            <h4 class="" style="display: flex;margin: 0;">
              <span class="badge badge-denied">Denied</span>
              <span class="badge" style="color:#656B76;text-align: right">{{workDeniedCount}}</span>
            </h4>
          </mat-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-1" style="max-height: 125px;min-height: 110px;">
          <mat-card class="statuscard-hover">
            <h5 class="block4-title">Business Travel</h5>
            <span class="b4">
              <span class="material-icons-outlined block4-icon">room</span>
            </span>
            <h4 class="" style="display: flex;">
              <span class="badge badge-approved">Approved</span>
              <span class="badge" style="color:#656B76;text-align: right">{{businessApproveCount}}</span>
            </h4>
            <h4 class="" style="display: flex;margin: 0;">
              <span class="badge badge-denied">Denied</span>
              <span class="badge" style="color:#656B76;text-align: right">{{businessDeniedCount}}</span>
            </h4>
          </mat-card>
        </div>
        <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-1" style="max-height: 125px;min-height: 110px;">
          <mat-card class="statuscard-hover">
            <h5 class="block4-title">Permission</h5>
            <span class="b4">
              <span class="material-icons-outlined block4-icon">history</span>
            </span>
            <h4 class="" style="display: flex;">
              <span class="badge badge-approved">Approved</span>
              <span class="badge" style="color:#656B76;text-align: right">{{permissionApproveCount}}</span>
            </h4>
            <h4 class="" style="display: flex;margin: 0;">
              <span class="badge badge-denied">Denied</span>
              <span class="badge" style="color:#656B76;text-align: right">{{permissionDeniedCount}}</span>
            </h4>
          </mat-card>
        </div>
      </div>
      <div class="row" style="margin-top: 10px;">
        <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-3">
          <mat-card class="block6-left">
            <div class="title-card-left">
              <div class="row">
                <div style="width:50%;padding: 0px 10px;">
                  <span class="intime">&nbsp;In-Time&nbsp;&nbsp;</span>
                </div>
                <div style="width:50%;padding: 0px 10px;">
                  <span class="intime">9.00 AM</span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 mb-3">
          <mat-card class="block6-right">
            <div class="title-card-left">
              <div class="row">
                <div style="width:50%;padding: 0px 10px;">
                  <span class="intime">Out-Time</span>
                </div>
                <div style="width:50%;padding: 0px 10px;">
                  <span class="intime">6.30 PM</span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="col-sm-6 responsive-814">
      <div class="row">
        <div class="col-sm-12">
          <h4 class="mystats">Upcoming Leaves</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <mat-card class="block5">
            <div class="table-responsive">
              <table class="table block5-table" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <!-- <th scope="col" class="text-center">S.No</th> -->
                    <th scope="col" class="text-center">Date</th>
                    <th scope="col" class="text-center">Day</th>
                    <th scope="col" class="text-center">Description</th>
                    <th scope="col" class="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- ShowJanuary -->
                  <tr *ngIf="January.length !=0">
                    <span scope="row" colspan="5" class="month-title">January,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of January; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>
                    
                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="February.length !=0">
                    <span scope="row" colspan="5" class="month-title">February,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of February; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="March.length !=0">
                    <span scope="row" colspan="5" class="month-title">March,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of March; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="April.length !=0">
                    <span scope="row" colspan="5" class="month-title">April,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of April; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="May.length !=0">
                    <span scope="row" colspan="5" class="month-title">May,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of May; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="June.length !=0">
                    <span scope="row" colspan="5" class="month-title">June,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of June; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="July.length !=0">
                    <span scope="row" colspan="5" class="month-title">July,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of July; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="August.length !=0">
                    <span scope="row" colspan="5" class="month-title">August,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of August; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="September.length !=0">
                    <span scope="row" colspan="5" class="month-title">September,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of September; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>
                    
                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="October.length !=0">
                    <span scope="row" colspan="5" class="month-title">October,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of October; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="November.length !=0">
                    <span scope="row" colspan="5" class="month-title">November,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of November; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>      -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                  <tr *ngIf="December.length !=0">
                    <span scope="row" colspan="5" class="month-title">December,&nbsp;{{currentyear}}</span>
                  </tr>
                  <tr *ngFor="let e of December; let i=index" class="month-list">
                    <!-- <td scope="row" class="text-center">{{i+1}}</td> -->
                    <ng-container *ngIf="e.endate;else startDate">
                      <td class="text-center">{{e.appliedDate}} - {{e.endate}}</td>
                    </ng-container>
                    <ng-template #startDate>
                      <td class="text-center">{{e.appliedDate}}</td>
                    </ng-template>
                    <td class="text-center" *ngIf="e.appliedDay ==1">Monday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==2">Tuesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==3">Wednesday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==4">Thursday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==5">Friday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==6">Saturday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <td class="text-center" *ngIf="e.appliedDay ==0">Sunday
                      <span class="text-center" *ngIf="e.appliedEndDay ==1">-Monday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==2">-Tuesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==3">-Wednesday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==4">-Thursday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==5">-Friday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==6">-Saturday</span>
                      <span class="text-center" *ngIf="e.appliedEndDay ==0">-Sunday</span>
                    </td>
                    <!-- <td class="text-center" *ngIf="e.description != 28">{{e.description}}</td>
                    <td class="text-center" *ngIf="e.description == 28">National</td>  -->
                    <td class="text-center">{{e.description}}</td>

                    <td class="text-center" *ngIf="e.leavestatus != 1" style="color:#279600">{{e.leavestatus}}</td>
                    <td class="text-center" *ngIf="e.leavestatus == 1">Holiday</td>
                  </tr>
                </tbody>
                <tbody *ngIf="totaldayscount == 0">
                  <tr>
                    <td class="text-center" colspan="5">
                      <span style="font-size: 13px;">NO DATA</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
   <!-- Admin Dashboard -->
  <div *ngIf="userType !='Employee'">
    <div class="row">
      <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12" style="border-right: 1px solid #EEEEEE;">
        <div class="row">
          <div class="col-sm-12">
            <h1 class="greetings">Hi {{ User_Name | titlecase }}!</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12">
           <h4 class="summary">Summary</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
            <div class="card admin-status-card">
              <div class="card-body" style="padding: 0 15px">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="card-title admin-status-title">Total Employees</h4>
                  </div>
                </div>
                <div class="row" style="transform: translate(0px, -3px);">
                  <div class="col-md-12">
                    <h3 class="card-title float-right emp-count">{{ActiveEmployees}}</h3>
                  </div>
                </div>
                <div class="row bottom-block">
                  <p class="card-text emp-entry">{{LeaveTotalCount}} employees applied for leave</p>
                  <p class="card-text emp-entry">{{PermissionTotalCount}} permission list are requested</p>
                </div> 
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
            <div class="card admin-status-card">
              <div class="card-body" style="padding: 0 15px">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="card-title admin-status-title">Employee Leave Count</h4>
                  </div>
                </div>
                <div class="row" style="transform: translate(0px, -3px);">
                  <div class="col-md-12">
                    <h3 class="card-title float-right emp-count">{{LeaveTotalCount}}</h3>
                  </div>
                </div>
                <div class="row bottom-block">
                  <p class="card-text emp-entry" *ngIf="LeavePendingCount!=0">{{LeavePendingCount}} leave pending for approval</p>
                  <p class="card-text emp-entry" *ngIf="LeavePendingCount==0">All leaves approved!</p>
                </div> 
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
            <div class="card admin-status-card">
              <div class="card-body" style="padding: 0 15px">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="card-title admin-status-title">Employee Permission Count</h4>
                  </div>
                </div>
                <div class="row" style="transform: translate(0px, -3px);">
                  <div class="col-md-12">
                    <h3 class="card-title float-right emp-count">{{PermissionTotalCount}}</h3>
                  </div>
                </div>
                <div class="row bottom-block">
                  <p class="card-text emp-entry" *ngIf="PermissionPendingCount!=0">{{PermissionPendingCount}} permission pending for approval</p>
                  <p class="card-text emp-entry" *ngIf="PermissionPendingCount==0">All permissions approved!</p>
                </div> 
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
            <div class="card admin-status-card">
              <div class="card-body" style="padding: 0 15px">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="card-title admin-status-title">Employee Work from Home Count</h4>
                  </div>
                </div>
                <div class="row" style="transform: translate(0px, -3px);">
                  <div class="col-md-12">
                    <h3 class="card-title float-right emp-count">{{WFHLeaveTotalCount}}</h3>
                  </div>
                </div>
                <div class="row bottom-block">
                  <p class="card-text emp-entry" *ngIf="WFHPendingCount!=0">{{WFHPendingCount}} work from home pending for approval</p>
                  <p class="card-text emp-entry" *ngIf="WFHPendingCount==0">All work from home approved!</p>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <h4 class="mystats">Request List</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="request-list table-responsive">
              <table class="table block5-table" style="margin-bottom: 0;">
                <thead>
                  <tr>
                    <th scope="col" class="text-center">Profile</th>
                    <th scope="col">Start&nbsp;Date</th>
                    <th scope="col">End&nbsp;Date</th>
                    <!-- <th scope="col">Submitted</th> -->
                    <th scope="col" class="text-center">Type</th>
                    <th scope="col" class="text-center">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let e of SortedLeaveAndWfh">
                    <td>
                      <div class="d-flex">
                        <img *ngIf="e.attachment != undefined" [src]="e.attachment.fileViewUrl" style="width:30px;height: 30px;border-radius: 50%;margin: 0 15px;">
                        <img *ngIf="e.attachment == undefined" src="assets/img/profilepic.jpg" style="width:30px;border-radius: 50%;margin: 0 15px;">
                        <div>
                          <span class="employeeName">{{e.employee.empName}}</span>
                          <span class="empDesignation">{{e.employee.designationName}}</span>
                        </div>                                           
                      </div>
                    </td>
                    <td style="white-space: nowrap;">{{e.startDate | date:'dd-MM-yyyy'}}</td>
                    <td style="white-space: nowrap;">{{e.endDate | date:'dd-MM-yyyy'}}</td>
                    <!-- <td style="white-space: nowrap;">{{e.submittedOn}}</td> -->
                    <td style="white-space: nowrap;" class="text-center">{{e.leaveType.leaveName}}</td>
                    <td class="text-center">
                      <button mat-stroked-button class="approve-button" type="submit" (click)="approve(e)">Approve</button>
                      <button mat-stroked-button class="cancel-button" type="submit" (click)="denied(e)">Deny</button>
                    </td>
                  </tr>
                </tbody>
                <tbody *ngIf="TotalLeaveAndWfhlength == 0">
                  <tr>
                    <td class="text-center" colspan="5">
                      <span style="font-size: 13px;">NO DATA</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="row">
          <div class="col-xl-12 col-lg-6 col-md-6 col-sm-12 col-12">
            <mat-card class="UpcomingSchedule">
              <div class="row">
                <div class="col-sm-12">
                  <h4 class="mystats" style="padding: 10px 10px 0px 10px;color: #53575f;">Upcoming Schedule</h4>
                </div>
              </div>
              <table class="table UpcomingSchedule-table">
                <tbody>
                  <tr *ngFor="let d of finalArr">
                    <div class="array-date">{{d[0].startDay2}} {{d[0].startDate.substring(0,10)| date:'dd/MM/yyyy'}}</div>
                    <div *ngFor="let e of d ;let i=index">
                      <!-- <div *ngFor="let f of dateArray;let j=index"> -->
                     
                        <!-- <div *ngIf="e.startDate.substring(0,10) == f"> -->
                          <td style="padding: 10px 10px;width: 75%;">
                            <div class="d-flex">
                              <img *ngIf="e.attachment != undefined" [src]="e.attachment.fileViewUrl" style="width: 27px;height: 27px;border-radius: 50%;margin: 0px 5px 0px 0px;;">
                              <img *ngIf="e.attachment == undefined" src="assets/img/profilepic.jpg" style="width: 27px;height: 27px; border-radius: 50%;margin: 0px 5px 0px 0px;;">
                              <div>
                                <span class="employeeName1">{{e.employee.empName}}</span>
                                <span class="empDesignation1">{{e.employee.designationName}}</span>
                              </div>                                           
                            </div>
                          </td>
                          <td style="padding: 10px 10px;width: 25%;">
                            <div>
                              <ng-container *ngIf="e.permissionHours == undefined else permission">
                                <span style="white-space: nowrap;" *ngIf="e.noOfLeaveDays<=1" class="numberofdays">{{e.noOfLeaveDays}} Day</span>
                                <span style="white-space: nowrap;" *ngIf="e.noOfLeaveDays>1" class="numberofdays">{{e.noOfLeaveDays}} Days</span>
                              </ng-container>
                              <ng-template #permission>
                                <!-- <span *ngIf="e.permissionHours == '00'" class="numberofdays">{{e.noOfLeaveDays}} mins</span>
                                <!-- <span *ngIf="e.permissionHours == '01'" class="numberofdays">{{e.noOfLeaveDays}} Hr</span> -->
                                <!-- <span *ngIf="e.permissionHours != '00' || e.permissionHours >= '01'" class="numberofdays">{{e.noOfLeaveDays}} Hr</span> -->
                                <span style="white-space: nowrap;" class="numberofdays">{{e.noOfLeaveDays}}</span>
                              </ng-template>
                              <span class="typename">{{e.leaveType.leaveName}}</span>
                            </div>  
                          </td>
                        <!-- </div> -->
                      <!-- </div> -->
                    </div>
                  </tr>
                </tbody>
              </table>
            </mat-card>
          </div>
          <div class="col-xl-12 col-lg-6 col-md-6 col-sm-6 col-12" style="margin: 0 auto;">
            <mat-card class="apply-block">
              <h3 class="apply-header">Apply Leave & Permission</h3>
              <button mat-stroked-button class="apply-button new-style" [routerLink]="['../apply']"
              color="primary" style="background: #1A83FF;border: 1px solid #1A83FF;">Apply Leave
              <mat-icon class="material-icons-outlined apply-button-icon">person_add_alt</mat-icon>
              </button>
              <button mat-stroked-button class="apply-button new-style" [routerLink]="['../permission']"
              color="primary" style="background: #0EAB00;border: 1px solid #0EAB00;">Apply Permission
              <mat-icon class="material-icons-outlined apply-button-icon">verified</mat-icon>
              </button>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>


<!-- <div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Traffic</h5>
                  <span class="h2 font-weight-bold mb-0">350,897</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-danger text-white rounded-circle shadow p-3">
                    <i class="material-icons-outlined">add</i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                <span class="text-nowrap">Since last month</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">New users</h5>
                  <span class="h2 font-weight-bold mb-0">2,356</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-warning text-white rounded-circle shadow p-3">
                    <i class="material-icons-outlined">add</i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-danger mr-2"><i class="fas fa-arrow-down"></i> 3.48%</span>
                <span class="text-nowrap">Since last week</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Sales</h5>
                  <span class="h2 font-weight-bold mb-0">924</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-info text-white rounded-circle shadow p-3">
                    <i class="material-icons-outlined">add</i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-warning mr-2"><i class="fas fa-arrow-down"></i> 1.10%</span>
                <span class="text-nowrap">Since yesterday</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6">
          <div class="card card-stats mb-4 mb-xl-0">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title text-uppercase text-muted mb-0">Performance</h5>
                  <span class="h2 font-weight-bold mb-0">49,65%</span>
                </div>
                <div class="col-auto">
                  <div class="icon icon-shape bg-info text-white rounded-circle shadow p-3">
                    <i class="material-icons-outlined">add</i>
                  </div>
                </div>
              </div>
              <p class="mt-3 mb-0 text-muted text-sm">
                <span class="text-success mr-2"><i class="fas fa-arrow-up"></i> 12%</span>
                <span class="text-nowrap">Since last month</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
