<!-- <ng-container *ngIf="isLoading; else elseLoading">
  <div class="loading">
    <h4 class="text-muted">loading....</h4>
    <br>
    <div class="spinner-grow text-secondary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-danger" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-dark" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-container> -->
<span>
  <h4 class="dynamic-title">Business Travel List</h4>
</span>
 
<ng-container *ngIf="loader; else elseLoading" class="loading">
  <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 

<ng-template #elseLoading>
  <div class="container-fluid">
    <!-- <div class="float-left">
      <button mat-raised-button style="transform: translateY(10px);">
        <mat-icon>post_add</mat-icon>
        Business Travel
      </button> </div> -->
    <div class="float-right" style="position: relative;top: 6px;">
      <div class="form-group search-input topnav-right">                                                                     
        <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
        <button mat-raised-button class="search-btn">
          <mat-icon class="search-icon">search</mat-icon>
        </button>                                                                                                
      </div>
    </div>
    <span> </span>
  </div>

  <ng-container>
    <div class="example-container mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort *ngIf="list_count >0">
        <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef class="text-center"><b>&nbsp;S.No&nbsp;</b></th>
          <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}</td>
        </ng-container>   
        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef > 
            <b>From&nbsp;Date</b> </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.startDate | date}} </td>
        </ng-container>
        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef >
            <b>To&nbsp;Date</b>
          </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.endDate | date }} </td>
        </ng-container>

        <ng-container matColumnDef="noOfDays">
          <th mat-header-cell *matHeaderCellDef class="text-center">
            <b>No.of&nbsp;Days</b>
          </th>
          <td mat-cell *matCellDef="let element" class="text-center" style="text-align: left;"> {{element.noOfDays}} </td>
        </ng-container>

        <ng-container matColumnDef="reason">
          <th mat-header-cell *matHeaderCellDef class="text-center" style="width:20%"><b>Reason</b></th>
          <td mat-cell *matCellDef="let element; let i = index">
            <span class="reason-span" *ngIf="changeasDenyReason==false">{{element.reason}}</span>

            <span class="reason-span" *ngIf="changeasDenyReason==true && clickedbusinessTravelId != i && (isMenuOpen==true || isMenuOpen==false)">{{element.reason}}</span>
            
            <span class="reason-span" *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==false">{{element.reason}}</span>

            <span class="denied-span menu" #menu *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">Denied Reason</span>
            <span class="reason-span-deny menu" #menu *ngIf="changeasDenyReason==true && clickedbusinessTravelId == i && isMenuOpen==true">{{element.businesstraveldenyReason}}</span>
          </td>
        </ng-container> 

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <b>Status</b>
          </th>
          <td mat-cell *matCellDef="let element; let i = index" style="white-space: nowrap;" [style.color]="withDrawStatus(element.businessTravelStatusName)">
            {{element.businessTravelStatusName}} 
            <span *ngIf="element.businessTravelStatusName=='Approved'" class="material-icons-outlined" 
              style="font-size: 16px;transform: translate(4px, 4px);">check_circle</span>
            <a *ngIf="element.businessTravelStatusName=='Denied'" class="material-icons-outlined" #toggleButton (click)="denyReason(i)"
              style="font-size: 16px;transform: translate(4px, 4px);">error_outline</a>
            <a *ngIf="element.businessTravelStatusName=='Denied'"
             style="font-size: 20px;">*</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="star" >
          <th mat-header-cell *matHeaderCellDef  class="text-center"></th>
          <td mat-cell *matCellDef="let element"  class="text-center">


            <!-- <ng-container *ngIf="element.businessTravelStatusName =='Denied'; else elseblock">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon style="font-size: 17px;">more_vert</mat-icon>
              </button>
            </ng-container>
            <ng-template #elseblock>
              <mat-icon  style="font-size: 17px;"> -- </mat-icon>
            </ng-template> -->


            <button *ngIf="!(element.businessTravelStatus == 8 || element.businessTravelStatus == 6)" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon style="font-size: 17px;">more_vert</mat-icon>
            </button>
            <mat-icon *ngIf="(element.businessTravelStatus == 6 || element.businessTravelStatus == 8)" style="font-size: 17px;"> -- </mat-icon>
            <mat-menu #menu="matMenu">
              <ng-container>
                <a mat-menu-item (click)="cancel(element)">
                    <mat-icon class="cancel-trip-icon material-icons-outlined">clear</mat-icon>
                    <span class="cancel-trip-text">Cancel Trip</span>
                </a>
              </ng-container>
              <!-- <a mat-menu-item (click)="Reason(element)">
                <mat-icon>chat</mat-icon>
                <span>Reason</span>
              </a> -->
              <!-- <a *ngIf="element.businessTravelStatusName =='Denied'"
              mat-menu-item (click)="showdenyreason(element.businesstraveldenyReason)">
                  <mat-icon class="cancel-trip-icon">do_not_disturb</mat-icon>
                  <span class="cancel-trip-text">Deny Reason</span>
              </a> -->
              <a mat-menu-item (click)="showLocation(element.businessTravelDetail)">
                <mat-icon class="cancel-trip-icon material-icons-outlined">place</mat-icon>
                <span class="cancel-trip-text">Show Location</span>
              </a>
            </mat-menu>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="container" *ngIf="noData">
        <div class="alert alert-primary" align="center" style="width: 100%">
          <!-- <h1 >No Business Travel Request</h1> -->
          No Business Travel Request
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<div [hidden]="loader">
  <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>
<!-- <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons>
</mat-paginator> -->

