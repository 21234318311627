<h2 mat-dialog-title class="title">Employee Probation Completion Details</h2>
<mat-dialog-content>
     <table class="table table-hover" style="width: 100%; position: sticky; top: 0;">
         <thead>
           <tr>
             <th scope="col">Employee&nbsp;Number</th>
             <th scope="col">Employee&nbsp;Name</th>
             <th scope="col">OrganizationEmail</th>
             <th scope="col">EmployeeJoinedDate</th>
           </tr>
         </thead>
         <tbody>
             <tr *ngFor="let item of this.dataSource; let i = index" >
                     <td>{{item.employeeNumber}}</td>
                     <td>{{item.employeeName}}</td>
                     <td>{{item.organizationEmail }}</td>
                     <td>{{item.employeeJoinedDate| date}}</td>
             </tr>
         </tbody>
       </table>
</mat-dialog-content>

<style>
    .title{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 38px;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;
        color: #4A4A4A;
    }
    table thead tr{
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
    }
    table thead tr th{
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 13px;
        line-height: 21px;
        align-items: center;
        letter-spacing: 0.005em;
    }
    td{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        align-items: center;
        letter-spacing: 0.005em;
        color: #4A4A4A;
    }
    table tbody tr{
        border: 1px solid #EEEEEE;
        background: #FFFFFF;
    }
    .table th{
      border-top: 0!important;
    }
    .table thead th{
        border-bottom: 0!important;
    }
</style>
