import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormArrayName, Validators, FormBuilder } from '@angular/forms';
import { MustMatch } from './mustmatch';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { EventEmitter } from 'protractor';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.css']
})
export class AccountSettingsComponent implements OnInit {
  hide = true;
  hide2 = true;
  hide1 = true;
  accountForm: FormGroup;
  submitted = false;
  @ViewChild("current", { static: true }) private current: ElementRef;
  constructor(private fb: FormBuilder, private router: Router, private gs: GlobalserviceService) { }

  ngOnInit(): void {

    this.accountForm = this.fb.group({
      employeeId: [],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$')]],
      confirmPassword: ['', Validators.required]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword'),
      }
    );
  }

  get f() { return this.accountForm.controls; }

  save(i) {
    console.log(i);
    if (this.accountForm.invalid) {
      return
    }
    let x = JSON.parse(localStorage.getItem('data'))
    let id = x.employeeId
    Swal.fire({
      title: 'Are you sure? Do you want to Update',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Update'
    }).then((result) => {
      if (result.value) {
        this.gs.updateAccountSettings(id, i).subscribe(d => {
          Swal.fire(
            {
              text: 'Changed!! Please login with new password',
              icon: 'success'
            }
          );
          this.router.navigate(['../../login'])
          localStorage.clear()
        },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            }
            )
          },
          () => {
            console.log('done')
          }
        )
      }
    })
  }
}
