<span>
    <h4 class="dynamic-title">Leave/Permission Balance Detail</h4>
  </span>
  <ng-container *ngIf="loader; else elseLoading" class="loading">
    <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
  </ng-container> 
  
  <ng-template #elseLoading>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="float-left">
            <!-- <button mat-raised-button style="transform: translateY(10px);margin-bottom: 30px;">
              <mat-icon>history</mat-icon>
              Permission
            </button>  -->
          </div>
          <div class="float-right">
            <!-- <button mat-raised-button>
              <mat-icon>search</mat-icon>
            </button>
            <mat-form-field style="line-height: 1.6;margin: 0 0 0 8px;  ">
              <mat-label>Search</mat-label>
              <input matInput (keyup)="applyFilter($event)">
            </mat-form-field> -->
  
            <div class="form-group search-input topnav-right">                                                                     
              <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
              <button mat-raised-button class="search-btn">
                  <mat-icon class="search-icon">search</mat-icon>
              </button>                                                                                                 
            </div>
          </div>
        </div>
      </div>
    </div>
<form [formGroup]="leaveReport" class="container-fluid">
    <div class="row">
        <div class="col-xl-3 col-md-3">
            <mat-form-field appearance="outline" style="display: inline;">
                <mat-label>Select Option</mat-label> 
                <mat-select
                    placeholder="Select Option" id="selvalue" formControlName="option" (selectionChange) = "isselected($event)">
                    <!-- <mat-option value="0">--  Select Option -- </mat-option> -->
                    <mat-option value="Permission"> Permission </mat-option>
                    <mat-option value="Leave"> Leave </mat-option>
                    <!-- <mat-option *ngFor="let employee of employeelist" [value]="employee.employeeId">
                      {{employee.employeeName}}
                    </mat-option> -->
                </mat-select>
            </mat-form-field>
            <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('option').hasError('required')">Please Select Option.</mat-error>
            <!-- <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('employeeId').hasError('required')">Please select employee</mat-error> -->
        </div>
        <ng-container *ngIf="option=='Leave'; else Permission">
            <div class="col-xl-3 col-md-3">
                <mat-form-field appearance="outline" style="display: inline;">
                    <mat-label>Select Employee</mat-label> 
                    <mat-select
                      placeholder="Select Employee" id="selvalue" formControlName="employeeId">
                      <mat-option [value]="">--  Select Employee -- </mat-option>
                      <mat-option value="All"> All </mat-option>
                      <mat-option *ngFor="let employee of employeelist" [value]="employee.employeeId">
                        {{employee.employeeName}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                  <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('employeeId').hasError('required')">Please Select Employee.</mat-error> 
             </div>
             <div class="col-xl-3 col-md-3">
                <mat-form-field appearance="outline" style="display: inline;">
                    <mat-label>Year</mat-label> 
                    <mat-select
                      placeholder="Select Year" id="selvalue" formControlName="year">
                      <mat-option [value]="">--  Select Year -- </mat-option>
                      <mat-option *ngFor="let year of yearlist" [value]="year.yearName">
                        {{year.yearName}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                  <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('year').hasError('required')">Please Select Year.</mat-error>
             </div>
            <div class="col-xl-3 col-md-3 but max-height">
                <button mat-stroked-button type="button" class="apply-button" (click)="search()" style="margin: 8px 5px 0px 0px;background: #1A83FF;border: 1px solid #1A83FF;">
                <mat-icon class="material-icons-outlined apply-button-icon">search</mat-icon>
                Search
                </button>
                <button mat-stroked-button type="reset" class="apply-button" (click)="Clear()" style="margin: 8px 0px 0px 5px;background: #FF7171;border: 1px solid #FF7171;">
                    <mat-icon class="material-icons-outlined apply-button-icon">clear</mat-icon>
                    Clear
                </button>
            </div> 
       </ng-container>
        <ng-template #Permission>
            <div class="col-xl-2 col-md-2">
                <mat-form-field appearance="outline" style="display: inline;">
                    <mat-label>Select Employee</mat-label> 
                    <mat-select
                      placeholder="Select Employee" id="selvalue" formControlName="employeeId">
                      <mat-option [value]="">--  Select Employee -- </mat-option>
                      <mat-option  value="All"> All </mat-option>
                      <mat-option *ngFor="let employee of employeelist" [value]="employee.employeeId">
                        {{employee.employeeName}}
                      </mat-option>
                    </mat-select>
                </mat-form-field>
                  <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('employeeId').hasError('required')">Please Select Employee.</mat-error>
             </div>
            <div class="col-xl-2 col-md-2 max-height">
                <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                  <mat-label>Start Date</mat-label> 
                  <input matInput formControlName="startdate"
                      placeholder="Choose a Date"
                      [matDatepicker]="picker1"
                      (dateChange)="startDate($event)">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                  <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('startdate').hasError('required')">Please Select Start Date</mat-error> 
                  <mat-error class="error_msg" *ngIf="startdate_required">Please select start date</mat-error>
            </div>
            <div class="col-xl-2 col-md-2 end max-height">
                <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                  <mat-label>End Date</mat-label> 
                  <input matInput formControlName="enddate"
                      placeholder ="Choose a Date"
                      [matDatepicker]="picker2"
                      (dateChange)="EndDate($event)"
                      [min]="min" [max]="maxDate">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                  <!-- <mat-error class="error_msg" *ngIf="enddate_required">Please select end date</mat-error> -->
                  <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('enddate').hasError('required')">Please Select End Date</mat-error>
                  <mat-error class="error_msg" *ngIf="show_EMsg">End date should be greater than start date</mat-error>
            </div>
            <div class="col-xl-3 col-md-3 but max-height">
                <button mat-stroked-button type="button" class="apply-button" (click)="search()" style="margin: 8px 5px 0px 0px;background: #1A83FF;border: 1px solid #1A83FF;">
                <mat-icon class="material-icons-outlined apply-button-icon">search</mat-icon>
                Search
                </button>
                <button mat-stroked-button type="reset" class="apply-button" (click)="Clear()" style="margin: 8px 0px 0px 5px;background: #FF7171;border: 1px solid #FF7171;">
                    <mat-icon class="material-icons-outlined apply-button-icon">clear</mat-icon>
                    Clear
                </button>
            </div> 
        </ng-template>
        <!-- <div class="col-xl-3 col-md-3">
            <mat-form-field appearance="outline" style="display: inline;">
                <mat-label>Select Employee</mat-label> 
                <mat-select
                  placeholder="Select Employee" id="selvalue" formControlName="employeeId" 
                  >
                  <mat-option value="0">--  Select Employee -- </mat-option>
                  <mat-option *ngFor="let employee of employeelist" [value]="employee.employeeId">
                    {{employee.employeeName}}
                  </mat-option>
                </mat-select>
            </mat-form-field>
              <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('employeeId').hasError('required')">Please select employee</mat-error>
         </div> -->
        <!-- <div class="col-xl-3 col-md-2 max-height">
            <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
              <mat-label>Start Date</mat-label> 
              <input matInput formControlName="startdate"
                  placeholder="Choose a Date"
                  [matDatepicker]="picker1"
                  (dateChange)="startDate($event)">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
              <mat-error class="error_msg" *ngIf="startdate_required">Please select start date</mat-error> -->
        <!-- </div> -->
        <!-- <div class="col-xl-3 col-md-2 end max-height">
            <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
              <mat-label>End Date</mat-label> 
              <input matInput formControlName="enddate"
                  placeholder ="Choose a Date"
                  [matDatepicker]="picker2"
                  (dateChange)="EndDate($event)"
                  [min]="min">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
              <mat-error class="error_msg" *ngIf="enddate_required">Please select end date</mat-error>
              <mat-error class="error_msg" *ngIf="show_EMsg">End date should be greater than start date</mat-error>
        </div> -->
        <!-- <div class="col-xl-3 col-md-4 but max-height">
            
            <button mat-stroked-button type="button" class="apply-button" (click)="search()" style="margin: 8px 5px 0px 0px;background: #1A83FF;border: 1px solid #1A83FF;">
              <mat-icon class="material-icons-outlined apply-button-icon">search</mat-icon>
              Search
            </button>

            <button mat-stroked-button type="reset" class="apply-button" (click)="Clear()" style="margin: 8px 0px 0px 5px;background: #FF7171;border: 1px solid #FF7171;">
                <mat-icon class="material-icons-outlined apply-button-icon">clear</mat-icon>
                Clear
            </button>
        </div> -->
    </div>
      <!-- <mat-error class="error_msg" *ngIf="all_null">Choose any of this fields.</mat-error> -->
</form>
  <ng-container>
    <div class="example-container mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort  *ngIf="showTable">
        <ng-container matColumnDef="EmployeeNumber">
          <th mat-header-cell *matHeaderCellDef class="text-center">
              <b>Emp&nbsp;No</b> 
          </th>
          <td mat-cell *matCellDef="let element" class="text-center"> {{element.employeeNumber}} </td>
        </ng-container>
        <!-- <ng-container matColumnDef="EmployeeNumber" sticky>
          <th mat-header-cell *matHeaderCellDef> <b >
              EMPLOYEE&nbsp;NUMBER
            </b> </th>
          <td mat-cell *matCellDef="let element"> {{element.employee.employeeNumber}} </td>
        </ng-container> -->
  
        <ng-container matColumnDef="EmployeeName">
          <th mat-header-cell *matHeaderCellDef> <b >
            Employee&nbsp;Name
            </b> </th>
          <td mat-cell *matCellDef="let element"> {{element.employeeName}} </td>
        </ng-container>
  
        <ng-container matColumnDef="OrganizationEmail">
          <th mat-header-cell *matHeaderCellDef> <b >
            Organization Email
            </b> </th>
          <td mat-cell *matCellDef="let element"> {{element.organizationEmail}} </td>
        </ng-container>
  
        <ng-container matColumnDef="Balance">
          <th mat-header-cell *matHeaderCellDef> 
            <b *ngIf="optionValue=='Permission'">
              Permission&nbsp;Balance
            </b> 
            <b *ngIf="optionValue=='Leave'">
              Leave&nbsp;Balance
            </b> 
          </th>
          <div *ngIf="optionValue=='Permission'; else elseBlock">
             <!-- <td mat-cell *matCellDef="let element">{{ element.balance | date: 'HH:mm:ss'}}</td>  -->
             <td mat-cell *matCellDef="let element">{{ getFormattedBalance(element.balance)}}</td> 
            <!-- <td mat-cell *matCellDef="let element">{{ getFormattedBalance(element.balance) | date: 'HH:mm' }}</td> -->
          </div>
          <ng-template #elseBlock>
            <td mat-cell *matCellDef="let element">{{element.noOfDays}}</td>
          </ng-template>
        </ng-container>
        <!-- <ng-template matColumnDef="Balanace">
          <th mat-header-cell *matHeaderCellDef> 
            <b>
              Permission&nbsp;Balance
            </b> 
          </th>
          <td mat-cell *matCellDef="let element"> {{element.employee}} </td> -->
        <!-- </ng-template> -->
        <!-- <ng-container matColumnDef="permissionHours">
          <th mat-header-cell *matHeaderCellDef >
            <b>
              Permission&nbsp;Hours
            </b>
          </th>
          <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.permissionHours}} </td>
        </ng-container> -->
  
        <!-- <ng-container matColumnDef="workFromHomeStatus">
          <th mat-header-cell *matHeaderCellDef >
            <b>Status</b>
          </th>
          <td mat-cell *matCellDef="let element" [style.color]="withDrawStatus(element.permissionStatusName)">
            {{element.permissionStatusName}} </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="container" *ngIf="noData">
        <div class="alert alert-primary" style="width: 100%">
          No Balance {{leaveReport.value.option}} List
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
  
  <div [hidden]="loader" >
    <mat-paginator   style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
    </mat-paginator>
  </div>