<span>
  <h4 class="dynamic-title">Record Timing Settings</h4>
</span>

<div class="container-fluid" style="margin: 15px 0px;">
  <div class="row">
    <div class="col-md-12">
      <div class="time-header">Time Setting</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-5 col-sm-8">
      <!-- <mat-card class="mat-card main-block"> -->
        <div class="main-block">
          <table class="table table-responsive">
            <thead>
              <th>Minimum&nbsp;Time</th>
              <th>Maximum&nbsp;Time</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let settings of settingslist  let i=index" >
                <td *ngIf="settings.minimumWokrHours == 1">{{settings.minimumWokrHours}} Hr</td>
                <td *ngIf="settings.minimumWokrHours != 1">{{settings.minimumWokrHours}} Hrs</td>
                <td *ngIf="settings.maximumWokrHours == 1">{{settings.maximumWokrHours}} Hr</td>
                <td *ngIf="settings.maximumWokrHours != 1">{{settings.maximumWokrHours}} Hrs</td>
                <td>
                  <mat-icon class="material-icons edit-icon" (click)="edit(settings.settingsId)">mode_edit</mat-icon> 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      <!-- </mat-card>  -->
    </div> 
  </div>
</div>

<div class="container-fluid" style="margin: 15px 0px;">
  <div class="row">
    <div class="col-md-12">
      <div class="time-header">Permission Setting</div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 col-sm-8">
      <!-- <mat-card class="mat-card main-block"> -->
        <div class="main-block">
          <table class="table table-responsive">
            <thead>
              <th>Maximum&nbsp;Time</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let settings of settingslist  let i=index" >
                <td *ngIf="settings.maximumPermissionHours == 1">{{settings.maximumPermissionHours}} Hr</td>
                <td *ngIf="settings.maximumPermissionHours != 1">{{settings.maximumPermissionHours}} Hrs</td>
                <td>
                  <mat-icon class="material-icons edit-icon" (click)="editPermission(settings.settingsId)">mode_edit</mat-icon> 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      <!-- </mat-card>  -->
    </div> 
  </div>
</div>
