<span>
  <h4 class="dynamic-title">Apply Business Travel</h4>
</span>

  <div class="col-sm-9">
    <mat-card>
      <!-- <mat-card class="header">
        <p> <span class="material-icons">
             local_airport
          </span>Apply Business Travel </p>
      </mat-card> -->

      <ng-container *ngIf="isLoading; else elseLoading">
        <div class="loading">
          <h4 class="text-muted">loading....</h4>
          <br>
          <div class="spinner-grow text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-danger" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-container>

      <ng-template #elseLoading>
      <form action="" [formGroup]="businessGroup" (ngSubmit)="applyWFH()">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">*Enter details to apply Business Travel*</h3>
          </div>
        </div>
        <!-- First Approver -->
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label class="form-label">First Approver</mat-label>
          <mat-icon class="form-icon" style=" color: #6d6b6b;
                    transform: translateY(0px);" matPrefix>mail</mat-icon>
          <input type="text" matInput [value]="first_approver" readonly>
        </mat-form-field>
        <!-- Second Approver -->
        <mat-form-field class="example-full-width" appearance="outline" *ngIf="secondError">
          <mat-label class="form-label">Second Approver</mat-label>
          <mat-icon class="form-icon" style=" color: #6d6b6b;
                    transform: translateY(0px);" matPrefix>mail</mat-icon>
          <input type="text" matInput [value]="second_approver" readonly>
        </mat-form-field>
        <div class="row">
          <div class="col-md-4">
            <!--  start date -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">From Date</mat-label>
                <mat-icon class="form-icon" style=" color: #6d6b6b;
                          transform: translateY(0px);" matPrefix>today</mat-icon>
                <!-- [matDatepickerFilter]="myFilter" -->
                <input matInput (click)="picker.open()"[matDatepickerFilter]="myFilter"
                  (dateChange)="someMethodName($event)" [errorStateMatcher]="matcher" formControlName="startDate"
                  [matDatepicker]="picker" placeholder="select start date" (dateInput)="nofoDays($event)">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
                <mat-error *ngIf=" f.startDate.hasError('required')">
                  Please select <strong>From Date</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-4">
            <!-- endDate -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">To Date</mat-label>
                <mat-icon class="form-icon" style="color: #6d6b6b;
                          transform: translateY(0px);" matPrefix>today</mat-icon>
                <input matInput (click)="a.open()" [matDatepickerFilter]="myFilter" (dateInput)="nofoDays($event)"
                [min]="min" [errorStateMatcher]="matcher" formControlName="endDate" [matDatepicker]="a"
                  placeholder="select end date" placeholder="select end date" [disabled]="endDate_disable">
                <mat-datepicker-toggle matSuffix [for]="a"></mat-datepicker-toggle>
                <mat-datepicker touchUi #a></mat-datepicker>
                <mat-error *ngIf=" f.endDate.hasError('required')">
                  Please select <strong>To Date</strong>
                </mat-error>
                <mat-error *ngIf="show_error_msg">
                  To date should be greater than from date.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-4">
          <!-- nofoDays -->
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">No.of Days</mat-label>
            <mat-icon class="form-icon" style=" color: #6d6b6b;
            transform: translateY(0px);" matPrefix>today</mat-icon>
            <input type="text" matInput [value]="noOFDays" readonly>
          </mat-form-field>
          </div>
        </div>

        <!-- reason -->
          <mat-form-field class="example-full-width form-textarea" appearance="outline">
            <mat-label class="form-label">Reason </mat-label>
            <mat-icon class="form-icon" style=" color: #6d6b6b;
              transform: translateY(15px);" matPrefix>insert_comment</mat-icon>
            <textarea matInput type="text" formControlName="reason" placeholder="Enter reason!!!!"
              [errorStateMatcher]="matcher"></textarea>
            <mat-error *ngIf=" f.reason.hasError('required')">
              Please enter <strong>leave reason</strong>
            </mat-error>
          </mat-form-field>

        <div class="business_controls">
          <div formArrayName="businessTravelDetail" class="location-part mt-1 mb-4" *ngFor="let item of travelControl.controls; let i = index">
            <div [formGroupName]="i">
              <div style="transform: translateY(-14px);">
                <div class="float-right">
                  <!-- <button type="button" *ngIf="i > 0" mat-raised-button color="primary" matBadgePosition="before"
                    matBadgeColor="accent" (click)="remove_location(i)">
                    Remove
                  </button> -->
                  <mat-icon class="material-icons-outlined" type="button" *ngIf="i > 0" mat-raised-button color="primary" matBadgePosition="before"
                    matBadgeColor="accent" (click)="remove_location(i)" class="location-delete">delete_outline
                    </mat-icon>
                </div>
                <button type="button" mat-raised-button color="primary" matBadgePosition="before" class="location-title"
                  matBadgeColor="accent">
                  Location - {{i + 1}}
                </button>
              </div>
              <!-- country -->
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">Country </mat-label>
                <mat-icon class="form-icon" style=" color: #6d6b6b;
                    transform: translateY(0px);" matPrefix>emoji_flags</mat-icon>
                <mat-select class="example-full-width" formControlName="country">
                  <mat-option *ngFor="let c of countryList" value={{c.name}}>{{c.name}}</mat-option>
                </mat-select>
                <mat-error *ngIf="travelControl.controls[i].get('country').errors?.required">
                  Please select <strong>Country</strong>
                </mat-error>
              </mat-form-field>
              <div class="row">
                <!-- from location -->
                <div class="col-md">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label class="form-label">From Location </mat-label>
                    <mat-icon class="form-icon" style=" color: #6d6b6b;
                        transform: translateY(0px);" matPrefix>place</mat-icon>
                    <input type="text" matInput formControlName="fromLocation" placeholder="Enter From Location" (keypress)="omit_special_char($event) && keyPress($event)">
                    <mat-error *ngIf="travelControl.controls[i].get('fromLocation').errors?.required">
                      Please Enter <strong>From Location</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- to location -->
                <div class="col-md">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label class="form-label">To Location </mat-label>
                    <mat-icon class="form-icon" style=" color: #6d6b6b;
                        transform: translateY(0px);" matPrefix>place</mat-icon>
                    <input type="text" matInput formControlName="toLocation" placeholder="Enter To Location"(keypress)="omit_special_char($event) && keyPress($event)">
                    <mat-error *ngIf="travelControl.controls[i].get('toLocation').errors?.required">
                      Please Enter <strong>To Location</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label class="form-label">Preferred Time </mat-label>
                    <mat-icon class="form-icon" style=" color: #6d6b6b;
                        transform: translateY(0px);" matPrefix>timer</mat-icon>
                    <input type="time" matInput step="2" formControlName="preferredTime">
                    <mat-error *ngIf="travelControl.controls[i].get('preferredTime').errors?.required">
                      Please Select <strong>Preferred Time</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                <!-- travel mode -->
                <div formGroupName="travelMode">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label class="form-label">Travel Mode </mat-label>
                    <mat-icon class="form-icon" style=" color: #6d6b6b;
                        transform: translateY(0px);" matPrefix>local_airport</mat-icon>
                    <mat-select class="example-full-width" formControlName="travelModeId">
                      <mat-option *ngFor="let t of traveModeList" [value]="t.travelModeId">{{t.travelModeName}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="travelControl.controls[i].get('travelMode').get('travelModeId').errors?.required">
                      Please Select <strong>Travel Mode</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- buttons -->
        <div class="form-group">
          <div class="row media-query">
            <section class="section-1">
              <button type="button" mat-stroked-button target="_blank" (click)="addLocation()" class="add-location">
                <span class="material-icons-outlined" style="font-size: 15px;line-height: 1.6;">location_on</span>&nbsp;Add Location
              </button>
            </section>
            <div class="section-2" align="center">
              <button mat-stroked-button class="cancel-button" color="accent" type="button" (click)="Cancel()">
                <span class="material-icons-outlined cancel-button-icon">close</span>
                Cancel
              </button>
            </div>
            <div class="section-3">
              <button mat-stroked-button class="apply-button" color="primary" type="submit">
            <!-- [disabled]="businessGroup.invalid" -->
                <span class="material-icons-outlined apply-button-icon">done</span>
                &nbsp;Apply&nbsp;
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
    </mat-card>
  </div>
