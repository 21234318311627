<p>multiselect works!</p>
<mat-form-field>
  <mat-select [formControl]="bankMultiCtrl" placeholder="Select Employee" [multiple]="true" #multiSelect>
    <mat-option *ngFor="let bank of filteredBanksMulti | async" [value]="bank.employeeName">
      {{bank.employeeName}}
    </mat-option>
    <!-- <ngx-mat-select-search [formControl]="bankMultiFilterCtrl"></ngx-mat-select-search> -->
  </mat-select>
</mat-form-field>


