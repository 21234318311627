<style>
  .full-width{
    width:100%;
  }
  .select-all{
    margin: 5px 17px;
  }
</style>
<div class="container">
  <h2 align="center">{{calendarName}}</h2>
  <form [formGroup]="workDaysForm" (ngSubmit)="onSubmit()">

  <div class="form-group">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Calendar Name</mat-label>
      <mat-select formControlName="calender">
        <mat-option *ngFor="let cal of calendarList" [value]="cal.calendarId">
          {{cal.calendarName}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.calender.hasError('required')">
        <span>Calendar is required</span>
      </mat-error>
    </mat-form-field>
  </div>

  <!-- <div class="form-group">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Working Days From</mat-label>
      <input matInput formControlName="workingdayfrom"  (keypress)="noNumber($event)" maxlength="1" autocomplete="off"/>
      <mat-error *ngIf="f.workingdayfrom.hasError('required')">
        <span>Working Days From is required</span>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="form-group">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Working Days To</mat-label>
      <input matInput formControlName="workingdayto"  (keypress)="noNumber($event)"  maxlength="1" autocomplete="off"/>
      <mat-error *ngIf="f.workingdayto.hasError('required')">
        <span>Working Days To is required</span>
      </mat-error>
    </mat-form-field>
  </div> -->

  <div class="form-group">
    <mat-form-field class="full-width" apperance="outline">
      <mat-label>Working Days</mat-label>
      <mat-select  #select multiple formControlName="workingdays">
        <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelected"
                            [ngModelOptions]="{standalone: true}"
                            (change)="toggleAllSelection()">Select All</mat-checkbox>
        </div>
        <mat-option (click)="optionClick()" *ngFor="let food of foods" [value]="food.value">
          {{food.viewValue}}
        </mat-option>
        <button mat-raised-button color="primary" style="margin:0 18px 18px 18px;" (click)="add()">Add</button>
        <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>
      </mat-select>
      <mat-error *ngIf="f.workingdays.hasError('required')">
        Working Days is required
      </mat-error>
    </mat-form-field>
  </div>


  <div class="form-group" align="center">
    <button mat-stroked-button color="accent" style="width: 45%; margin: 0 0 0 8px" type="button"
    mat-dialog-close>
      Cancel
    </button>
    <button mat-stroked-button color="primary" style="width: 45%; margin: 0 0 0 8px" type="submit">
      <span class="material-icons"> beenhere </span> OK
    </button>
  </div>
</form>

</div>
