<div class="col-md-12">
  <mat-card class="mat-card">
    <div class="row">
      <div class="col-md-12 p-0">
        <div class="d-flex float-right">
          <div class="">
            <button type="button" class="btn btn-primary float-right" (click)="createAsImport()" id="cratebut">
              <!-- <mat-icon class="material-icons-outlined" id="log1">publish</mat-icon> -->
              <!-- <span class="displaynone">Import&nbsp;Timings</span> -->
              <mat-icon class="material-icons-outlined" id="log1" style="top:3px" >save_alt</mat-icon >
              <span class="displaynone">Import</span>
            </button>
          </div>
          <div class="">
            <button type="button" class="btn btn-primary float-right" (click)="create()" id="cratebut">
              <mat-icon class="material-icons-outlined" id="log1">add</mat-icon>
              <span class="displaynone">Record&nbsp;Timings</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="empListForm">
      <div class="row mb-4">
        <div class="col-md-12 p-0">
          <div class="row" id="r1">
            <div class="col-md-3">
              <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                <mat-label>Choose a Month</mat-label> 
                <mat-select (selectionChange)="day($event)" formControlName="month" placeholder="Select Month"
                (selectionChange) = "isselected($event)">
                <mat-option value="0"> -- Select Month -- </mat-option>
                  <mat-option value="01">January</mat-option>
                  <mat-option value="02">February</mat-option>
                  <mat-option value="03">March</mat-option>
                  <mat-option value="04">April</mat-option>
                  <mat-option value="05">May</mat-option>
                  <mat-option value="06">June</mat-option>
                  <mat-option value="07">July</mat-option>
                  <mat-option value="08">August</mat-option>
                  <mat-option value="09">September</mat-option>
                  <mat-option value="10">October</mat-option>
                  <mat-option value="11">November</mat-option>
                  <mat-option value="12">December</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <div *ngIf="(isSubmitted && empListForm.controls['month'].invalid)">
                <small *ngIf="empListForm.controls['month'].errors?.required"  id="req" style="color:red;font-size:14px;">Month required</small>
              </div> -->
            </div>
            <div class="col-md-3">
              <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                <mat-label>Start Date</mat-label> 
                <input matInput formControlName="startdate" 
                  (dateChange)="date($event)"
                  placeholder="Choose a Date" 
                  [max]="todayDate" [min]="startdate" [matDatepicker]="picker1">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <!-- <div *ngIf="(isStartDate && empListForm.controls['startdate'].invalid)">
                <small *ngIf="empListForm.controls['startdate'].errors?.required"  id="req" style="color:red;font-size:14px;">Start date required</small>
              </div> -->
              <span class="error_msg" *ngIf="startdate_required">Please select start date</span>  
            </div>
            <div class="col-md-3 end">
              <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                <mat-label>End Date</mat-label> 
                <input matInput formControlName="enddate"
                  placeholder ="Choose a Date"
                  (dateChange)="datechange($event)"
                  [min]="startmin" [max]="endate" [matDatepicker]="picker2">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
              <!-- <div *ngIf="(isEndDate && empListForm.controls['enddate'].invalid)">
                <small *ngIf="empListForm.controls['enddate'].errors?.required"  id="req" style="color:red;font-size:14px;">End date required</small>
              </div>  -->
              <span class="error_msg" *ngIf="enddate_required">Please select end date</span> 
              <span class="error_msg" *ngIf="startdate_validation">End date should be greater than start date</span>  
            </div>
            <div class="col-md-3 but">
              <button type="button" class="btn btn-primary" (click)="search()" id="b1">Search</button>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div *ngIf="submitted" style="color:red;font-size:13px;position: relative;bottom:10px">Please select month or start date and end date.</div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-2 mb-2" *ngIf="list_count>5">
        <div class="select-pagination">
            <span class="show-text">Show : </span>
            <select class="select_list new-select_list" [(ngModel)]="itempages" [ngModelOptions]="{standalone: true}" (change)="showChanges()">
              <option class="form-control" value="5" selected>5</option>
              <option class="form-control" value="10">10</option>
              <option class="form-control" value="15">15</option>
              <option class="form-control" value="20">20</option>
              <option class="form-control" value="25">25</option>
            </select>
        </div>
      </div>
      <div class="col-md-10 mb-2 float-right" *ngIf="list_count>5">
        <div class="dataTables_filter">
          <label class="show-text mb-0">Search :
            <input type="search" class="form-control input" [(ngModel)]="dataSearch" (keyup) = "changepage()"  
            [ngModelOptions]="{standalone: true}"> 
          </label>
        </div>
      </div>
    </div>
    <ng-container *ngIf="listData.length>0">
      <!-- <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="Date">
              <th  mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date"> Date </th>
              <td mat-cell *matCellDef="let row"> {{row.date}} </td>
          </ng-container>
          <ng-container matColumnDef="Action">
              <th  mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
              <td mat-cell *matCellDef="let row">  <mat-icon (click)="edit(row.empInOutHeaderId)">mode_edit</mat-icon> </td>
          </ng-container>
            <tr mat-header-row *matHeaderRowDef="headers"></tr>
            <tr mat-row *matRowDef="let row; columns: headers;"></tr>
        </table>
      </div> -->
      <table class="table table-bordered table-responsive" matSort (matSortChange)="sortData($event)">
        <thead>
          <th mat-sort-header="date">Date</th>
          <th>Action</th>
        </thead>
        <tbody>
          <tr *ngFor="let e of listData | employeeInOutListpipe:dataSearch | paginate: { itemsPerPage:itempages, currentPage: p }; let i=index">
            <td>{{e.date}}</td>
            <td>
              <!-- <i _ngcontent-gaq-c231="" class="material-icons edit-icon" (click)="edit(e.empInOutHeaderId)">mode_edit</i> -->
              <mat-icon class="material-icons-outlined edit-icon" (click)="edit(e.empInOutHeaderId)">mode_edit</mat-icon> 
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <div class="container p-0" *ngIf="noData">
      <div class="alert alert-primary text-center" style="width: 100%">
        No Record Timings List
      </div>
    </div>
    <div class="col-md-12 text-right" *ngIf="list_count>5" style="padding-right: 0px;">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
    <!-- <mat-paginator  [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users" id="page"></mat-paginator> -->
  </mat-card>
</div>
