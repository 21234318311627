<span>
    <h4 class="dynamic-title">Leave Detail</h4>
  </span>
<ng-container *ngIf="loader; else elseLoading" class="loading">
    <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 

<ng-template #elseLoading>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="float-left">
                    <!-- <button mat-raised-button style="transform: translateY(10px);margin-right: 15px;" [routerLink]="['./../withdrawList']">
                        <span class="material-icons">keyboard_arrow_right</span> Show WithDraw List
                    </button> -->
                    <!-- <button mat-raised-button style="transform: translateY(10px);">Reports</button> -->
                    <button mat-stroked-button class="apply-button" [routerLink]="['./../withdrawList']" color="primary" style="margin: 10px 0px;">
                        <mat-icon class="material-icons-outlined apply-button-icon">keyboard_arrow_right</mat-icon>Show WithDraw List
                    </button>
                </div>
                <div class="float-right">
                    <!-- <button mat-raised-button>
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-form-field style="line-height: 1.6;margin: 0 0 0 8px;  ">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)">
                    </mat-form-field> -->

                    <div class="form-group search-input topnav-right">                                                                     
                        <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
                        <button mat-raised-button class="search-btn">
                            <mat-icon class="search-icon">search</mat-icon>
                        </button>                                                                                                 
                    </div>
                </div>

            </div>
        </div> 
    </div>
    <form [formGroup]="leaveReport" class="container-fluid">
        <div class="row">
            <div class="col-xl-3 col-md-3">
                <mat-form-field appearance="outline" style="display: inline;">
                    <mat-label>Select Employee</mat-label> 
                    <mat-select
                      placeholder="Select Employee" id="selvalue" formControlName="employeeId" 
                      (selectionChange) = "isselected($event)">
                      <mat-option value="0">--  Select Employee -- </mat-option>
                      <mat-option *ngFor="let employee of employeelist" [value]="employee.employeeId">
                        {{employee.employeeName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('employeeId').hasError('required')">Please select employee</mat-error>
            </div>
            <div class="col-xl-3 col-md-2 max-height">
                <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                <mat-label>Start Date</mat-label> 
                <input matInput formControlName="startdate"
                    placeholder="Choose a Date"
                    [matDatepicker]="picker1"
                    (dateChange)="startDate($event)">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <!-- <mat-error class="error_msg" *ngIf="submitted && leaveReport.get('startdate').hasError('required')">Please select start date</mat-error> -->
                <mat-error class="error_msg" *ngIf="startdate_required">Please select start date</mat-error>
            </div>
            <div class="col-xl-3 col-md-2 end max-height">
                <mat-form-field class="example-full-width" appearance="outline" style="display: inline;">
                <mat-label>End Date</mat-label> 
                <input matInput formControlName="enddate"
                    placeholder ="Choose a Date"
                    [matDatepicker]="picker2"
                    (dateChange)="EndDate($event)"
                    [min]="min">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
                <mat-error class="error_msg" *ngIf="enddate_required">Please select end date</mat-error>
                <mat-error class="error_msg" *ngIf="show_EMsg">End date should be greater than start date</mat-error>
            </div>
            <div class="col-xl-3 col-md-4 but max-height">
                <!-- <button type="button" class="btn btn-primary" id="b1" (click)="search()">Search</button>&nbsp;&nbsp; -->
                <button mat-stroked-button type="button" class="apply-button" (click)="search()" style="margin: 8px 5px 0px 0px;background: #1A83FF;border: 1px solid #1A83FF;">
                    <mat-icon class="material-icons-outlined apply-button-icon">search</mat-icon>
                    Search
                </button>
                <!-- <button type="reset" class="btn btn-primary" id="b1" (click)="Clear()">Clear</button>&nbsp;&nbsp; -->
                <button mat-stroked-button type="reset" class="apply-button" (click)="Clear()" style="margin: 8px 0px 0px 5px;background: #FF7171;border: 1px solid #FF7171;">
                    <mat-icon class="material-icons-outlined apply-button-icon">clear</mat-icon>
                    Clear
                </button>
            </div>
        </div>
        <mat-error class="error_msg" *ngIf="all_null">Choose any of this fields.</mat-error>
    </form>

    <ng-container>
        <div class="example-container mat-elevation-z8">
            <table mat-table [dataSource]="dataS" matSort *ngIf="list_count > 0">

                <ng-container matColumnDef="employeeNumber">
                    <th mat-header-cell *matHeaderCellDef class="text-center">
                        <b>Emp&nbsp;No</b> 
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.employee.employeeNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="employeeName">
                    <th mat-header-cell *matHeaderCellDef > 
                        <b>Emp&nbsp;Name</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.employee.employeeName | titlecase}} </td>
                </ng-container>


                <ng-container matColumnDef="organizationEmail">
                    <th mat-header-cell *matHeaderCellDef >
                        <b>Organization&nbsp;Email</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.employee.organizationEmail}} </td>
                </ng-container>
                <!-- <ng-container matColumnDef="contact">
                    <th mat-header-cell *matHeaderCellDef>
                        <b>CONTACT</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.employee.mobileNumber}} </td>
                </ng-container> -->

                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>
                        <b>From&nbsp;Date</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.startDate | date}} </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef>
                        <b>To&nbsp;Date</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.endDate | date}} </td>
                </ng-container>

                <ng-container matColumnDef="noOfLeaveDays">
                    <th mat-header-cell *matHeaderCellDef class="text-center"> 
                        <b>No.of&nbsp;Days</b> 
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.noOfLeaveDays}} </td>
                </ng-container>

                <ng-container matColumnDef="leaveStatus">
                    <th mat-header-cell *matHeaderCellDef> 
                        <b>Status</b> 
                    </th>
                    <td mat-cell *matCellDef="let element" [style.color]="test(element)"> {{element.leaveStatusName}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <div class="container" *ngIf="noData">
                <div class="alert alert-primary" align="center" style="width: 100%">
                    No Leave List
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>
<div [hidden]="loader">
    <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
    </mat-paginator>
</div>

