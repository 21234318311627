<span>
  <h4 class="dynamic-title">{{id?"Update":"Create"}} Branch</h4>
</span>
<div class="col-sm-9">
  <mat-card class="mat-card">
    <!-- <mat-card class="header">
      <p><span class="material-icons">home_work</span>Branch</p>
    </mat-card> -->
    <ng-container *ngIf="isLoading; else elseTemplate">
      <div class="loading">
        <h4 class="text-muted">loading....</h4>
        <br>
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <form [formGroup]="branchForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">*{{id?"Update":"Create"}} Branch*</h3>
          </div>
        </div>
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Organization Name</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>business</mat-icon>
            <mat-select placeholder="Select Organization Name" formControlName="organization">
              <mat-option *ngFor="let org of organizationList" [value]="org.organizationId">
                {{org.organizationName}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="f.organization.hasError('required')">
              <span><strong>Organization Name</strong> is required</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Branch Name</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>room</mat-icon>
            <input matInput placeholder="Ex:Hosur Branch" formControlName="branchname" />
            <mat-error *ngIf="f.branchname.hasError('required')">
              <span><strong>Branch Name</strong> is required</span>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Branch Location</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>my_location</mat-icon>
            <input placeholder="Ex:Hosur" matInput formControlName="branchlocation">
            <mat-error *ngIf="f.branchlocation.hasError('required')">
              <span><strong>Branch Location</strong> is required</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- button -->

        <!-- <div class="form-group" align="center">
          <button mat-stroked-button color="accent" style="width: 45%; margin: 0 0 0 8px;" type="button"
            [routerLink]="['']">Cancel
          </button>
          <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit">
            <span class="material-icons">beenhere</span>  {{button_label | titlecase}}
          </button>
        </div> -->

        <!-- button -->
        <div class="form-group">
          <div class="d-flex">
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="cancel-button" color="accent" type="button"
              [routerLink]="['']">
                <span class="material-icons-outlined cancel-button-icon">close</span>
                Cancel
              </button>
            </div>
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="apply-button" color="primary" type="submit">
                <span class="material-icons-outlined apply-button-icon">done</span>
                {{button_label | titlecase}}
              </button>
            </div>
          </div>
        </div>
        <!-- <br> -->
      </form>
    </ng-template>
  </mat-card>
  <br>

</div>
