<h2 mat-dialog-title class="title">Leave List</h2>
<mat-dialog-content>
     <table class="table table-hover" style="width: 100%; position: sticky; top: 0;">
         <thead>
           <tr>
             <th scope="col" class="text-center">&nbsp;S.No&nbsp;</th>
             <th scope="col">Employee&nbsp;Name</th>
             <th scope="col">From&nbsp;Date</th>
             <th scope="col">To&nbsp;Date</th>
             <th scope="col">Leave&nbsp;Type</th>
             <th scope="col" class="text-center">No.of&nbsp;Days</th>
             <th scope="col">Status</th>
           </tr>
         </thead>
         <tbody>
             <tr *ngFor="let item of x; let i = index" >
                <div  *ngIf="!item">
                    <div class="alert alert-primary" >
                        No Leave Request
                    </div>
                </div>
                 <ng-container *ngIf="i >= 0 ">
                     <td class="text-center">{{i+1}}</td>
                     <td>{{item.employee.employeeName}}</td>
                     <td>{{item.startDate | date}}</td>
                     <td>{{item.endDate | date}}</td>
                     <td>{{item.leaveType.leaveName}}</td>
                     <td class="text-center">{{item.noOfLeaveDays}}</td>
                     <td [style.color]="test(item)">{{item.leaveStatusName}}</td>
                 </ng-container>
             </tr>
         </tbody>
       </table>
</mat-dialog-content>
<!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Ok</button>
</mat-dialog-actions> -->
<style>
    .title{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 38px;
        display: flex;
        align-items: center;
        letter-spacing: 0.005em;
        color: #4A4A4A;
    }
    table thead tr{
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
    }
    table thead tr th{
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 13px;
        line-height: 21px;
        align-items: center;
        letter-spacing: 0.005em;
    }
    td{
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        align-items: center;
        letter-spacing: 0.005em;
        color: #4A4A4A;
    }
    table tbody tr{
        border: 1px solid #EEEEEE;
        background: #FFFFFF;
    }
    .table th{
      border-top: 0!important;
    }
    .table thead th{
        border-bottom: 0!important;
    }
</style>
