<span>
  <h4 class="dynamic-title">{{label | titlecase}}</h4>
</span>
<div class="col-sm-9">
  <mat-card>
    <!-- <mat-card class="header">
      <p> <span class="material-icons">
          perm_identity
        </span>{{label | titlecase}}</p>
    </mat-card> -->
    <div class="container">
      <!-- loading -->
      <ng-container *ngIf="isLoading; else elseTemplate">
        <div class="loading">
          <h4 class="text-muted">loading...</h4>
          <br>
          <div class="spinner-grow text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-danger" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-warning" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="spinner-grow text-dark" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-container>
      <ng-template #elseTemplate>

        <!-- ++++++++++++++++++++++++++++++++++++form+++++++++++++++++++++++++++++++++++ -->
        <form action="" [formGroup]="myform" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-md-12">
              <h3 class="title">*{{label | titlecase}}*</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- ..Employee Id.. -->
              <div class="form-group">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label class="form-label">Employee Number </mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>account_circle</mat-icon>
                  <input type="text" matInput formControlName="employeeNumber" (keypress)="noNumber($event)"
                    [errorStateMatcher]="matcher">
                  <mat-error *ngIf=" f.employeeNumber.hasError('required')">
                    Please enter <strong>Employee Number</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label class="form-label">Employee Name</mat-label>
                  <mat-icon matPrefix class="form-icon" style="color: #6d6b6b;">person</mat-icon>
                  <input matInput type="text" formControlName="employeeName" placeholder="Enter the Name!!"
                    [errorStateMatcher]="matcher" (keypress)="omit_special_char($event) && keyPress($event)">
                  <mat-error *ngIf="f.employeeName.hasError('required')">
                    Please enter <strong>Name</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- ..Gender.. -->
              <div class="form-group">
                <label class="form-label genderhead">Gender: &nbsp;&nbsp;</label>
                <mat-radio-group aria-label="Select an option" formControlName="gender">
                  <mat-radio-button *ngFor="let u of  genderValue" [value]="u.listTypeValueId"
                    class="gendervalue">
                    {{u.listTypeValueName}} &nbsp;&nbsp;
                  </mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf=" submitted && f.gender.hasError('required')">
                  Please select <strong>Gender</strong>
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <!-- ..user photo.. -->
              <div class="form-group">
                <label class="form-label genderhead">User Photo :&nbsp;&nbsp;</label>
                <sup style="color: rgb(80, 77, 77);"></sup>
                <input type="file" (change)="fileChange($event)" name="image" accept='image/jpeg,image/png,image/x-eps'
                  placeholder="Upload file" accept=".png,.jpg, .jpeg">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- ..Dob.. -->
              <div class="form-group">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label class="form-label">DOB</mat-label>
                  <input matInput (click)="dateOfBirth.open()" formControlName="dateOfBirth"
                    [errorStateMatcher]="matcher" [matDatepicker]="dateOfBirth" placeholder="Choose a dateOfBirth">
                  <mat-datepicker-toggle matPrefix [for]="dateOfBirth">
                    <mat-icon class="form-icon" style="color: #6d6b6b;" matDatepickerToggleIcon>redeem</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #dateOfBirth></mat-datepicker>
                  <mat-error *ngIf=" f.dateOfBirth.hasError('required')">
                    Please select <strong>DOB</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <!-- .. Blood Group... -->
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">Select Blood Group</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>invert_colors</mat-icon>
                  <mat-select style="color: red" id="bloodGroup" formControlName="bloodGroup"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let u of  bloodGroupValue" [value]="u.listTypeValueId">
                      {{u.listTypeValueName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf=" f.bloodGroup.hasError('required')">
                    Please select <strong>Blood Group</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- ..Organization Mail-Id.. -->
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">Organization Mail Id</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>email</mat-icon>
                  <input matInput type="text" formControlName="organizationEmail" placeholder="Organization Mail Id"
                    [errorStateMatcher]="matcher" />
                  <mat-error
                    *ngIf="f.organizationEmail.hasError('pattern') && !f.organizationEmail.hasError('required')">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="f.organizationEmail.hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <!-- ..password.. -->
              <ng-container *ngIf="hide == true; else elseTemplate">
                <div class="form-group">
                  <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label class="form-label">Password</mat-label>
                    <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>lock</mat-icon>
                    <input matInput type="password" formControlName="password" placeholder=" enter password!!"
                      [errorStateMatcher]="matcher" />
                    <mat-error *ngIf=" !f.password.hasError('required')">
                      Password length atleast <strong>6</strong>
                    </mat-error>
                    <mat-error *ngIf="f.password.hasError('required')">
                      Password is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-template #elseTemplate></ng-template>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- ..contact.. -->
              <div class="from-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">Contact</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>phone</mat-icon>
                  <input matInput type="text" placeholder="Contact" maxlength="10" (keypress)="noNumber($event)"
                    formControlName="mobileNumber" [errorStateMatcher]="matcher" />
                  <mat-error *ngIf="f.mobileNumber.hasError('required')">Please enter <strong>Contact</strong>
                  </mat-error>
                  <mat-error *ngIf="f.mobileNumber.hasError('minlength')"> Enter <strong> Valid
                      Number</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <!-- ..Emergency Contact Number.. -->
              <div class="from-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">Emergency Contact</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>phone</mat-icon>
                  <input matInput type="text" placeholder="Emergency Number" maxlength="10"
                    formControlName="emergencyContactNumber" (keypress)="noNumber($event)"
                    [errorStateMatcher]="matcher" />
                  <mat-error *ngIf=" f.emergencyContactNumber.hasError('required') ">
                    Please enter <strong>Number</strong>
                  </mat-error>
                  <mat-error *ngIf="f.emergencyContactNumber.hasError('minlength')">
                    Enter <strong> Valid Number</strong>
                  </mat-error>
                  <mat-error *ngIf="f.emergencyContactNumber.hasError('NotMustMatch')">
                    Contact & EmergencyContactNumber should not be <strong>Same</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <!-- ..address.. -->
              <div class="form-group">
                <mat-form-field appearance="outline" class="example-full-width form-textarea">
                  <mat-label class="form-label">Address</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b;transform: translateY(15px)"
                    matPrefix>home</mat-icon>
                  <textarea matInput name="address" id="address" formControlName="address" placeholder="Address"
                    [errorStateMatcher]="matcher"></textarea>
                  <mat-error *ngIf="f.address.hasError('required')">
                    Please enter <strong>Address</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- ..designation.. -->
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">Select Designation</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>business_center</mat-icon>
                  <mat-select style="color: red" id="designation" formControlName="designation"
                    [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let u of  designationValue" [value]="u.listTypeValueId">
                      {{u.listTypeValueName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.designation.hasError('required')">
                    Please enter <strong>Designation</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <!-- //usertype -->
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">Select User Type</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>account_box</mat-icon>
                  <mat-select formControlName="userType" [errorStateMatcher]="matcher" [(ngModel)]="myuserType">
                    <mat-option *ngFor="let u of userTypeList" [value]="u.userTypeId" (click)="form(u.userType)">
                      {{u.userType}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.userType.hasError('required')">
                    Please select <strong>User Type</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <!-- ..Reporting To Manager.. -->
              <div class="form-group">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label class="form-label"> Select First Approver </mat-label>
                  <mat-icon style="color: #6d6b6b;transform: translateY(0px);" matPrefix>account_circle</mat-icon>
                  <mat-select formControlName="reportingTo" [errorStateMatcher]="matcher">
                    <mat-option *ngFor="let p of employeeList" [value]="p.employeeId">
                      {{p.employeeName}} <em style="color: brown;">*</em> ({{p.userType.userType}})
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.reportingTo.hasError('required')">
                    Please select <strong>First Approver</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <!-- fyi -->
              <ng-container *ngIf="sApprover;else sApprover1">
                <div class="form-group">
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label class="form-label"> Select Second Approver </mat-label>
                    <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>account_circle</mat-icon>
                    <mat-select formControlName="reportingToSecond" [errorStateMatcher]="matcher">
                      <mat-option *ngFor="let p of employeeList" [value]="p.employeeId">
                        {{p.employeeName}} <em style="color: brown;">*</em> ({{p.userType.userType}})
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </ng-container>
              <ng-template #sApprover1>
                <div class="form-group">
                  <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label class="form-label"> Select Second Approver </mat-label>
                    <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>account_circle</mat-icon>
                    <mat-select formControlName="reportingToSecond1" [errorStateMatcher]="matcher">
                      <mat-option *ngFor="let p of employeeList" [value]="p.employeeId">
                        {{p.employeeName}} <em style="color: brown;">*</em> ({{p.userType.userType}})
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="f.reportingToSecond1.hasError('required')">
                      Please select <strong>Second Approver</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-template>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label class="form-label">Organization Name</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>corporate_fare</mat-icon>
                  <mat-select formControlName="organization">
                    <mat-option *ngFor="let t of organization" [value]="t.organizationId"
                      (click)="optionClick(t.organizationId,$event)">
                      {{t.organizationName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.organization.hasError('required')">
                    Please select <strong>Organization Name</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <!-- ..Branch.. -->
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">Branch</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>email</mat-icon>
                  <mat-select formControlName="branch">
                    <mat-option *ngFor="let branch of branchList"
                      [value]="branch.branchId">{{branch.branchname}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.branch.hasError('required')">
                    Branch is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- <div class="form-group">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Organization Name</mat-label>
              <mat-icon style=" color: #6d6b6b;
              transform: translateY(0px);" matPrefix>corporate_fare</mat-icon>
              <input matInput type="text" formControlName="organization">
              <mat-error *ngIf="f.organization.hasError('required')">
                Please enter <strong>Organization Name</strong>
              </mat-error>
            </mat-form-field>
          </div> -->
          <div class="row">
            <div class="col-md-6">
              <!-- ..joining Date.. -->
              <div class="form-group">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label class="form-label">Joining Date</mat-label>
                  <input matInput (click)="joiningDate.open()" formControlName="employeeJoinedDate"
                    [errorStateMatcher]="matcher" [matDatepicker]="joiningDate" placeholder="Choose Joining Date">
                  <mat-datepicker-toggle matPrefix [for]="joiningDate" style="justify-content: initial;display: inline-flex;">
                    <mat-icon class="form-icon" style="color: #6d6b6b" matDatepickerToggleIcon>today</mat-icon>
                  </mat-datepicker-toggle>
                  <mat-datepicker #joiningDate></mat-datepicker>
                  <mat-error *ngIf=" f.employeeJoinedDate.hasError('required')">
                    Please select <strong>Joining Date</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-md-6">
              <!-- working days -->
              <div class="form-group">
                <mat-form-field appearance="outline" class="example-full-width">
                  <mat-label class="form-label">No.of Working Days</mat-label>
                  <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix></mat-icon>
                  <mat-select formControlName="noOfWorkingDays" [errorStateMatcher]="matcher">
                    <mat-option value=5>5</mat-option>
                    <mat-option value=6>6</mat-option>
                  </mat-select>
                  <mat-error *ngIf=" f.noOfWorkingDays.hasError('required')">
                    Please select <strong>Working Days</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <!-- Employee Type -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label class="form-label">Employee Type</mat-label>
                  <mat-icon matPrefix class="form-icon" style="color: #6d6b6b;">person</mat-icon>
                  <mat-select  formControlName="employeeType" >
                    <mat-option *ngFor="let emp of empTypeList"
                      [value]="emp.listTypeValueId">{{emp.listTypeValueName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="f.branch.hasError('required')">
                    Employee Type is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- Leave Type -->
          <!-- <div class="form-group">
            <div class="form-group" align="center">
              <button mat-stroked-button color="warn" style="width: 45%; margin: 0 0 0 8px;" type="button"
                [routerLink]="['../../employee']">Cancel</button>
              <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit">
                <span class="material-icons">
                  beenhere
                </span> {{button_label | titlecase}}
              </button>
            </div>
          </div> -->


          <!-- button -->
          <div class="form-group">
            <div class="d-flex">
              <div style="width: 45%;margin: 0 auto;">
                <button mat-stroked-button class="cancel-button" color="accent" type="button"
                  [routerLink]="['../../employee']">
                  <span class="material-icons-outlined cancel-button-icon">close</span>
                  Cancel
                </button>
              </div>
              <div style="width: 45%;margin: 0 auto;">
                <button mat-stroked-button class="apply-button" color="primary" type="submit">
                  <span class="material-icons-outlined apply-button-icon">done</span>
                  {{button_label | titlecase}}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ng-template>
    </div>
  </mat-card>
</div>