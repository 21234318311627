import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent  implements OnInit {

  isLoading:boolean;
  permissionForm:FormGroup;
  empId:any;
  firstApprover = new FormControl()
  secondApprover = new FormControl()
  employee_Data
  myFilter:any;
  secondError:boolean;
  arrayData:any =[]
  workingDay:any = []
  totalDay:any = []
  branchId:any
  workDay:any = []
  From_Date:any;
  End_Date:any;
  constructor(private fb:FormBuilder,
              private gs:GlobalserviceService,
              private router:Router,
              private datepipe:DatePipe,
              private location: Location) { }

  ngOnInit(): void {
    this.isLoading = false
    let localData = JSON.parse(localStorage.getItem("data"))
    this.empId = localData.employeeId;

    this.gs.getEmpListById(localData.employeeId).subscribe(
      (d:any)=>{
        this.branchId = d.branch.branchId
      }
    )

    this.permissionForm = this.fb.group({
      employeeName:[''],
      organizationEmail:[''],
      permissionDate:['',Validators.required],
      permissionHours:['',Validators.required],
      permissionReason:['',Validators.required]
    })

    this.gs.getEmpListById(this.empId).subscribe(
      (data:any)=>{
        this.employee_Data = data;
        this.permissionForm.patchValue({
          employeeName:data.employeeName,
          organizationEmail:data.organizationEmail
        })

        this.gs.getEmpListById(data.reportingTo).subscribe(
          (x:any)=>{
            this.firstApprover.setValue([x.organizationEmail])
          }
        )
        this.secondError = true
        if(data.userType.userType != 'Employee'){
          if(data.reportingToSecond){
            this.secondError = true
          }
          else{
            this.secondError = false
          }
        }
        this.gs.getEmpListById(data.reportingToSecond).subscribe(
          (x:any)=>{
            this.secondApprover.setValue([x.organizationEmail])
          }
        )

         // get date
         const sample_date_data: any[] = []
         this.gs.get_leave_date(this.empId).subscribe((a: any) => {
           a.forEach(element => {
             let x = element.split("-");
             sample_date_data.push(new Date(`'${x[0]}/${x[1]}/${x[2]}'`).getTime())
           });
           let x = sample_date_data;
         })


         if(this.branchId){
          this.gs.getCalByBranch(this.branchId).subscribe(
            (dato: any) => {
              dato.map(
                log => {
                  this.gs.getCalendarById(log.calendarId).subscribe(
                    (i: any) => {
                      var exception
                      let data
                      i.exceptions.map(
                        b => {
                          if(b.exceptionStatus == 1){
                            if(b.exceptionType != '31'){
                              exception = b.exceptionDate
                              data = new Date(b.exceptionDate)
                              data.setDate(data.getDate());
                              this.arrayData.push(data)
                            }
                            if(b.exceptionType == '31'){
                              exception = b.exceptionDate
                              data = new Date(b.exceptionDate)
                              data.setDate(data.getDate());
                              this.workingDay.push(data)
                            }
                          }

                        }
                      )

                      i.holiday.map(a => {
                        if(a.holidayStatus == 1){
                          data = new Date(a.holidayDate)
                          data.setDate(data.getDate());
                          this.arrayData.push(data)
                        }
                      })

                      let b = ["1","2","3","4","5","6","0"]
                      i.calendarWorkingDays.map(dot => {
                        var difference:any = b.filter(x => dot.workingdays.indexOf(x) === -1);
                        this.workDay = difference
                        this.myFilter = (d: Date): boolean => {
                          const day = new Date(d).getDay();
                          const time = new Date(d).getTime()
                          if(day !== Number(difference[0]) && day !== Number(difference[1]) &&
                          day !== Number(difference[2]) && day !== Number(difference[3]) &&
                          day !== Number(difference[4]) && day !== Number(difference[5]) &&
                          day !== Number(difference[6])){

                           this.totalDay.push(new Date(d))
                           this.workingDay.map(
                             e=>{
                               this.totalDay.push(new Date(e))
                             }
                           )
                       }

                       return this.totalDay.find(x=> new Date(x).getTime() == time) &&
                              !this.arrayData.find(x=> new Date(x).getTime() == time)
                          // return day !== Number(difference[0]) && day !== Number(difference[1])
                          // && day !== Number(difference[2]) && day !== Number(difference[3])
                          // && day !== Number(difference[4]) && day !== Number(difference[5])
                          // && day !== Number(difference[6])&& !this.arrayData.find(x => new Date(x).getTime() == time)
                        }
                      })
                    }
                  )
                }
              )

            }
          )
        }
      }
    )

  }

  Cancel(){
    this.location.back();
  }

  get f(){
    return this.permissionForm.controls;
  }

  employeeName() {
    return this.f.employeeName.hasError('required') ? 'Please Enter EmployeeName*' : ''

  }

  organizationEmail() {
    return this.f.organizationEmail.hasError('required') ? 'Please Enter Organization Email*' : ''
  }

  date() {
    return this.f.permissionDate.hasError('required') ? 'Please Select Date*' : ''
  }

  hour() {
    return this.f.permissionHours.hasError('required') ? 'Please Select Hour*' : ''
  }

  reason() {
    return this.f.permissionReason.hasError('required') ? 'Please Enter Reason*' : ''
  }

  cancel(){
    this.router.navigate(['./permission-list'])
  }

  onSubmit(){
    if(this.permissionForm.invalid){
      return
    }
    let x = this.permissionForm.value;
    x['employeeName'] = x.employeeName;
    x['organizationEmail'] = x.organizationEmail;
    x['applyTo'] = [x.organizationEmail];
    x['cc'] = [];
    x['permissionreportingTo'] = this.firstApprover.value[0];
    x['permissionreportingToSecond'] = this.secondApprover.value[0];
    x['permissionStatus'] = 3;
    x['status'] = 1;
    x['employee'] = {
      employeeId:this.empId
    }
    if(x.permissionHours == "15"){
      x['permissionHours'] = `${'00:15:00'}`
    }    
    if(x.permissionHours == "30"){
      x['permissionHours'] = `${'00:30:00'}`
    }
    if(x.permissionHours == "1"){
      x['permissionHours'] = `${'01:00:00'}`
    }
    if(x.permissionHours == "2"){
      x['permissionHours'] = `${'02:00:00'}`
    }
    if(x.permissionHours == "3"){
      x['permissionHours'] = `${'03:00:00'}`
    }
    console.log(x.permissionHours);
    x['submittedOn'] = new Date();
    let c= new Date(this.f.permissionDate.value)
    let d = new Date(c);
    d.setMinutes(d.getMinutes() + 480);
    let e = d.toISOString();
    x['permissionDate'] = e;
    // console.log(JSON.stringify(x))
    // return
    // console.log(this.permissionForm.permissionHours);
    Swal.fire({
      title: 'Are you sure? Do you want to Apply Permission!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Apply'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.gs.applyPermission(x).subscribe(
          d => {
            Swal.fire({
              text: 'Applied',
              icon: 'success'
            }
            );
            this.router.navigate(['./permission-list'])
            this.isLoading = false;
          },
          err => {
            Swal.fire(
              {
                text: err.error.message,
                icon: 'error'
              }
            )
            this.isLoading = false
          },
          () => {
            this.isLoading = false
          }
        )
      }
    })
  }

}
