
<div class="col-md-12 full-content">
  <mat-card class="mat-card">
    <mat-card class="header">
      <span style="display: flex;">
        <mat-icon class="material-icons-outlined" id="logo">how_to_reg</mat-icon>
        <span class="attendance">Attendance</span>
      </span>
    </mat-card>
    <form [formGroup]="employeeAttendence">
      <div class="row mb-2">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3" id="r1">
              <mat-form-field appearance="outline" style="display: inline;">
                <mat-label>Choose a Month</mat-label> 
                <mat-select (selectionChange)="change($event)" 
                  placeholder="Select Month" id="selvalue" formControlName="month" 
                  (selectionChange) = "isselected($event)">
                  <mat-option value="0">--  Select Month -- </mat-option>
                  <mat-option value="01">January</mat-option>
                  <mat-option value="02">February</mat-option>
                  <mat-option value="03">March</mat-option>
                  <mat-option value="04">April</mat-option>
                  <mat-option value="05">May</mat-option>
                  <mat-option value="06">June</mat-option>
                  <mat-option value="07">July</mat-option>
                  <mat-option value="08">August</mat-option>
                  <mat-option value="09">September</mat-option>
                  <mat-option value="10">October</mat-option>
                  <mat-option value="11">November</mat-option>
                  <mat-option value="12">December</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <div *ngIf="(isSubmitted && employeeAttendence.controls['attendence'].invalid) || employeeAttendence.controls['attendence'].dirty || employeeAttendence.controls['attendence'].touched ">
                <small *ngIf="employeeAttendence.controls['attendence'].errors?.required"  id="req" style="color:red;font-size:12px;">*Month Required.</small>
              </div> -->
            </div>
            <div class="col-md-3">
              <mat-form-field class="example-full-width" appearance="outline"  style="display: inline;">
                <mat-label>Start Date</mat-label> 
                <input matInput formControlName="startdate" (dateChange)="startDate($event)"
                (dateChange)="date($event)"
                  placeholder="Choose a Date"  [max]="todayDate" [min]="startdate"
                    [matDatepicker]="picker1">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
              <!-- <div *ngIf="(isStartDate && employeeAttendence.controls['startdate'].invalid)">
                <small *ngIf="employeeAttendence.controls['startdate'].errors?.required"  id="req" style="color:red;font-size:14px;">Start date required</small>
              </div> -->
              <span class="error_msg" *ngIf="startdate_required">Please select start date</span>  
            </div>
            <div class="col-md-3 end">
              <mat-form-field class="example-full-width" appearance="outline"  style="display: inline;">
                <mat-label>End Date</mat-label> 
                <input matInput formControlName="enddate" (dateChange)="endDate($event)"
                  placeholder ="Choose a Date"
                  [matDatepicker]="picker2"
                  (dateChange)="datechange($event)"  [min]="startmin" [max]="endate" >
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
              </mat-form-field>
              <!-- <div *ngIf="(isEndDate && employeeAttendence.controls['enddate'].invalid)">
                <small *ngIf="employeeAttendence.controls['enddate'].errors?.required"  id="req" style="color:red;font-size:14px;">End date required</small>
              </div>  -->
              <span class="error_msg" *ngIf="enddate_required">Please select end date</span>     
              <span class="error_msg" *ngIf="startdate_validation">End date should be greater than start date</span>  
            </div>
            <div class="col-md-3 but">
              <button type="button" class="btn btn-primary" id="b1" (click)="search()">Search</button>&nbsp;&nbsp;
              <button *ngIf="isDisabled==false" type="button" class="btn btn-primary" id="b1" (click)="export()">Export</button>
            </div>
          </div>
          <div *ngIf="submitted" style="color:red;font-size:13px;position: relative;bottom:20px">Please select month or start date and end date.</div>
        </div>
      </div>
    </form>
         <!-- <table mat-table [dataSource]="dataSource" matSort>

        
            <ng-container matColumnDef="{{header}}" *ngFor="let header of objkey">
              <th  mat-header-cell *matHeaderCellDef mat-sort-header>{{header}}</th>
              <td mat-cell *matCellDef="let row"> {{row.header}} </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="objkey"></tr>
            <tr mat-row *matRowDef="let row; columns: objkey;"></tr>
        
        </table>
        <mat-paginator  style="bottom: 0; position: sticky;" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>  -->

                   <!-- <div class="table-responsive" *ngIf="Data.length>0"> -->
           <!-- <h4 class="card-title text-right" id="Title1" *ngIf="attLength>0" (change)="showChanges()">Show
                        <select  [(ngModel)]="itempages">
                           <option value="5" selected>5</option>
                           <option value="10">10</option>
                           <option value="20">20</option>
                           <option value="30">30</option>
                         </select>
                      </h4> -->
    <div class="row">
      <div class="col-md-2 mb-2" *ngIf="Data.length>5">
        <div class="select-pagination">
            <span class="show-text">Show : </span>
            <select class="select_list new-select_list" [(ngModel)]="itempages" [ngModelOptions]="{standalone: true}" (change)="showChanges()">
              <option class="form-control" value="5" selected>5</option>
              <option class="form-control" value="10">10</option>
              <option class="form-control" value="15">15</option>
              <option class="form-control" value="20">20</option>
              <option class="form-control" value="25">25</option>
            </select>
        </div>
      </div>
      <div class="col-md-10 mb-2 float-right" *ngIf="Data.length>5">
        <div class="dataTables_filter">
          <label class="show-text mb-0">Search :
            <input type="search" class="form-control input" [(ngModel)]="dataSearch" (keyup) = "changepage()"  
            [ngModelOptions]="{standalone: true}"> 
          </label>
        </div>
      </div>
    </div>
    <div class="row table-content">
        <table class="table table-bordered table-responsive text-nowrap" matSort (matSortChange)="sortData($event)" *ngIf="Data.length>0">
            <thead>
                <th mat-sort-header="employee" class="sticky-col first-col text-left">Employee</th> 
                <!-- <th *ngFor="let header of Data">{{header.date}}</th>  -->
                <!-- <li *ngFor='let key of demo | keys'>
                    Key: {{key.key}}, value: {{key.value}}
                </li>  -->

              <th  *ngFor="let header of objkey">{{header}}</th> 
                
            </thead> 
            <tbody>
                <tr class="empRow" *ngFor="let emp of attendence | empattendence:dataSearch | paginate: { itemsPerPage: itempages, currentPage: p }"> 
                    <td  class="text-capitalize sticky-col first-col text-left">{{emp.employee}}</td> 
                      <td  class="text-center" *ngFor="let line of emp.date">
                        <!-- <span *ngIf="line" class="material-icons icon-green">
                            check_circle_outline
                          </span> -->
                          <span *ngIf="line == 0" style="color:red;font-weight : 'bold'">{{line}}</span>
                          <span *ngIf="line == 0.5" class="text-warning" style="font-weight : 'bold'" >{{line}}</span>
                          <span *ngIf="line == 1"  class="text-success" style="font-weight : 'bold'" >{{line}}</span>
                          <span *ngIf="line == 2"   style="font-weight : 'bold'">-</span>
                          <!-- <mat-icon *ngIf="line" [ngStyle]="{'color':'green'}">done</mat-icon> -->
                          <!-- <span *ngIf="!line" class="material-icons icon-red">
                            cancel
                            </span> -->
                            <!-- <span *ngIf="!line"  [ngStyle]="{'color':'green'}">P</span> -->
                            <!-- <mat-icon *ngIf="!line" [ngStyle]="{'color':'red'}">clear</mat-icon> -->
                      </td>                              
                    <!-- <td>{{line.outTime}}</td>
                    <td>{{line.present}}</td> -->
                  </tr>   
              </tbody>  
        </table>
        <p class="text-muted mt-3" *ngIf="Data.length>0">Note :<br>
          <span class="text-success" style="margin-right:21px;margin-left: 10px;">1</span><span class="text-success">-</span><span class="text-success" style="font-weight : '500';margin-left: 10px;" >Present</span><br>
          <span class="text-danger" style="margin-right:21px;margin-left: 10px;">0</span><span class="text-danger" >-</span><span class="text-danger" style=" font-weight : '500';margin-left: 10px;" >Absent</span><br>
          <span class="text-warning" style="margin-right:10px;margin-left: 10px;">0.5</span><span class="text-warning">-</span><span class="text-warning" style=" font-weight : '500';margin-left: 10px;" >Half day</span>
        </p>
        
    </div> 
    <div *ngIf="noData">
        <div class="alert alert-primary text-center" style="width: 100%">
          No Record Attendence List
        </div>
    </div>
    <div class="col-md-12 text-right" id="page" *ngIf="Data.length>5">
      <pagination-controls (pageChange)="p = $event"></pagination-controls>
    </div>
      <!-- <mat-paginator   [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
      <!-- <div class="row topnav-right pagination-content">        
          <div class="col-md-12">
              <pagination-controls class="float-right pagination" style="float: right;"        
                (pageChange)="p = $event; beforepage = $event"></pagination-controls>          
          </div>        
      </div> -->
      <!-- </mat-card-actions>  -->
  </mat-card> 
</div>


 