//Test
      export const url=`http://38.242.135.217:6001/leavemanagement/`;
      // export const url=`http://192.168.18.11:6001/leavemanagement/`;
      // export const url=`http://192.168.18.11:6001/leavemanagement/`; 
      // export const url=`http://192.168.18.38:6010/leavemanagement/`; 
      //  export const url=`http://38.242.135.217:6001/leavemanagement/`;
      // export const url=`http://192.168.18.38:6010/leavemanagement/`;

      // export const url=`http://192.168.1.5:6001/leavemanagement/`; 
    
//Local
  // export const url=`http://192.168.18.21:6001/leavemanagement/`

//production

 // export const url=`http://164.52.205.112:6001/leavemanagement/`

 //production contabo
  // export const url=`https://leave.jesperapps.com:6001/leavemanagement/`;

  export const globalTimeOut = 20000;
// https://drive.google.com/file/d/1Npz6968Y4VYBSTHAw7P3Tn_TW36SJxbU/view?usp=sharing
