<span>
  <h4 class="dynamic-title">Leave Adjustment</h4>
</span>

<div class="col-sm-9">
  <mat-card class="mat-card">
    <!-- <mat-card class="header">
      <p> <span class="material-icons">
        equalizer
        </span>Leave Adjustment</p>
    </mat-card> -->
    <ng-container *ngIf="isLoading; else elseTemplate">
      <div class="loading">
        <h4 class="text-muted">loading....</h4>
        <br>
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <form [formGroup]="leaveForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">*Create Leave Adjustment*</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- EmployeeId -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">Employee Number</mat-label>
                <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>badge</mat-icon>
                <mat-select formControlName="employeeId">
                  <mat-option *ngFor="let id of employeeIdList" (click)="empValue(id.employeeName,id.employeeId)"
                    [value]="id.employeeId">
                    {{id.employeeNumber}}
                  </mat-option> 
                </mat-select>
                <mat-error *ngIf="f.employeeId.hasError('required')">
                  Please select <strong>Employee Number</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <!-- EmployeeName -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">EmployeeName</mat-label>
                <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>account_box</mat-icon>
                <input formControlName="employeeName" matInput [(ngModel)]="employeeValue" readonly />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- leaveType -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">Leave Type</mat-label>
                <mat-icon sclass="form-icon" style="color: #6d6b6b" matPrefix>filter_alt</mat-icon>
                <mat-select placeholder="Select Leave Type" formControlName="leaveBalance">
                  <mat-option *ngFor="let t of leaveTypeList; let i = index" value="{{ t.leaveBalanceId }}"
                  (click)="leave(t.noOfDays)">
                    {{ t.leaveName }} <em> * </em>bal: {{ t.noOfDays }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="f.leaveBalance.hasError('required')">
                  Please select <strong>Leave Type</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Leave Adjustment -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">Leave Adjustment </mat-label>
                <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>equalizer</mat-icon>
                <mat-select formControlName="leaveadjustmenttype">
                  <mat-option value="add" >Add</mat-option>
                  <mat-option value="detect">Detect</mat-option>
                </mat-select>
                <mat-error *ngIf="f.leaveadjustmenttype.hasError('required')">
                  Please Select <strong>Leave Adjustment </strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <!-- noOfDays -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">Adjustment Days </mat-label>
                <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>pin</mat-icon>
                <input matInput [maxlength]="length" (keypress)="noNumber($event)" formControlName="leaveadjustmentdays"
                (keyup)="leaveData($event)"/>
                <mat-error *ngIf="f.leaveadjustmentdays.hasError('required')">
                  Please Enter <strong>Adjustment Days </strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        <div class="col-md-6">
          <!-- Reason -->
          <div class="form-group">
            <mat-form-field class="example-full-width form-textarea" appearance="outline">
              <mat-label class="form-label">Adjustment Reason </mat-label>
              <mat-icon class="form-icon" style="color: #6d6b6b;transform: translateY(15px)" matPrefix>article</mat-icon>
              <textarea matInput formControlName="leaveadjustmentreason"></textarea>
              <mat-error *ngIf="f.leaveadjustmentreason.hasError('required')">
                Please Enter <strong>Adjustment Reason</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        </div>

        <!-- button -->
        <!-- <div class="form-group" align="center">
          <button mat-stroked-button color="accent" style="width: 45%; margin: 0 0 0 8px;" type="button"
            [routerLink]="['']">Cancel
          </button>
          <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit">
            <span class="material-icons">beenhere</span> Create
          </button>
        </div> -->
        <!-- button -->
        <div class="form-group">
          <div class="d-flex">
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="cancel-button" color="accent" type="button"
                [routerLink]="['']">
                <span class="material-icons-outlined cancel-button-icon">close</span>
                Cancel
              </button>
            </div>
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="apply-button" color="primary" type="submit">
                <span class="material-icons-outlined apply-button-icon">done</span>
                Create
              </button>
            </div>
          </div>
        </div>
        <!-- <br> -->
      </form>
    </ng-template>
  </mat-card>
  <br>

</div>
