<h1 mat-dialog-title class="title">Deny Reason</h1>
<div mat-dialog-content>
  <form (ngSubmit)="onSubmit(denyreasonform.value)" [formGroup]="denyreasonform">
    <div class="form-group">
      <mat-form-field class="example-full-width form-textarea" appearance="outline">
        <mat-label class="form-label">Reason for Denial</mat-label>
        <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(15px)"
          matPrefix>insert_comment</mat-icon>
        <textarea matInput type="text" formControlName="denyReason" placeholder="Enter deny reason"></textarea>
        <mat-error *ngIf="f.denyReason.hasError('required')">
          Please enter <strong>deny reason</strong>
        </mat-error>
      </mat-form-field>
    </div>
<!-- 
    <div class="form-group" align="center">
      <button mat-stroked-button color="accent" style="width: 45%; margin: 0 0 0 8px" type="button" (click)="cancel()">
        Cancel
      </button>
      <button mat-stroked-button color="primary" style="width: 45%; margin: 0 0 0 8px" type="submit">
        <span class="material-icons"> beenhere </span> OK
      </button>
    </div> -->

    <div class="form-group" align="end">
      <div class="d-flex">
        <div style="width: 45%;margin: 0 auto;">
          <button mat-stroked-button mat-dialog-close class="cancel-button" color="accent" type="button"  (click)="cancel()">
            <span class="material-icons-outlined cancel-button-icon" >close</span>
            Cancel
          </button>
        </div>
        <div style="width: 45%;margin: 0 auto;">
          <button mat-stroked-button class="apply-button" color="primary" type="submit" cdkFocusInitial>
          <span class="material-icons-outlined apply-button-icon">done</span>&nbsp;Deny&nbsp;</button>
        </div>
      </div>
    </div>
  </form>

</div>
<style>
  .example-full-width {
    width: 100%;
  }
  .title{
    color:#4A4A4A;
    font-size: 18px;
    font-family: 'Poppins', sans-serif !important;
  }
  :host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 10px 0 12px 0;
    font-family: 'Poppins', sans-serif !important;
  }
   :host ::ng-deep .mat-form-field-outline-start{
    width: 30.15px !important;
  }

  .form-icon {
    font-size: 18px;
    transform: translateY(0px);
  }

  :host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix input.mat-input-element {
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
  }

  .form-label {
    font-size: 12px;
  }

  :host ::ng-deep .form-textarea .mat-form-field-wrapper .mat-form-field-flex {
    align-items: start;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .apply-button {
    background: #1A83FF;
    border: 1px solid #1A83FF;
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(26, 131, 255, 0.20) !important;
    color: white;
    float: left;
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 550;
    letter-spacing: 0.020em;
  }

  .cancel-button {
    background: #FFFFFF;
    border: 1px solid #F98080;
    box-shadow: 0px 4px 15px rgba(255, 116, 116, 0.1);
    border-radius: 5px;
    float: right;
    font-size: 13px;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 550;
    color: #FF3434;
  }

  .cancel-button-icon {
    font-size: 15px;
    position: relative;
    bottom: 1px;
  }

  .apply-button-icon {
    font-size: 15px;
    position: relative;
    bottom: 1px;
  }

  button:focus {
    outline: 0px !important;
  }

  :host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #5fa8ff7d;
  }
</style>