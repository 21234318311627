
<span>
  <h4 class="dynamic-title">Apply Permission</h4>
</span>
<marquee bahaviour="scroll" direvtion="left">
  <h4>Note : You can take only 2hours Permission for a month...</h4>
</marquee>
<div class="col-sm-9">
  <mat-card>
    <!-- <mat-card class="header">
      <p><span class="material-icons"> timelapse </span>Apply Permission</p>
    </mat-card> -->
    <ng-container *ngIf="isLoading; else elseTemplate">
      <div class="loading">
        <h4 class="text-muted">loading....</h4>
        <br />
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <form [formGroup]="permissionForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">*Enter details to apply Permission*</h3>
          </div>
        </div>
        <!-- employeeName -->
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Employee Name</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix>account_box</mat-icon>
            <input matInput type="text" formControlName="employeeName" readonly />
          </mat-form-field>
        </div>

        <!-- employeeEmail -->
        <div class="form-group">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Email-id</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px) ;" matPrefix>email</mat-icon>
            <input matInput type="text" formControlName="organizationEmail"  readonly />
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col-md-6">
            <!-- Date -->
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">Date</mat-label>
                <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon>
                <input matInput (click)="picker.open()" [matDatepicker]="picker" [matDatepickerFilter]="myFilter"
                  placeholder="select date" formControlName="permissionDate" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker touchUi></mat-datepicker>
                <mat-error *ngIf="f.permissionDate">{{date()}}</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <mat-form-field class="example-full-width" appearance="outline">
                <mat-label class="form-label">Hours</mat-label>
                <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix>history</mat-icon>
                <mat-select placeholder="Select Hour" formControlName="permissionHours">
                  <mat-option value="15">15 Minutes</mat-option>
                  <mat-option value="30">30 Minutes</mat-option>
                  <mat-option value="1">1 Hour</mat-option>
                  <mat-option value="2">2 Hour</mat-option>
                  <!-- <mat-option value="3">3 Hour</mat-option> -->
                </mat-select>
                <mat-error *ngIf="f.permissionHours">{{hour()}}</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- First Approver -->
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label class="form-label">First Approver</mat-label>
          <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix>mail</mat-icon>
          <input type="text" [formControl]="firstApprover" matInput readonly />
        </mat-form-field>

        <!-- Second Approver -->
        <div class="form-group" *ngIf="secondError">
          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label class="form-label">Second Approver</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix>account_box</mat-icon>
            <input matInput type="text" [formControl]="secondApprover" readonly />
          </mat-form-field>
        </div>
        
        <div class="form-group">
          <mat-form-field class="example-full-width form-textarea" appearance="outline">
            <mat-label class="form-label">Reason for Permission</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(15px)" matPrefix>insert_comment</mat-icon>
            <textarea matInput type="text" formControlName="permissionReason"
              placeholder="Enter permission reason!!!!"  rows="2"></textarea>
            <mat-error *ngIf="f.permissionReason">{{reason()}}</mat-error>
          </mat-form-field>
        </div>

        <!-- button -->
        <!-- <div class="form-group" align="center">
          <button mat-stroked-button color="accent" style="width: 45%; margin: 0 0 0 8px" type="button" (click)="cancel()">
            Cancel
          </button>
          <button mat-stroked-button color="primary" style="width: 45%; margin: 0 0 0 8px" type="submit">
            <span class="material-icons"> beenhere </span> Apply
          </button>
        </div> -->
        <div class="form-group">
          <div class="d-flex">
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="cancel-button" color="accent" type="button" (click)="Cancel()">
                <span class="material-icons-outlined cancel-button-icon">close</span>
                Cancel
              </button>
            </div>
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="apply-button" color="primary" type="submit">
                <span class="material-icons-outlined apply-button-icon">done</span>
                &nbsp;Apply&nbsp;
              </button>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </mat-card>
