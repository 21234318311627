
<h1 class="title" mat-dialog-title style="color:#4A4A4A;font-size: 20px;">Leave Withdraw</h1>

<mat-dialog-content>
  <form action="" [formGroup]="withDrawForm" (ngSubmit)="withdraw()">
    <div class="row">
      <!-- from date -->
      <div class="col-md-6">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label class="form-label">Choose FromDate</mat-label>
          <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon>
          <input matInput [matDatepicker]="picker"
          [matDatepickerFilter]="myFilter"
          (click)="picker.open()" formControlName="startDate"
          (dateChange)="someMethodName($event)"
          [min] = "From_Date"
          [max]="End_Date"
           cdkFocusInitial [errorStateMatcher]="matcher">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <!-- err -->
          <mat-error *ngIf="f.startDate.hasError('required')">
            StartDate is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div> &nbsp;&nbsp;
      <!-- to date -->
      <div class="col-xsm">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label class="form-label">Choose ToDate</mat-label>
          <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon>
          <input matInput [matDatepicker]="a" (click)="a.open()"
          [matDatepickerFilter]="myFilter"
          formControlName="endDate"
          [errorStateMatcher]="matcher"
          [min] = "EndDate_min"
          [max]="End_Date" [disabled]="EndDate_disable" (dateInput)="nofoDays($event)">
          <mat-datepicker-toggle matSuffix [for]="a"></mat-datepicker-toggle>
          <mat-datepicker #a></mat-datepicker>
                 <!-- err -->
                 <mat-error *ngIf="f.endDate.hasError('required')">
                  StartDate is <strong>required</strong>
                </mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- Number of Days -->
    <!-- <mat-form-field style="width: 100%;" appearance="outline">
      <mat-label>Number Of days</mat-label>
      <input matInput type="number" [value]="noOFDays" >
    </mat-form-field> -->

      <!-- Half Day -->
      <ng-container  *ngIf="halfDay;else weekDay">
        <mat-form-field style="width:100%"  appearance="outline" class="example-full-width">
          <mat-label class="form-label">&nbsp; No.of Days</mat-label>
          <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix>today</mat-icon>
          <ng-container *ngIf="validate;else notValidate">
            <input formControlName="noOfDays" matInput readonly/>
          </ng-container>
          <ng-template #notValidate>
            <mat-select formControlName="noOfDays">
              <mat-option value="0.5">0.5</mat-option>
              <mat-option value="1">1</mat-option>
            </mat-select>
          </ng-template>

          <mat-error *ngIf="f.noOfDays.hasError('required')">
            <strong>Number Of Days</strong> is required
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-template #weekDay>

      <mat-form-field  style="width:100%" appearance="outline" class="example-full-width">
        <mat-label>&nbsp; No.of Days</mat-label>
        <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(0px)" matPrefix
          >today</mat-icon
        >
        <input matInput type="number" [value]="noOFDays"  readonly />
      </mat-form-field>
      </ng-template>
    <!-- reason -->
    <mat-form-field style="width: 100%;" class="example-full-width form-textarea" appearance="outline">
      <mat-label class="form-label">Reason</mat-label>
      <mat-icon class="form-icon" style="color: #6d6b6b; transform: translateY(15px)" matPrefix>insert_comment</mat-icon>
      <textarea matInput placeholder="Reason" [errorStateMatcher]="matcher" formControlName="withdrawReason"></textarea>
             <!-- err -->
             <mat-error *ngIf="f.withdrawReason.hasError('required')">
              Reason is <strong>required</strong>
            </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<!-- accion -->
<!-- <div class="form-group" align="end">
  <button mat-stroked-button color="warn" mat-dialog-close>Cancel</button>
  <button mat-stroked-button color="primary" [mat-dialog-close]="close_dialogue" cdkFocusInitial
    (click)="withdraw()">WithDraw</button>
<div> -->

  <div class="form-group" align="end">
    <div class="d-flex">
      <div style="width: 45%;margin: 0 auto;">
        <button mat-stroked-button mat-dialog-close class="cancel-button" color="accent" type="button">
          <span class="material-icons-outlined cancel-button-icon" >close</span>
          Cancel
        </button>
      </div>
      <div style="width: 45%;margin: 0 auto;">
        <button mat-stroked-button class="apply-button" color="primary" type="submit" [mat-dialog-close]="close_dialogue" cdkFocusInitial
        (click)="withdraw()">
        <span class="material-icons-outlined apply-button-icon">done</span>&nbsp;Withdraw&nbsp;</button>
      </div>
    </div>
  </div>

  <style>
    :host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 10px 0 12px 0;
  font-family: 'Poppins', sans-serif !important;
}
.form-icon{
  font-size: 18px;
  transform: translateY(0px);
}
:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix input.mat-input-element{
  font-size: 13px;
  font-family: 'Poppins', sans-serif !important;
}
.form-label{
  font-size: 13px;
}
:host ::ng-deep .form-textarea .mat-form-field-wrapper .mat-form-field-flex{
  align-items: start;
}
.form-group{
  margin-bottom: 0px;
}
.apply-button{
  background: #1A83FF;
  border: 1px solid #1A83FF;
  border-radius: 5px;
  box-shadow: 0px 4px 15px rgba(26, 131, 255, 0.20)!important;
  color: white;
  float: left;
  font-size: 13px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 550;
  letter-spacing: 0.020em;
}
.cancel-button{
  background: #FFFFFF;
  border: 1px solid #F98080;
  box-shadow: 0px 4px 15px rgba(255, 116, 116, 0.1);
  border-radius: 5px;
  float: right;
  font-size: 13px;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 550;
  color: #FF3434;
}
.cancel-button-icon{
  font-size: 15px;
  position: relative;
  bottom: 1px;
}
.apply-button-icon{
  font-size: 15px;
  position: relative;
  bottom: 1px;
}
button:focus{
  outline: 0px!important;
}
:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color:#5fa8ff7d;
}
  </style>