import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { url } from '../providers/properties'
import { AuthService } from '../Authenticaton/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Input } from '@angular/core'
import { GlobalserviceService } from '../providers/globalservice.service';
import { MatSidenav } from '@angular/material/sidenav';
 import * as SockJS from 'sockjs-client';
import { Client, Message } from '@stomp/stompjs';
import { Stomp } from '@stomp/stompjs';
import { LeavebalancenotifictionComponent} from '../leavebalancenotifiction/leavebalancenotifiction.component'
import { MatDialog,MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css']
})
export class MainLayoutComponent{

  
  theme: boolean;
  testpic:any;
  image:any
  User_Name: string;
  Profile_Image: any;
  notify: any = 0;
  withdrawNotify:any =0;
  wfhNotify:any =0;
  btNotify:any =0;
  permissionNotify:any = 0;
  showNotify: boolean = true;
  showWithdrawNotify:boolean = true;
  showWfhNotify:boolean = true;
  showBTNotify:boolean = true;
  showPermissionNotify:boolean = true;
  isManager: boolean;
  isHr: boolean;
  myList:any;
  userType:any;
  //
  isShow: boolean
  active: boolean;
  isShowsettings:boolean = false;
  showmanage:boolean = false;
  showadd:boolean = false;
  isreports:boolean = false;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  User_designation: any;
  isEmployee: boolean=false;
  orgmail: any;
  value: any;
  reqLen: number;
  notifytest: number=0;
  name: string;
  iconColor: string = 'blue';
  isDropdownOpen: boolean = false;
  showNotification: boolean=false;
  notificationlist : any = [];
  notificationcount : any = 0;
  usertype:any;
  employeeName :string;
  stompClient: any;
  notifylist :any;
  id1:any;
  id2:any;
  list: any;
  sendData: any;
  a: any;
  // @Input() receivedData: any;
  constructor(private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private router: Router,
    private gs: GlobalserviceService,
    public dialog: MatDialog,
    private cd:ChangeDetectorRef) { }

  ngOnInit() {
    // console.log("notifytest",this.notifytest);
     console.log("receivedData"+this.notify);
    this.theme = false
    this.image = false
    this.isManager = false
    this.isHr = false
    this.isShow = true;
    this.showmanage = false;
    this.showadd = false;
    this.isreports=false;
    let x = JSON.parse(localStorage.getItem('data'));
    console.log(x);
    // console.log(x.userType.userTypeId);
    if(x == null)   
    {
      this.router.navigate(['login'])
    }
     this.User_Name = x.employeeName;
     this.User_designation = x.designationResponse.designationName;
    let y = JSON.parse(localStorage.getItem('data'));
    let user_type = y.userType.userType;
    this.userType = y.userType.userType;
    let id = x.employeeId;
    if(x.userType.userTypeId==1||x.userType.userTypeId==3)
    {
      this.showNotification=true;
    }
    else{
      this.showNotification=false;
    } 
    if(user_type == 'HumanResource') {
      this.isHr = true;
    }
    if(user_type == 'Manager') {
      this.isManager = true;
    }
    if(user_type == 'Employee') {
      this.isEmployee = true;
    }
    this.testpic = `${url}employee_profile/${id}`;
    let z = JSON.parse(localStorage.getItem('data'));
    let u = x.userType;
    let mail = x.organizationEmail;
    console.log("mail"+mail);
    this.orgmail=mail;
    console.log(this.orgmail);
    // get employee
    this.gs.getEmpListById(id).subscribe((d:any) => {
      this.myList = d.attachment.fileViewUrl;
    })
    this.gs.getleaveReqPending(mail).subscribe((d: any) => {
      // this.value=d.length;
        this.notify = d.length;
        console.log(this.notify);
      if (this.notify >= 1) {
        this.showNotify = false;
      }
    })

    this.gs.getemp_withdraw_list(mail).subscribe((d: any) => {
      this.withdrawNotify = d.length
      if (this.withdrawNotify >= 1) {
        this.showWithdrawNotify = false;
      }
    })

    this.gs.getWFHRequest(mail,3).subscribe((d: any) => {
      this.wfhNotify = d.length
      if (this.wfhNotify >= 1) {
        this.showWfhNotify = false;
      }
    })

    this.gs.getEmployeeBusinessTravelRequest_EmailID(mail, 3).subscribe((d: any) => {
      this.btNotify = d.length
      if (this.btNotify >= 1) {
        this.showBTNotify = false;
      }
    })

    this.gs.getPermission_Manage(x.organizationEmail).subscribe((d: any) => {
      this.permissionNotify = d.length
      if (this.permissionNotify >= 1) {
        this.showPermissionNotify = false;
      }
    })
    this.dateDifference();

    // profile
    this.gs.get_profile(id).subscribe(d => {
      this.image = true
    },
    err => {
      this.image = false
    },
    () => console.log('done')
    )
    this.notificationlist=[];
    let a=localStorage.getItem("notifications");
    console.log("Value:",a);
    if(a!=null)
    {
      this.notificationlist=JSON.parse(a);
      console.log("List:",this.notificationlist);
    }
    console.log(this.notificationlist);
    console.log("ng init notification"+JSON.stringify(this.notificationlist));
    console.log(this.notificationlist.length);
    if(this.notificationlist!= null)
      {
        this.notificationcount = this.notificationlist.length;
        console.log("this.notificationcount init",this.notificationcount);
      }
    console.log(x.employeeId);
    let nofitication = "/probationnotification/"+x.employeeId;
    let ws = new SockJS(`${url}getprobationnotification`);
    this.stompClient = Stomp.over(ws);
    const _this = this;
    _this.stompClient.connect({}, function (frame: any) {
        _this.stompClient.subscribe(nofitication, function (sdkEvent: any) {
          console.log("sdkEvent:",sdkEvent);
            _this.onMessageReceived(sdkEvent);
        });
        //_this.stompClient.reconnect_delay = 2000;
    }, this.errorCallBack);
  }

  errorCallBack(error: string) {
    // console.log("errorCallBack -> " + error)
    setTimeout(() => {
    }, 5000); 
    }

onMessageReceived(message: any) 
{
  console.log(message);
  console.log("Message Recieved from Server:" + message.body);
  let x = localStorage.getItem("notifications")
  console.log(x);
  let y = JSON.parse(x);
  console.log("notifications");
  console.log(y);   

      if(y !=  null)
      {
        if(y.length != 0)
        {
          //alert("x.lengtj " + y.length)
        // this.notificationlist=[];
        // console.log("emty notificationlist"+this.notificationlist);
         this.notificationlist = y;
         console.log(this.notificationlist);
        }
      }   
      this.notifylist = JSON.parse(message.body);
      console.log("Msg Body",this.notifylist);
      for(let id of this.notifylist)
      {
      console.log(id);
      this.id1 =id.employeeId;
      console.log(this.id1);
      // console.log("this.notificationlist" + JSON.stringify(this.notificationlist));
      let resourceidExist = false;
      if(this.notificationlist.length != 0)
      {
        for(let data of this.notificationlist)
        {
          for(let e of data.emp)
          {
          console.log(e);
          this.id2 = e.employeeId;
          console.log(this.id2);
          console.log("Id1:",this.id1+"Id2:",this.id2);
          if(this.id1 == this.id2)
          {
            console.log(this.id1);
            resourceidExist = true;
          }
          }
        }
      }
      console.log("resourceidExist" + resourceidExist);
      if(resourceidExist == false)
      {
        let data=
        {
          title:"Probation Completion Notification",
          emp:JSON.parse(message.body)
        }
        console.log("Data Pushed:"+data);
        this.notificationlist.push(data);
        console.log(this.notificationlist);
      }
     }
      this.notificationcount  = this.notificationlist.length;
      console.log("this.notificationcount" + this.notificationcount);
      localStorage.setItem('notifications', JSON.stringify(this.notificationlist)); 
}


resourceDetails(resource)
{
   console.log("resource" +this.notificationlist);
   console.log("resource" +resource);
  // var dialogbox = this.dialog.open(LeavebalancenotifictionComponent, { 
  //   width: '900px',height:'560px',
  //   data:this.notificationlist
  // });
  // var dialogbox = this.dialog.open(NotificationResourceDetailComponent, { 
  //   width: '900px',height:'560px',
  //   data:resource
  // });
  // this.selectedvalue != null
  // this.isDetails = ! this.isDetails;
 }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  toggle() {
    this.isShow = !this.isShow;
  }

  toggleSideNav(nav: MatSidenav) 
  {
    console.log("toggleSideNav");
    const isSmallScreen = this.breakpointObserver.isMatched("(max-width: 959px)");
    if (isSmallScreen) 
    {
      nav.toggle();
    }
  }
  managetoggle(){
    this.showmanage =!this.showmanage;
  } 
  addtoggle(){
    this.showadd =!this.showadd;
  }
  settingstoggle(){
    this.isShowsettings = !this.isShowsettings
  }
  reportstoggle(){
    this.isreports = !this.isreports
  }
  showtimeSettings(){
    this.active = true
  }

  Notify() 
  {
    let x = JSON.parse(localStorage.getItem('data'));
    let mail = x.organizationEmail;
    let y = JSON.parse(localStorage.getItem('data'));
    let user_type = y.userType;
    this.gs.getleaveReqPending(mail).subscribe((d: any) => {
      this.notify = d.length
      console.log("Notify Method:"+this.notify);
      if (this.notify >= 1) {
        // window.location.reload()
      }
    })

    this.gs.getemp_withdraw_list(mail).subscribe((d: any) => {
      this.withdrawNotify = d.length
      if (this.withdrawNotify >= 1) {
        // window.location.reload()
      }
    })

    this.gs.getWFHRequest(mail,3).subscribe((d: any) => {
      this.wfhNotify = d.length
      if (this.wfhNotify  >= 1) {
        // window.location.reload()
      }
    })
    this.gs.getEmployeeBusinessTravelRequest_EmailID(mail, 3).subscribe((d: any) => {
      this.btNotify = d.length
      if (this.btNotify  >= 1) {
        // window.location.reload()
      }
    })

    this.gs.getPermission_Manage(x.organizationEmail).subscribe((d: any) => {
      this.permissionNotify = d.length
      if (this.permissionNotify < 1) {
        // window.location.reload()
      }
    })
  }

  //
  logout() {
    Swal.fire({
      title: 'Are you sure? Do you want to logout?',
      icon:'success',
      iconHtml: '<img src="assets/img/Vector.png">',
      customClass: {
        icon: 'no-border',
        confirmButton:'swal2-styled.swal2-confirm',
        cancelButton:'swal2-styled.swal2-cancel'
      },
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Logout'
    }).then((result) => {
      if (result.value) {
        // Swal.fire(
        //   'Done!'
        // )
        localStorage.clear();
        this.router.navigate(['login'])
      }
      //
    })
  }

  calendar(){
    let x = JSON.parse(localStorage.getItem('data'));
    localStorage.setItem('calendarData',JSON.stringify(x))
  }
   dateDifference() {
    let start = new Date();
    let end = new Date('2020/05/25')
    // Copy date objects so don't modify originals
    var s:any = new Date(+start);
    var e:any = new Date(+end);

    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12,0,0,0);
    e.setHours(12,0,0,0);

    // Get the difference in whole days
    var totalDays = Math.round((e - s) / 8.64e7);

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    var days = wholeWeeks * 5;

    // If not even number of weeks, calc remaining weekend days
    if (totalDays % 7) {
      s.setDate(s.getDate() + wholeWeeks * 7);

      while (s < e) {
        s.setDate(s.getDate() + 1);

        // If day isn't a Sunday or Saturday, add to business days
        if (s.getDay() != 0 && s.getDay() != 6) {
          ++days;
        }
      }
    }
    return days;
  }
  background1() {
      this.theme = !this.theme;
    if(this.theme == false) {

      return `linear-gradient(rgba(0, 123, 255, 0.75), rgba(103, 58, 183, 0.49019607843137253) ),
      url(../../assets/sidenav/mountain.jpg) no-repeat left fixed`
    } 
    else 
    {
      return `linear-gradient( rgba(63, 81, 181, 0.9686274509803922),
      rgba(63, 81, 181, 0.9215686274509803),rgba(63, 80, 181, 0.92) ),
      url(../../assets/img/tree.jpg) no-repeat center fixed`
    }
  }


  delete(i){
    console.log("delete"+i);

    // for(let data of this.notificationlist)
    //     {
    //       console.log(data);
          // for(let id of data.emp)
          // {
          //   console.log(id);
            this.notificationlist.splice(i,1);
          // }
        // }
    console.log("this.notificationlist",this.notificationlist);
    this.notificationcount  = this.notificationlist.length;
    localStorage.setItem('notifications', JSON.stringify(this.notificationlist)); 
  }

  view(i)
  {
    console.log("view",i);
    console.log("notificationlist",this.notificationlist);
    this.sendData=[];
    // for(let data of this.notificationlist)
    // {
      // console.log(data);
      console.log(this.notificationlist[i]);
      this.a=this.notificationlist[i];
      console.log(this.a);
    //  if(thisa.indexOf()==i)
    //  {
      // this.list=data(i);
      // console.log(this.list)
       for(let d of this.a.emp)
        {
          console.log(d);
          let emp={
            employeeId: d.employeeId,
            employeeJoinedDate:d.employeeJoinedDate,
            employeeName: d.employeeName,
            employeeNumber: d.employeeNumber,
            mobileNumber: d.mobileNumber,
            organizationEmail: d.organizationEmail
          }
          this.sendData.push(d);
        }
    console.log("this.sendData"+this.sendData);
    // }
  // }
     if(this.sendData.length!=0)
     {
      const x = this.dialog.open(LeavebalancenotifictionComponent, {
        data: this.sendData,
        // width: '500px',height:'275px',
      });
     }
  }

  deleteAll()
  {
    console.log("delete all");
    this.notificationlist=[];
    localStorage.removeItem('notification'); 
    console.log("this.notificationlist",this.notificationlist);
    // this.notificationlist.splice(this.notificationlist);
    this.notificationcount  = this.notificationlist.length;
    localStorage.setItem('notifications', JSON.stringify(this.notificationlist)); 
  }

  public leaveRequest()
{
  console.log("Child Components Call");
  // console.log("receivedData"+this.receivedData);
   let x = JSON.parse(localStorage.getItem('data'));
   let organzationmail = x.organizationEmail;
  //  console.log("organzationmail"+organzationmail);
   this.gs.getleaveReqPending(organzationmail).subscribe((d: any) => 
   {
    console.log("API Value:",d);
    this.notify =d.length;
    console.log("Value:"+this.notify);
    if (this.notify >= 1) 
    {
      this.showNotify = false;
    }
  })
  // this.refreshSidenav();
  // {
  //   console.log("referesh SideNav");
  //   console.log(this.notify);
  //   location.reload();
  // }
  // this.router.navigate(['/request'])
  // console.log("Final Value:"+this.notify);
  // get notify(): number {
  //   return this.notify;
  // }

  // // Setter method to update the badge value
  // set notify(value: number) {
  //   this.notify = value;
  // }
}

// refreshSidenav()
// {
//  console.log("referesh SideNav");
//  console.log(this.notify);
// //  location.reload();
// }
 
  // this.gs.getemp_withdraw_list(mail).subscribe((d: any) => {
  //   this.withdrawNotify = d.length
  //   if (this.withdrawNotify >= 1) {
  //     this.showWithdrawNotify = false;
  //   }
  // })

  // this.gs.getWFHRequest(mail,3).subscribe((d: any) => {
  //   this.wfhNotify = d.length
  //   if (this.wfhNotify >= 1) {
  //     this.showWfhNotify = false;
  //   }
  // })

  // this.gs.getEmployeeBusinessTravelRequest_EmailID(mail, 3).subscribe((d: any) => {
  //   this.btNotify = d.length
  //   if (this.btNotify >= 1) {
  //     this.showBTNotify = false;
  //   }
  // })
  // this.gs.getPermission_Manage(x.organizationEmail).subscribe((d: any) => {
  //   this.permissionNotify = d.length
  //   if (this.permissionNotify >= 1) {
  //     this.showPermissionNotify = false;
  //   }
  // })

  updateNotify(newNotify: number) {
    console.log("newNotify"+newNotify);
    this.notify = newNotify;
  }

}
