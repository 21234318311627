import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { TaskDialogComponent } from '../task-dialog/task-dialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css']
})
export class ProjectListComponent implements OnInit {

  displayedColumns:string[] = ['position','name','weight','symbol'];
  dataSource:any[];
  taskName:any;

  constructor(private dialog:MatDialog,
              private gs:GlobalserviceService) { }

  ngOnInit(): void {
    this.dataSource = [
      {position: 1, name: 'Leave Application', weight: 1.0079, symbol: 'H'},
      {position: 2, name: 'Leave Application', weight: 4.0026, symbol: 'He'},
      {position: 3, name: 'Leave Application', weight: 6.941, symbol: 'Li'},
      {position: 4, name: 'Leave Application', weight: 9.0122, symbol: 'Be'},
      {position: 5, name: 'Leave Application', weight: 10.811, symbol: 'B'},
      {position: 6, name: 'Leave Application', weight: 12.0107, symbol: 'C'},
    ];
  }

  openDialog(i){

    const dialogRef = this.dialog.open(TaskDialogComponent, {
      width: '350px',
      data :this.dataSource,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != ''){
        let resultData:any = result;
        resultData['resourceId'] = i;
        Swal.fire({
          text:'Task Submitted Successfully',
          icon:'success'
        })
      }
    })
  }
}
