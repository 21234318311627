import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCalendar, MatCalendarCellCssClasses } from '@angular/material/datepicker';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarOptions, DateSelectArg } from '@fullcalendar/common';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import Swal from 'sweetalert2';
import { dialog } from '../leave-req/leave-req.component';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import * as XLSX from 'xlsx';
import { identifierModuleUrl } from '@angular/compiler';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  data = [];
  myFilter:any
  calendarOptions: CalendarOptions
  leaveBalanceCount: any;
  calendarVisible = true;
  checked: boolean;
  branchId: any
  calendarId: any
  employeeId:any
  holidayType:any = []
  exceptionType:any = []
  calendarList: any = []
  myArray: any = []
  myArray1: any = []
  arrayData: any = []
  userType:any;
  calendarName:any
  workArray:any =[ ]
  workingDays:any = []

  constructor(private gs: GlobalserviceService, private dialog: MatDialog) {
    const name = Calendar.name
  }

  ngOnInit() {
    // this._datePicker.selectedChange.subscribe(x => {
    //   console.log(x);
    // });
    let oned = 24 * 60 * 60 * 1000;
    console.log(Math.ceil((6 - 1) / oned))
    let data = JSON.parse(localStorage.getItem('data'))
    this.userType = data.userType.userType
    this.employeeId = data.employeeId
    // this.branchId = data.branch.branchId

    this.gs.getEmpListById(data.employeeId).subscribe(
      (d:any)=>{
        this.branchId = d.branch.branchId
      }
    )

    this.gs.getListTypeById(5).subscribe(
      (d:any)=>{
        this.holidayType = d
      }
    )

    this.gs.getListTypeById(6).subscribe(
      (d:any)=>{
        this.exceptionType = d
      }
    )


    if(this.userType != 'Employee'){
      this.calendarOptions = {
        droppable: true,
        // weekends: true,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        themeSystem: "bootstrap4",
        // themeName: 'Materia',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth'
        },
        initialView: 'dayGridMonth',
        displayEventTime: false,
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        select: this.addEvent.bind(this),
        eventClick: this.updateEvent.bind(this),
        plugins: [interactionPlugin],
      };
    }
    else{
      this.calendarOptions = {
        droppable: true,
        // weekends: true,
        // editable: true,
        // selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        themeSystem: "bootstrap4",
        // themeName: 'Materia',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth'
        },
        initialView: 'dayGridMonth',
        displayEventTime: false,
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        // select: this.addEvent.bind(this),
        // eventClick: this.updateEvent.bind(this),
        plugins: [interactionPlugin],
      };
    }

    this.gs.GetMYleaveBalance(data.employeeId).subscribe(
      d => {
        d.map(
          e => {
            this.leaveBalanceCount = e.noOfDays;
          }
        )
      }
    )

    this.fetchData()
  }


  fetchData() {
    this.gs.getEmpListById(this.employeeId).subscribe(
      (d:any)=>{
        this.branchId = d.branch.branchId

          //getbranch
    this.gs.getCalByBranch(this.branchId).subscribe(
      (d: any) => {
        console.log(d)

        d.map(
          e => {
            let date = new Date()
            if(e.year.yearName == date.getFullYear()){
              this.calendarId = e.calendarId
            }
          }
        )


        // getWoringDays
        this.gs.getCalendarById(this.calendarId).subscribe(
          (d: any) => {
            let b = ["1","2","3","4","5","6","0"]
            d.calendarWorkingDays.map(dot => {
              this.workingDays = dot.workingdays
              var difference:any = b.filter(x => dot.workingdays.indexOf(x) === -1);
              difference.map(
                a=>{
                  this.workArray.push(Number(a))
                }
              )

              // this.calendarOptions.hiddenDays = this.workArray

            })
            var event = []
            // getHolidays
            d.holiday.forEach((e) => {
              // if(e.holidayStatus == 1){
                let MyDate = new Date(e.holidayDate)
                let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
                if(e.holidayStatus == 1){
                  event.push(
                    {
                      id: e.holidayId,
                      title: e.holidayName,
                      start: MyDateString,
                      groupId: Number(e.holidaytype),
                      color: '#6488ea',
                    })
                }
                if(e.holidayStatus == 2){
                  event.push(
                    {
                      id: e.holidayId,
                      title: e.holidayName,
                      start: MyDateString,
                      groupId: Number(e.holidaytype),
                      color: '#F88379',
                    })
                }

                this.calendarOptions.events = event;
                this.arrayData = this.calendarOptions.events;
                this.dateClass()
              // }

            })

            d.exceptions.forEach((e) => {
                let MyDate = new Date(e.exceptionDate)
              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)
              if(e.exceptionStatus == 1){
                event.push(
                  {
                    id: e.exceptionId,
                    title: e.exceptionName,
                    start: MyDateString,
                    groupId: Number(e.exceptionType),
                    color: '#40E0D0',
                  })
              }
              if(e.exceptionStatus == 2){
                event.push(
                  {
                    id: e.exceptionId,
                    title: e.exceptionName,
                    start: MyDateString,
                    groupId: Number(e.exceptionType),
                    color: '#F88379',
                  })
              }

              this.calendarOptions.events = event;
              this.arrayData = this.calendarOptions.events;
              this.dateClass()
            })
          }
        )

      }
    )

    this.gs.getCalendar().subscribe(
      (x: any) => {
        x.map(e=>{
          // if(e.calendarStatus == 1){
            this.calendarList.push(e)
          // }
        })
        this.calendarList.map(
          e=>{
            if(e.calendarId == this.calendarId){
              e.checked = true
              this.calendarName = e.calendarName
            }
            else{
              e.checked = false
            }
          }
        )
      }
    )
      }
    )





  }

  changeCalendar(cal){
     // getWoringDays
     this.calendarId = cal.calendarId
        this.calendarList.map(
          e=>{
            if(e.calendarId == this.calendarId){
              e.checked = true
              this.calendarName = e.calendarName
            }
            else{
              e.checked = false
            }
          }
        )
        this.workArray =[]
        this.workingDays = []
     this.gs.getCalendarById(this.calendarId).subscribe(
      (d: any) => {

        var event = []
        if(d.holiday == ''){
          event.push(
            {
              id: '',
              title: '',
              start: '',
              end: '',
              groupId: '',
            })
          this.calendarOptions.events = event;
          this.arrayData = this.calendarOptions.events;
        }
        if(d.exception == ''){
          event.push(
            {
              id: '',
              title: '',
              start: '',
              end: '',
              groupId: '',
            })
          this.calendarOptions.events = event;
          this.arrayData = this.calendarOptions.events;
        }
        // getHolidays
        d.holiday.forEach((e) => {
          let MyDate = new Date(e.holidayDate)
          let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)

          if(e.holidayStatus == 1){
            event.push(
              {
                id: e.holidayId,
                title: e.holidayName,
                start: MyDateString,
                end: MyDateString,
                groupId: Number(e.holidaytype),
                color: '#6488ea',
              })
          }
          if(e.holidayStatus == 2){
            event.push(
              {
                id: e.holidayId,
                title: e.holidayName,
                start: MyDateString,
                end: MyDateString,
                groupId: Number(e.holidaytype),
                color: '#F88379',
              })
          }
          this.calendarOptions.events = event;
          this.arrayData = this.calendarOptions.events;
        })

        d.exceptions.forEach((e) => {
          let MyDate = new Date(e.exceptionDate)
          let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)

          if(e.exceptionStatus == 1){
            event.push(
              {
                id: e.exceptionId,
                title: e.exceptionName,
                start: MyDateString,
                end: MyDateString,
                groupId: Number(e.exceptionType),
                color: '#40E0D0',
              })
          }
          if(e.exceptionStatus == 2){
            event.push(
              {
                id: e.exceptionId,
                title: e.exceptionName,
                start: MyDateString,
                end: MyDateString,
                groupId: Number(e.exceptionType),
                color: '#F88379',
              })
          }
          this.calendarOptions.events = event;
          this.arrayData = this.calendarOptions.events;
        })
        let b = ["1","2","3","4","5","6","0"]
        if(d.calendarWorkingDays == ''){
          this.calendarOptions.hiddenDays = []
        }
        else{
          d.calendarWorkingDays.map(dot => {
            if(dot.workingdays != ''){
              this.workingDays = dot.workingdays
              var difference:any = b.filter(x => dot.workingdays.indexOf(x) === -1);
              difference.map(
                a=>{
                  this.workArray.push(Number(a))
                }
              )
              // this.calendarOptions.hiddenDays = this.workArray
            }
          })
        }

      }
    )
  }

  deactivateCalendar(cal){
    this.gs. deactiveCalendar(cal.calendarId,'').subscribe(
      (d:any)=>{
        Swal.fire({
          text:d.description,
          icon:'success'
        })
        setTimeout(()=>{
          window.location.reload()
        },2000)
      },
      err=>{
        Swal.fire({
          text:err.error.message,
          icon:'error'
        })
      }
    )
  }

  dateClass() {
    return (d: Date) => {
      const day = new Date(d).getDay();
      const time = new Date(d).getTime()
      return day !== 1 && day !== 2 && day !== 3 && day !== 4&& day !== 5 ? 'special-date' : ''
    }
    // const datesToHighlight = ["2021-05-22T00:00:00.000Z", "2021-05-06T00:00:00.000Z"];

    // return  (date: Date): MatCalendarCellCssClasses => {
    //   const highlightDate = datesToHighlight
    //     .map(strDate => new Date(strDate))
    //     .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());

    //   return highlightDate ? 'special-date' : '';
    // }
  }

  createCalendar() {
    const dialogRef = this.dialog.open(MyCalendar, {
      width: '500px',
      data:'e'
    })
  }

  updateCalendar(cal){
    console.log(cal)
    const dialogRef = this.dialog.open(MyCalendar, {
      width: '500px',
      data:cal
    })
  }



  // ************ Add Event ************
  addEvent(selectInfo: DateSelectArg) {
    let data = {
      start: selectInfo.start,
      groupId: this.calendarId
    }
    const calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
    const dialogRef = this.dialog.open(AddHoliday, {
      width: '400px',
      data: data
    })
  }

  // *********** Update Event ***********
  updateEvent(clickInfo) {
    console.log(clickInfo.event.groupId)
    this.gs.getListTypeById(5).subscribe(
      (d:any)=>{
        d.map(
          e => {
            if (clickInfo.event.groupId == e.listTypeValueId) {
              let eventData;
              eventData = {
                id: clickInfo.event.id,
                title: clickInfo.event.title,
                start: clickInfo.event.start,
                groupId: this.calendarId,
                holidayType: clickInfo.event.groupId,
              }

              const dialogRef = this.dialog.open(AddHoliday, {
                width: '400px',
                data: eventData
              })

            }
          }
        )

      }
    )

    this.gs.getListTypeById(6).subscribe(
      (d:any)=>{
        d.map(
          e=>{
            if (clickInfo.event.groupId == e.listTypeValueId) {
              let eventData;
              eventData = {
                id: clickInfo.event.id,
                title: clickInfo.event.title,
                start: clickInfo.event.start,
                groupId: this.calendarId,
                holidayType: clickInfo.event.groupId,
              }

              const dialogRef = this.dialog.open(ExceptionHoliday, {
                width: '400px',
                data: eventData
              })

            }
          }
        )
      }
    )
    // if(clickInfo.event.groupId == '39' || clickInfo.event.groupId == '40'){
    //   console.log(clickInfo.event.groupId)
    //   let eventData;
    // eventData = {
    //   id: clickInfo.event.id,
    //   title: clickInfo.event.title,
    //   start: clickInfo.event.start,
    //   groupId: this.calendarId,
    //   holidayType: clickInfo.event.groupId,
    // }

    // const dialogRef = this.dialog.open(AddHoliday, {
    //   width: '400px',
    //   data: eventData
    // })
    // }

    // if (clickInfo.event.groupId == '41' || clickInfo.event.groupId == '42') {
    //   let eventData;
    //   eventData = {
    //     id: clickInfo.event.id,
    //     title: clickInfo.event.title,
    //     start: clickInfo.event.start,
    //     groupId: this.calendarId,
    //     holidayType: clickInfo.event.groupId,
    //   }

    //   const dialogRef = this.dialog.open(ExceptionHoliday, {
    //     width: '400px',
    //     data: eventData
    //   })
    // }

  }


  // Create Woring Days
  createWorkingDays(cal) {
    let d = {
      id:cal.calendarId,
      calendarName:cal.calendarName
    }
    const dialogRef = this.dialog.open(WorkingDays, {
      width: '400px',
      data: d
    })
  }

  //Update Working Days
  updateWorkingDays(cal) {
    const dialogRef = this.dialog.open(WorkingDays, {
      width: '400px',
      data: cal
    })
  }

  // Convvert xlsx into JSON
  onFileChange(ev) {
    this.myArray = []
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary', cellDates: true });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData);
      // console.log(jsonData)
      // return
      let arr = new Array()
      let arr1 = new Array()

      jsonData.Sheet1.map(
        e => {
          let x = {}
          if (e.LeaveType == "Holiday") {
           this.holidayType.map(
             v=>{
              if (e.HolidayType == v.listTypeValueName) {
                x['holidaytype'] = v.listTypeValueId

              }
             }
           )
            var date = new Date(e.HolidayDate);
            date.setDate(date.getDate() + 1)
            var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
            x['holidayDate'] = newdate;
            x['holidayStatus'] = 1
            x['calender'] = {
              calendarId: this.calendarId
            }
            x['holidayName'] = e.HolidayName
            arr.push(x)
            this.myArray.push(x)
          }

          if (e.LeaveType == "Exception") {
            this.exceptionType.map(
              v=>{
               if (e.HolidayType == v.listTypeValueName) {
                 x['exceptionType'] = v.listTypeValueId

               }
              }
            )
            var date = new Date(e.HolidayDate);
            date.setDate(date.getDate() + 1)
            var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
            x['exceptionDate'] = newdate;
            x['exceptionStatus'] = 1
            x['calender'] = {
              calendarId: this.calendarId
            }
            x['exceptionName'] = e.HolidayName
            arr1.push(x)
          }
        }
      )
      this.myArray = arr
      this.myArray1 = arr1

        // this.myArray.map(
        //   x=>{
        //     let date = new Date(x.holidayDate)
        //     this.workArray.map(
        //       y=>{
        //         if(date.getDay() == y){
        //           alert("Allowded only working days for the calendar")
        //           this.myArray = []
        //           setTimeout(()=>{
        //             window.location.reload()
        //           },2000)
        //           return
        //         }
        //       }
        //     )
        //   }
        // )

        this.myArray1.map(
          x=>{
            let date = new Date(x.exceptionDate)
            this.workArray.map(
              y=>{
                // if(x.exceptionType == 41){
                  if(date.getDay() == y){
                    alert(`${x.exceptionDate} already considered as a leave`)
                    this.myArray1 = []
                    setTimeout(()=>{
                      window.location.reload()
                    },2000)
                    return
                  }
                // }
              }
            )
          }
        )

        // this.myArray1.map(
        //   x=>{
        //     let date = new Date(x.exceptionDate)
        //     this.workingDays.map(
        //       y=>{
        //         if(x.exceptionType == 31){
        //           if(date.getDay() == y){
        //             alert(`${x.exceptionDate} already considered as a working day`)
        //             this.myArray1 = []
        //             setTimeout(()=>{
        //               window.location.reload()
        //             },2000)
        //             return
        //           }
        //         }

        //       }
        //     )
        //   }
        // )

      if (this.myArray != '') {
        this.holiday()
      }

      if (this.myArray1 != '') {
        this.exception()
      }

    }
    reader.readAsBinaryString(file);
  }

  holiday() {
    this.gs.createMultipleHoliday(this.myArray).subscribe(
      (d: any) => {
        Swal.fire({
          text: d.description,
          icon: 'success'
        })
        setTimeout(()=>{
          window.location.reload()
        },2000)
      },
      err => {
        Swal.fire({
          text: err.error.description,
          icon: 'error'
        })
        setTimeout(()=>{
          window.location.reload()
        },2000)
      }
    )
  }

  exception() {
    this.gs.createMultipleException(this.myArray1).subscribe(
      (d: any) => {
        Swal.fire({
          text: d.description,
          icon: 'success'
        })
        setTimeout(()=>{
          window.location.reload()
        },2000)
      },
      err => {
        Swal.fire({
          text: err.error.message,
          icon: 'error'
        })
        setTimeout(()=>{
          window.location.reload()
        },2000)
      }
    )
  }
}

@Component({
  selector: 'MyCalendar',
  templateUrl: 'MyCalendar.html',
})
export class MyCalendar implements OnInit {

  organization: any[];
  year: any[];
  calendarForm: FormGroup;
  branchList: any = []
  holiday:boolean;
  exception:boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private gs: GlobalserviceService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MyCalendar>
  ) { }

  ngOnInit() {

    this.gs.getOrganization().subscribe(
      (data: any) => {
        this.organization = data
      }
    )

    this.gs.getYear().subscribe(
      (data: any) => {
        this.year = data
      }
    )

    // this.gs.getBranch().subscribe(
    //   d => {
    //     this.branchList = d
    //   }
    // )

    this.calendarForm = this.fb.group({
      calendarName: ['', Validators.required],
      organization: ['', Validators.required],
      year: ['', Validators.required],
      branch: ['', Validators.required],
      calendarStatus:['',Validators.required]
    })

    if(this.data.calendarId){
      this.gs.getBranch().subscribe(
        (a:any)=>{
          a.map(
            e=>{
              if(e.organization.organizationId == this.data.organization.organizationId){
                this.branchList.push(e)
              }
            }
          )
        }
      )
      this.calendarForm.patchValue({
        calendarName:this.data.calendarName,
        organization:this.data.organization.organizationId,
        branch:this.data.branch.branchId,
        year:this.data.year.yearId,
        calendarStatus:this.data.calendarStatus.toString()
      })
    }
    else{
      this.calendarForm.patchValue({
        calendarStatus:1
      })
    }

  }

  change(d){
    this.branchList = []
    this.gs.getBranch().subscribe(
      (a:any)=>{
        a.map(
          e=>{
            if(e.organization.organizationId == d){
              this.branchList.push(e)
            }
          }
        )
      }
    )
  }

  get f() {
    return this.calendarForm.controls;
  }

  onSubmit() {
    let x = this.calendarForm.value;
    if (this.calendarForm.invalid) {
      return
    }

    x['organization'] = {
      'organizationId': Number(x.organization)
    }

    x['year'] = {
      'yearId': x.year
    }

    x['branch'] = {
      'branchId': x.branch
    }

    console.log(x)

    if(this.data.calendarId){
      x['calendarId'] = this.data.calendarId
      x['calendarStatus'] = Number(x.calendarStatus)

      this.gs.updateCalendar(this.data.calendarId,x).subscribe(
        (data: any) => {
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          this.dialogRef.close()
          setTimeout(()=>{
            window.location.reload()
          },2000)
        },
        error => {
          Swal.fire({
            text: error.error.message,
            icon: 'error'
          })
          this.dialogRef.close()
        }
      )
    }
    else{
      x['calendarStatus'] = 1
      this.gs.createCalendar(x).subscribe(
        (data: any) => {
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          this.dialogRef.close()
          window.location.reload()
        },
        error => {
          Swal.fire({
            text: error.error.message,
            icon: 'error'
          })
          this.dialogRef.close()
        }
      )
    }

  }
}

@Component({
  selector: 'AddHoliday',
  templateUrl: 'AddHoliday.html',
})
export class AddHoliday implements OnInit {

  holidayFrom: FormGroup;
  myFilter:any
  holidayType: any = []
  exceptionType:any = []
  holiday:boolean;
  exception:boolean;
  dateDisable:boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private gs: GlobalserviceService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddHoliday>
  ) { }

  ngOnInit() {
    this.holidayFrom = this.fb.group({
      holidayName: ['', Validators.required],
      holidayDate: this.data.start,
      holidayStatus: ['', Validators.required],
      holidaytype: ['', Validators.required],
      leaveType:['',Validators.required],
      calender: {
        calendarId: this.data.groupId
      }
    })


    this.gs.getListTypeById(5).subscribe(
      (d: any) => {
        this.holidayType = d;
      }
    )

    this.gs.getListTypeById(6).subscribe(
      (d: any) => {
        console.log(d)
        this.exceptionType = d
        // d.map(
        //   e=>{
        //     if (e.listTypeValueName == "Leave") {
        //       this.exceptionType.push(e);
        //     }
        //   }
        // )
      }
    )

    if (this.data.id) {
      this.dateDisable = true
      this.gs.getHolidayById(this.data.id).subscribe(
        (d:any)=>{
          this.holidayFrom.patchValue({
            holidayStatus:d.holidayStatus.toString()
          })
        }
      )
      this.holidayFrom.patchValue({
        holidayId: this.data.id,
        holidayName: this.data.title,
        holidayDate: this.data.start,
        holidaytype: this.data.holidayType,
        calender: {
          calendarId: Number(this.data.groupId)
        },
        leaveType:0
      })
    }
    else{
      this.holidayFrom.patchValue({
        holidayStatus:1
      })
    }
  }

  get f() {
    return this.holidayFrom.controls
  }

  holiClick(e){
    if(e == 0){
      this.holiday = true
      this.exception = false
    }
    if(e == 1){
      this.holiday = false
      this.exception = true
    }
  }

  delete(e) {
    this.gs.deleteHoliday(e.id).subscribe(
      (data: any) => {
        Swal.fire({
          text: data.description,
          icon: 'success'
        })
        window.location.reload()
      },
      err => {
        Swal.fire({
          text: err.error.message,
          icon: 'error'

        })
      }
    )
  }

  onSubmit() {

    let x = this.holidayFrom.value;
    if (this.holidayFrom.invalid) {
      return
    }

    var date = new Date(x.holidayDate);
    var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
    x['holidayDate'] = newdate;

    console.log(JSON.stringify(x))
    if (this.data.id) {
      x['holidayId'] = this.data.id
      x['holidayStatus'] = Number(x.holidayStatus)
      x['holidaytype'] = Number(x.holidaytype)
      this.gs.UpdateHoliday(this.data.id, x).subscribe(
        (data: any) => {
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          setTimeout(()=>{
            window.location.reload()
          },2000)

        },
        err => {
          Swal.fire({
            text: err.err.message,
            icon: 'error'
            })
            setTimeout(()=>{
              window.location.reload()
            },2000)
        }
      )
    }
    else {
      if(x.leaveType == 0 ){
        x['holidayStatus'] = 1
        x['holidaytype'] = Number(x.holidaytype)
        this.gs.createHoliday(x).subscribe(
          (data: any) => {
            Swal.fire({
              text: data.description,
              icon: 'success'
            })
            this.dialogRef.close()
            setTimeout(()=>{
              window.location.reload()
            },2000)
          },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
            this.dialogRef.close()
          }
        )
      }
      if(x.leaveType == 1 ){
        let y = this.holidayFrom.value;
        y['exceptionName'] = y.holidayName;
        var dato = new Date(y.holidayDate);
        var newdato = (dato.getMonth() + 1) + '-' + dato.getDate() + '-' + dato.getFullYear();
        y['exceptionDate'] = newdato;
        y['exceptionStatus'] = 1 ;
        y['exceptionType'] = Number(y.holidaytype)
        y['calender'] ={
          calendarId :this.data.groupId
        }
        this.gs.createException(y).subscribe(
          (data: any) => {
            Swal.fire({
              text: data.description,
              icon: 'success'
            })
            this.dialogRef.close()
            setTimeout(()=>{
              window.location.reload()
            },2000)
          },
          err => {
            Swal.fire({
              text: err.error.message,
              icon: 'error'
            })
            this.dialogRef.close()
          }
        )
      }
    }

  }
}

@Component({
  selector: 'ExceptionHoliday',
  templateUrl: 'ExceptionHoliday.html',
})
export class ExceptionHoliday implements OnInit {

  exceptionForm:FormGroup;
  exceptionType:any =[]
  dateDisable:boolean;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private gs: GlobalserviceService,
  private fb: FormBuilder,
  public dialog: MatDialog,
  public dialogRef: MatDialogRef<AddHoliday>
) { }

  ngOnInit(){
    this.exceptionForm = this.fb.group({
      exceptionName:['',Validators.required],
      exceptionDate:['',Validators.required],
      exceptionType:['',Validators.required],
      calender:['',Validators.required],
      exceptionStatus:['',Validators.required]
    })

    this.gs.getListTypeById(6).subscribe(
      (d: any) => {
        this.exceptionType = d;
      }
    )

    if (this.data.id) {
      this.dateDisable = true
      this.gs.getExceptionById(this.data.id).subscribe(
        (d:any)=>{
          this.exceptionForm.patchValue({
            exceptionStatus:d.exceptionStatus.toString()
          })
        }
      )
      this.exceptionForm.patchValue({
        exceptionId: this.data.id,
        exceptionName: this.data.title,
        exceptionDate: this.data.start,
        exceptionType: Number(this.data.holidayType),
        calender: {
          calendarId: Number(this.data.groupId)
        }
      })
    }
  }

  get f(){
    return this.exceptionForm.controls
  }



  onSubmit(){
    if(this.exceptionForm.invalid){
      return
    }
    let x = this.exceptionForm.value
    var date = new Date(x.exceptionDate);
    var newdate = (date.getMonth() + 1) + '-' + date.getDate() + '-' + date.getFullYear();
    x['exceptionDate'] = newdate;
    x['exceptionStatus'] = Number(x.exceptionStatus)

    if (this.data.id) {
      console.log(x)
      x['exceptionId'] = this.data.id
      this.gs.UpdateException(this.data.id, x).subscribe(
        (data: any) => {
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          this.dialogRef.close()
          setTimeout(()=>{
            window.location.reload()
          },2000)

        },
        err => {
          Swal.fire({
            text: err.err.message,
            icon: 'error'
          })
          this.dialogRef.close()
          setTimeout(()=>{
            window.location.reload()
          },2000)
        }
      )
    }
  }
}

@Component({
  selector: 'WorkingDays',
  templateUrl: 'WorkingDays.html',
})
export class WorkingDays implements OnInit {

  @ViewChild('select') select: MatSelect;
  workDaysForm: FormGroup;
  calendarList: any = []
  allSelected=false;
  foods: any[] = [
    {viewValue: 'Monday', value: '1'},
    {viewValue: 'Tuesday', value: '2'},
    {viewValue: 'Wednesday', value: '3'},
    {viewValue: 'Tursday', value: '4'},
    {viewValue: 'Friday', value: '5'},
    {viewValue: 'Saturday', value: '6'},
    {viewValue: 'Sunday', value: '0'}
  ];
  workingDayId: any
  calendarName: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private gs: GlobalserviceService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MyCalendar>
  ) { }

  ngOnInit() {
    this.workDaysForm = this.fb.group({
      workingdays: ['', Validators.required],
      calendarworkingdaysStatus: 1,
      calender: ['', Validators.required]
    })

    this.gs.getCalendar().subscribe(
      (x: any) => {
        x.map(e=>{
          if(e.calendarStatus == 1){
            this.calendarList.push(e)
          }
        })
      }
    )

    this.calendarName = this.data.calendarName

    if(this.data.calendarId){
      this.data.calendarWorkingDays.map(e=>{
        this.workingDayId = e.calendarworkingdaysId
        this.workDaysForm.patchValue({
          workingdays:e.workingdays,
          calender:this.data.calendarId
        })
      })
    }
    else{
      this.workDaysForm.patchValue({
        calender:this.data.id
      })
    }
  }

  get f() {
    return this.workDaysForm.controls
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  noNumber(event: any) {
    const pattern = /[0-9]|\./;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  add(){
    let x = this.workDaysForm.value;
    if(x.workingdays == ''){
      alert("Please select the options")
    }
    else{
      this.select.close()
    }
  }

  cancel(){
    this.workDaysForm.patchValue({workingdays:''})
    this.select.close()
  }

  onSubmit() {

    if (this.workDaysForm.invalid) {
      return
    }

    let x = this.workDaysForm.value;

    x['calender'] = {
      calendarId: x.calender
    }

    if(this.data.calendarId){
      x['calendarworkingdaysId'] = this.workingDayId
      console.log(JSON.stringify(x))
      this.gs.updateWoringDays(this.workingDayId,x).subscribe(
        (data: any) => {
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          this.dialogRef.close()
          setTimeout(()=>{
            window.location.reload()
          },2000)
        },
        err => {
          Swal.fire({
            text: err.error.message,
            icon: 'error'
          })
          this.dialogRef.close()
        }
      )
    }
    else{
      this.gs.createWoringDays(x).subscribe(
        (data: any) => {
          Swal.fire({
            text: data.description,
            icon: 'success'
          })
          this.dialogRef.close()
          setTimeout(()=>{
            window.location.reload()
          },2000)
        },
        err => {
          Swal.fire({
            text: err.error.message,
            icon: 'error'
          })
          this.dialogRef.close()
        }
      )
    }

  }
}
