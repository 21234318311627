<span>
  <h4 class="dynamic-title">Add / Manage Employee</h4>
</span>
<ng-container *ngIf="loader; else elseLoading" class="loading">
  <img src="assets/img/Loader3.gif" alt="LoaderImage" class="center">
</ng-container> 
<ng-template #elseLoading>
<div class="container-fluid" style="padding: 5px 15px 0px 15px;">
  <!-- <div class="float-right">
    <button mat-raised-button style="transform: translateY(10px);" [routerLink]="['../employee-create']"
      *ngIf="isHr else employee">
      <mat-icon class="material-icons-outlined">person_add_alt</mat-icon>
      Add Employee
    </button>
    <ng-template #employee></ng-template>
    <button mat-raised-button style="transform: translateY(10px);" [routerLink]="['../branch']"> 
      <span class="material-icons-outlined">business</span>
      Create Branch</button>
  </div> -->
        <!-- button -->

  <div class="float-left">
    <button mat-stroked-button class="apply-button ml-0" [routerLink]="['../employee-create']"
      *ngIf="isHr else employee" color="primary" >
      <mat-icon class="material-icons-outlined apply-button-icon">person_add_alt</mat-icon>
      Add Employee</button>
    <ng-template #employee></ng-template>
    <button mat-stroked-button class="apply-button mr-0" [routerLink]="['../branch']" color="primary" >
      <mat-icon class="material-icons-outlined apply-button-icon">business</mat-icon>
      Create Branch</button>
  </div>

  <div class="float-right">
    <!-- <button mat-raised-button>
      <mat-icon>search</mat-icon>
    </button>
    <mat-form-field style="line-height: 1.6;margin: 0 0 0 8px;  ">
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event)">
    </mat-form-field> -->
    <div class="form-group search-input topnav-right">                                                                     
      <input matInput class="search form-control" type="text" placeholder="Search.." (keyup)="applyFilter($event)" name="search">
      <button mat-raised-button class="search-btn">
        <mat-icon class="search-icon">search</mat-icon>
      </button>                                                                                                  
    </div>
  </div>
  <span> </span>
</div>
<ng-container>
  <div class="example-container mat-elevation-z8 main-block">
    <table class="table-responsive" mat-table [dataSource]="dataSource" matSort *ngIf="list_count>0">
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef class="text-center">
          <b>S.No</b>
        </th>
        <td mat-cell class="text-center" *matCellDef="let element; let i = index">{{i+1}}</td>
      </ng-container>  
      <ng-container matColumnDef="employeeNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <b>{{'Emp&nbsp;No'}}</b>
        </th>
        <td mat-cell *matCellDef="let element">{{element.employeeNumber}} </td>
      </ng-container> 
      <ng-container matColumnDef="employeeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <b>{{'Emp&nbsp;Name'}}</b>
        </th>
        <td mat-cell *matCellDef="let element">{{element.employeeName | titlecase}}</td>
      </ng-container>

      <ng-container matColumnDef="organizationEmail">
        <th mat-header-cell *matHeaderCellDef>
          <b>{{'Organization&nbsp;Email'}}</b>
        </th>
        <td mat-cell *matCellDef="let element">{{element.organizationEmail}}</td>
      </ng-container>

      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>
          <b>{{'Contact'}}</b>
        </th>
        <td mat-cell *matCellDef="let element">{{element.mobileNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="designation">
        <th mat-header-cell *matHeaderCellDef>
          <b>{{'Designation'}}</b>
        </th>
        <td mat-cell *matCellDef="let element">{{element.designation}}</td>
      </ng-container>

      <ng-container matColumnDef="userType">
        <th mat-header-cell *matHeaderCellDef>
          <b>{{'User&nbsp;Type'}}</b>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.userType.userType}}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <b>{{'Status'}}</b>
        </th>
        <td mat-cell *matCellDef="let element" [style.color]="statuscolor(element.status)">{{element.status}}</td>
      </ng-container>

      <ng-container matColumnDef="star">
        <th mat-header-cell *matHeaderCellDef class="text-center"></th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-center">
          <ng-container *ngIf="isHr; else elseTemplate">
            <button mat-icon-button [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu">
              <mat-icon style="font-size: 17px;">more_vert</mat-icon>
            </button>
          </ng-container>
          <ng-template #elseTemplate>
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" disabled>
              <mat-icon style="font-size: 17px;">more_vert</mat-icon>
            </button>
          </ng-template>

          <mat-menu #menu="matMenu">
            <a mat-menu-item (click)="openDialog(element)">
              <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#5E06D0">visibility</mat-icon>
              <span class="cancel-trip-text" style="color:#5E06D0">Leave History</span>
            </a>
            <a mat-menu-item (click)="edit(element)">
              <mat-icon class="cancel-trip-icon material-icons-outlined" style="color:#1A83FF">create</mat-icon>
              <span class="cancel-trip-text" style="color:#1A83FF">Edit</span>
            </a>
            <!-- <a mat-menu-item (click)="delete(element)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </a> -->
            <a mat-menu-item (click)="statusupdate(element)">
              <mat-icon *ngIf="element.status=='Active'" class="cancel-trip-icon material-icons-outlined" style="color:#FF7171">block</mat-icon>
              <span *ngIf="element.status=='Active'" class="cancel-trip-text" style="color:#FF7171">In-Active</span>
              <mat-icon *ngIf="element.status=='In-Active'" class="cancel-trip-icon material-icons-outlined" style="color:#01E325">done</mat-icon>
              <span *ngIf="element.status=='In-Active'" class="cancel-trip-text" style="color:#01E325">Active</span>
            </a>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns ;  sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <div class="container" *ngIf="nodata">
        <div class="alert alert-primary" style="width: 100%; justify-content: center;">
          No Employees Found
        </div>
    </div>
  </div>
</ng-container>
</ng-template>

<div [hidden]="loader">
  <mat-paginator style="bottom: 0; position: sticky;" [pageSizeOptions]="[ 5,10, 20, 50, 100]" showFirstLastButtons>
  </mat-paginator>
</div>
