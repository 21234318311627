import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-myleave-bal',
  templateUrl: './myleave-bal.component.html',
  styleUrls: ['./myleave-bal.component.css']
})
export class MyleaveBalComponent implements OnInit {
  data:any[] =  []
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns =
  ['leaveName','noOfDays'];
  dataSource:any;
  list_count = 0;
  noData=false;
  loader=true;
  //  dataSource = ELEMENT_DATA;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
   constructor(private gs:GlobalserviceService, private router: Router) {
   }
  ngOnInit() {
    
      let x = JSON.parse(localStorage.getItem('data'));
      let id = x.employeeId
      console.log(id);
      this.gs.GetMYleaveBalance(id).subscribe(
        a => {
          this.loader=false;
          console.log("Success");
          if(a.length==0)
          {
            this.noData=true;
            console.log("NoData");
          }
          this.data = a;
          console.log(this.data);
          this.dataSource = new MatTableDataSource<any>(this.data);
          this.dataSource.filterPredicate = (data, filter) => {
            const dataStr = data.leaveType.leaveName + data.noOfDays;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.list_count = this.data.length;
        },
        (error) =>
      {
        this.loader=false;
        this.noData= true;
        console.log("error");
        if(error.statusCode==204)
        {
           this.noData=true;
           console.log("204");
        }
      }
      )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  edit(i) {
    console.log(i)
    this.router.navigate(['Home/type-edit', i.leaveTypeId])

  }
  delete(i) {
    console.log(i)
    Swal.fire({
      title: 'Are you sure? Do you want to Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {

          this.gs.deleteLeaveType(i.leaveTypeId).subscribe(
            d => {
              Swal.fire(
                'done!',
                'Your file has been deleted.',
                'success'
              )
              this.gs.getLeaveType().subscribe( (d:any) => {
                console.log(d)
                this.data = d
                this.dataSource = new MatTableDataSource<any>(this.data);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              })
            },
            e => {
              Swal.fire(
                'Error!',
                'Somthing went wrong',
                'error'
              )
            },
            () => console.log('done')
          )
      }
      //
    })
  }
}
