import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { DatePipe} from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-leave-adjustment-list',
  templateUrl: './leave-adjustment-list.component.html',
  styleUrls: ['./leave-adjustment-list.component.css']
})
export class LeaveAdjustmentListComponent implements OnInit {
   noData=false;
  list_count = 0
  data: any[] = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['employeeNumber', 'employeeName', 'organizationEmail', 'LeaveType', 'leaveadjustmentdays', 'leaveadjustmenttype'];
  dataS: any;
  branchId:any
  organization:any
  loader=true;

  leaveReport:FormGroup;
  startdate: any;
  enddate: string;
  submitted: boolean = false;
  min: string;
  show_EMsg:boolean = false;
  startdate_required:boolean = false;
  enddate_required:boolean = false;
  max: string;
  employeelist: any;
  leavetypelist:any
  employeeId: any;
  orgId:any;
  all_null:boolean = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  leaveType: any;
  constructor(private gs: GlobalserviceService, 
    private router: Router,
    private fb: FormBuilder,
    public datepipe:DatePipe) {
  }

  ngOnInit() {
    let data = JSON.parse(localStorage.getItem('data'));

    this.gs.activeemployeelist().subscribe(
      (d:any)=>{
        console.log(d);
        this.employeelist = d;
      })
    this.gs.getLeaveType().subscribe(
      (d: any) => {
        console.log(d);
        this.leavetypelist = d;
    })
    this.leaveReport = this.fb.group({
      employeeId:[''],
      leaveType:[''],
    });

    this.gs.getEmpListById(data.employeeId).subscribe(
      (d:any)=>{
        this.loader=false;
        if(d.length==0)
            {
              this.noData =true;
              console.log("NoData");
            }
        this.organization = d.organization.organizationId
        this.gs.getAdjustment(this.organization).subscribe(
          (d: any) => {
            this.loader=false;
            if(d.length==0)
            {
              this.noData =true;
              console.log("NoData");
            }
            this.data = d
            this.dataS = new MatTableDataSource<any>(d);
            this.dataS.filterPredicate = (data, filter) => {
              const dataStr = data.leaveBalance.employee.employeeNumber + data.leaveBalance.employee.employeeName + data.leaveBalance.employee.organizationEmail
              +data.leaveBalance.leaveType.leaveName + data.leaveadjustmentdays + data.leaveadjustmenttype;
              return dataStr.trim().toLowerCase().indexOf(filter) != -1;
            }
            this.dataS.paginator = this.paginator;
            this.list_count = this.data.length;
          },
          (error) =>
        {
          this.loader=false;
           this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
        }
          )
      },
      (error) =>
        {
          this.loader=false;
           this.noData= true;
          console.log("error");
          if(error.statusCode==404)
          {
             this.noData=true;
             console.log("404");
          }
        }
    )

  }
  // status color
  statuscolor(i:any) {
    if (i == null) {
      return
    }
    if (i == 'add') {
      return '#28a745'
    }
    if (i == 'detect') {
      return '#f44336'
    }
  }
  search(){
    this.submitted = true;
    console.log(this.leaveReport.value);
    if(this.leaveReport.invalid){
      return;
    }
    this.leaveType= this.leaveReport.value.leaveType;
    console.log(this.leaveType);
    this.employeeId = this.leaveReport.value.employeeId;
    console.log(this.employeeId);

    if(this.leaveType == 0 || this.leaveType == '' || this.leaveType == null){
      if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
        this.all_null=true;
        setTimeout(() => {
          this.all_null = false;
        }, 2000)
        return
      }
    }
    //this.loader=true;
    if(this.employeeId == 0 || this.employeeId=='' || this.employeeId== null){
      console.log("show leave type");
      this.gs.leaveadjustmentByLeavetype(this.leaveType).subscribe(
        (d:any) => {
             console.log(d);
          this.loader=false;
          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            const dataStr = data.employee.employeeName + data.employee.organizationEmail
            + data.employee.employeeNumber + data.employee.mobileNumber
            +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.list_count = this.data.length;
        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
        }
      )
    }
    else if(this.leaveType == 0 || this.leaveType == '' || this.leaveType == null){
      console.log("show Emp type");
      this.gs.leaveadjustmentByEmp(this.employeeId).subscribe(
        (d:any) => {
             console.log(d);
          this.loader=false;
          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            const dataStr = data.employee.employeeName + data.employee.organizationEmail
            + data.employee.employeeNumber + data.employee.mobileNumber
            +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.list_count = this.data.length;
        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
        }
      )
    }
    else{
      console.log("show all");
      this.gs.leaveadjustmentByEmpAndLeavetype(this.leaveType,this.employeeId).subscribe(
        (d:any) => {
             console.log(d);
          this.loader=false;
          this.noData =false;
          if(d.length==0)
          {
            this.noData = true;
            console.log("NoData");
          }
          if(d.statusCode == 204){
            this.noData = true;
            console.log("NoData");
          }
          this.data = d
          this.dataS = new MatTableDataSource<any>(d);
          this.dataS.paginator = this.paginator;
          this.dataS.filterPredicate = (data, filter) => {
            const dataStr = data.employee.employeeName + data.employee.organizationEmail
            + data.employee.employeeNumber + data.employee.mobileNumber
            +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
            return dataStr.trim().toLowerCase().indexOf(filter) != -1;
          }
          this.list_count = this.data.length;
        },
        (error) =>
        {
          this.loader=false;
          this.noData= true;
          console.log("error");
          if(error.statusCode==204)
          {
             this.noData=true;
             console.log("204");
          }
        }
      )
    }
  }
  Clear(){
    this.noData =false;
    this.enddate_required = false;
    this.show_EMsg = false;
    this.startdate_required = false;
    this.all_null=false;
      this.gs.getAdjustment(this.organization).subscribe(
      (d: any) => {
        this.loader=false;
        if(d.length==0)
        {
          this.noData =true;
          console.log("NoData");
        }

        this.data = d
        this.dataS = new MatTableDataSource<any>(d);
        console.log(this.dataS);
        this.dataS.paginator = this.paginator;
        this.dataS.filterPredicate = (data, filter) => {
          const dataStr = data.employee.employeeName + data.employee.organizationEmail
          + data.employee.employeeNumber + data.employee.mobileNumber
          +data.startDate + data.endDate + data.noOfLeaveDays + data.leaveStatusName;
          return dataStr.trim().toLowerCase().indexOf(filter) != -1;
        }
        this.list_count = this.data.length;
      },
      (error) =>
      {
        this.loader=false;
        this.noData= true;
        console.log("error");
        if(error.statusCode==204)
        {
           this.noData=true;
           console.log("204");
        }
      }
      )
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    console.log("Search");
    this.dataS.filter = filterValue.trim().toLowerCase();
    if(!isNaN(Date.parse(filterValue)))
    {
       console.log("date");
       const searchDate = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
       console.log(searchDate);
       this.dataS.filter = searchDate .trim().toLowerCase();
       if (this.dataS.paginator) 
       {
          this.dataS.paginator.firstPage();
       }
    }
    else
    {
      console.log("Number");
      this.dataS.filter = filterValue .trim().toLowerCase();
      // this.dataSource.filter = filterValue .trim().toLowerCase();
     if (this.dataS.paginator) {
      this.dataS.paginator.firstPage();
    }
    }
    // if (this.dataS.paginator) {
    //   this.dataS.paginator.firstPage();
    // }
  }
}
