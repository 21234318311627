import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-leave',
  templateUrl: './share-leave.component.html',
  styleUrls: ['./share-leave.component.css']
})
export class ShareLeaveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
