<span>
  <h4 class="dynamic-title">{{label | titlecase}}</h4>
</span>
<div class="col-sm-9">
  <mat-card>
    <!-- <mat-card class="header">
      <p> <span class="material-icons">
          perm_identity
        </span>{{label | titlecase}} </p>
    </mat-card> -->
    <div class="container">
      <form action="" [formGroup]="leaveTypeForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-12">
            <h3 class="title">*{{label | titlecase}}*</h3>
          </div>
        </div>
        <!-- LeaveName -->
        <div class="form-group">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label class="form-label">Leave Name</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b" matPrefix>double_arrow</mat-icon>
            <input type="text" matInput placeholder="Enter leave Name!!" formControlName="leaveName"
              [ngClass]="{ 'is-invalid': submitted && f.leaveName.errors }">
            <mat-error *ngIf="f.leaveName.hasError('required')">
              Please Enter <strong>Leave Name</strong>
            </mat-error>
          </mat-form-field>
        </div>



        <div class="form-group" *ngIf="id">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label class="form-label">Leave Status</mat-label>
            <mat-icon class="form-icon" style="color: #6d6b6b;" matPrefix>double_arrow</mat-icon>
            <mat-select formControlName="status">
              <mat-option value="1">Active</mat-option>
              <mat-option value="2">InActive</mat-option>
            </mat-select>
            <mat-error *ngIf="f.status.hasError('required')">
              Please select <strong>Leave Status</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Number Of Days -->
        <div class="form-group">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label class="form-label">Select number of days</mat-label>
            <mat-icon  class="form-icon" style="color: #6d6b6b;" matPrefix>double_arrow</mat-icon>
            <mat-select style="color: red" placeholder="Enter Number Of Days" formControlName="noOfDays">
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="9">9</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="11">11</mat-option>
              <mat-option value="12">12</mat-option>
              <mat-option value="13">13</mat-option>
              <mat-option value="14">14</mat-option>
              <mat-option value="15">15</mat-option>
              <mat-option value="16">16</mat-option>
              <mat-option value="17">17</mat-option>
              <mat-option value="18">18</mat-option>
              <mat-option value="19">19</mat-option>
              <mat-option value="20">20</mat-option>
              <mat-option value="21">21</mat-option>
            </mat-select>
            <mat-error *ngIf="f.noOfDays.hasError('required')">
              Please Select <strong>Number Of Days</strong>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- <div class="form-group" align="center">
          <button mat-stroked-button color="warn" style="width: 45%; margin: 0 0 0 8px;" type="button"
            [routerLink]="['../../type-list']">Cancel</button>
          <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit">
            <span class="material-icons">
              beenhere
            </span> {{button_label | titlecase}}
          </button>
        </div> -->

        <!-- button -->
        <div class="form-group">
          <div class="d-flex">
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="cancel-button" color="accent" type="button"
              [routerLink]="['../../type-list']">
                <span class="material-icons-outlined cancel-button-icon">close</span>Cancel
              </button>
            </div>
            <div style="width: 45%;margin: 0 auto;">
              <button mat-stroked-button class="apply-button" color="primary" type="submit">
                <span class="material-icons-outlined apply-button-icon">done</span>&nbsp;{{button_label | titlecase}}&nbsp;
              </button>
            </div>
          </div>
        </div>

      </form>
    </div>
  </mat-card>
</div>
