import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Reason } from '../../withdraw-list/withdraw-list.component';
import { leavedenyreason } from '../../withdraw-list/withdraw-list.component';
import { Console } from 'console';

@Component({
  selector: 'app-wfh-list',
  templateUrl: './wfh-list.component.html',
  styleUrls: ['./wfh-list.component.css']
})
export class WfhListComponent implements OnInit {
  list_count = 0
  noData=false;
  data: any[] = []
  isLoading:Boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['sno','startDate', 'endDate', "noOfDays","reason", "workFromHomeStatus", 'star',];
  dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  changeasDenyReason: boolean=false;
  isMenuOpen: boolean = false;
  clickedbusinessTravelId: any;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  constructor(private gs: GlobalserviceService, private router: Router, public dialog: MatDialog,private renderer: Renderer2) {
    // this.renderer.listen('window', 'click',(e:Event)=>{
    //   if(e.target !== this.toggleButton.nativeElement && e.target!==this.menu.nativeElement){
    //       this.isMenuOpen=false;
    //       console.log(this.isMenuOpen);
    //   }
    // });
  }
  ngOnInit() {
    console.log("success");
    let x = JSON.parse(localStorage.getItem("data"))
    let id = x.employeeId;
    this.gs.get_myWfh(id, 1).subscribe((d: any) => {
      this.loader=false;
      if(d.length==0)
      {
        this.noData=true;
        console.log("noData");
      }
      if(d.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      this.list_count = this.data.length
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  Reason(i) {
    console.log(i);
    this.dialog.open(Reason, {
      data: i.reason
    });
  }
  showdenyreason(i){
    console.log(i)
    let x = i;
    this.dialog.open(leavedenyreason, {
      data: x
    });
  }
  denyReason(e){
    console.log(e);
    this.clickedbusinessTravelId = e;
    this.isMenuOpen = !this.isMenuOpen;
    console.log(this.isMenuOpen);
    if(this.isMenuOpen==true){
      this.changeasDenyReason=true;
      console.log(this.changeasDenyReason);
    }
    else{
      this.changeasDenyReason=false;
      console.log(this.changeasDenyReason);
    }
  }
  cancel(e){
    e['workFromHomeStatus'] = 8
    Swal.fire({
      title: 'Are you sure? Do you want to Cancel Work From Home Request!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor:'#28a745',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if(result.value){
        this.isLoading = true;
        this.gs.Actionwfh(e.workFromHomeId,e).subscribe(
          d=>{
            Swal.fire({
              text:'Cancelled',
              icon:'success'
            })
            this.isLoading = false
            window.location.reload()
          },
          err =>{
            Swal.fire({
              text:err.error.message,
              icon:'error'
            })
            this.isLoading = false
          }
        )
      }
    })
  }

  // status color
  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
    if(i == 'Cancelled'){
      return  '#e40000'
    }
  }
}

