import { Component, OnInit,Inject,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { dialog } from '../leave-req/leave-req.component';
import { ElementSchemaRegistry } from '@angular/compiler';

@Component({
  selector: 'app-balancedetails',
  templateUrl: './balancedetails.component.html',
  styleUrls: ['./balancedetails.component.css']
})
export class BalancedetailsComponent implements OnInit {
  list_count = 0;
  showTable:boolean=false;
  noData=false;
  // data: any;
  data: any[] = []

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // displayedColumns =
  //   ['EmployeeNumber','EmployeeName','organizationEmail','MobileNumber','permissionDate', "permissionHours", "workFromHomeStatus"];
  displayedColumns =
    ['EmployeeNumber','EmployeeName','OrganizationEmail','Balance'];
  dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  dataS: any;
  loader=true;
  stDate:any;
  edDate:any;
  enddate_required:boolean = false;
  startdate_required:boolean = false;
  startdate_validation: boolean = false;
  enddate_errror: boolean = false;
  isSubmitted = false;
  leaveReport:FormGroup;
  min: string;
  show_EMsg:boolean = false;
  max: string;
  submitted: boolean = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  startdate: string;
  enddate: string;
  employeelist: any;
  employeeId: any;
  orgId:any;
  all_null:boolean = false;
  option: string;
   selectedOption: any;
  selected = 'option2';
  yearlist: any;
  maxDate: string;
  hours: number;
  minutes: number;
  employeeNumber:number;
  optionValue: any;
  balance: string;
  
  constructor(
    private gs: GlobalserviceService, 
    private router: Router, 
    public dialog: MatDialog,
    public fb:FormBuilder,
    private datepipe:DatePipe
  )
  { 

  }

  ngOnInit(): void {
    let x = JSON.parse(localStorage.getItem("data"))
    console.log(x);
    // console.log(this.leaveReport.value);
    let id = x.employeeId;
    console.log(this.option);
    this.gs.activeemployeelist().subscribe(
      (d:any)=>{
        console.log(d);
        this.employeelist = d;
      })

      this.gs.activeyearlist().subscribe(
        (d:any)=>{
          console.log(d);
          this.yearlist = d;
        })


    this.leaveReport = this.fb.group({
      startdate:[''],
      enddate:[''],
      employeeId:[''],
      year:[''],
      option:['',Validators.required],
    });
 
    //  this.selectedOption = 'Leave';
    this.leaveReport.patchValue
    ({
      // employeeId:'Leave',
      option:'Permission',
    })

    if(this.leaveReport.value.option=="Permission")
    {
      // this.isselected(this.leaveReport.value.option);
      this.leaveReport.get('employeeId').setValidators([Validators.required]);
      this.leaveReport.get('startdate').setValidators([Validators.required]); 
      this.leaveReport.get('enddate').setValidators([Validators.required]); 
      console.log("Vaildator in Permission");
      // this.leaveReport.get('startdate').setValidators([Validators.required ]); 
      // this.leaveReport.get('enddate').setValidators([Validators.required ]); 
    }
    console.log(this.leaveReport.value);
    console.log(x.organization.organizationId);
    this.orgId=x.organization.organizationId;
    console.log(this.orgId);
    this.loader=false;
    console.log(this.leaveReport.value.option);
  }

 

  isselected(event)
  {
     console.log(event.value);
     this.option=event.value;
     console.log(this.option);
     if(this.option=="Permission")
      {
        console.log("Permission Vaildator");
        this.leaveReport.get('employeeId').setValidators([Validators.required]);
        this.leaveReport.get('startdate').setValidators([Validators.required]); 
        this.leaveReport.get('enddate').setValidators([Validators.required]); 
        this.leaveReport.get('year').clearValidators();
        this.leaveReport.get('year').updateValueAndValidity(); 
      }
      else
      {
        // this.leaveReport.reset();
        console.log("Leave Vaildator");
        this.leaveReport.get('employeeId').setValidators([Validators.required]);
        this.leaveReport.get('year').setValidators([Validators.required]); 
        //  this.leaveReport.get('year').updateValueAndValidity();
        this.leaveReport.get('startdate').clearValidators(); 
        this.leaveReport.get('startdate').updateValueAndValidity();
        this.leaveReport.get('enddate').clearValidators();
        this.leaveReport.get('enddate').updateValueAndValidity(); 
      }
  }

  
  

  // if (this.option==Permission') 
  // {
  //   this.leaveReport.get('startdate').setValidators(Validators.required);
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    console.log("Search");
    // if(isNaN(s) && !isNaN(Date.parse(s)))
    // return true;
    // else return false;
    if(!isNaN(Date.parse(filterValue)))
    {
       console.log("date");
       const searchDate = this.datepipe.transform(filterValue, 'yyyy-MM-dd');
       console.log(searchDate);
       this.dataSource.filter = searchDate .trim().toLowerCase();
       if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
    else
    {
      console.log("Number");
      this.dataSource.filter = filterValue .trim().toLowerCase();
      // this.dataSource.filter = filterValue .trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  withDrawStatus(i) {
    if (i == null) {
      return
    }
    if (i == 'Pending') {
      return '#8313ff'
    }
    if (i == 'Approved') {
      return '#28a745'
    }
    if (i == 'Denied') {
      return '#f44336'
    }
    if (i == 'Cancelled') {
      return '#e40000'
    }
  }

  startDate(event){
    this.startdate_required=false;
    console.log("start Date:"+event.value);
    this.show_EMsg = false;
    this.min = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    console.log("this.min:"+this.min);
    if(this.max != null){
      if(this.max < this.min){
        this.show_EMsg = true;
      }
    }
    const currentDate = new Date(this.min);
    console.log(currentDate);
    // Get the last day of the current month
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    console.log(lastDayOfMonth);
    this.maxDate = this.datepipe.transform(lastDayOfMonth, 'yyyy-MM-dd');
    console.log("maxDate:"+this.maxDate);
  }
   
  EndDate(event){
    this.enddate_required=false;
    this.show_EMsg = false;
    console.log("start Date:"+event.value);
    this.max = this.datepipe.transform(event.value, 'yyyy-MM-dd');
    console.log("this.min:"+this.max);
  }

  // getFormattedBalance(balance: string): Date {
  //   console.log(balance);
  //   const [hours, minutes] = balance.split(':');
  //   console.log(balance);
  //   const totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
  //   console.log(totalMinutes);
  //   return new Date(0, 0, 0, 0, totalMinutes);
  // }

   getFormattedBalance(balance: string) 
   {
    console.log(balance);
    const [hoursPart, minutesPart] = balance.split(':');
    this.hours = parseInt(hoursPart, 10);
    this.minutes = parseInt(minutesPart, 10);
//     const paddedHours = hours.toString().padStart(2, '0');
// const paddedMinutes = minutes.toString().padStart(2, '0');
    console.log(this.hours.toString().padStart(2, '0')+":"+this.minutes.toString().padStart(2, '0'));
    this.balance=this.hours.toString().padStart(2, '0')+":"+this.minutes.toString().padStart(2, '0');
    console.log("Ban:"+this.balance);
    return this.balance;
   }

  search()
  {
    console.log("Option"+this.leaveReport.value.option);
    console.log("Option"+this.leaveReport.value.startdate);
    console.log("Option"+this.leaveReport.value.enddate);
    console.log("Option"+this.leaveReport.value.year);
    this.data=[];
    this.submitted = true;
    console.log(this.leaveReport);
    console.log(this.leaveReport.value);
    if(this.leaveReport.invalid){
      console.log("invaild",this.leaveReport.value);
      return;
    }
    this.startdate = this.datepipe.transform(this.leaveReport.value.startdate,'yyyy-MM-dd');
    console.log(this.startdate);
    this.enddate = this.datepipe.transform(this.leaveReport.value.enddate,'yyyy-MM-dd');
    console.log(this.enddate);
    this.employeeId = this.leaveReport.value.employeeId;
    console.log(this.employeeId);
    console.log(this.leaveReport.value.option);
    this.optionValue=this.leaveReport.value.option;
    console.log(this.optionValue);
    if(this.leaveReport.value.option=="Permission"&&this.leaveReport.value.option!=null)
    {
      console.log("Permission"+this.leaveReport.value.employeeId);
      console.log("min Start Date"+ this.min);
      console.log("Max End Date"+this.max);
      if((this.min!=null||this.min!="")&&(this.max!= null||this.max!="")&&(this.leaveReport.value.employeeId!=null||this.leaveReport.value.employeeId!="")&&(this.leaveReport.value.startdate!=null||this.leaveReport.value.startdate!="null")&&(this.leaveReport.value.enddate!=null||this.leaveReport.value.enddate!="null"))
      {
        if(this.employeeId=="All")
        {
           console.log("Totally All Employee");
           this.gs.filterAllBalancePermissionList(this.min,this.max,this.orgId).subscribe(
            (d:any) => {
              console.log(d);
              this.loader=false;
              this.noData =false;
              // if(d.length==0)
              // {
              //   this.noData = true;
              //   console.log("Data");
              // }
              if(d.statusCode==204)
              {
                this.noData = true;
                this.showTable=false;
                console.log("NoData");
              }
              else if(Array.isArray(d))
              {
                this.data = d;
                console.log(this.data);
                this.dataSource = new MatTableDataSource<any>(this.data);
                console.log(this.dataSource);
                this.dataSource.filterPredicate = (data, filter) => {
                 const dataStr = data.employeeNumber + data.employeeName+ data.organizationEmail
                 +data.balance;
                 return dataStr.trim().toLowerCase().indexOf(filter) != -1;
               }
               this.dataSource.paginator = this.paginator;
              //  this.paginator.pageSize = 5; 
               this.dataSource.sort = this.sort;
               this.list_count = this.data.length;
               console.log(this.list_count);
               if(this.list_count>0)
               {
                this.showTable=true;
               }
              }
            },
            (error) =>
            {
              this.loader=false;
              this.noData= true;
              this.showTable=false;
              console.log("error");
              if(error.statusCode==204)
              {
                 this.noData=true;
                 console.log("204");
              }
            }
          )
        }
        else
        {
          console.log("Single Employee only");
          // console.log("Single Employee only");
          this.gs.filterBalancePermissionList(this.min,this.max,this.employeeId).subscribe(
            (d:any) => {
              console.log(d);
              this.loader=false;
              this.noData =false;
              // if(d.length==0)
              // {
              //   this.noData = true;
              //   console.log("Data");
              // }
              if(d.statusCode==204)
              {
                this.noData = true;
                this.showTable=false;
                console.log("NoData");
              }
              // this.data = d;
              else if (typeof d=== 'object' && !Array.isArray(d && d !== null))
              {
              this.data.push(d);
              console.log(this.data);
              this.dataSource = new MatTableDataSource<any>(this.data);
              console.log(this.dataSource);
              this.dataSource.filterPredicate = (data, filter) => {
              //   const dataStr = data.employee.employeeNumber + data.employee.employeeName + data.employee.organizationEmail
              //  +data.Balance;
              const dataStr = data.employeeNumber + data.employeeName+ data.organizationEmail
               +data.balance;
               return dataStr.trim().toLowerCase().indexOf(filter) != -1;
             }
             this.dataSource.paginator = this.paginator;
            //  this.paginator.pageSize = 5; 
             this.dataSource.sort = this.sort;
            this.list_count = this.data.length;
            // if(this.list_count)
            // if(this.data[0].statusCode==204)
            //  {
            //   this.showTable=false;
            //  console.log(this.list_count);
            //  }
            if(this.list_count>0)
            {
             this.showTable=true;
            }
            }
            },
            (error) =>
            {
              this.loader=false;
              this.noData= true;
              this.showTable=false;
              console.log("error");
              if(error.statusCode==204)
              {
                 this.noData=true;
                 console.log("204");
              }
            }
          )
        }
      }
    }
    else if(this.leaveReport.value.option=="Leave"&&this.leaveReport.value.option!=null)
    {
      console.log("Leave"+this.leaveReport.value.employeeId+" "+this.leaveReport.value.year);
      if((this.leaveReport.value.employeeId!=null||this.leaveReport.value.employeeId!="")&&(this.leaveReport.value.year!=null||this.leaveReport.value.year!=""))
      {
        console.log("Leave Inside Api");
        if(this.employeeId=="All")
        {
          console.log("Leave Inside All Employee");
          this.gs.filterAllBalanceLeavelistByempId(this.orgId,this.leaveReport.value.year).subscribe(
            (d:any) => {
              console.log(d);
              this.loader=false;
              this.noData =false;
              // if(d.length==0)
              // {
              //   this.noData = true;
              //   console.log("NoData");
              // }
              if(d.statusCode==204){
                this.noData = true;
                this.showTable=false;
                console.log("NoData");
              }
              else if(Array.isArray(d))
              {
              this.data = d
              console.log(this.data);
              this.dataSource = new MatTableDataSource<any>(this.data);
              this.dataSource.filterPredicate = (data, filter) => {
                const dataStr = data.employeeNumber + data.employeeName+ data.organizationEmail
                +data.noOfDays;
               return dataStr.trim().toLowerCase().indexOf(filter) != -1;
             }
             this.dataSource.paginator = this.paginator;
            //  this.paginator.pageSize = 5; 
             this.dataSource.sort = this.sort;
             this.list_count = this.data.length;
              if(this.list_count>0)
             {
             this.showTable=true;
              }
            }
            },
            (error) =>
            {
              this.loader=false;
              this.noData= true;
              this.showTable=false;
              console.log("error");
              if(error.statusCode==204)
              {
                 this.noData=true;
                 console.log("204");
              }
            }
            )
        }
        else
        {
          console.log("Leave Inside single employee");
          this.gs.filterBalanceLeavelistByempId(this.employeeId, this.leaveReport.value.year).subscribe(
            (d:any) => {
              console.log(d);
              this.loader=false;
              this.noData =false;
              // if(d.length==0)
              // {
              //   this.noData = true;
              //   console.log("NoData");
              // }
              if(d.statusCode==204){
                this.noData = true;
                this.showTable=false;
                console.log("NoData");
              }
              // this.data = d
              else if (typeof d=== 'object' && !Array.isArray(d && d !== null))
              {
              this.data.push(d);
              console.log(this.data);
              this.dataSource = new MatTableDataSource<any>(this.data);
              this.dataSource.filterPredicate = (data, filter) => {
              //  const dataStr = data.employee.employeeName + data.employee.organizationEmail + data.employee.employeeNumber
              //  +data.employee.mobileNumber + data.permissionDate + data.permissionHours + data.permissionStatusName;
              const dataStr = data.employeeNumber + data.employeeName+ data.organizationEmail
              +data.noOfDays;
               return dataStr.trim().toLowerCase().indexOf(filter) != -1;
             }
             this.dataSource.paginator = this.paginator;
            //  this.paginator.pageSize = 5; 
             this.dataSource.sort = this.sort;
             this.list_count = this.data.length;
             if(this.data[0].statusCode==204)
             {
              this.showTable=false;
              console.log(this.list_count);
             }
            }
            },
            (error) =>
            {
              this.loader=false;
              this.noData= true;
              this.showTable=false;
              console.log("error");
              if(error.statusCode==204)
              {
                 this.noData=true;
                 console.log("204");
              }
            }
          )
        }
      }
    }
    else{
       console.log("Not Match");
    }
  }

  Clear()
  {
    // this.leaveReport.reset();
    // this.data=[];
    // this.list_count = 0;
    this.showTable=false;
    this.submitted = false;
    this.leaveReport.get('employeeId').clearValidators();
    this.leaveReport.get('startdate').clearValidators(); 
    this.leaveReport.get('enddate').clearValidators(); 
    this.leaveReport.get('year').clearValidators(); 
    // this.leaveReport.reset({
    //   option: 'Permission', // Set default values if needed
    // });
  }
}
