<div class="grid-container">
  <!-- <marquee bahaviour="scroll" direvtion="left" *ngIf="leaveBalanceCount">
    <h4>
      Your Leave Balance for the year 2021 -
      <b style="color: red">{{ leaveBalanceCount }}</b
      >...
    </h4>
  </marquee> -->
  <h1
    *ngIf="calendarName"
    style="margin-top: 10px; color: #333; font-weight: 500"
    align="center"
  >
    {{ calendarName }}
  </h1>

  <!-- <button type="button" (click)="createWorkingDays()" mat-raised-button color="primary"> Create WoringDays</button> -->
  <div class="container">
    <div class="row" *ngIf="userType != 'Employee'">
      <div class="col-sm-4">
        <mat-card style="margin-top: 20px">
          <div style="margin: 0 50px 0 40px" >
            <div>
              <form enctype="multipart/form-data" method="post" action=".">
                <p>
                  <label
                    for="myDragElement"
                    class="dragAndUpload"
                    data-post-string="?todo=test"
                  >
                    <span class="dragAndUploadIcon">
                      <i class="dragAndUploadIconNeutral fas fa-arrow-up"></i>
                      <i
                        class="dragAndUploadIconUploading fas fa-cog fa-spin"
                      ></i>
                      <i class="dragAndUploadIconSuccess fas fa-thumbs-up"></i>
                      <i
                        class="dragAndUploadIconFailure fas fa-thumbs-down"
                      ></i>
                    </span>
                    <b class="dragAndUploadText"
                      ><span class="dragAndUploadTextLarge"
                        >Choose File Here...</span
                      >
                    </b>
                    <!-- <i class="dragAndUploadCounter">0%</i> -->
                    <input
                      type="file"
                      multiple="multiple"
                      (change)="onFileChange($event)"
                      class="dragAndUploadManual"
                      name="myDragElement"
                      id="myDragElement"
                    />
                  </label>
                </p>
              </form>
            </div>
            <hr />
            <!-- AddCalendar -->
            <mat-list role="list" >
              <mat-list-item role="listitem" class="content-item">
                <span>Add Calendar</span>
                <span class="material-icons" (click)="createCalendar()"
                  >add</span
                >
              </mat-list-item>
              <mat-list-item role="listitem" *ngFor="let cal of calendarList">
                <span
                  class="content"
                  [style.color]="cal.checked ? '#6699cc' : ''"
                  (click)="changeCalendar(cal)"
                >
                  <span [style.color]="cal.calendarStatus == 2 ? 'red' : ''">{{
                    cal.calendarName
                  }}</span>
                </span>
                <span
                  class="material-icons"
                  [style.color]="cal.checked ? '#6699cc' : ''"
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <span [style.color]="cal.calendarStatus == 2 ? 'red' : ''"
                    >more_vert</span
                  >
                </span>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    (click)="createWorkingDays(cal)"
                    *ngIf="cal.calendarWorkingDays == ''"
                  >
                    <mat-icon>add</mat-icon>
                    <span>Add WorkingDays</span>
                  </button>

                  <button
                    mat-menu-item
                    (click)="updateWorkingDays(cal)"
                    *ngIf="cal.calendarWorkingDays != ''"
                  >
                    <mat-icon>pending_actions</mat-icon>
                    <span>Update WorkingDays</span>
                  </button>

                  <button
                    mat-menu-item
                    (click)="deactivateCalendar(cal)"
                    *ngIf="cal.calendarStatus == 1"
                  >
                    <mat-icon>disabled_by_default</mat-icon>
                    <span>Deactivate</span>
                  </button>

                  <button mat-menu-item (click)="updateCalendar(cal)">
                    <mat-icon>edit</mat-icon>
                    <span>Edit</span>
                  </button>
                </mat-menu>
              </mat-list-item>
            </mat-list>
            <hr />

            <!-- Calendar -->
            <mat-calendar #MatCalendar></mat-calendar>
          </div>
        </mat-card>
      </div>
      <div class="col-sm-8">
        <mat-card style="margin-top: 20px">
          <full-calendar
            #fullcalendar
            *ngIf="calendarVisible"
            [options]="calendarOptions"
            plugins="calendarOptions.plugins"
          ></full-calendar>
        </mat-card>
      </div>
    </div>

    <div class="row" *ngIf="userType == 'Employee'">
      <div class="col-sm-4">
        <mat-card style="margin-top: 20px">
          <!-- Calendar -->
          <mat-calendar></mat-calendar>
        </mat-card>
      </div>
      <div class="col-sm-8">
        <mat-card style="margin-top: 20px">
          <full-calendar
            #fullcalendar
            *ngIf="calendarVisible"
            [options]="calendarOptions"
            plugins="calendarOptions.plugins"
          ></full-calendar>
        </mat-card>
      </div>
    </div>
  </div>
</div>
