import { Component, OnInit,ViewChild } from '@angular/core';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
// import { MatSortModule } from '@angular/material/sort';
// import { MatSort } from '@angular/material/sort';
import { MatSort, Sort} from '@angular/material/sort';
import { MatPaginatorModule} from '@angular/material/paginator';
import { EmployeeTimingsImportComponent } from '../employee-timings-import/employee-timings-import.component';
// import {LiveAnnouncer} from '@angular/cdk/a11y';
// import {AfterViewInit, Component, ViewChild} from '@angular/core';
// import {MatSort, Sort} from '@angular/material/sort';
// import {MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
// import { Sort } from '@angular/material/sort';

export interface listData {
  date: string;
  // position: number;
  // weight: number;
  // symbol: string;
}

@Component({
  selector: 'app-employee-in-out-list',
  templateUrl: './employee-in-out-list.component.html',
  styleUrls: ['./employee-in-out-list.component.css']
})
export class EmployeeInOutListComponent implements OnInit {
  noData=false;
  monthDate:any;
  monthEndDate:any;
  headers=["Date","Action"];
  // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  employee:any;
  listData:any=[];
  startmin:any;
  empListForm:FormGroup;
  sortedData:any=[];
  // start:any;
  // end:any;
  p:number =1;
  todayDate:any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource: any;
  beforesearchpage :any;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  list_count = 0;
  isSubmitted = false;
  isStartDate=false;
  isEndDate=false;
  itempages : any = 5 ;
  dataSearch:any;
  submitted=false;
  enddate_required:boolean = false;
  startdate_required:boolean = false;
  startdate_validation: boolean = false;
   endate :any;
   startdate:any;
  
  constructor(private GlobalserviceService : GlobalserviceService,
    private httpClient:HttpClient,
    private router:Router,
    private fb: FormBuilder,
    private datepipe:DatePipe,
    private dialog:MatDialog
    ) { }

    @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.todayDate =new Date();
    console.log(this.todayDate);
    this.empListForm = this.fb.group({
      startdate:[''],
      enddate:[''],
      month:['']
    })
    
     this.GlobalserviceService.
     Get()
     .subscribe
     ((data:any)=>
     {
       console.log(data);
       console.log(data.date);
       console.log(data.empInOutHeaderId);
       this.employee=data;
       console.log(this.employee);
       console.log("getEmployee Data");
     }
     )
  }
  
  // vaild()
  // {
  //   console.log("vaild");
  //   this.isSubmitted=true;
  //     // if(this.id) 
  //     // {
  //       // if(this.empListForm.invalid) 
  //       // {
  //       //   console.log("form invalid")
  //       //   return
  //       // }
  // }

  date(date){ 
    console.log(date);
    // console.log(startdate);
    console.log(date.target.value);
    let startDate = this.datepipe.transform(date.target.value, 'YYYY-MM-dd');
    console.log(startDate);
    this.startmin=startDate;
    console.log(this.startmin);
    if(date.value!=null ){
      this.submitted = false;
      this.startdate_required =false;
    }
    console.log(this.empListForm.value.enddate);//3
    console.log(date.value);//4
    if(this.empListForm.value.enddate){
      if(date.value<=this.empListForm.value.enddate){
        console.log("endadte");
        this.startdate_validation=false;
      }
      else if(date.value>this.empListForm.value.enddate){
        this.startdate_validation=true;
      }
    }
  }
  datechange(date){
    if(date.value!=null ){
      this.submitted = false;
      this.enddate_required =false;
    } 
    console.log(this.empListForm.value.startdate);
    console.log(date.value);
    if(date.value>=this.empListForm.value.startdate){
      console.log("endadte");
      this.startdate_validation=false;
    }
  }
  
  day(month)
  {
    this.empListForm.patchValue({
      startdate : null,
      enddate :null
    })
    
    if(month.value != 0)
    {
      
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), month.value-1, 1);
   //     console.log(firstDay);
        var lastDay = new Date(date.getFullYear(), month.value, 0);
    //    console.log(lastDay);
        let monthstartDate = this.datepipe.transform(firstDay,'YYYY-MM-dd');
    //    console.log(monthstartDate);
        this.monthDate=monthstartDate;
    //    console.log(this.monthDate);
        let monthLastDate = this.datepipe.transform(lastDay,'YYYY-MM-dd');
   //     console.log(monthLastDate);
        this.monthEndDate=monthLastDate;
    //    console.log(this.monthEndDate);
        this.todayDate = this.monthEndDate;
        this.endate = this.monthEndDate;
        this.startdate = monthstartDate;
    }else
    {
      this.todayDate =new Date();
      this.endate =new Date();
    }
   
    // var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  create()
  {
    console.log("create");
    this.router.navigateByUrl(`empInOut`);
    console.log("redircet create Employee In Out Form");
  }
  createAsImport(){
    console.log("create as import");
    const dialogRef = this.dialog.open(EmployeeTimingsImportComponent,{
      width:'400px',
      height:'fit-content'
    })
  }

  
  edit(headerid)
  {
    console.log(headerid);
    // this.router.navigate('');
    this.router.navigate([`/empInOut/${headerid}`]);
    //  this.router.navigateByUrl(`newclient/${clientId}`);
    //  this.router.navigate[`empInOut/${index}`];
    console.log("edit Sucessfully");
  }
   
  showChanges()
    {
      console.log("show Change");
      this.p=1;
    }
     
    // mat Sort Function
  sortData(sort: Sort) {
    const data = this.listData.slice();
    if (!sort.active || sort.direction === '') {
      this.listData  = data;
      return;
    }

    this.listData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
       case 'date':
          return this.compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
   
  changepage(){
    console.log("Search");
    if(this.dataSearch.length!= 0)
    {
       this.p = 1;
        console.log(this.p);
        console.log("success Data");event
    }
    else{
       this.p = this.beforesearchpage;
       console.log("nodata");
    }
  }

  // change(event)
  // {
  //   let today=new Date();
  //   let year=today.getFullYear();
  //   this.month=event.value;
  //   console.log(this.month);
  //   this.monthyear=year+"-"+this.month; 
  //   console.log(this.monthyear);
  // }
   //search filter in Month and Start and End Date 
  isselected(event){
    console.log(event);
    this.submitted=false;
  }
   search()
   {

    this.listData.length =0;
    console.log(this.empListForm);
    if((this.empListForm.value.month == 0 || this.empListForm.value.month == null)  
      && (this.empListForm.value.startdate== '' || this.empListForm.value.startdate == null)
      && (this.empListForm.value.enddate == '' || this.empListForm.value.enddate == null))
    {
      this.submitted=true;
    }
    else if((this.empListForm.value.month == 0  || this.empListForm.value.month == null) 
      || ((this.empListForm.value.startdate== ''|| this.empListForm.value.startdate == null) 
      && (this.empListForm.value.enddate == ''|| this.empListForm.value.enddate == null)))
    {
      this.submitted=false;
    }

    if(this.empListForm.value.month==0 && this.empListForm.value.startdate ==null && this.empListForm.value.enddate ==null){
      console.log("invalid");
      this.startdate_required =false;
      this.enddate_required = false;
      return
    }


     
    if(this.empListForm.value.month!=0 && this.empListForm.value.startdate!=null && this.empListForm.value.enddate ==null){
      console.log("invalid");
      this.enddate_required = true;
      return
    }

    if(this.empListForm.value.month!=0 && this.empListForm.value.startdate==null && this.empListForm.value.enddate !=null){
      console.log("start invalid");
      this.startdate_required = true;
      return
    }

     let startDate = this.datepipe.transform(this.empListForm.value.startdate, 'YYYY-MM-dd');
    //  this.startmin=startDate;
    //  console.log(this.startmin);
     //  this.startDate=caldate;
      console.log(startDate);
      let endDate = this.datepipe.transform(this.empListForm.value.enddate, 'YYYY-MM-dd');
      console.log(endDate);
      let today=new Date();
      let year=today.getFullYear();
      console.log(this.empListForm.value.month);
      let month=this.empListForm.value.month;
      console.log(month);
      let monthyear=year+"-"+month;
      console.log(monthyear);
      this.isSubmitted = true;
      // let finalmonth=monthyear;
      // console.log(finalmonth);
      if(this.empListForm.value.month!=0 &&(startDate!=null&&endDate!=null))
    {
      console.log("both Month and StartDate and EndDate");
      this.isSubmitted=false;
         this.GlobalserviceService.GetDate(startDate,endDate)
        .subscribe((data: any) =>{
          console.log("call Date Api");
          console.log(data);
          this.noData =false;
          this.listData=data;
          console.log(this.listData);
          //mat Paginator code
          this.dataSource = new MatTableDataSource<any>(this.listData);
          console.log(this.dataSource);
          this.dataSource.sort = this.sort;
          console.log(this.dataSource.sort);
            //  const sortState: Sort = {active:"Date", direction: 'desc'};
            //  this.sort.active = sortState.active;
            //  this.sort.direction = sortState.direction;
            //  this.sort.sortChange.emit(sortState);

          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource.paginator);
            //  this.dataSource.sort = this.sort;
            //  this.dataSource.sort = this.listData.length;
            //  console.log(this.dataSource.sort);
          this.list_count = this.listData.length;
          console.log(this.list_count);
        },
        (error) =>{
          console.log("error");
          if(error.status==404){
            this.list_count =0;
            this.noData=true;
            console.log("404");
          }
        })
       }

      // if(this.empListForm.value.month!=0)
      else if(this.empListForm.value.month!=0)
     {
      this.isSubmitted = false;
      this.isStartDate=false;
      this.isEndDate=false;
       console.log("Month Function");
      //  if(this.empListForm.value.month!=0 && (startDate==null && endDate==null))
      //  {
        console.log("Month Api consume");
        //call Month Api
        this.GlobalserviceService.GetMonth(monthyear).subscribe((data: any) =>
      {
       console.log("call Month Api Data");
       console.log(data);
        this.noData =false;
      //  if(data.length==0)
      // {
      //   this.noData =true;
      //   console.log("NoData");
      // }
       this.listData=data;
      
       //mat Paginator code
       this.dataSource = new MatTableDataSource<any>(this.listData);
      //  dataSource = new MatTableDataSource(ELEMENT_DATA);//sample code
       console.log(this.dataSource);
       this.dataSource.sort = this.sort;
       console.log(this.dataSource.sort);
    
      // const sortState: Sort = {active:"Date", direction: 'desc'};
      // this.sort.active = sortState.active;
      // this.sort.direction = sortState.direction;
      // this.sort.sortChange.emit(sortState);

       this.dataSource.paginator = this.paginator;
       console.log(this.dataSource.paginator);
      //  this.dataSource.sort = this.sort;
      //  console.log(this.dataSource.sort);
       this.list_count = this.listData.length;
       console.log(this.list_count);
     },
     (error) =>
     {
      console.log("error");
      if(error.status==404)
      {
        this.list_count =0;
         this.noData=true;
         console.log("404");
      }
     }
     )
    //  this.listData.length=0;
    //  console.log("no list");
    }
    
    // else
    // {
      //Call Date Api
    // if(this.empListForm.value.month!=0 &&(startDate!=null && endDate!=null))
      // {
       
      // }
      // else{
      //   this.isDate=true;
      //   console.log("start or end Date are InVaild");
      // }
    // }
  // }
  else if(this.empListForm.value.month==0 || this.empListForm.value.month==undefined)
  {
    console.log("StartDate and EndDate Function");
    console.log("start and end Date Vaild");
    this.isStartDate=false;
    this.isEndDate=false;
    if(this.empListForm.value.startdate!= '' || this.empListForm.value.enddate != ''){
      if(this.empListForm.value.startdate== '' || this.empListForm.value.startdate == null){
        this.startdate_required=true;
        this.enddate_required=false;
      }
      else if(this.empListForm.value.enddate == '' || this.empListForm.value.enddate == null){
        this.startdate_required=false;
        this.enddate_required=true;
      }
    }
    if(startDate>endDate){
      console.log("if");
      this.startdate_validation=true;
      return
    }
    else{
      console.log("else");
      this.startdate_validation=false;
    }

    
    if(startDate!=null)
    {
      this.isSubmitted=false;
      if(endDate!=null)
      {
        this.isSubmitted=false;
         this.GlobalserviceService.GetDate(startDate,endDate)
        .subscribe((data: any) =>{
          console.log("call Date Api");
          console.log(data);
          this.noData =false;
          this.listData=data;
          console.log(this.listData);
          //mat Paginator code
          this.dataSource = new MatTableDataSource<any>(this.listData);
          console.log(this.dataSource);
          this.dataSource.sort = this.sort;
          console.log(this.dataSource.sort);
            //  const sortState: Sort = {active:"Date", direction: 'desc'};
            //  this.sort.active = sortState.active;
            //  this.sort.direction = sortState.direction;
            //  this.sort.sortChange.emit(sortState);

          this.dataSource.paginator = this.paginator;
          console.log(this.dataSource.paginator);
            //  this.dataSource.sort = this.sort;
            //  this.dataSource.sort = this.listData.length;
            //  console.log(this.dataSource.sort);
          this.list_count = this.listData.length;
          console.log(this.list_count);
        },
        (error) =>{
          console.log("error");
          if(error.status==404){
            this.list_count =0;
            this.noData=true;
            console.log("404");
          }
        })
      }
      else
      {
        // this.isStartDate=true;
        this.isEndDate=true;
        console.log("EndDate Null");
      }
    }
    else
    {
      // this.isEndDate=false;
      this.isStartDate=true;
      console.log("StartDate Null");
    }
   
  }
  else{
    this.isSubmitted=true;
    this.isStartDate =false;
    console.log("StartDate and EndDate vaildation error");
  }
  }
 
  //  announceSortChange(sortState: Sort) {
  //   if (sortState.direction) {
  //     this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  //   } else {
  //     this._liveAnnouncer.announce('Sorting cleared');
  //   }
  // }

  //  sortData(sort: Sort)
  // {
  //   //const data = this.resultData.slice();
  //   const data = this.dataSource;
  //   console.log(data);
  //   if (!sort.active || sort.direction === '')
  //   {
  //     this.sortedData = data;
  //     console.log("sort Data:"+this.sortedData);
  //   }

  //   this.sortedData = data.sort((a, b) =>
  //   {
  //     console.log(this.sortedData);
  //     const isAsc = sort.direction === 'asc';

  //     switch (sort.active)
  //     {
  //       case 'date' : 
  //         return this.compare(a.date, b.date, isAsc);

  //       // case 'roleDescription':
  //       //   return compare(a.sortedData, b.sortedData, isAsc);
  //         default:
  //         return 0;
  //     }
  //   });
  // }
 
  //      compare(a: number | string, b: number | string, isAsc: boolean)
  //     {
  //       return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  //     }   
   

 }


























//   search()
//   {
//     // this.isSubmitted = true;
//     // if(this.empListForm.invalid) 
//     //     {
//     //       console.log("form data invalid")
//     //       return
//     //     }
//     //Date Filter in List
//     console.log(this.empListForm.value.startdate);
//     let startDate = this.datepipe.transform(this.empListForm.value.startdate, 'YYYY-MM-dd');
//     //  this.startDate=caldate;
//      console.log(startDate);
//      let endDate = this.datepipe.transform(this.empListForm.value.enddate, 'YYYY-MM-dd');
//      console.log(endDate);
//      //Date Api Consume
//      if(startDate!=null && endDate!=null)
//      {
//      if(startDate!=null &&endDate!=null)
//      {
//      this.GlobalserviceService.GetDate(startDate,endDate).subscribe((data: any) =>
//      {
//        console.log("call Date Api");
//        console.log(data);
//        this.listData=data;
//        console.log(this.listData);
//        //mat Paginator code
//        this.dataSource = new MatTableDataSource<any>(this.listData);
//        console.log(this.dataSource);
//        this.dataSource.paginator = this.paginator;
//        console.log(this.dataSource.paginator);
//        this.dataSource.sort = this.sort;
//        console.log(this.dataSource.sort);
//        this.list_count = this.listData.length;
//        console.log(this.list_count);

//       //  this.chooseMonth=data;
//       //  console.log(this.chooseMonth);
//       //  for(let d of data)
//       //  {
//       //    console.log(d);
//       //    console.log(d.date);
         
//       //  }
//      }
//      )

//     }
//     else
//     {
//      let today=new Date();
//     let year=today.getFullYear();
//     console.log(this.empListForm.value.month);
//     let month=this.empListForm.value.month;
//     console.log(month);
//     let monthyear=year+"-"+month;
//     console.log(monthyear);
//     console.log("filter");
//     // let today=new Date();
//     // let year=today.getFullYear();
//     // let month=event.value;
//     // console.log(month);
//     // let monthyear=year+"-"+month; 
//     // console.log(monthyear);
//     //Call Month Api
//     this.GlobalserviceService.GetMonth(monthyear).subscribe((data: any) =>
//     {
     
//       console.log("call Month Api");
//       console.log(data);
//       this.listData=data;
//       console.log(this.listData);
//       // for(let m of data)
//       // {
//       //   console.log(m);
//       //   console.log(m.date);
//       // }
//       //mat Paginator code
//       this.dataSource = new MatTableDataSource<any>(this.listData);
//       console.log(this.dataSource);
//       this.dataSource.paginator = this.paginator;
//       console.log(this.dataSource.paginator);
//       this.dataSource.sort = this.sort;
//       console.log(this.dataSource.sort);
//       this.list_count = this.listData.length;
//       console.log(this.list_count);
//     }
//     )
//      }
     
//   }

// }

