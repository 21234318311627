<form [formGroup]="timesettingsForm" (ngSubmit)="save()">
    <div class="d-flex mb-3" >
        <div class="title-block">
            <!-- <mat-card class="header"> -->
                <!-- <mat-icon class="material-icons-outlined logo">schedule</mat-icon> -->
                <div class="title">Enter Record Timing</div>
            <!-- </mat-card> -->
            <!-- <h2 class="title">Create Employee Timings</h2> -->
        </div>
        <div class="concel-block">
            <mat-icon class="material-icons cancel-icon" mat-dialog-close>close</mat-icon>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" id="loginform">
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Max Permission Hours<span class="text-danger"> *</span></mat-label> 
                <input matInput formControlName="maxtime" placeholder="Enter maximum permission hours" maxlength="1">
                <mat-error *ngIf="(isSubmitted && timesettingsForm.controls['maxtime'].invalid) || timesettingsForm.controls['maxtime'].dirty || timesettingsForm.controls['maxtime'].touched">
                    <small *ngIf="timesettingsForm.controls['maxtime'].errors?.required"  id="req" style="color:red;font-size:12px;font-family: 'Poppins', sans-serif;">Max time required</small>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row float-right">
        <!-- <div class="col-md-12 pt-1" id="loginform">
            <button mat-stroked-button color="primary" class="form-button" type="submit">Update</button>
        </div> -->
        <div style="margin: 0 15px;">
            <button mat-stroked-button class="apply-button" color="primary" type="submit">
                Update
            </button>
          </div>
    </div>
</form>



