import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ErrorStateMatcher } from '@angular/material/core';
import { County } from '../country';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-business-apply',
  templateUrl: './business-apply.component.html',
  styleUrls: ['./business-apply.component.css']
})
export class BusinessApplyComponent implements OnInit {
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  @Output() dateInput: EventEmitter<MatDatepickerInputEvent<any>>;

  matcher = new MyErrorStateMatcher();
  noOFDays: any = 0
  WorkingDays: any;
  endDate_disable: boolean;
  min: any;
  startMin: any;
  isLoading: Boolean;
  myFilter: any;
  businessGroup: FormGroup;
  first_approver: string;
  first_approver_id;
  second_approver: string;
  second_approver_id
  traveModeList: any = [];
  countryList: any = County;
  secondError: boolean;
  arrayData: any = []
  branchId: any
  workDay: any = []
  From_Date:any;
  End_Date:any;
  halfDay: boolean;
  weekDay: boolean;
  show_error_msg: boolean=false;
  constructor(
    private fb: FormBuilder,
    private gs: GlobalserviceService,
    private router: Router,
    private datepipe:DatePipe,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.endDate_disable = true;
    // this.startMin = new Date();
    this.businessGroup = this.fb.group({
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      reason: ['', Validators.required],
      businessTravelStatus: ['', Validators.required],
      // applyTo: new FormControl(''),
      // cc: new FormControl(''),
      empl: this.fb.group({
        employeeId: [''],
        employeeName: ['']
      }),
      category: this.fb.group({
        categoryId: ['']
      }),
      businessTravelDetail: this.fb.array([this.travelGroup()], Validators.required)
    });
    this.min = new Date(this.f.startDate.value);

    // defaults
    this.f.businessTravelStatus.setValue(3);
    this.businessGroup.patchValue({
      category: {
        categoryId: 2
      }
    })

    //  empdata
    let x = JSON.parse(localStorage.getItem('data'));
    let id = x.employeeId
    this.gs.getEmpListById(x.employeeId).subscribe(
      (d:any)=>{
        this.branchId = d.branch.branchId
      }
    )
    this.gs.getEmpListById(id).subscribe((d: any) => {
      this.WorkingDays = d.noOfWorkingDays;
      this.gs.getEmpListById(d.reportingTo).subscribe(
        (e: any) => {
          this.first_approver = e.organizationEmail;
          this.first_approver_id = e.employeeId;
        }
      )
      this.secondError = true
      if (d.userType.userType != 'Employee') {
        if (d.reportingToSecond) {
          this.secondError = true
        }
        else {
          this.secondError = false
        }
      }
      this.gs.getEmpListById(d.reportingToSecond).subscribe(
        (e: any) => {
          this.second_approver = e.organizationEmail;
          this.second_approver_id = e.employeeId
        }
      )
      this.businessGroup.patchValue({
        empl: {
          employeeId: d.employeeId,
          employeeName: d.employeeName
        }
      })

      if (this.branchId) {
        this.gs.getCalByBranch(this.branchId).subscribe(
          (dato: any) => {
            dato.map(
              log => {
                this.gs.getCalendarById(log.calendarId).subscribe(
                  (i: any) => {
                    var exception
                    let data
                    i.exceptions.map(
                      b => {
                        if (b.exceptionStatus == 1) {
                          if(b.exceptionType != '41'){
                            exception = b.exceptionDate
                            data = new Date(b.exceptionDate)
                            data.setDate(data.getDate());
                            this.arrayData.push(data)
                          }
                        }

                      }
                    )

                    i.holiday.map(a => {
                      if (a.holidayStatus == 1) {
                        data = new Date(a.holidayDate)
                        data.setDate(data.getDate());
                        this.arrayData.push(data)
                      }
                    })

                    let b = ["1", "2", "3", "4", "5", "6", "0"]
                    i.calendarWorkingDays.map(dot => {
                      var difference: any = b.filter(x => dot.workingdays.indexOf(x) === -1);
                      this.workDay = difference
                      this.myFilter = (d: Date): boolean => {
                        const day = new Date(d).getDay();
                        const time = new Date(d).getTime()
                        return day !== Number(difference[0]) && day !== Number(difference[1])
                          && day !== Number(difference[2]) && day !== Number(difference[3])
                          && day !== Number(difference[4]) && day !== Number(difference[5])
                          && day !== Number(difference[6]) && !this.arrayData.find(x => new Date(x).getTime() == time)
                      }
                    })

                  }
                )
              }
            )

          }
        )

      }
    })
    // get travel mode
    this.gs.getTravel_Mode().subscribe(a => {
      this.traveModeList = a
    })
  }
  Cancel(){
    this.location.back();
  }
  get f() {
    return this.businessGroup.controls
  }

  // ++++++++++++++++++++++++++ business travel group +++++++++++++++++++++++++++++++++//
  private travelGroup(): FormGroup {
    return this.fb.group({
      fromLocation: ['', [Validators.required]],
      toLocation: ['', [Validators.required]],
      country: ['', [Validators.required]],
      preferredTime: ['', [Validators.required]],
      travelMode: this.fb.group({
        travelModeId: ['', [Validators.required]]
      })
    });
  }

  get travelControl() {
    return this.businessGroup.get('businessTravelDetail') as FormArray
  }
  addLocation() {
    this.travelControl.push(this.travelGroup())
  }
  remove_location(i) {
    Swal.fire({
      title: 'Are you sure? Do you want to Remove!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Remove'
    }).then((result) => {
      if (result.value) {
        (this.businessGroup.get('businessTravelDetail') as FormArray).removeAt(i);

        Swal.fire(
          "Removed"
        )
      }
    })
  }

  // keys
  omit_special_char(event) {
    let k;
    let n;
    n = event.keyCode;
    k = event.charCode;
    return ((n > 'A' && n < 'z') || (k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57) || (k === 1));
  }
  keyPress(event: any) {
    const pattern = /^[a-zA-Z ]*$/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  // ++++++++++++++++++++++++++ business travel group +++++++++++++++++++++++++++++++++//*
  applyWFH() {
    let x = this.businessGroup.value
    // if(this.businessGroup.invalid) {
    //   return;
    // }

    x['noOfDays'] = this.noOFDays
    if (this.first_approver == undefined) {
      x['applyTo'] = [];
    }
    else {
      x['applyTo'] = [this.first_approver];
    }
    if (this.second_approver == undefined) {
      x['cc'] = [];
    }
    else {
      x['cc'] = [this.second_approver];
    }


    x['businesstravelreportingTo'] = this.first_approver;
    x['businesstravelreportingToSecond'] = this.second_approver;
    let a = this.f.startDate.value;

    // startDate
    if (x) {
      let b = new Date(a);
      b.setMinutes(b.getMinutes() + 480);
      let s = b.toISOString()
      x['startDate'] = s
    }
    // endDate
    let c = this.f.endDate.value;
    if (c) {
      let d = new Date(c);
      d.setMinutes(d.getMinutes() + 480);
      let e = d.toISOString();
      x['endDate'] = e;
    }
    console.log(JSON.stringify(x));
    this.savedata(x)

  }

  private savedata(x) {
    Swal.fire({
      title: 'Are you sure? Do you want to Apply!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3f51b5',
      cancelButtonColor: '#f44336',
      confirmButtonText: 'Apply'
    }).then((result) => {
      if (result.value) {
        this.isLoading = true;
        this.gs.Apply_Business_Travel_Request(x).subscribe(
          d => {
            Swal.fire({
              text: 'Applied',
              icon: 'success'
            }
            );
            this.isLoading = false;
            this.router.navigate(['/myBusiness-List'])
          },
          err => {
            Swal.fire(
              {
                text: err.error.message,
                icon: 'error'
              }
            )
            this.isLoading = false
          },
          () => {
            this.isLoading = false
          }
        )
      }
    })
  }

  someMethodName(date: any) {
    this.min = new Date(date.value)
    this.endDate_disable = false

    let w = this.datepipe.transform(this.f.endDate.value, 'YYYY-MM-dd');
    let v = this.datepipe.transform(this.min, 'YYYY-MM-dd');
    if(w != null){
      if(w < v){
        this.show_error_msg = true;
      }
    }

  }


  // number of days
  nofoDays(i) {
    if (this.f.startDate.value>this.f.endDate.value) {
      console.log("date In Vaild");
      return
    }
    
    console.log("No of days in Business Travel");
    let start = new Date(this.f.startDate.value);
    let end = new Date(this.f.endDate.value);
    console.log(start);
    console.log(end);

    let v = this.datepipe.transform(start, 'YYYY-MM-dd');
    let w = this.datepipe.transform(end, 'YYYY-MM-dd');
    console.log(v);
    console.log(w);
    this.From_Date=v;
     this.End_Date=w;

     if (v != w) {
      this.weekDay = true;
      this.halfDay = false;
    }
    if (v == w) {
      this.halfDay = true;
      this.weekDay = false;
    }
    // Copy date objects so don't modify originals
    var s: any = new Date(+start);
    var e: any = new Date(+end);
    console.log(s);
    console.log(e);
    this.From_Date=s;
    // this.End_Date=e;
    // Set time to midday to avoid dalight saving and browser quirks
    s.setHours(12, 0, 0, 0);
    e.setHours(12, 0, 0, 0);

    // Get the difference in whole days
    // var totalDays = Math.round((e - s) / 8.64e7);
    if(e!=0&&s!=0)
    {
      console.log("e to s");
      var totalDays = Math.round((e - s) / 8.64e7);
      console.log(totalDays);
    }
    else{
      console.log("s to e");
      var totalDays = Math.round((s - e) / 8.64e7);
      console.log(totalDays);
    }

    // Get the difference in whole weeks
    var wholeWeeks = totalDays / 7 | 0;

    // Estimate business days as number of whole weeks * 5
    // Estimate business days as number of whole weeks * 5
    var days = 0
    days = wholeWeeks * this.WorkingDays + 1;
    // console.log(days)
    let dateArray = new Array()
    let result = new Array()
    while (start <= end) {
      dateArray.push(new Date(start));
      start.setDate(start.getDate() + 1);
    }
    dateArray.map(
      c => {
        if (this.arrayData) {
          this.arrayData.map(
            t => {
              let MyDate = new Date(c)
              let MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2)

              let MyDate1 = new Date(t)
              let MyDateString1 = MyDate1.getFullYear() + '-' + ('0' + (MyDate1.getMonth() + 1)).slice(-2) + '-' + ('0' + MyDate1.getDate()).slice(-2)
              if (MyDateString1 == MyDateString) {
                result.push(t)
              }
            }
          )
        }
      }
    )

    // If not even number of weeks, calc remaining weekend days

    s.setDate(s.getDate() + wholeWeeks * 7);
    let dat = new Array()

    dateArray.map(
      e => {
        if (e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
          && e.getDay() != this.workDay[3] && e.getDay() != this.workDay[4] && e.getDay() != this.workDay[5]
          && e.getDay() != this.workDay[6]) {
          dat.push(e)
        }
        //  if (this.WorkingDays == 5){
        //    if (e.getDay() != 0 && e.getDay() != 6) {
        //      dat.push(e)
        //    }
        //  }
        //  else{
        //    if (e.getDay() != 0 && e.getDay() != 7) {
        //      dat.push(e)
        //    }
        //  }

      }
    )

    let uniqueArray = result
      .map(function (date) { return date.getTime() })
      .filter(function (date, i, array) {
        return array.indexOf(date) === i;
      })
      .map(function (time) { return new Date(time); });

    let uniqueArray1 = new Array()
    uniqueArray.map(
      e => {
        if (e.getDay() != this.workDay[0] && e.getDay() != this.workDay[1] && e.getDay() != this.workDay[2]
          && e.getDay() != this.workDay[3] && e.getDay() != this.workDay[4] && e.getDay() != this.workDay[5]
          && e.getDay() != this.workDay[6]) {
          uniqueArray1.push(e)
        }
        // if (this.WorkingDays == 5){
        //   if (e.getDay() != 0 && e.getDay() != 6) {
        //     uniqueArray1.push(e)
        //   }
        // }
        // else{
        //   if (e.getDay() != 0 && e.getDay() != 7) {
        //     uniqueArray1.push(e)
        //   }
        // }
      }
    )

    if (uniqueArray1.length > dat.length) {
      days = uniqueArray1.length - dat.length
    }
    if (dat.length > uniqueArray1.length) {
      days = dat.length - uniqueArray1.length
    }

    this.noOFDays = days;
    return days;
  }
}
