<div class="container">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Task Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery </th>
      <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef mat-sort-header ></th>
      <td mat-cell *matCellDef="let element" [matMenuTriggerFor]="menu">
        <button mat-icon-button type="submit" ><mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <ng-container>
            <a mat-menu-item>
              <mat-icon>check_box</mat-icon>
              <span>Approve</span>
            </a>
          </ng-container>
          <ng-container>
            <a mat-menu-item>
              <mat-icon>cancel</mat-icon>
              <span>Denied</span>
            </a>
          </ng-container>
          <ng-container>
            <a mat-menu-item (click)="openDialog(element.name)">
              <mat-icon>visibility</mat-icon>
                <span>View description</span>
            </a>
          </ng-container>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>


