<div class="col-sm-8">
  <mat-card>
    <mat-card class="header">
      <p> <span class="material-icons">
          settings
        </span>Account Settings </p>
    </mat-card>

    <div class="container">
      <form action="" [formGroup]="accountForm" (ngSubmit)="save(accountForm.value)">
        <div class="form-group">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Current Password</mat-label>
            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <input matInput [type]="hide ? 'password' : 'text'" placeholder="enter current password!!" id="current"
              formControlName="oldPassword"
              [class.is-invalid]="accountForm.get('oldPassword').invalid&&accountForm.get('oldPassword').touched"
              [ngClass]="{ 'is-invalid': submitted && f.oldPassword.errors }" />
            <div *ngIf="submitted && f.oldPassword.errors" class="invalid-feedback">
              <div *ngIf="f.oldPassword.errors.required">currentPassword is required</div>
            </div>
          </mat-form-field>
          <span class="text-danger"
            *ngIf="accountForm.controls['oldPassword'].hasError('required') && (accountForm.controls['oldPassword'].dirty ||accountForm.controls['oldPassword'].touched)">Please
            enter the Current Password</span>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>New Password</mat-label>
            <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide1">
              <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <input id="newPassword" [type]="hide1 ? 'password' : 'text'" matInput placeholder="enter newPassword"
              formControlName="newPassword"
              [class.is-invalid]="accountForm.get('newPassword').invalid&&accountForm.get('newPassword').touched"
              [ngClass]="{ 'is-invalid': submitted && f.newPassword.errors }" />
          </mat-form-field>
          <span class="text-danger"
            *ngIf="accountForm.controls['newPassword'].hasError('required') && (accountForm.controls['newPassword'].dirty ||accountForm.controls['newPassword'].touched)">Please
            enter the New Password</span>
          <span class="text-danger" *ngIf="accountForm.controls['newPassword'].hasError('pattern') &&
                    (accountForm.controls['newPassword'].dirty ||accountForm.controls['newPassword'].touched)">Please
            enter password atleast one special character,number,Capital and four small letters </span>
        </div>

        <div class="form-group">
          <mat-form-field appearance="outline" class="example-full-width">
            <mat-label>Confirm Password</mat-label>
            <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide2">
              <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <input [type]="hide2 ? 'password' : 'text'" matInput placeholder="enter confirmPassword"
              id="confirmPassword" formControlName="confirmPassword" [class.is-inValid]="accountForm.errors?.misMatch"
              [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
          </mat-form-field>
          <span class="text-danger"
            *ngIf="accountForm.controls['confirmPassword'].hasError('required') && (accountForm.controls['confirmPassword'].dirty || accountForm.controls['confirmPassword'].touched)">Confirm
            Password
            is required</span>
          <span class="text-danger"
            *ngIf="accountForm.controls['confirmPassword'].hasError('mustMatch') && (accountForm.controls['confirmPassword'].dirty ||accountForm.controls['confirmPassword'].touched)">Must
            match the new password</span>
        </div>

        <div class="form-group">
          <div class="form-group" align="center">
            <button mat-stroked-button color="accent" style="width: 45%; margin: 0 0 0 8px;" type="button"
              [routerLink]="['../../dashboard']">Cancel</button>
            <button mat-stroked-button color="primary" style="width: 45%;margin: 0 0 0 8px;" type="submit">
              <span class="material-icons">
                beenhere
              </span> Save
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-card>
</div>
