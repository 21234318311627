import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { GlobalserviceService } from 'src/app/providers/globalservice.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-type-list',
  templateUrl: './type-list.component.html',
  styleUrls: ['./type-list.component.css']
})
export class TypeListComponent implements OnInit {
  list_count = 0;
  noData=false;
  data: any[] = []
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns =
    ['sno','leaveName', 'noOfDays', "status", 'star',];
  dataSource: any;
  //  dataSource = ELEMENT_DATA;
  searchModel: string;
  loader=true;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private gs: GlobalserviceService, private router: Router) {
  }
  ngOnInit() {
    console.log("loader");
    this.gs.getLeaveType().subscribe((d: any) => {
      console.log("Not loader");
      this.loader=false;

      if(d.length==0)
      {
        this.noData =true;
        console.log("NoData");
      }
      d.forEach(
        e => {
          if (e.status == 1) {
            e.status = 'Active'
          }
          if (e.status == 2) {
            e.status = 'In-Active'
          }
        }
      )
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.list_count = this.data.length
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  // status color
  statuscolor(i:any) {
    if (i == null) {
      return
    }
    if (i == 'Active') {
      return '#28a745'
    }
    if (i == 'In-Active') {
      return '#f44336'
    }
  }
  edit(i) {
    this.router.navigate(['Home/type-edit', i.leaveTypeId])
  }
  inactive(i) {
    console.log(i);
    console.log(i.leaveTypeId);
        // this.gs.deleteLeaveType(i.leaveTypeId).subscribe(
        //   (d:any) => {
        //     console.log(d)
        //     Swal.fire({
        //       text:d.description,
        //       icon:'success'
        //     })
        //     this.gs.getLeaveType().subscribe((d: any) => {
        //       this.data = d
        //       this.dataSource = new MatTableDataSource<any>(this.data);
        //       this.dataSource.paginator = this.paginator;
        //       this.dataSource.sort = this.sort;
        //       if (this.list_count < 1) {
        //         window.location.reload()
        //       }
        //     })
        //   },
        //   e => {
        //     Swal.fire({
        //       text:e.error.message,
        //       icon:'error'
        //     })
        //   },
        //   () => console.log('done')
        // )
        if(i.status=='Active'){
          Swal.fire({
            title: 'Are you sure? Do you want to In-Activate',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3f51b5',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'In-Active'
          }).then((result) => {
            if (result.value) {
              this.gs.inactiveLeaveType(i.leaveTypeId,2).subscribe(d => {
                // Swal.fire(
                //   'done!',
                // )
                // this.data.splice(i, 1)
                // this.dataSource = new MatTableDataSource<any>(this.data);
                this.fetchdata();
              },
                () => {
                  setTimeout(
                    e => {
                      this.data
                    }, 50
                  )
                }
              );
            }
          })
        }
        else if (i.status=='In-Active'){
          Swal.fire({
            title: 'Are you sure? Do you want to Activate',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3f51b5',
            cancelButtonColor: '#f44336',
            confirmButtonText: 'Active'
          }).then((result) => {
            if (result.value) {
              this.gs.inactiveLeaveType(i.leaveTypeId,1).subscribe(d => {
                // Swal.fire(
                //   'done!',
                // )
                // this.data.splice(i, 1)
                //this.dataSource = new MatTableDataSource<any>(this.data);
                this.fetchdata();
              },
                () => {
                  setTimeout(
                    e => {
                      this.data
                    }, 50
                  )
                }
              );
            }
          })
        }
  }

  fetchdata()
  {
    console.log("Overall listreload");
    this.gs.getLeaveType().subscribe((d: any) => {

      console.log("Not loader");
      this.loader=false;

      if(d.length==0)
      {
        this.noData =true;
        console.log("NoData");
      }
      d.forEach(
        e => {
          if (e.status == 1) {
            e.status = 'Active'
          }
          if (e.status == 2) {
            e.status = 'In-Active'
          }
        }
      )
      this.data = d
      this.dataSource = new MatTableDataSource<any>(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.list_count = this.data.length
    },
    (error) =>
    {
      this.loader=false;
      this.noData= true;
      console.log("error");
      if(error.statusCode==204)
      {
         this.noData=true;
         console.log("204");
      }
    }
    )
  }

}
