<style>
  .full-width{
    width:100%;
  }
</style>
<div class="container">
  <div class="form-group">
    <form [formGroup]="exceptionForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field class="full-width">
          <mat-label>Exception Name</mat-label>
          <input matInput formControlName="exceptionName" />
          <mat-error *ngIf="f.exceptionName.hasError('required')">
            Please enter <strong>Exception Name</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field class="full-width">
          <mat-label>Date</mat-label>
          <input
            matInput
            (click)="picker.open()"
            formControlName="exceptionDate"
            [matDatepicker]="picker"
            placeholder="select date"
            [disabled] = "dateDisable"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker touchUi></mat-datepicker>
          <mat-error *ngIf=" f.exceptionDate.hasError('required')">
            Please select <strong>Exception Date</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group" *ngIf="data.id">
        <mat-form-field class="full-width">
          <mat-label>Exception Status</mat-label>
          <mat-select formControlName="exceptionStatus">
            <mat-option value="1">
              Active
            </mat-option>
            <mat-option value="2">
              InActive
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.exceptionStatus.hasError('required')">
            <span>Exception Status is required</span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group">
        <button mat-stroked-button color="accent" style="margin: 0 0 0 5px" type="button"
        mat-dialog-close>
          Cancel
        </button>
        <button mat-stroked-button color="primary" style="margin: 0 0 0 5px" type="submit">
          <span class="material-icons"> beenhere </span>
          {{data.id ? 'Update' :'Create'}}
        </button>

      </div>

    </form>
  </div>
</div>
